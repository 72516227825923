/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
import { AddressTypes, AdpIntegrationStatuses, AgeBandSizes, AssignableReportOptions, AutoEnrollmentStatus, BinderStatuses, CategoryIds, ChangeTypes, ContributionFrequency, EnrollmentStatuses, EnrollmentVerificationStatuses, EnrollmentVerificationTypes, EntityType, ExpenseInputTypes, ExpenseStatus, FamilyMemberTypes, FinchIntegrationStatuses, Flows, GroupLevels, HealthCareTypeIds, HealthInsuranceParts, HouseholdEligibilityStatus, HouseholdMemberTypes, HrsIntegrationProviders, HsaEligibility, IchraOfferLevels, IneligibleToKeepReasons, LineFourteenCodes, LineSixteenCodes, MajorMedicalWaitingPeriodIds, MedicaidApplicationStatuses, MedicaidRenewalStatuses, MedicalReimbursementTypes, MonthlyFeeTypes, OfferLevels, OtherBenefits, OtherCoverageEligibilities, PartnerTeams, PathwayDecisions, PathwayInputTypes, PathwayTypes, PathwayUserTags, PaylocityIntegrationStatuses, PayrollReportChangeDiffType, PayTypeIds, PlanListing, PlanStateIds, PlanTypeIds, ProviderSource, ReimbursementDisplayLevels, ReimbursementReportFrequencies, RelationshipTypeIds, RenewalDecisions, RhManagementTypes, SearchResultType, SelectedPlanComparisonTypes, ShoppingConfigurationIds, SortOrder, SourceTypes, SurveyTypes, TaskGroups, TaskItems, Tasks, TaskStatuses, TaxFilingStatuses, TeamBenefitTypes, TeamStateIds, TeamStrategies, TerminationDateDelays, TopLineReimbursementStrategies, UserInfoStatus, UserRelationshipTypeIds, UserStatus, UserTeamBenefitTermDetailStatuses, YearlyUserInfoTypes } from './enums';
export interface IUser {
    userId: string;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    memberVerifiedInfos?: MemberVerifiedInfo[] | undefined;
    householdMembers?: HouseholdMember[] | undefined;
    userRoles?: UserRole[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    userAddresses?: UserAddress[] | undefined;
    enrollmentVerifications?: EnrollmentVerification[] | undefined;
    submittedExpenses?: SubmittedExpense[] | undefined;
    loginInfo?: LoginInfo | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    teamId: string;
    team?: Team | undefined;
    displayName?: string | undefined;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    phone?: string | undefined;
    status?: UserStatus | undefined;
    activeDate?: string | undefined;
    activeWageUps?: ActiveWageUp[] | undefined;
    taxData?: TaxData[] | undefined;
    userInfoStatus: UserInfoStatus;
    memberId?: string | undefined;
    userUserRelationships?: UserUserRelationship[] | undefined;
    profilePictureRhFileId?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    terminationDate?: string | undefined;
    inactiveDate?: string | undefined;
    hasHsa?: boolean | undefined;
    surveyLastSentDate?: string | undefined;
    surveyLastSentBy?: string | undefined;
    surveyLastSentByUser?: User | undefined;
    inviteLastSentDate?: string | undefined;
    inviteLastSentBy?: string | undefined;
    inviteLastSentByUser?: User | undefined;
    isVip?: boolean | undefined;
    wagePlusShoppingConfigurationOverride?: ShoppingConfigurationIds | undefined;
    ichraShoppingConfigurationOverride?: ShoppingConfigurationIds | undefined;
    hireDate?: string | undefined;
    dateOfBirth?: string | undefined;
    payrollSystemId?: string | undefined;
    statusLastModifiedDate?: string | undefined;
    statusLastModifiedBy?: string | undefined;
    statusLastModifiedByUser?: User | undefined;
    yearlyInfo?: YearlyUserInfo[] | undefined;
    originalStartDate?: string | undefined;
    needsFollowUp?: boolean | undefined;
    followUpNote?: string | undefined;
    followUpLastModifiedDate?: string | undefined;
    followUpLastModifiedBy?: string | undefined;
    followUpLastModifiedByUser?: User | undefined;
    previousGroupLevelId: GroupLevels;
    isAdpSetWithholdingAndWageUp: boolean;
    surveyTypeToSend: SurveyTypes;
    hasPaymentsSetup: boolean;
    isHrsAssociateIdSet: boolean;
    keyId: string;
    preferredNameWithFallback?: string | undefined;
    affectsPathwayWageUpPaths?: string[] | undefined;
}
export interface IUserRoleStatus extends IUser {
    roleIds?: string[] | undefined;
}
export interface IMemberQuoteInfo {
    userId: string;
    user?: User | undefined;
    spouseDateOfBirth?: string | undefined;
    employeeAnnualIncome: number;
    previousEmployeePremium: number;
    previousEmployerPremium: number;
    previousMonthlyPremium: number;
    previousRenewalEmployeePremium?: number | undefined;
    previousRenewalEmployerPremium?: number | undefined;
    previousRenewalMonthlyPremium?: number | undefined;
    dependentsOver20: number;
    dependentsUnder21: number;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    affectsPathwayWageUpPaths?: string[] | undefined;
}
export interface IMemberVerifiedInfo {
    memberVerifiedInfoId: string;
    userId: string;
    occupation?: string | undefined;
    gender?: string | undefined;
    usesTobacco: boolean;
    hasUnclaimedHouseholdMember?: boolean | undefined;
    isPregnant?: boolean | undefined;
    otherCoverageEligibilities: OtherCoverageEligibilities;
    hasInterestInFaithBasedSharingOptions?: boolean | undefined;
    hasHouseholdPreExistingConditions: boolean;
    householdPreExistingConditions?: Note | undefined;
    preferredProviders?: Provider[] | undefined;
    drugs?: Drug[] | undefined;
    employeeIncome: number;
    deductions?: number | undefined;
    additionalIncome?: number | undefined;
    totalEmployeeIncome: number;
    filingStatusId: TaxFilingStatuses;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    socialSecurityNumber?: string | undefined;
    year: number;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    incomeVerifiedDate?: string | undefined;
    lastSurveySubmittedDate?: string | undefined;
    lastSurveySubmittedBy?: string | undefined;
    surveyTypeCompleted: SurveyTypes;
    keyId: string;
    affectsPathwayWageUpPaths?: string[] | undefined;
}
export interface INote {
    noteId: string;
    title?: string | undefined;
    noteText?: string | undefined;
    createdDate: string;
    createdBy: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    isArchived: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    teamId: string;
    userId: string;
    keyId: string;
}
export interface IProvider {
    providerId: string;
    entityId?: string | undefined;
    nationalProviderId?: string | undefined;
    name?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    distance: number;
    gender?: string | undefined;
    taxonomy?: string | undefined;
    type?: string | undefined;
    specialties?: string[] | undefined;
    entityIds?: string[] | undefined;
    phoneNumber?: string | undefined;
    source: ProviderSource;
}
export interface IDrug {
    drugId: string;
    entityId?: string | undefined;
    rxcui?: string | undefined;
    name?: string | undefined;
    strength?: string | undefined;
    route?: string | undefined;
    fullName?: string | undefined;
    entityIds?: string[] | undefined;
}
export interface IHouseholdMember {
    householdMemberId: string;
    userId: string;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    dateOfBirth: string;
    gender?: string | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    socialSecurityNumber?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMemberAddresses?: HouseholdMemberAddress[] | undefined;
    householdMemberYearInfo?: HouseholdMemberYearInfo[] | undefined;
    householdMemberProviders?: HouseholdMemberProvider[] | undefined;
    householdMemberDrugs?: HouseholdMemberDrug[] | undefined;
    keyId: string;
}
export interface IHouseholdMemberAddress {
    id: string;
    householdMemberId: string;
    addressId: string;
    year: number;
    typeId: AddressTypes;
    address?: Address | undefined;
}
export interface IAddress {
    addressId: string;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    county?: string | undefined;
    countyFips?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMemberAddresses?: HouseholdMemberAddress[] | undefined;
    keyId: string;
}
export interface IHouseholdMemberYearInfo {
    id: string;
    householdMemberId: string;
    year: number;
    householdMemberTypeId: HouseholdMemberTypes;
    income?: number | undefined;
    usesTobacco: boolean;
    isPregnant?: boolean | undefined;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    isEmployed?: boolean | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageEligibilities: OtherCoverageEligibilities;
    otherCoverageSource?: string | undefined;
    employerName?: string | undefined;
    needsCoverage: boolean;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
}
export interface IHouseholdMemberProvider {
    householdMemberProviderId: string;
    householdMemberId: string;
    providerId: string;
    year: number;
    householdMember?: HouseholdMember | undefined;
    provider?: Provider | undefined;
}
export interface IHouseholdMemberDrug {
    householdMemberDrugId: string;
    householdMemberId: string;
    drugId: string;
    year: number;
    householdMember?: HouseholdMember | undefined;
    drug?: Drug | undefined;
}
export interface IUserRole {
    userId: string;
    roleId: string;
    createdDate: string;
    createdBy: string;
}
export interface ISelectedPlan {
    selectedPlanId: string;
    userId: string;
    planStateId: PlanStateIds;
    planTypeId: PlanTypeIds;
    isMedicaidChip: boolean;
    planId?: string | undefined;
    healthCareTypeId: HealthCareTypeIds;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;
    year: number;
    isEnrolled: boolean;
    enrollmentDate?: string | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    isHsaEligible?: boolean | undefined;
    comment?: string | undefined;
    isCustom: boolean;
    networkType?: string | undefined;
    benefitsLink?: string | undefined;
    familyDeductibleCost?: number | undefined;
    familyMoopCost?: number | undefined;
    individualDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    genericDrugCost?: string | undefined;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    memberId?: string | undefined;
    binderStatus?: BinderStatuses | undefined;
    payTypeId?: PayTypeIds | undefined;
    applicationDate?: string | undefined;
    isPassiveRenewal: boolean;
    isAddedToPaymentTracking?: boolean | undefined;
    isAddedToPayrollReport?: boolean | undefined;
    applicationLink?: string | undefined;
    exchange?: string | undefined;
    state?: string | undefined;
    isAutoEnrollEligible: boolean;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;
    autoEnrollmentStatus?: AutoEnrollmentStatus | undefined;
    keyId: string;
}
export interface IReimbursementOverlayDto {
    medicalReimbursementTypeId?: MedicalReimbursementTypes | undefined;
    coverageLevelId?: GroupLevels | undefined;
    displayLevelId: ReimbursementDisplayLevels;
    individualMedicalReimbursementAmount: number;
    familyMedicalReimbursementAmount: number;
    individualMedicalResponsibility: number;
    familyMedicalResponsibility: number;
    individualMoopWithRhReimbursement: number;
    familyMoopWithRhReimbursement: number;
    individualMedicalResponsibilityGap: number;
    familyMedicalResponsibilityGap: number;
    hsaEmployerContribution?: number | undefined;
    fullFamilyMedicalReimbursementAmount: number;
    showExtraTextExplanation: boolean;
    teamBenefitType: TeamBenefitTypes;
}
export interface IUserTeamBenefitTermDetail {
    id: string;
    teamBenefitId: string;
    userId: string;
    user?: User | undefined;
    statusId: UserTeamBenefitTermDetailStatuses;
    coverageLevelId?: GroupLevels | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    planCost: number;
    employerCost: number;
    employeeCost: number;
    employeeCostEquation?: string | undefined;
    employeeCostVariableEquation?: string | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    employerCostEquation?: string | undefined;
    employerCostVariableEquation?: string | undefined;
    employerContribution?: number | undefined;
    teamBenefitTermDetailId: string;
    teamBenefitTermDetail?: TeamBenefitTermDetail | undefined;
    lastErrorMessage?: string | undefined;
    lastErrorDate?: string | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    userTeamBenefitTermDetailHouseholdMembers?: UserTeamBenefitTermDetailHouseholdMember[] | undefined;
    memberHasCost: boolean;
    teamBenefit?: TeamBenefit | undefined;
    keyId: string;
    hasError: boolean;
    hasActiveStatus: boolean;
}
export interface ITeamBenefitTermDetail {
    id: string;
    teamBenefitId: string;
    startDate: string;
    endDate: string;
    employeeOnlyPlanCost: number;
    employeeSpousePlanCost: number;
    employeeChildrenPlanCost: number;
    employeeFamilyPlanCost: number;
    employerCoverEmployeePlanPercentage: number;
    employerCoverHouseholdMembersPlanPercentage: number;
    expenseInputs?: ExpenseInput[] | undefined;
    employeeOnlyContribution?: number | undefined;
    employeeSpouseContribution?: number | undefined;
    employeeChildrenContribution?: number | undefined;
    employeeFamilyContribution?: number | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    benefitDescription?: string | undefined;
    useHsaReimbursement?: boolean | undefined;
    onlyGrossUpReimbursementsAfterHsaLimit?: boolean | undefined;
    teamBenefitTermDetailAgeRanges?: TeamBenefitTermDetailAgeRanges[] | undefined;
    teamBenefit?: TeamBenefit | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
}
export interface IExpenseInput {
    id: string;
    teamBenefitTermDetailId: string;
    expenseTypeId: number;
    expenseType?: ExpenseType | undefined;
    expenseInputTypeId: ExpenseInputTypes;
    value?: string | undefined;
}
export interface IExpenseType {
    id: number;
    name?: string | undefined;
}
export interface ITeamBenefitTermDetailAgeRanges {
    id: string;
    teamBenefitTermDetailId: string;
    minimumAge: number;
    cost: number;
}
export interface ITeamBenefit {
    id: string;
    teamId: string;
    team?: Team | undefined;
    teamBenefitTypesCarrierId: number;
    startDate?: string | undefined;
    endDate?: string | undefined;
    nextRenewableDate?: string | undefined;
    nextMemberEnrollmentStartDate?: string | undefined;
    nextMemberEnrollmentEndDate?: string | undefined;
    rhManagementDate?: string | undefined;
    eligibilityWaitingDays?: number | undefined;
    rhManagementTypeId?: RhManagementTypes | undefined;
    planMinimumCost?: number | undefined;
    planName?: string | undefined;
    groupPlanId?: string | undefined;
    rhFee?: number | undefined;
    submitExpenseUrl?: string | undefined;
    utilization?: number | undefined;
    useForReimbursementOverlay: boolean;
    useForAncillarySelection: boolean;
    offerLevel?: OfferLevels | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamBenefitTypesCarrier?: TeamBenefitTypesCarrier | undefined;
    rhFiles?: RhFile[] | undefined;
    teamBenefitsRhFiles?: TeamBenefitsRhFile[] | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    teamBenefitTermDetails?: TeamBenefitTermDetail[] | undefined;
    teamBenefitType: TeamBenefitTypes;
    keyId: string;
}
export interface ITeam {
    teamId: string;
    name?: string | undefined;
    addressId: string;
    address?: Address | undefined;
    isCustomer: boolean;
    isPartner: boolean;
    isAdvisor: boolean;
    partTimeEmployeeCount?: number | undefined;
    fullTimeEmployeeCount?: number | undefined;
    ownerId?: string | undefined;
    representativeId?: string | undefined;
    advisementTeamId?: string | undefined;
    adpIntegrationKey?: string | undefined;
    adpOrganizationId?: string | undefined;
    adpClientId?: string | undefined;
    adpClientSecret?: string | undefined;
    majorMedicalWaitingPeriodId: MajorMedicalWaitingPeriodIds;
    majorMedicalWaitingPeriodOffset?: number | undefined;
    terminationDateDelayId: TerminationDateDelays;
    hrsIntegrationProvider?: HrsIntegrationProviders | undefined;
    adpIntegrationStatus: AdpIntegrationStatuses;
    postTaxDeductionCode?: string | undefined;
    wageUpDeductionCode?: string | undefined;
    preTaxDeductionCode?: string | undefined;
    taxFreeReimbursementDeductionCode?: string | undefined;
    hrsEligibilityClassCodes?: string[] | undefined;
    paylocityIntegrationKey?: string | undefined;
    paylocityOrganizationId?: string | undefined;
    paylocityClientId?: string | undefined;
    paylocityClientSecret?: string | undefined;
    paylocityIntegrationStatus: PaylocityIntegrationStatuses;
    hasPaylocityIntegration: boolean;
    finchAccessToken?: string | undefined;
    finchProviderId?: string | undefined;
    finchIntegrationStatus: FinchIntegrationStatuses;
    teamStateId: TeamStateIds;
    isArchived: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    accountId?: string | undefined;
    legalName?: string | undefined;
    fein?: string | undefined;
    primaryPhoneNumber?: string | undefined;
    secondaryPhoneNumber?: string | undefined;
    website?: string | undefined;
    originalStartDate?: string | undefined;
    memberAllowShoppingDefault: boolean;
    wagePlusShoppingConfiguration: ShoppingConfigurationIds;
    ichraShoppingConfiguration: ShoppingConfigurationIds;
    usePremiumTaxCreditsDefault: boolean;
    calendlyAdvisementLink?: string | undefined;
    medicareCalendlyLink?: string | undefined;
    vipCalendlyLink?: string | undefined;
    sbeEnrollmentCalendlyLink?: string | undefined;
    hbaLink?: string | undefined;
    isColonialContractSigned?: boolean | undefined;
    activeDate?: string | undefined;
    surveyYear?: number | undefined;
    inactiveDate?: string | undefined;
    parentTeamId: string;
    partnerTeamId?: PartnerTeams | undefined;
    enrollmentDeadline?: string | undefined;
    useIchraLaunchFlow: boolean;
    useReimbursementProgramManagement: boolean;
    useMultiFactorAuthorization: boolean;
    allowRememberDeviceForMfa: boolean;
    allowRecurringExpensesByDefault: boolean;
    reimbursementReportFrequencyId: ReimbursementReportFrequencies;
    expenseLockDateOffset: number;
    payrollReportSharingEnabled: boolean;
    payrollReportLockDay: number;
    payrollReportShareDay?: number | undefined;
    includeFaithBasedQuestionInSurvey: boolean;
    includeQualitativeQuestionsInSurvey: boolean;
    defaultSurveyTypeToSend: SurveyTypes;
    setWageUpAndWithholdingOnPayrollReportShare: boolean;
    isIchraExpress: boolean;
    hasPaymentsSetup: boolean;
    show1095CCoding: boolean;
    teamAdminForEmailId?: string | undefined;
    brokerAgencyName?: string | undefined;
    brokerAgentName?: string | undefined;
    brokerEmail?: string | undefined;
    brokerPhoneNumber?: string | undefined;
    capitalBlueCrossPaStockId?: string | undefined;
    createdDate: string;
    createdBy: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamNotes?: TeamNote[] | undefined;
    teamRhFiles?: TeamRhFile[] | undefined;
    users?: User[] | undefined;
    payrollReports?: PayrollReport[] | undefined;
    teamOperationsInfos?: TeamOperationsInfo[] | undefined;
    keyId: string;
    originalParentTeamId: string;
}
export interface ITeamNote {
    teamNoteId: string;
    teamId: string;
    noteId: string;
    note?: Note | undefined;
    keyId: string;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface ITeamRhFile {
    id: string;
    teamId: string;
    rhFileId: string;
    rhFile?: RhFile | undefined;
    joinEntityId: string;
}
export interface IRhFile {
    id: string;
    originalFileName?: string | undefined;
    fileSizeInBytes?: number | undefined;
    fileExtension?: string | undefined;
    name?: string | undefined;
    isShared: boolean;
    createdDate: string;
    createdBy: string;
    createdByUser?: User | undefined;
    isArchived: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    archivedByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    rhFileCategories?: RhFileCategory[] | undefined;
    keyId: string;
}
export interface IRhFileCategory {
    id: string;
    rhFileId: string;
    categoryId: CategoryIds;
}
export interface IPayrollReport {
    id: string;
    teamId: string;
    team?: Team | undefined;
    year: number;
    month: number;
    isShared: boolean;
    isLocked: boolean;
    note?: string | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
    payrollReportUserSnapshots?: PayrollReportUserSnapshot[] | undefined;
    changes?: PayrollReportUserChangeLog[] | undefined;
    wageUpTotal: number;
    ichraReimbursementTotal: number;
    teamBenefitsTotal: number;
    expenseSummary?: ExpenseSummary | undefined;
}
export interface IPayrollReportUserSnapshot {
    id: string;
    payrollReportId: string;
    payrollReport?: PayrollReport | undefined;
    userId?: string | undefined;
    user?: User | undefined;
    payrollSystemId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userStatus?: UserStatus | undefined;
    groupLevel: GroupLevels;
    wageUp?: number | undefined;
    wageUpAdjustment?: number | undefined;
    wageUpTotal: number;
    ichraReimbursement?: number | undefined;
    ichraReimbursementAdjustment?: number | undefined;
    ichraReimbursementTotal: number;
    isHsaEligible?: boolean | undefined;
    preTaxWithholding: number;
    preTaxWithholdingAdjustment?: number | undefined;
    preTaxWithholdingTotal: number;
    postTaxWithholding: number;
    postTaxWithholdingAdjustment?: number | undefined;
    postTaxWithholdingTotal: number;
    taxFreeReimbursement: number;
    taxFreeReimbursementAdjustment?: number | undefined;
    taxFreeReimbursementTotal: number;
    reimbursablePremiumsTotal: number;
    note?: string | undefined;
    teamBenefits?: PayrollReportUserSnapshotTeamBenefit[] | undefined;
    postTaxPlans?: PayrollReportUserSnapshotPostTaxPlan[] | undefined;
    preTaxPlans?: PayrollReportUserSnapshotPreTaxPlan[] | undefined;
    majorMedicalPlans?: PayrollReportUserSnapshotMajorMedicalPlan[] | undefined;
    reimbursablePremiums?: ReimbursablePremium[] | undefined;
    taxFreeReimbursements?: PayrollReportUserSnapshotTaxFreeReimbursement[] | undefined;
    noteLastModifiedDate?: string | undefined;
    noteLastModifiedBy?: string | undefined;
    noteLastModifiedByUser?: User | undefined;
    hsaTermDetailId?: string | undefined;
    hsaEmployerContribution?: number | undefined;
    customHsaEmployerContribution?: number | undefined;
    fsaTermDetailId?: string | undefined;
    preventativeMecTeamBenefitId?: string | undefined;
    hsaEligibleSelectedPlanId?: string | undefined;
    hsaEligibleSelectedPlan?: SelectedPlan | undefined;
    fsaEmployerContribution?: number | undefined;
    customFsaEmployerContribution?: number | undefined;
    reimbursementSummary?: ReimbursementSummary | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    ficaTaxRate?: number | undefined;
    lineFourteenCode?: LineFourteenCodes | undefined;
    lineSixteenCode?: LineSixteenCodes | undefined;
    lineSeventeenCode?: string | undefined;
    isHrsAssociateIdSet: boolean;
    keyId: string;
}
export interface IPayrollReportUserSnapshotTeamBenefit {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    userTeamBenefitTermDetailId?: string | undefined;
    userTeamBenefitTermDetail?: UserTeamBenefitTermDetail | undefined;
    teamBenefitType: TeamBenefitTypes;
    employeeCost: number;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IPayrollReportUserSnapshotPostTaxPlan {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    carrier?: string | undefined;
    planName?: string | undefined;
    premium: number;
    premiumWithCredits: number;
    appliedReimbursement: number;
    healthCareTypeId?: HealthCareTypeIds | undefined;
    withheldAmount: number;
    withholdingOrContribution: number;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IReimbursablePremium {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    planType: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    healthCareTypeId?: HealthCareTypeIds | undefined;
    planPremium: number;
    adjustment?: number | undefined;
    total: number;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IPayrollReportUserSnapshotPreTaxPlan {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    healthCareType?: HealthCareTypeIds | undefined;
    premium: number;
    appliedReimbursement: number;
    withheldAmount: number;
    withholdingOrContribution: number;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IPayrollReportUserSnapshotMajorMedicalPlan {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    planType: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IPayrollReportUserSnapshotTaxFreeReimbursement {
    id: string;
    payrollReportUserSnapshotId: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    planPremium: number;
    appliedReimbursement: number;
    withholdingOrContribution: number;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IReimbursementSummary {
    postTaxWithholding?: ReimbursementSummarySection | undefined;
    taxFreeReimbursements?: ReimbursementSummarySection | undefined;
    preTaxWithholding?: ReimbursementSummarySection | undefined;
    reimbursement: number;
}
export interface IReimbursementSummarySection {
    title?: string | undefined;
    adjustment: number;
    total: number;
    remainingReimbursement: number;
    reimbursableLineItems?: ReimbursableLineItem[] | undefined;
    nonReimbursableLineItems?: NonReimbursableLineItem[] | undefined;
}
export interface IReimbursableLineItem {
    title?: string | undefined;
    amount: number;
    premium: number;
    premiumAdjustment: number;
    appliedReimbursement: number;
}
export interface INonReimbursableLineItem {
    title?: string | undefined;
    amount: number;
}
export interface IPayrollReportUserChangeLog {
    payrollReportUserSnapshotId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    diffType: PayrollReportChangeDiffType;
    changes?: PayrollReportUserChange[] | undefined;
}
export interface IPayrollReportUserChange {
    property?: string | undefined;
    benefitType?: TeamBenefitTypes | undefined;
    planType?: PlanTypeIds | undefined;
    healthCareType?: HealthCareTypeIds | undefined;
    oldValue?: string | undefined;
    newValue?: string | undefined;
    diffType?: PayrollReportChangeDiffType | undefined;
    changes?: PayrollReportUserChange[] | undefined;
}
export interface IExpenseSummary {
    id: string;
    payrollReportId: string;
    monthlySubscriptionFee: number;
    sharedResponsibility?: number | undefined;
    sharedResponsibilityEquation?: string | undefined;
    teamIsAle?: boolean | undefined;
    ficaEmployerExpense: number;
    hraUtilization?: number | undefined;
    reimbursementProgramUtilization?: number | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    customExpenses?: ExpenseSummaryCustomExpense[] | undefined;
    expenseSummaryTeamBenefits?: ExpenseSummaryTeamBenefit[] | undefined;
    expenseSummaryTeamBenefitsTotal?: number | undefined;
    total: number;
}
export interface IExpenseSummaryCustomExpense {
    id: string;
    expenseSummaryId: string;
    name?: string | undefined;
    amount: number;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
}
export interface IExpenseSummaryTeamBenefit {
    id: string;
    expenseSummaryId: string;
    teamBenefitId?: string | undefined;
    teamBenefitTypeId: TeamBenefitTypes;
    total: number;
    keyId: string;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface ITeamOperationsInfo {
    id: string;
    teamId: string;
    year: number;
    monthlyServiceFeeTypeId?: MonthlyFeeTypes | undefined;
    monthlyServiceFee?: number | undefined;
    employeePerMonthRate?: number | undefined;
    annualServiceFee?: number | undefined;
    hraAdminFee?: number | undefined;
    estimatedWithholding?: number | undefined;
    payrollDeduct?: boolean | undefined;
    rhCares?: boolean | undefined;
    pathwayNote?: string | undefined;
    spouseParentContributionNote?: string | undefined;
    medicaidChipContributionNote?: string | undefined;
    medicareContributionNote?: string | undefined;
    hsaHraContributionNote?: string | undefined;
    isIchra?: boolean | undefined;
    ichraOfferLevelId?: IchraOfferLevels | undefined;
    isAle?: boolean | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
}
export interface ITeamBenefitTypesCarrier {
    id: number;
    teamBenefitTypeId: TeamBenefitTypes;
    teamBenefitCarrierId: number;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamBenefitType?: TeamBenefitType | undefined;
    teamBenefitCarrier?: TeamBenefitCarrier | undefined;
}
export interface ITeamBenefitType {
    id: TeamBenefitTypes;
    name?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface ITeamBenefitCarrier {
    id: number;
    name?: string | undefined;
    submitExpenseUrlDefault?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface ITeamBenefitsRhFile {
    id: string;
    teamBenefitId: string;
    rhFileId: string;
    rhFile?: RhFile | undefined;
    joinEntityId: string;
}
export interface IBasicHouseholdMemberDto {
    householdMemberId: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    preferredName?: string | undefined;
    preferredNameWithFallback?: string | undefined;
}
export interface IUserTeamBenefitTermDetailHouseholdMember {
    id: string;
    householdMemberId: string;
    userTeamBenefitTermDetailId: string;
    householdMember?: HouseholdMember | undefined;
}
export interface IUserAddress {
    userAddressId: string;
    userId: string;
    addressId: string;
    year: number;
    typeId: AddressTypes;
    address?: Address | undefined;
}
export interface IEnrollmentVerification {
    id: string;
    userId: string;
    includePrimary: boolean;
    type: EnrollmentVerificationTypes;
    year: number;
    dueDate: string;
    status: EnrollmentVerificationStatuses;
    receivedDate?: string | undefined;
    submittedDate?: string | undefined;
    verifiedDate?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    householdMemberEnrollmentVerifications?: HouseholdMemberEnrollmentVerification[] | undefined;
    rhFiles?: RhFile[] | undefined;
    enrollmentVerificationRhFiles?: EnrollmentVerificationRhFile[] | undefined;
    keyId: string;
}
export interface IHouseholdMemberEnrollmentVerification {
    id: string;
    householdMemberId: string;
    enrollmentVerificationId: string;
    householdMember?: HouseholdMember | undefined;
    enrollmentVerification?: EnrollmentVerification | undefined;
}
export interface IEnrollmentVerificationRhFile {
    id: string;
    enrollmentVerificationId: string;
    rhFileId: string;
    enrollmentVerification?: EnrollmentVerification | undefined;
    rhFile?: RhFile | undefined;
}
export interface ISubmittedExpense {
    id: number;
    expenseId?: string | undefined;
    globalId: string;
    userId: string;
    user?: User | undefined;
    coveredMemberId: string;
    isCoveredMemberPrimary: boolean;
    expenseTypeId: number;
    expenseType?: ExpenseType | undefined;
    expenseStatus: ExpenseStatus;
    expenseAmount: number;
    amountEligibleForReimbursement?: number | undefined;
    reimbursementToWages?: number | undefined;
    submittedDate: string;
    dateOfService: string;
    approvalDate?: string | undefined;
    reimbursementReportId?: number | undefined;
    reimbursementReport?: ReimbursementReport | undefined;
    expenseSource?: string | undefined;
    decisionSummary?: string | undefined;
    submittedExpenseRhFiles?: SubmittedExpenseRhFile[] | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    reimburseToHsa: boolean;
    reimbursementToHsa?: number | undefined;
    wagesReimbursementSummary?: string | undefined;
    hsaReimbursementSummary?: string | undefined;
    isRecurring: boolean;
    isArchived: boolean;
    keyId: string;
}
export interface IReimbursementReport {
    id: number;
    globalId: string;
    teamId: string;
    team?: Team | undefined;
    startDate: string;
    endDate: string;
    isShared: boolean;
    submittedExpenses?: SubmittedExpense[] | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    termLabel?: string | undefined;
}
export interface ISubmittedExpenseRhFile {
    id: number;
    submittedExpenseId: number;
    submittedExpense?: SubmittedExpense | undefined;
    rhFileId: string;
    rhFile?: RhFile | undefined;
    joinEntityId: number;
}
export interface IAccountLockout {
    lastFailedLoginDate?: string | undefined;
    failedLoginAttemptsCount: number;
    lastFailedMfaDate?: string | undefined;
    failedMfaAttemptsCount: number;
}
export interface ILoginInfo extends IAccountLockout {
    userId: string;
    user?: User | undefined;
    passwordHash?: string | undefined;
    enabled: boolean;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    multiFactorAuthorizationCode?: string | undefined;
    mfaCodeExpirationDateTime?: string | undefined;
    keyId: string;
}
export interface IPathwayUser {
    id: string;
    userId: string;
    user?: User | undefined;
    pathwayBlueprintId: string;
    pathwayBlueprint?: PathwayBlueprint | undefined;
    pathwayId?: string | undefined;
    pathway?: Pathway | undefined;
    planCost?: number | undefined;
    planCostWithoutTaxCredits?: number | undefined;
    benchmarkPlanCost?: number | undefined;
    projectedWageUp?: number | undefined;
    customProjectedWageUp?: number | undefined;
    customProjectedWageUpNote?: string | undefined;
    projectedReimbursement?: number | undefined;
    customProjectedReimbursement?: number | undefined;
    customProjectedReimbursementNote?: string | undefined;
    customPlanCost?: number | undefined;
    customPlanCostNote?: string | undefined;
    employeeCost?: number | undefined;
    tags: PathwayUserTags;
    calculationSummary?: PathwayCalculationSummary | undefined;
    fullYearPlanCost?: number | undefined;
    fullYearProjectedWageUp?: number | undefined;
    fullYearProjectedReimbursement?: number | undefined;
    fullYearEmployeeCost?: number | undefined;
    fullYearTags: PathwayUserTags;
    fullYearCalculationSummary?: PathwayCalculationSummary | undefined;
    totalTaxRate: number;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    customChipCost?: number | undefined;
    customMedicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    otherCostsNote?: string | undefined;
    hasOtherCosts: boolean;
    totalCosts: number;
    taxedUpTotalCosts: number;
    totalFullYearCosts: number;
    fullYearTaxedUpTotalCosts: number;
    lastWageUpAffectedDate?: string | undefined;
    lastCalculatedDate?: string | undefined;
    lastCalculatedBy?: string | undefined;
    lastCalculatedByUser?: User | undefined;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    currentEmployeeCost?: number | undefined;
    currentEmployerCost?: number | undefined;
    renewalEmployeeCost?: number | undefined;
    renewalEmployerCost?: number | undefined;
    lastCalculatedByBaseUser?: BaseUser | undefined;
    isStale: boolean;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    hasError: boolean;
    errorMessage?: string | undefined;
    ineligibleRecommendedPlanNames?: string[] | undefined;
    hasMismatchedContributions: boolean;
    keyId: string;
}
export interface IPathwayBlueprint {
    id: string;
    teamId: string;
    team?: Team | undefined;
    name?: string | undefined;
    year: number;
    isActive: boolean;
    softwareFee?: number | undefined;
    hraCost?: number | undefined;
    mecCost?: number | undefined;
    adjustments?: number | undefined;
    wageUpFloor: number;
    lastBulkCalculatedDate?: string | undefined;
    lastBulkCalculatedBy?: string | undefined;
    lastBulkCalculatedByUser?: User | undefined;
    pathways?: Pathway[] | undefined;
    ichraClasses?: IchraClass[] | undefined;
    isCalculating: boolean;
    isProjection: boolean;
    createdDate: string;
    createdBy: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    pathwayUsersWithLostEligibilities?: BasicPathwayUserDto[] | undefined;
    pathwayUsersWithUnaffordableIchra?: BasicPathwayUserDto[] | undefined;
    pathwayUsersWithIneligiblePlans?: BasicPathwayUserDto[] | undefined;
    numberOfUnassignedUsers?: number | undefined;
    hasMismatchedContributionsUsers: boolean;
    keyId: string;
    isStale: boolean;
    annualEmployeeCost: number;
    annualProjectedWageUpCost: number;
    annualProjectedReimbursementCost: number;
    annualEmployerCost: number;
    annualEmployerCostEquation?: string | undefined;
    annualCost: number;
    annualOldEmployeeCost: number;
    annualOldEmployerCost: number;
    annualOldRenewalEmployeeCost: number;
    annualOldRenewalEmployerCost: number;
    annualOldCost: number;
    annualOldRenewalCost: number;
    projectedSavings: number;
    projectedRenewalSavings: number;
    projectedSavingsEquation?: string | undefined;
    projectedRenewalSavingsEquation?: string | undefined;
    additionalFicaCost: number;
    additionalFicaCostEquation?: string | undefined;
    employeesEligibleForTaxCredits: number;
    sharedResponsibilityEstimate: number;
    sharedResponsibilityEstimateEquation?: string | undefined;
    sharedResponsibilityFeeYear: number;
    hasIchraPathway: boolean;
}
export interface IPathway {
    id: string;
    name?: string | undefined;
    pathwayBlueprintId: string;
    pathwayTypeId: PathwayTypes;
    ichraClassId?: number | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    pathwayInputs?: PathwayInput[] | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    blueprint?: PathwayBlueprint | undefined;
    ichraClass?: IchraClass | undefined;
    pathwayRecommendedPlans?: PathwayRecommendedPlan[] | undefined;
    keyId: string;
}
export interface IPathwayInput {
    id: string;
    pathwayId: string;
    pathwayInputTypeId: PathwayInputTypes;
    value?: string | undefined;
}
export interface IIchraClass {
    id: number;
    globalId: string;
    pathwayBlueprintId: string;
    name?: string | undefined;
    topLineReimbursementStrategyId: TopLineReimbursementStrategies;
    ageBandSizeId: AgeBandSizes;
    ee64?: number | undefined;
    eeRatio?: number | undefined;
    es64?: number | undefined;
    esRatio?: number | undefined;
    ec64?: number | undefined;
    ecRatio?: number | undefined;
    ef64?: number | undefined;
    efRatio?: number | undefined;
    dependentKickerThreshold: number;
    additionalDependentKicker: number;
    benchmarkPlanType?: PlanListing | undefined;
    zipCode?: string | undefined;
    county?: string | undefined;
    state?: string | undefined;
    benchmarkCarrier?: string | undefined;
    benchmarkPlanId?: string | undefined;
    dependentKickerCoveragePercentage?: number | undefined;
    eeCoveragePercentage?: number | undefined;
    esCoveragePercentage?: number | undefined;
    ecCoveragePercentage?: number | undefined;
    efCoveragePercentage?: number | undefined;
    otherHouseholdMemberCoveragePercentage?: number | undefined;
    importedAgeBandedTable?: PathwayIchraAgeBandedTableDto[] | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    isStale: boolean;
    keyId: number;
}
export interface IPathwayIchraAgeBandedTableDto {
    bandLabel?: string | undefined;
    eeAmount: number;
    esAmount: number;
    ecAmount: number;
    efAmount: number;
}
export interface IPathwayRecommendedPlan {
    id: number;
    globalId: string;
    pathwayId: string;
    pathway?: Pathway | undefined;
    planId?: string | undefined;
    planName?: string | undefined;
    carrierId?: string | undefined;
    planLabel?: string | undefined;
    description?: string | undefined;
    order: number;
    planDetails?: BasicMarketplacePlanDto | undefined;
    benchmarkType?: PlanListing | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: number;
}
export interface IBasicMarketplacePlanDto {
    id?: string | undefined;
    name?: string | undefined;
    issuer?: Issuer | undefined;
    exchange?: string | undefined;
    state?: string | undefined;
    metalLevel?: string | undefined;
    isHsaEligible: boolean;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    genericDrugCost?: string | undefined;
    individualDeductibleCost?: number | undefined;
    familyDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    familyMoopCost?: number | undefined;
}
export interface IIssuer {
    id?: string | undefined;
    name?: string | undefined;
}
export interface IBasicPathwayUserDto {
    userFirstName?: string | undefined;
    userLastName?: string | undefined;
    pathwayName?: string | undefined;
}
export interface IPathwayCalculationSummary {
    summarySections?: SummarySection[] | undefined;
}
export interface ISummarySection {
    title?: string | undefined;
    columns?: ContentColumn[] | undefined;
}
export interface IContentColumn {
    sections?: ContentSection[] | undefined;
}
export interface IContentSection {
    title?: string | undefined;
    content?: string[] | undefined;
}
export interface IBaseUser {
    userId: string;
    teamId: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    displayName?: string | undefined;
}
export interface IActiveWageUp {
    id: string;
    userId: string;
    user?: User | undefined;
    wageUp?: number | undefined;
    netWageUp: number;
    reimbursement?: number | undefined;
    year: number;
    note?: string | undefined;
    reimbursementNote?: string | undefined;
    isCustom: boolean;
    isReimbursementCustom: boolean;
    employeeCost: number;
    planCost: number;
    totalTaxRate: number;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    otherCostsNote?: string | undefined;
    tags: PathwayUserTags;
    calculationSummary?: PathwayCalculationSummary | undefined;
    benchmarkType?: PlanListing | undefined;
    isIchra: boolean;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
    wasSetFromIchraPathway: boolean;
}
export interface ITaxData {
    id: string;
    userId: string;
    taxBracketId: number;
    taxBracket?: TaxBracket | undefined;
    stateTaxRateId: string;
    stateTaxRate?: StateTaxRate | undefined;
    ficaTaxRate: number;
    year: number;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    totalRate: number;
}
export interface ITaxBracket {
    taxBracketId: number;
    taxYear: number;
    filingStatusId: TaxFilingStatuses;
    incomeMinimum: number;
    taxRate: number;
}
export interface IStateTaxRate {
    stateTaxRateId: string;
    state?: string | undefined;
    taxRate: number;
    taxYear: number;
}
export interface IUserUserRelationship {
    id: string;
    year: number;
    userId: string;
    representativeId: string;
    userRelationshipTypeId: UserRelationshipTypeIds;
    representative?: User | undefined;
    user?: User | undefined;
}
export interface IYearlyUserInfo {
    id: string;
    userId: string;
    user?: User | undefined;
    year: number;
    propertyTypeId: YearlyUserInfoTypes;
    value?: string | undefined;
    createdBy: string;
    createdByUser?: User | undefined;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
}
export interface IInvite {
    teamId: string;
    name?: string | undefined;
    email?: string | undefined;
    isTeamAdmin: boolean;
    isAdvisor: boolean;
}
export interface IUserProfile {
    year: number;
    user?: User | undefined;
    address?: Address | undefined;
    mailingAddress?: Address | undefined;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    memberVerifiedInfo?: MemberVerifiedInfo | undefined;
    userUserRelationships?: UserUserRelationship[] | undefined;
    yearlyUserInfo?: YearlyUserInfoDto | undefined;
    userNotes?: Note[] | undefined;
    hasLoginInfo: boolean;
    isLockedOut: boolean;
    loginCreatedDate?: string | undefined;
    signUpToken?: string | undefined;
    majorMedicalCoverageLevel: GroupLevels;
    areVerificationRhFilesNeeded: boolean;
    employeeAnnualIncome: number;
    spouseIncome: number;
    totalDependentIncome: number;
    activeWageUp?: ActiveWageUp | undefined;
    canOpenUpShopping: boolean;
    dateOfBirth?: string | undefined;
    singleFilerStandardDeduction: number;
    filingStatusId: TaxFilingStatuses;
    householdIncome: number;
    baseHouseholdIncome: number;
    employeeIncome: number;
}
export interface IYearlyUserInfoDto {
    userId: string;
    year: number;
    enrollmentStatus?: YearlyUserPropertyDtoOfEnrollmentStatuses | undefined;
    allowShopping?: boolean | undefined;
    acceptedPathway?: boolean | undefined;
    advisementCompleted?: YearlyUserPropertyDtoOfNullableBoolean | undefined;
    finalReviewCompleted?: YearlyUserPropertyDtoOfBoolean | undefined;
    renewalDecision?: RenewalDecisions | undefined;
    isMinister?: boolean | undefined;
    applicationSubmittedBy?: string | undefined;
    usePremiumTaxCredits: boolean;
    majorMedicalCoverageLevel: GroupLevels;
    needsMajorMedicalCoverage: boolean;
    hsaContributionLimitOverride?: number | undefined;
    otherCoverageSource?: string | undefined;
    allowRecurringExpenses: boolean;
}
export interface IYearlyUserPropertyDtoOfEnrollmentStatuses {
    value: EnrollmentStatuses;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;
}
export interface IYearlyUserPropertyDtoOfNullableBoolean {
    value?: boolean | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;
}
export interface IYearlyUserPropertyDtoOfBoolean {
    value: boolean;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;
}
export interface IToken {
    accessToken?: string | undefined;
    refreshToken?: string | undefined;
    intermediateToken?: string | undefined;
}
export interface ISignUp {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    password?: string | undefined;
    token?: string | undefined;
}
export interface IForgotPassword {
    email?: string | undefined;
}
export interface IResetPassword {
    password?: string | undefined;
    token?: string | undefined;
}
export interface IRepresentativeOption {
    userId: string;
    name?: string | undefined;
}
export interface IPaginatedResultsDtoOfUserProfile {
    results?: UserProfile[] | undefined;
    pageSize: number;
    page: number;
    totalCount: number;
}
export interface IOrdering {
    property?: string | undefined;
    sortOrder: SortOrder;
}
export interface IUserProfilesMetadataDto {
    hasVerifiedInfoCount: number;
    needSurveyCount: number;
    hasBothSurveyTypes: boolean;
    hasAnyReimbursements: boolean;
    invitableMemberCount: number;
}
export interface IHousehold {
    userBudget?: UserBudget | undefined;
    userProfile?: UserProfile | undefined;
    householdMembers?: HouseholdMemberDto[] | undefined;
    spouseBirthDate?: string | undefined;
}
export interface IUserBudget {
    userBudgetId: string;
    userId: string;
    amount: number;
    supplementalWageIncrease: number;
    taxBracketId: number;
    year: number;
    isCustom: boolean;
    note?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    stateTaxRate: number;
    ficaTaxRate: number;
    taxBracket?: TaxBracket | undefined;
    federalTaxRate?: number | undefined;
    taxData?: TaxDataDto | undefined;
    user?: User | undefined;
}
export interface ITaxDataDto {
    id: string;
    userId: string;
    year: number;
    stateTaxRate: number;
    ficaTaxRate: number;
    federalTaxRate: number;
    totalTaxRate: number;
    taxBracket?: TaxBracket | undefined;
}
export interface IHouseholdMemberDto {
    householdMemberId: string;
    userId: string;
    householdMemberTypeId: HouseholdMemberTypes;
    isSpouse: boolean;
    isDependent: boolean;
    isUnclaimed: boolean;
    isIchraChild: boolean;
    displayName?: string | undefined;
    displayNameWithLabel?: string | undefined;
    preferredNameWithFallback?: string | undefined;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    dateOfBirth: string;
    gender?: string | undefined;
    usesTobacco: boolean;
    isPregnant?: boolean | undefined;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    isEmployed?: boolean | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageEligibilities: OtherCoverageEligibilities;
    otherCoverageSource?: string | undefined;
    employerName?: string | undefined;
    needsCoverage: boolean;
    socialSecurityNumber?: string | undefined;
    year?: number | undefined;
    haveDataForYear: boolean;
    income?: number | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    addressId?: string | undefined;
    address?: Address | undefined;
    mailingAddressId?: string | undefined;
    mailingAddress?: Address | undefined;
    householdMemberProviders?: HouseholdMemberProvider[] | undefined;
    householdMemberDrugs?: HouseholdMemberDrug[] | undefined;
    affectsBudgetPaths?: string[] | undefined;
    affectsPathwayWageUpPaths?: string[] | undefined;
    keyId: string;
}
export interface IOperationBase {
    path?: string | undefined;
    op?: string | undefined;
    from?: string | undefined;
}
export interface IOperation extends IOperationBase {
    value?: any | undefined;
}
export interface IVerifiedInfoComparison {
    differences?: Difference[] | undefined;
    hasVerifiedInfoForSourceYear: boolean;
    hasVerifiedInfoForDestinationYear: boolean;
}
export interface IDifference {
    name?: string | undefined;
    before?: string | undefined;
    after?: string | undefined;
}
export interface IHouseholdMemberPatchRecalculateDto {
    householdMember?: HouseholdMemberDto | undefined;
    activeWageUp?: ActiveWageUp | undefined;
}
export interface IMemberEligibility {
    memberId: string;
    eligibilityStatus: HouseholdEligibilityStatus;
    otherCoverageEligibility: OtherCoverageEligibilities;
}
export interface ICopyTeamInfoRequest {
    teamId: string;
    sourceYear: number;
    targetYear: number;
}
export interface ISocialSecurityRequest {
    entityId: string;
    socialSecurityNumber?: string | undefined;
    entityType: EntityType;
}
export interface IQualitativeAnswers {
    id: string;
    userId: string;
    likeAboutCurrentInsuranceAnswer?: string | undefined;
    improveCurrentInsuranceAnswer?: string | undefined;
    anyFeedbackAnswer?: string | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId: string;
    healthInsuranceAnswers?: QualitativeHealthInsuranceAnswers[] | undefined;
    otherBenefitsAnswers?: QualitativeOtherBenefitsAnswers[] | undefined;
}
export interface IQualitativeHealthInsuranceAnswers {
    id: string;
    qualitativeAnswerId: string;
    healthInsurancePartId: HealthInsuranceParts;
    rank: number;
    qualitativeAnswers?: QualitativeAnswers | undefined;
}
export interface IQualitativeOtherBenefitsAnswers {
    id: string;
    qualitativeAnswerId: string;
    otherBenefitId: OtherBenefits;
    qualitativeAnswers?: QualitativeAnswers | undefined;
}
export interface ICustomQuestionAnswer {
    id: string;
    customQuestionId: string;
    userId: string;
    answer?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    customQuestion?: CustomQuestion | undefined;
}
export interface ICustomQuestion {
    id: string;
    teamId: string;
    text?: string | undefined;
    order: number;
    isArchived: boolean;
    archivedDate?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId: string;
    hasAnswers: boolean;
    answers?: CustomQuestionAnswer[] | undefined;
}
export interface IDataForEnrollmentDto {
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;
}
export interface IPaginatedResultsDtoOfUserTermDetailManageMemberDto {
    results?: UserTermDetailManageMemberDto[] | undefined;
    pageSize: number;
    page: number;
    totalCount: number;
}
export interface IUserTermDetailManageMemberPaginatedResults extends IPaginatedResultsDtoOfUserTermDetailManageMemberDto {
    allPossibleUserIds?: string[] | undefined;
}
export interface IUserTermDetailManageMemberDto {
    userTermDetailId?: string | undefined;
    userId: string;
    coverageLevelId?: GroupLevels | undefined;
    statusId?: UserTeamBenefitTermDetailStatuses | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    planCost?: number | undefined;
    employerCost?: number | undefined;
    employeeCost?: number | undefined;
    employeeCostEquation?: string | undefined;
    employeeCostVariableEquation?: string | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    employerCostEquation?: string | undefined;
    employerCostVariableEquation?: string | undefined;
    employerContribution?: number | undefined;
    teamBenefitTermDetailId: string;
    user?: ManageMemberUserDto | undefined;
    coveredHouseholdMembers?: BasicHouseholdMemberDto[] | undefined;
    allHouseholdMembers?: BasicHouseholdMemberDto[] | undefined;
    lastErrorMessage?: string | undefined;
    lastErrorDate?: string | undefined;
}
export interface IManageMemberUserDto extends IBaseUser {
    hireDate?: string | undefined;
}
export interface IEditUserTeamBenefitTermDetailsRequest {
    userIds?: string[] | undefined;
    status?: UserTeamBenefitTermDetailStatuses | undefined;
    allHouseholdMembersCovered?: boolean | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
}
export interface ITeamBenefitDto {
    id: string;
    teamId: string;
    startDate?: string | undefined;
    endDate?: string | undefined;
    nextRenewableDate?: string | undefined;
    nextMemberEnrollmentStartDate?: string | undefined;
    nextMemberEnrollmentEndDate?: string | undefined;
    rhManagementDate?: string | undefined;
    eligibilityWaitingDays?: number | undefined;
    rhManagementTypeId?: RhManagementTypes | undefined;
    planMinimumCost?: number | undefined;
    planName?: string | undefined;
    groupPlanId?: string | undefined;
    rhFee?: number | undefined;
    submitExpenseUrl?: string | undefined;
    utilization?: number | undefined;
    useForReimbursementOverlay: boolean;
    useForAncillarySelection: boolean;
    offerLevel?: OfferLevels | undefined;
    rhFiles?: RhFileDto[] | undefined;
    teamBenefitType: TeamBenefitTypes;
    teamBenefitTypesCarrier?: TeamBenefitTypesCarrierDto | undefined;
    createdDate: string;
}
export interface ITeamBenefitWithTermDetailsDto extends ITeamBenefitDto {
    teamBenefitTermDetails?: TeamBenefitTermDetailDto[] | undefined;
}
export interface ITeamBenefitTermDetailDto {
    id: string;
    startDate: string;
    endDate: string;
    employeeOnlyPlanCost: number;
    employeeSpousePlanCost: number;
    employeeChildrenPlanCost: number;
    employeeFamilyPlanCost: number;
    employerCoverEmployeePlanPercentage: number;
    employerCoverHouseholdMembersPlanPercentage: number;
    expenseInputs?: ExpenseInput[] | undefined;
    employeeOnlyContribution?: number | undefined;
    employeeSpouseContribution?: number | undefined;
    employeeChildrenContribution?: number | undefined;
    employeeFamilyContribution?: number | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    teamBenefitTermDetailAgeRanges?: TeamBenefitTermDetailAgeRanges[] | undefined;
    benefitDescription?: string | undefined;
    useHsaReimbursement?: boolean | undefined;
    onlyGrossUpReimbursementsAfterHsaLimit?: boolean | undefined;
}
export interface IRhFileDto {
    id: string;
    originalFileName?: string | undefined;
    fileSizeInBytes?: number | undefined;
    fileExtension?: string | undefined;
    name?: string | undefined;
    isShared: boolean;
    createdDate: string;
    createdByUser?: BaseUser | undefined;
}
export interface ITeamBenefitTypesCarrierDto {
    id: number;
    teamBenefitTypeId: TeamBenefitTypes;
    teamBenefitType?: TeamBenefitTypeDto | undefined;
    teamBenefitCarrier?: TeamBenefitCarrierDto | undefined;
}
export interface ITeamBenefitTypeDto {
    id: TeamBenefitTypes;
    name?: string | undefined;
}
export interface ITeamBenefitCarrierDto {
    id: number;
    name?: string | undefined;
}
export interface IAncillaryBenefitDto {
    userTermDetailId: string;
    teamBenefitId: string;
    userId: string;
    initiallyCoveredHouseholdMemberIds?: string[] | undefined;
    rhFiles?: RhFileDto[] | undefined;
    teamBenefitTypeId: TeamBenefitTypes;
    teamBenefitType?: string | undefined;
    carrierName?: string | undefined;
    planName?: string | undefined;
    statusId: UserTeamBenefitTermDetailStatuses;
    offerLevel: OfferLevels;
    costData?: { [key: string]: number; } | undefined;
    canShowCost: boolean;
}
export interface ISelectAncillaryBenefitRequest {
    userTermDetailId: string;
    statusId: UserTeamBenefitTermDetailStatuses;
    teamBenefitType: TeamBenefitTypes;
    householdMemberIds?: string[] | undefined;
}
export interface IUserFlowDto {
    globalId: string;
    flowId: Flows;
    userId: string;
    year: number;
    tasks?: TaskDto[] | undefined;
    isVisible: boolean;
}
export interface ITaskDto {
    globalId: string;
    taskStatusId: TaskStatuses;
    taskId: Tasks;
    childTasks?: TaskDto[] | undefined;
    response?: any | undefined;
}
export interface IEditTaskStatusRequest {
    userFlowTaskStatusGlobalId: string;
    taskStatusId: TaskStatuses;
}
export interface ICompleteTaskRequest {
    userFlowTaskStatusGlobalId: string;
    response?: any | undefined;
}
export interface ISocialSecurityNumbersDto {
    socialSecurityNumbers?: SsnDto[] | undefined;
}
export interface ISsnDto {
    entityKeyId: string;
    socialSecurityNumber?: string | undefined;
    entityType: EntityType;
}
export interface ITeamMembersStats {
    countQuote: number;
    countVerified: number;
    statusCount?: { [key in keyof typeof EnrollmentStatuses]?: number; } | undefined;
    year: number;
}
export interface IProvidersListDto {
    hasError: boolean;
    providerPlans?: ProviderPlan[] | undefined;
}
export interface IProviderPlan {
    carrier?: Carrier | undefined;
    carrierName?: string | undefined;
    externalIds?: ExternalId[] | undefined;
    id?: string | undefined;
    lineOfCoverage?: string | undefined;
    market?: string | undefined;
    name?: string | undefined;
    networks?: Network[] | undefined;
}
export interface ICarrier {
    issuerId?: string | undefined;
    id?: string | undefined;
    name?: string | undefined;
    logoUrl?: string | undefined;
}
export interface IExternalId {
    type?: string | undefined;
    value?: string | undefined;
}
export interface INetwork {
    addresses?: Address[] | undefined;
    carrierName?: string | undefined;
    carriers?: Carrier[] | undefined;
    groupAffiliations?: GroupAffiliation[] | undefined;
    hospitalAffiliations?: HospitalAffiliation[] | undefined;
    id: number;
    languages?: Language[] | undefined;
    lastImportedAt?: string | undefined;
    name?: string | undefined;
    pcp?: boolean | undefined;
    pcpId?: string | undefined;
    source?: string | undefined;
    specialties?: Specialty[] | undefined;
    tier?: string | undefined;
}
export interface IGroupAffiliation {
    name?: string | undefined;
}
export interface IHospitalAffiliation {
    name?: string | undefined;
}
export interface ILanguage {
    id?: string | undefined;
    iso6392?: string | undefined;
    iso6393?: string | undefined;
    name?: string | undefined;
}
export interface ISpecialty {
    id?: string | undefined;
    taxonomyCode?: string | undefined;
    name?: string | undefined;
    subSpecialty?: string | undefined;
}
export interface ISelectedPlansForReviewDto {
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    selectedPlans?: SelectedPlanDto[] | undefined;
}
export interface IMajorMedicalBenefitCostDto {
    preTaxWagePlus?: number | undefined;
    totalTaxRate: number;
    postTaxWagePlus: number;
    preTaxTotalPlanCost: number;
    postTaxTotalPlanCost?: number | undefined;
    postTaxTotalMajorMedicalBenefitCost: number;
    preTaxTotalMajorMedicalBenefitCost: number;
    reimbursementAmount?: number | undefined;
    isIchra: boolean;
    ichraMajorMedicalBenefitCost?: number | undefined;
}
export interface ISelectedPlanDto {
    selectedPlanId: string;
    userId: string;
    planTypeId: PlanTypeIds;
    planId?: string | undefined;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;
}
export interface ISelectedPlansForRenewalDto {
    effectiveMajorMedicalSelectedPlans?: SelectedPlan[] | undefined;
    comparedMarketplaceAndOffExchangeSelectedPlans?: ComparedSelectedPlan[] | undefined;
    selectedPlansToDelete?: SelectedPlan[] | undefined;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    effectiveAncillarySelectedPlans?: SelectedPlan[] | undefined;
    estimatedEffectiveAncillaryCost: number;
    hasEffectivePlans: boolean;
    allPlansParentOrSpouse: boolean;
}
export interface IComparedSelectedPlan extends ISelectedPlan {
    previousPlanId?: string | undefined;
    previousSelectedPlanId: string;
    isPlanCrosswalked: boolean;
    planChangeReason?: string | undefined;
    comparisons?: SelectedPlanComparison[] | undefined;
    ineligibleToKeepReason?: IneligibleToKeepReasons | undefined;
}
export interface IComparison {
    name?: string | undefined;
    before?: string | undefined;
    after?: string | undefined;
}
export interface ISelectedPlanComparison extends IComparison {
    difference?: string | undefined;
    changeType: ChangeTypes;
    comparisonType: SelectedPlanComparisonTypes;
}
export interface IIdeonProvider {
    addresses?: IdeonProviderAddress[] | undefined;
    doingBusinessAsName?: string | undefined;
    firstName?: string | undefined;
    gender?: string | undefined;
    id: number;
    lastName?: string | undefined;
    middleName?: string | undefined;
    networkIds?: number[] | undefined;
    networks?: Network[] | undefined;
    npiSpecialty?: Specialty | undefined;
    npis?: number[] | undefined;
    organizationName?: string | undefined;
    phone?: string | undefined;
    presentationName?: string | undefined;
    specialty?: string | undefined;
    suffix?: string | undefined;
    title?: string | undefined;
    type?: string | undefined;
}
export interface IIdeonProviderAddress {
    acceptingNewPatients: boolean;
    city?: string | undefined;
    externalId?: string | undefined;
    id?: string | undefined;
    latitude: number;
    longitude: number;
    pcp: boolean;
    pcpId?: string | undefined;
    phoneNumbers?: string[] | undefined;
    state?: string | undefined;
    street_line_1?: string | undefined;
    street_line_2?: string | undefined;
    zipCode?: string | undefined;
}
export interface ISearchCommand {
    searchTerm?: string | undefined;
    planYear: number;
    zipCode?: string | undefined;
    token?: string | undefined;
}
export interface IInNetworkProviderDto {
    planId?: string | undefined;
    planYear: number;
    providers?: IdeonProvider[] | undefined;
}
export interface IGetProvidersInPlanNetworkQuery {
    planId?: string | undefined;
    planYear: number;
    providerIds?: number[] | undefined;
}
export interface IPaymentsInfoDto {
    accountSnapshotDto?: AccountSnapshotDto | undefined;
    canSetupInPayments: boolean;
}
export interface IAccountSnapshotDto {
    accountNumber?: string | undefined;
    routingNumber?: string | undefined;
    debitLastFour?: string | undefined;
    accountApplicationStatus?: string | undefined;
    currentBalance?: number | undefined;
    availableBalance?: number | undefined;
}
export interface IApplyIchraClassStrategyResponseDto {
    sixtyFourEeReimbursement: number;
    sixtyFourEsReimbursement: number;
    sixtyFourEcReimbursement: number;
    sixtyFourEfReimbursement: number;
    twentyThreeEeReimbursement: number;
    twentyThreeEsReimbursement: number;
    twentyThreeEcReimbursement: number;
    twentyThreeEfReimbursement: number;
    strategyEeRatio: number;
    strategyEsRatio: number;
    strategyEcRatio: number;
    strategyEfRatio: number;
    additionalDependentKicker: number;
}
export interface IImportAgeBandedTableResponseDto {
    errors?: string[] | undefined;
    ageBandedRows?: PathwayIchraAgeBandedTableDto[] | undefined;
}
export interface IPathwayBlueprintDto {
    id: string;
    teamId: string;
    name?: string | undefined;
    year: number;
    isActive: boolean;
    isCalculating: boolean;
    isProjection: boolean;
    lastBulkCalculatedDate?: string | undefined;
    lastBulkCalculatedByUser?: BaseUser | undefined;
    createdDate: string;
    createdByUser?: BaseUser | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;
    numberOfUnassignedUsers?: number | undefined;
    hasMismatchedContributionsUsers: boolean;
    isStale: boolean;
    projectedSavings: number;
    ichraClasses?: IchraClass[] | undefined;
}
export interface IUserPathwayBlueprintDto {
    pathwayUserDto?: PathwayUserDto | undefined;
    pathwayBlueprintDto?: PathwayBlueprintDto | undefined;
}
export interface IPathwayUserDto {
    id: string;
    userId: string;
    pathwayBlueprintId: string;
    pathwayId?: string | undefined;
    planId?: string | undefined;
    extraBenefitText?: string | undefined;
    displayNameOverride?: string | undefined;
    planCost?: number | undefined;
    projectedWageUp?: number | undefined;
    customProjectedWageUp?: number | undefined;
    customProjectedWageUpNote?: string | undefined;
    projectedReimbursement?: number | undefined;
    customProjectedReimbursement?: number | undefined;
    customProjectedReimbursementNote?: string | undefined;
    employeeCost?: number | undefined;
    tags: PathwayUserTags;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    hasOtherCosts: boolean;
    pathwayType?: PathwayTypes | undefined;
    pathwayName?: string | undefined;
    lastWageUpAffectedDate?: string | undefined;
    lastCalculatedDate?: string | undefined;
    lastCalculatedBy?: string | undefined;
    isStale: boolean;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    ichraBenchmarkType?: PlanListing | undefined;
    useRestrategizedRenewalFlow: boolean;
    hasMismatchedContributions: boolean;
}
export interface IPathwayMarketplacePlanMigration {
    pathway?: Pathway | undefined;
    ichraClass?: IchraClass | undefined;
    nextYearPlans?: BasicMarketplacePlanDto[] | undefined;
    currentPlan?: BasicMarketplacePlanDto | undefined;
    crosswalk?: MarketplacePlanCrosswalk | undefined;
}
export interface IMarketplacePlanCrosswalk {
    newPlanId?: string | undefined;
    level?: string | undefined;
    reason?: string | undefined;
    isDiscontinued: boolean;
    bronzeToSilver: boolean;
}
export interface IPathwayDecisionDto {
    userId: string;
    pathwayId: string;
    decision: PathwayDecisions;
    advisementScheduled: boolean;
    requestedWageUp?: number | undefined;
    planData?: any | undefined;
}
export interface IIchraOfferDto {
    activeReimbursementAmount: number;
    groupLevel: GroupLevels;
}
export interface IValidateOtpCommand {
    mfaCode?: string | undefined;
    rememberDevice: boolean;
}
export interface ICreateAccessTokenCommand {
    code?: string | undefined;
    teamId: string;
}
export interface IFavoriteTeamDto {
    id: string;
    userId: string;
    teamId: string;
    teamName?: string | undefined;
    teamStateId: TeamStateIds;
    activeDate?: string | undefined;
    isArchived: boolean;
    adminUsers?: AdminUserDto[] | undefined;
}
export interface IAdminUserDto {
    userId: string;
    displayName?: string | undefined;
    isTeamAdmin: boolean;
    isPayrollAdmin: boolean;
}
export interface IMemberBenefitsDto {
    year: number;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetailDto[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;
}
export interface IUserTeamBenefitTermDetailDto {
    id: string;
    userId: string;
    coverageLevelId?: GroupLevels | undefined;
    statusId: UserTeamBenefitTermDetailStatuses;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    teamBenefit?: TeamBenefitDto | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
}
export interface IPaginatedResultsDtoOfBasicSubmittedExpenseDto {
    results?: BasicSubmittedExpenseDto[] | undefined;
    pageSize: number;
    page: number;
    totalCount: number;
}
export interface IBasicSubmittedExpenseDto {
    globalId: string;
    expenseId?: string | undefined;
    userId: string;
    teamId: string;
    memberName?: string | undefined;
    teamName?: string | undefined;
    submittedDate: string;
    expenseTypeId: number;
    expenseType?: string | undefined;
    expenseStatus: ExpenseStatus;
    dateOfService: string;
    isArchived: boolean;
}
export interface ISubmittedExpenseDto extends IBasicSubmittedExpenseDto {
    expenseFor?: string | undefined;
    expenseAmount: number;
    expenseSource?: string | undefined;
    amountEligibleForReimbursement?: number | undefined;
    reimbursementToWages?: number | undefined;
    decisionSummary?: string | undefined;
    reimburseToHsa: boolean;
    reimbursementToHsa?: number | undefined;
    files?: RhFileDto[] | undefined;
    useHsaReimbursement: boolean;
    isHsaEligible: boolean;
    hsaReimbursementSummary?: string | undefined;
    wagesReimbursementSummary?: string | undefined;
    isLocked: boolean;
    reimbursementReportGlobalId?: string | undefined;
    isRecurring: boolean;
    recurringAllowed: boolean;
}
export interface ICalculateReimbursementResponseDto {
    hsaReimbursement: number;
    wageReimbursement: number;
    hsaReimbursementSummary?: string | undefined;
    wageReimbursementSummary?: string | undefined;
    reimburseToHsa: boolean;
}
export interface IListReimbursementReportsResponseDto {
    years?: number[] | undefined;
    reimbursementReports?: BasicReimbursementReportDto[] | undefined;
}
export interface IBasicReimbursementReportDto {
    globalId: string;
    teamId: string;
    startDate: string;
    endDate: string;
    isShared: boolean;
    termLabel?: string | undefined;
}
export interface IReimbursementReportDto extends IBasicReimbursementReportDto {
    reimbursementReportUsers?: ReimbursementReportUserDto[] | undefined;
    useHsaReimbursement: boolean;
}
export interface IReimbursementReportUserDto {
    userId: string;
    memberName?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    payrollSystemId?: string | undefined;
    submittedExpenses?: ReimbursementReportUserSubmittedExpenseDto[] | undefined;
    reportYear: number;
    reportYearTotalReimbursementToHsa: number;
    previousYearTotalReimbursementToHsa: number;
    totalReimbursementToWages: number;
    totalReimbursement: number;
}
export interface IReimbursementReportUserSubmittedExpenseDto {
    expenseId?: string | undefined;
    expenseYear: number;
    expenseType?: ExpenseType | undefined;
    reimburseToHsa: boolean;
    reimbursementToWages: number;
    reimbursementToHsa: number;
    amountEligibleForReimbursement: number;
    hsaReimbursementSummary?: string | undefined;
    wagesReimbursementSummary?: string | undefined;
    totalReimbursement: number;
}
export interface IReimbursementReportsYearlyDto {
    members?: MemberYearlyReimbursementDto[] | undefined;
    useHsaReimbursement: boolean;
}
export interface IMemberYearlyReimbursementDto {
    userId: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    name?: string | undefined;
    reimbursementCoverageLevel?: GroupLevels | undefined;
    medicalAmountEligibleForReimbursement: number;
    medicalReimbursementLimit: number;
    counselingReimbursementLimit: number;
    otherReimbursementLimits: number;
    totalAmountEligibleForReimbursement: number;
    totalReimbursementLimit: number;
    totalReimbursementToWages: number;
    totalReimbursementToHsa: number;
    useHsaReimbursement: boolean;
    grandTotalReimbursement: number;
}
export interface IUserInfoToSubmitExpenseDto {
    isEligibleForReimbursementProgram: boolean;
    isHsaEligible: boolean;
    useHsaForReimbursement: boolean;
    expenseTypes?: ExpenseType[] | undefined;
    allowRecurring: boolean;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    isAtHsaContributionLimit: boolean;
}
export interface IMemberReimbursementUtilizationDto {
    expenseTypeId: number;
    coverageLevelId: GroupLevels;
    individualMedicalResponsibility?: number | undefined;
    individualMedicalReimbursementAmount?: number | undefined;
    familyMedicalResponsibility?: number | undefined;
    familyMedicalReimbursementAmount?: number | undefined;
    medicalReimbursementType?: MedicalReimbursementTypes | undefined;
    individualReimbursementUsed?: number | undefined;
    individualReimbursementRemaining?: number | undefined;
    familyReimbursementUsed?: number | undefined;
    familyReimbursementRemaining?: number | undefined;
    householdMemberReimbursementUtilizations?: HouseholdMemberReimbursementUtilizationDto[] | undefined;
    counselingUsedVisits?: number | undefined;
    counselingMaximumVisits?: number | undefined;
    counselingPerVisitReimbursementAmount?: number | undefined;
    defaultReimbursementUsed?: number | undefined;
    defaultMaximumReimbursement?: number | undefined;
    totalHsaReimbursements: number;
    hsaContributionLimitValue: number;
    hsaContributionLimitEligibility: HsaEligibility;
    useHsaReimbursement?: boolean | undefined;
    hasHsaUtilizations?: boolean | undefined;
}
export interface IHouseholdMemberReimbursementUtilizationDto {
    name?: string | undefined;
    reimbursementUsed: number;
}
export interface IAssignableReportDto {
    reimbursementReportGlobalId?: string | undefined;
    termLabel?: string | undefined;
    assignableReportOption: AssignableReportOptions;
    startDate: string;
    endDate: string;
}
export interface IAdpUserConfigurationDto {
    userId: string;
    isAdpSetWithholdingAndWageUp: boolean;
}
export interface ICcmEnrollmentInfo {
    userId: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    status?: UserStatus | undefined;
    enrollmentStatus?: EnrollmentStatuses | undefined;
    activeDate?: string | undefined;
    email?: string | undefined;
    socialSecurityNumber?: string | undefined;
    gender?: string | undefined;
    isMarried: boolean;
    programType?: string | undefined;
    dateOfBirth?: string | undefined;
    ahp?: string | undefined;
    premium?: number | undefined;
    programEffectiveDate?: string | undefined;
    isPrimaryCovered?: boolean | undefined;
    phone?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    county?: string | undefined;
    householdMembers?: CcmEnrollmentInfoHouseholdMember[] | undefined;
}
export interface ICcmEnrollmentInfoHouseholdMember {
    firstName?: string | undefined;
    lastName?: string | undefined;
    socialSecurityNumber?: string | undefined;
    gender?: string | undefined;
    dateOfBirth: string;
}
export interface IAddEnrollmentVerification extends IEnrollmentVerification {
    householdMemberIds?: string[] | undefined;
}
export interface ICounty {
    name?: string | undefined;
    zipCode?: string | undefined;
    stateAbbrev?: string | undefined;
    fips?: string | undefined;
}
export interface IMarketplacePlanDto extends IBasicMarketplacePlanDto {
    planType?: string | undefined;
    benefitsUrl?: string | undefined;
    premium?: number | undefined;
    premiumWithCredits?: number | undefined;
    isCsrEligible: boolean;
    hasError: boolean;
    isIneligible: boolean;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;
}
export interface IPlanDetailsAndRatesRequest {
    planIds?: string[] | undefined;
    excludingMembers?: string[] | undefined;
    isOffExchange: boolean;
    year: number;
    overrideNeedsCoverage: boolean;
}
export interface IMedicaidDetails {
    userId: string;
    applicationDate?: string | undefined;
    applicationStatus?: MedicaidApplicationStatuses | undefined;
    eligibleFamilyMembers?: FamilyMemberTypes | undefined;
    lastTouchPointDate?: string | undefined;
    note?: string | undefined;
    isAuthFormSubmitted?: boolean | undefined;
    acceptDenyDate?: string | undefined;
    createdBy: string;
    createdDate: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    renewalDetails?: MedicaidRenewalDetails[] | undefined;
    keyId: string;
}
export interface IMedicaidRenewalDetails {
    id: string;
    userId: string;
    year: number;
    renewalSentDate?: string | undefined;
    renewalStatus?: MedicaidRenewalStatuses | undefined;
}
export interface IMediSharePlan {
    ahp?: string | undefined;
    productDescription?: string | undefined;
    totalAmount: number;
    originalTotalAmount: number;
    userId: string;
    hasError: boolean;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
}
export interface IPayrollReportDto {
    id: string;
    teamId: string;
    year: number;
    month: number;
    isShared: boolean;
    isLocked: boolean;
    note?: string | undefined;
    expenseSummary?: ExpenseSummary | undefined;
    wageUpTotal: number;
    ichraReimbursementTotal: number;
    individualBenefitsTotal: number;
    teamBenefitsTotal: number;
    payrollReportUserSnapshots?: PayrollReportUserSnapshotDto[] | undefined;
    changes?: PayrollReportUserChangeLog[] | undefined;
}
export interface IPayrollReportUserSnapshotDto {
    id: string;
    payrollReportId: string;
    userId?: string | undefined;
    payrollSystemId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userStatus?: UserStatus | undefined;
    groupLevel: GroupLevels;
    hsaEmployerContribution?: number | undefined;
    customHsaEmployerContribution?: number | undefined;
    fsaEmployerContribution?: number | undefined;
    customFsaEmployerContribution?: number | undefined;
    wageUp?: number | undefined;
    wageUpAdjustment?: number | undefined;
    wageUpTotal: number;
    ichraReimbursement?: number | undefined;
    ichraReimbursementAdjustment?: number | undefined;
    ichraReimbursementTotal: number;
    isHsaEligible?: boolean | undefined;
    preTaxWithholding: number;
    preTaxWithholdingAdjustment?: number | undefined;
    preTaxWithholdingTotal: number;
    postTaxWithholding: number;
    postTaxWithholdingAdjustment?: number | undefined;
    postTaxWithholdingTotal: number;
    taxFreeReimbursement?: number | undefined;
    taxFreeReimbursementAdjustment?: number | undefined;
    taxFreeReimbursementTotal: number;
    lineFourteenCode?: LineFourteenCodes | undefined;
    lineSixteenCode?: LineSixteenCodes | undefined;
    lineSeventeenCode?: string | undefined;
    note?: string | undefined;
    noteLastModifiedDate?: string | undefined;
    noteLastModifiedByUser?: BaseUser | undefined;
    majorMedicalPlans?: PayrollReportUserSnapshotMajorMedicalPlan[] | undefined;
    postTaxWithholdings?: PostTaxWithholdingDto[] | undefined;
    preTaxWithholdings?: PreTaxWithholdingsDto[] | undefined;
    reimbursablePremiums?: ReimbursablePremiumDto[] | undefined;
    taxFreeReimbursements?: TaxFreeReimbursementsDto[] | undefined;
    reimbursementSummary?: ReimbursementSummary | undefined;
    isHrsAssociateIdSet: boolean;
}
export interface IPostTaxWithholdingDto {
    id: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount: number;
}
export interface IPreTaxWithholdingsDto {
    id: string;
    sourceId?: string | undefined;
    sourceType: SourceTypes;
    benefitType?: string | undefined;
    amount: number;
}
export interface IReimbursablePremiumDto {
    id: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount: number;
    adjustment?: number | undefined;
    total: number;
}
export interface ITaxFreeReimbursementsDto {
    id: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount: number;
}
export interface IEditReimbursablePremiumAdjustmentRequest {
    adjustment?: number | undefined;
    note?: string | undefined;
}
export interface IYearMonthDto {
    year: number;
    month: number;
}
export interface IPermission {
    permissionId?: string | undefined;
    name?: string | undefined;
    description?: string | undefined;
    hasPermission: boolean;
}
export interface IPermissionRole {
    permissionId?: string | undefined;
    roleId: string;
    createdDate: string;
    createdBy: string;
}
export interface IResource {
    id: string;
    title?: string | undefined;
    url?: string | undefined;
    isSharedToAllMembers?: boolean | undefined;
    isSharedWithAllTeams?: boolean | undefined;
    isPinnedToTop: boolean;
    teamResources?: TeamResource[] | undefined;
    sharingEndDate?: string | undefined;
    createdDate: string;
    createdBy: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    shouldNotify: boolean;
    keyId: string;
}
export interface ITeamResource {
    id: string;
    teamId: string;
    team?: Team | undefined;
    resourceId: string;
}
export interface IRole {
    roleId: string;
    name?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    userCount: number;
    keyId: string;
}
export interface ISearchResults {
    results?: SearchResult[] | undefined;
    totalCount: number;
}
export interface ISearchResult {
    id: string;
    name?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    metadata?: { [key: string]: string; } | undefined;
    type: SearchResultType;
}
export interface IRhPasswordOptions {
    requireDigit: boolean;
    requireLowercase: boolean;
    requireNonAlphanumeric: boolean;
    requireUppercase: boolean;
    requiredLength: number;
    requiredUniqueChars: number;
    lockoutAttemptThreshold: number;
    lockoutDurationMinutes: number;
}
export interface ISurveyInfo {
    userProfile?: UserProfile | undefined;
    incomeYear: number;
    singleFilerStandardDeduction: number;
    team?: Team | undefined;
    householdMembers?: SurveyHouseholdMemberDto[] | undefined;
}
export interface ISurveyHouseholdMemberDto {
    householdMemberId: string;
    householdMemberTypeId: HouseholdMemberTypes;
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    preferredName?: string | undefined;
    gender?: string | undefined;
    address?: Address | undefined;
    needsCoverage: boolean;
    isEmployed?: boolean | undefined;
    usesTobacco: boolean;
    isPregnant?: boolean | undefined;
    hasParentOtherCoverage: boolean;
    hasEmployerOtherCoverage: boolean;
    employerName?: string | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageSource?: string | undefined;
}
export interface ISurveyAuthentication {
    token?: string | undefined;
    email?: string | undefined;
}
export interface ISendSurveyRequest {
    userId: string;
}
export interface ISurvey {
    userProfile?: UserProfile | undefined;
    householdMembers?: HouseholdMemberDto[] | undefined;
    qualitativeAnswers?: QualitativeAnswers | undefined;
    customQuestionAnswers?: CustomQuestionAnswer[] | undefined;
    token?: string | undefined;
}
export interface ITaskItem {
    taskItemId: TaskItems;
    taskGroupId: TaskGroups;
    order: number;
    name?: string | undefined;
    description?: string | undefined;
}
export interface ICompletedTeamTask {
    teamId: string;
    taskItemId: TaskItems;
    taskItem?: TaskItem | undefined;
}
export interface ICompletedUserTask {
    userId: string;
    taskItemId: TaskItems;
    createdDate?: string | undefined;
    taskItem?: TaskItem | undefined;
}
export interface ILaunchDetails {
    teamId: string;
    advisementDeadlineDate: string;
    meetingStartDateTime: string;
    meetingEndDateTime: string;
    meetingLink?: string | undefined;
    meetingPhoneNumber?: string | undefined;
    meetingConferenceId?: string | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface IPaginatedResultsDtoOfTeamProfile {
    results?: TeamProfile[] | undefined;
    pageSize: number;
    page: number;
    totalCount: number;
}
export interface ITeamProfile {
    team?: Team | undefined;
    address?: Address | undefined;
    createdBy?: string | undefined;
    adminEmail?: string | undefined;
}
export interface ITeamPlan {
    teamPlanId: string;
    teamId: string;
    carrier?: string | undefined;
    planName?: string | undefined;
    deductible?: number | undefined;
    familyDeductible?: number | undefined;
    outOfPocketMax?: number | undefined;
    familyOutOfPocketMax?: number | undefined;
    primaryVisitCopay?: number | undefined;
    specialistVisitCopay?: number | undefined;
    genericDrugsCopay?: number | undefined;
    createdDate: string;
    createdBy: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
}
export interface ITeamActionRequest {
    teamId: string;
}
export interface IUserTeamRelationship {
    userTeamRelationshipId: string;
    teamId: string;
    userId: string;
    user?: User | undefined;
    relationshipTypeId: RelationshipTypeIds;
    relationshipType?: TeamRelationshipType | undefined;
    isOwner: boolean;
}
export interface ITeamRelationshipType {
    teamRelationshipTypeId: RelationshipTypeIds;
    name?: string | undefined;
}
export interface IEditTeamRelationshipRequest {
    userId?: string | undefined;
    relationshipTypeId: RelationshipTypeIds;
}
export interface IUserCredentials {
    email?: string | undefined;
    password?: string | undefined;
}
export interface ITokenResponse {
    token_type?: string | undefined;
    access_token?: string | undefined;
    scope?: string | undefined;
    expires_in: number;
}
export interface IServiceUserDto {
    serviceUserId: string;
    userId: string;
    name?: string | undefined;
    appId?: string | undefined;
    primarySecret?: string | undefined;
    secondarySecret?: string | undefined;
}
export interface IWistiaVideo {
    name?: string | undefined;
    url?: string | undefined;
    hashed_id?: string | undefined;
}
export interface IAncillaryBenefitsDto {
    ancillaryBenefits?: AncillaryBenefitInfoDto[] | undefined;
}
export interface IAncillaryBenefitInfoDto {
    userTermDetailId: string;
    statusId: UserTeamBenefitTermDetailStatuses;
    teamBenefitType: TeamBenefitTypes;
    householdMemberIds?: string[] | undefined;
}
export interface IHouseholdMemberInfoDto {
    householdMemberId?: string | undefined;
    householdMemberTypeId: HouseholdMemberTypes;
    firstName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    birthdate: string;
    gender?: string | undefined;
    usesTobacco: boolean;
    sameEmployer?: boolean | undefined;
    address?: AddressDto | undefined;
}
export interface IAddressDto {
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    county?: string | undefined;
    countyFips?: string | undefined;
    zip?: string | undefined;
}
export interface IHouseholdMembersDto {
    householdMembers?: HouseholdMemberInfoDto[] | undefined;
}
export interface IMemberTermsDto {
    signature?: string | undefined;
    downloadUrl?: string | undefined;
}
export interface IPersonalInfoDto {
    firstName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    birthdate: string;
    gender?: string | undefined;
    phone?: string | undefined;
    usesTobacco: boolean;
}
export interface IPlanInfoDto {
    planId?: string | undefined;
    planStateId: PlanStateIds;
    planTypeId: PlanTypeIds;
    healthCareTypeId: HealthCareTypeIds;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;
    year: number;
    isHsaEligible: boolean;
    exchange?: string | undefined;
    state?: string | undefined;
    networkType?: string | undefined;
    benefitsLink?: string | undefined;
    familyDeductibleCost?: number | undefined;
    familyMoopCost?: number | undefined;
    individualDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    genericDrugCost?: string | undefined;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;
}
export interface IPreferredProvidersDto {
    providers?: Provider[] | undefined;
}
export interface IPrescriptionsDto {
    drugs?: Drug[] | undefined;
}
export interface ISelectedPlansDto {
    planInfoDtos?: PlanInfoDto[] | undefined;
    isWaived: boolean;
}
export interface IStringResponseDto {
    value?: string | undefined;
}
export interface IYourJobDto {
    jobTitle?: string | undefined;
    hireDate: string;
}
export interface FileParameter {
    data: any;
    fileName: string;
}
export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
export class User implements IUser {
    userId!: string;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    memberVerifiedInfos?: MemberVerifiedInfo[] | undefined;
    householdMembers?: HouseholdMember[] | undefined;
    userRoles?: UserRole[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    userAddresses?: UserAddress[] | undefined;
    enrollmentVerifications?: EnrollmentVerification[] | undefined;
    submittedExpenses?: SubmittedExpense[] | undefined;
    loginInfo?: LoginInfo | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    teamId!: string;
    team?: Team | undefined;
    displayName?: string | undefined;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    phone?: string | undefined;
    status?: UserStatus | undefined;
    activeDate?: string | undefined;
    activeWageUps?: ActiveWageUp[] | undefined;
    taxData?: TaxData[] | undefined;
    userInfoStatus!: UserInfoStatus;
    memberId?: string | undefined;
    userUserRelationships?: UserUserRelationship[] | undefined;
    profilePictureRhFileId?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    terminationDate?: string | undefined;
    inactiveDate?: string | undefined;
    hasHsa?: boolean | undefined;
    surveyLastSentDate?: string | undefined;
    surveyLastSentBy?: string | undefined;
    surveyLastSentByUser?: User | undefined;
    inviteLastSentDate?: string | undefined;
    inviteLastSentBy?: string | undefined;
    inviteLastSentByUser?: User | undefined;
    isVip?: boolean | undefined;
    wagePlusShoppingConfigurationOverride?: ShoppingConfigurationIds | undefined;
    ichraShoppingConfigurationOverride?: ShoppingConfigurationIds | undefined;
    hireDate?: string | undefined;
    dateOfBirth?: string | undefined;
    payrollSystemId?: string | undefined;
    statusLastModifiedDate?: string | undefined;
    statusLastModifiedBy?: string | undefined;
    statusLastModifiedByUser?: User | undefined;
    yearlyInfo?: YearlyUserInfo[] | undefined;
    originalStartDate?: string | undefined;
    needsFollowUp?: boolean | undefined;
    followUpNote?: string | undefined;
    followUpLastModifiedDate?: string | undefined;
    followUpLastModifiedBy?: string | undefined;
    followUpLastModifiedByUser?: User | undefined;
    previousGroupLevelId!: GroupLevels;
    isAdpSetWithholdingAndWageUp!: boolean;
    surveyTypeToSend!: SurveyTypes;
    hasPaymentsSetup!: boolean;
    isHrsAssociateIdSet!: boolean;
    keyId!: string;
    preferredNameWithFallback?: string | undefined;
    affectsPathwayWageUpPaths?: string[] | undefined;

    constructor(data?: IUser) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.memberQuoteInfo = _data["memberQuoteInfo"] ? MemberQuoteInfo.fromJS(_data["memberQuoteInfo"]) : <any>undefined;
            if (Array.isArray(_data["memberVerifiedInfos"])) {
                this.memberVerifiedInfos = [] as any;
                for (let item of _data["memberVerifiedInfos"])
                    this.memberVerifiedInfos!.push(MemberVerifiedInfo.fromJS(item));
            }
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(HouseholdMember.fromJS(item));
            }
            if (Array.isArray(_data["userRoles"])) {
                this.userRoles = [] as any;
                for (let item of _data["userRoles"])
                    this.userRoles!.push(UserRole.fromJS(item));
            }
            if (Array.isArray(_data["selectedPlans"])) {
                this.selectedPlans = [] as any;
                for (let item of _data["selectedPlans"])
                    this.selectedPlans!.push(SelectedPlan.fromJS(item));
            }
            if (Array.isArray(_data["userTeamBenefitTermDetails"])) {
                this.userTeamBenefitTermDetails = [] as any;
                for (let item of _data["userTeamBenefitTermDetails"])
                    this.userTeamBenefitTermDetails!.push(UserTeamBenefitTermDetail.fromJS(item));
            }
            if (Array.isArray(_data["userAddresses"])) {
                this.userAddresses = [] as any;
                for (let item of _data["userAddresses"])
                    this.userAddresses!.push(UserAddress.fromJS(item));
            }
            if (Array.isArray(_data["enrollmentVerifications"])) {
                this.enrollmentVerifications = [] as any;
                for (let item of _data["enrollmentVerifications"])
                    this.enrollmentVerifications!.push(EnrollmentVerification.fromJS(item));
            }
            if (Array.isArray(_data["submittedExpenses"])) {
                this.submittedExpenses = [] as any;
                for (let item of _data["submittedExpenses"])
                    this.submittedExpenses!.push(SubmittedExpense.fromJS(item));
            }
            this.loginInfo = _data["loginInfo"] ? LoginInfo.fromJS(_data["loginInfo"]) : <any>undefined;
            if (Array.isArray(_data["pathwayUsers"])) {
                this.pathwayUsers = [] as any;
                for (let item of _data["pathwayUsers"])
                    this.pathwayUsers!.push(PathwayUser.fromJS(item));
            }
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.displayName = _data["displayName"];
            this.firstName = _data["firstName"];
            this.middleName = _data["middleName"];
            this.lastName = _data["lastName"];
            this.preferredName = _data["preferredName"];
            this.suffix = _data["suffix"];
            this.email = _data["email"];
            this.jobTitle = _data["jobTitle"];
            this.phone = _data["phone"];
            this.status = _data["status"];
            this.activeDate = _data["activeDate"];
            if (Array.isArray(_data["activeWageUps"])) {
                this.activeWageUps = [] as any;
                for (let item of _data["activeWageUps"])
                    this.activeWageUps!.push(ActiveWageUp.fromJS(item));
            }
            if (Array.isArray(_data["taxData"])) {
                this.taxData = [] as any;
                for (let item of _data["taxData"])
                    this.taxData!.push(TaxData.fromJS(item));
            }
            this.userInfoStatus = _data["userInfoStatus"];
            this.memberId = _data["memberId"];
            if (Array.isArray(_data["userUserRelationships"])) {
                this.userUserRelationships = [] as any;
                for (let item of _data["userUserRelationships"])
                    this.userUserRelationships!.push(UserUserRelationship.fromJS(item));
            }
            this.profilePictureRhFileId = _data["profilePictureRhFileId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.terminationDate = _data["terminationDate"];
            this.inactiveDate = _data["inactiveDate"];
            this.hasHsa = _data["hasHsa"];
            this.surveyLastSentDate = _data["surveyLastSentDate"];
            this.surveyLastSentBy = _data["surveyLastSentBy"];
            this.surveyLastSentByUser = _data["surveyLastSentByUser"] ? User.fromJS(_data["surveyLastSentByUser"]) : <any>undefined;
            this.inviteLastSentDate = _data["inviteLastSentDate"];
            this.inviteLastSentBy = _data["inviteLastSentBy"];
            this.inviteLastSentByUser = _data["inviteLastSentByUser"] ? User.fromJS(_data["inviteLastSentByUser"]) : <any>undefined;
            this.isVip = _data["isVip"];
            this.wagePlusShoppingConfigurationOverride = _data["wagePlusShoppingConfigurationOverride"];
            this.ichraShoppingConfigurationOverride = _data["ichraShoppingConfigurationOverride"];
            this.hireDate = _data["hireDate"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.payrollSystemId = _data["payrollSystemId"];
            this.statusLastModifiedDate = _data["statusLastModifiedDate"];
            this.statusLastModifiedBy = _data["statusLastModifiedBy"];
            this.statusLastModifiedByUser = _data["statusLastModifiedByUser"] ? User.fromJS(_data["statusLastModifiedByUser"]) : <any>undefined;
            if (Array.isArray(_data["yearlyInfo"])) {
                this.yearlyInfo = [] as any;
                for (let item of _data["yearlyInfo"])
                    this.yearlyInfo!.push(YearlyUserInfo.fromJS(item));
            }
            this.originalStartDate = _data["originalStartDate"];
            this.needsFollowUp = _data["needsFollowUp"];
            this.followUpNote = _data["followUpNote"];
            this.followUpLastModifiedDate = _data["followUpLastModifiedDate"];
            this.followUpLastModifiedBy = _data["followUpLastModifiedBy"];
            this.followUpLastModifiedByUser = _data["followUpLastModifiedByUser"] ? User.fromJS(_data["followUpLastModifiedByUser"]) : <any>undefined;
            this.previousGroupLevelId = _data["previousGroupLevelId"];
            this.isAdpSetWithholdingAndWageUp = _data["isAdpSetWithholdingAndWageUp"];
            this.surveyTypeToSend = _data["surveyTypeToSend"];
            this.hasPaymentsSetup = _data["hasPaymentsSetup"];
            this.isHrsAssociateIdSet = _data["isHrsAssociateIdSet"];
            this.keyId = _data["keyId"];
            this.preferredNameWithFallback = _data["preferredNameWithFallback"];
            if (Array.isArray(_data["affectsPathwayWageUpPaths"])) {
                this.affectsPathwayWageUpPaths = [] as any;
                for (let item of _data["affectsPathwayWageUpPaths"])
                    this.affectsPathwayWageUpPaths!.push(item);
            }
        }
    }

    static fromJS(data: any): User {
        data = typeof data === 'object' ? data : {};
        let result = new User();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["memberQuoteInfo"] = this.memberQuoteInfo ? this.memberQuoteInfo.toJSON() : <any>undefined;
        if (Array.isArray(this.memberVerifiedInfos)) {
            data["memberVerifiedInfos"] = [];
            for (let item of this.memberVerifiedInfos)
                data["memberVerifiedInfos"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        if (Array.isArray(this.userRoles)) {
            data["userRoles"] = [];
            for (let item of this.userRoles)
                data["userRoles"].push(item.toJSON());
        }
        if (Array.isArray(this.selectedPlans)) {
            data["selectedPlans"] = [];
            for (let item of this.selectedPlans)
                data["selectedPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.userTeamBenefitTermDetails)) {
            data["userTeamBenefitTermDetails"] = [];
            for (let item of this.userTeamBenefitTermDetails)
                data["userTeamBenefitTermDetails"].push(item.toJSON());
        }
        if (Array.isArray(this.userAddresses)) {
            data["userAddresses"] = [];
            for (let item of this.userAddresses)
                data["userAddresses"].push(item.toJSON());
        }
        if (Array.isArray(this.enrollmentVerifications)) {
            data["enrollmentVerifications"] = [];
            for (let item of this.enrollmentVerifications)
                data["enrollmentVerifications"].push(item.toJSON());
        }
        if (Array.isArray(this.submittedExpenses)) {
            data["submittedExpenses"] = [];
            for (let item of this.submittedExpenses)
                data["submittedExpenses"].push(item.toJSON());
        }
        data["loginInfo"] = this.loginInfo ? this.loginInfo.toJSON() : <any>undefined;
        if (Array.isArray(this.pathwayUsers)) {
            data["pathwayUsers"] = [];
            for (let item of this.pathwayUsers)
                data["pathwayUsers"].push(item.toJSON());
        }
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["displayName"] = this.displayName;
        data["firstName"] = this.firstName;
        data["middleName"] = this.middleName;
        data["lastName"] = this.lastName;
        data["preferredName"] = this.preferredName;
        data["suffix"] = this.suffix;
        data["email"] = this.email;
        data["jobTitle"] = this.jobTitle;
        data["phone"] = this.phone;
        data["status"] = this.status;
        data["activeDate"] = this.activeDate;
        if (Array.isArray(this.activeWageUps)) {
            data["activeWageUps"] = [];
            for (let item of this.activeWageUps)
                data["activeWageUps"].push(item.toJSON());
        }
        if (Array.isArray(this.taxData)) {
            data["taxData"] = [];
            for (let item of this.taxData)
                data["taxData"].push(item.toJSON());
        }
        data["userInfoStatus"] = this.userInfoStatus;
        data["memberId"] = this.memberId;
        if (Array.isArray(this.userUserRelationships)) {
            data["userUserRelationships"] = [];
            for (let item of this.userUserRelationships)
                data["userUserRelationships"].push(item.toJSON());
        }
        data["profilePictureRhFileId"] = this.profilePictureRhFileId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["terminationDate"] = this.terminationDate;
        data["inactiveDate"] = this.inactiveDate;
        data["hasHsa"] = this.hasHsa;
        data["surveyLastSentDate"] = this.surveyLastSentDate;
        data["surveyLastSentBy"] = this.surveyLastSentBy;
        data["surveyLastSentByUser"] = this.surveyLastSentByUser ? this.surveyLastSentByUser.toJSON() : <any>undefined;
        data["inviteLastSentDate"] = this.inviteLastSentDate;
        data["inviteLastSentBy"] = this.inviteLastSentBy;
        data["inviteLastSentByUser"] = this.inviteLastSentByUser ? this.inviteLastSentByUser.toJSON() : <any>undefined;
        data["isVip"] = this.isVip;
        data["wagePlusShoppingConfigurationOverride"] = this.wagePlusShoppingConfigurationOverride;
        data["ichraShoppingConfigurationOverride"] = this.ichraShoppingConfigurationOverride;
        data["hireDate"] = this.hireDate;
        data["dateOfBirth"] = this.dateOfBirth;
        data["payrollSystemId"] = this.payrollSystemId;
        data["statusLastModifiedDate"] = this.statusLastModifiedDate;
        data["statusLastModifiedBy"] = this.statusLastModifiedBy;
        data["statusLastModifiedByUser"] = this.statusLastModifiedByUser ? this.statusLastModifiedByUser.toJSON() : <any>undefined;
        if (Array.isArray(this.yearlyInfo)) {
            data["yearlyInfo"] = [];
            for (let item of this.yearlyInfo)
                data["yearlyInfo"].push(item.toJSON());
        }
        data["originalStartDate"] = this.originalStartDate;
        data["needsFollowUp"] = this.needsFollowUp;
        data["followUpNote"] = this.followUpNote;
        data["followUpLastModifiedDate"] = this.followUpLastModifiedDate;
        data["followUpLastModifiedBy"] = this.followUpLastModifiedBy;
        data["followUpLastModifiedByUser"] = this.followUpLastModifiedByUser ? this.followUpLastModifiedByUser.toJSON() : <any>undefined;
        data["previousGroupLevelId"] = this.previousGroupLevelId;
        data["isAdpSetWithholdingAndWageUp"] = this.isAdpSetWithholdingAndWageUp;
        data["surveyTypeToSend"] = this.surveyTypeToSend;
        data["hasPaymentsSetup"] = this.hasPaymentsSetup;
        data["isHrsAssociateIdSet"] = this.isHrsAssociateIdSet;
        data["keyId"] = this.keyId;
        data["preferredNameWithFallback"] = this.preferredNameWithFallback;
        if (Array.isArray(this.affectsPathwayWageUpPaths)) {
            data["affectsPathwayWageUpPaths"] = [];
            for (let item of this.affectsPathwayWageUpPaths)
                data["affectsPathwayWageUpPaths"].push(item);
        }
        return data; 
    }
}
export class UserRoleStatus extends User implements IUserRoleStatus {
    roleIds?: string[] | undefined;

    constructor(data?: IUserRoleStatus) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            if (Array.isArray(_data["roleIds"])) {
                this.roleIds = [] as any;
                for (let item of _data["roleIds"])
                    this.roleIds!.push(item);
            }
        }
    }

    static fromJS(data: any): UserRoleStatus {
        data = typeof data === 'object' ? data : {};
        let result = new UserRoleStatus();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.roleIds)) {
            data["roleIds"] = [];
            for (let item of this.roleIds)
                data["roleIds"].push(item);
        }
        super.toJSON(data);
        return data; 
    }
}
export class MemberQuoteInfo implements IMemberQuoteInfo {
    userId!: string;
    user?: User | undefined;
    spouseDateOfBirth?: string | undefined;
    employeeAnnualIncome!: number;
    previousEmployeePremium!: number;
    previousEmployerPremium!: number;
    previousMonthlyPremium!: number;
    previousRenewalEmployeePremium?: number | undefined;
    previousRenewalEmployerPremium?: number | undefined;
    previousRenewalMonthlyPremium?: number | undefined;
    dependentsOver20!: number;
    dependentsUnder21!: number;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    affectsPathwayWageUpPaths?: string[] | undefined;

    constructor(data?: IMemberQuoteInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.spouseDateOfBirth = _data["spouseDateOfBirth"];
            this.employeeAnnualIncome = _data["employeeAnnualIncome"];
            this.previousEmployeePremium = _data["previousEmployeePremium"];
            this.previousEmployerPremium = _data["previousEmployerPremium"];
            this.previousMonthlyPremium = _data["previousMonthlyPremium"];
            this.previousRenewalEmployeePremium = _data["previousRenewalEmployeePremium"];
            this.previousRenewalEmployerPremium = _data["previousRenewalEmployerPremium"];
            this.previousRenewalMonthlyPremium = _data["previousRenewalMonthlyPremium"];
            this.dependentsOver20 = _data["dependentsOver20"];
            this.dependentsUnder21 = _data["dependentsUnder21"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            if (Array.isArray(_data["affectsPathwayWageUpPaths"])) {
                this.affectsPathwayWageUpPaths = [] as any;
                for (let item of _data["affectsPathwayWageUpPaths"])
                    this.affectsPathwayWageUpPaths!.push(item);
            }
        }
    }

    static fromJS(data: any): MemberQuoteInfo {
        data = typeof data === 'object' ? data : {};
        let result = new MemberQuoteInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["spouseDateOfBirth"] = this.spouseDateOfBirth;
        data["employeeAnnualIncome"] = this.employeeAnnualIncome;
        data["previousEmployeePremium"] = this.previousEmployeePremium;
        data["previousEmployerPremium"] = this.previousEmployerPremium;
        data["previousMonthlyPremium"] = this.previousMonthlyPremium;
        data["previousRenewalEmployeePremium"] = this.previousRenewalEmployeePremium;
        data["previousRenewalEmployerPremium"] = this.previousRenewalEmployerPremium;
        data["previousRenewalMonthlyPremium"] = this.previousRenewalMonthlyPremium;
        data["dependentsOver20"] = this.dependentsOver20;
        data["dependentsUnder21"] = this.dependentsUnder21;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        if (Array.isArray(this.affectsPathwayWageUpPaths)) {
            data["affectsPathwayWageUpPaths"] = [];
            for (let item of this.affectsPathwayWageUpPaths)
                data["affectsPathwayWageUpPaths"].push(item);
        }
        return data; 
    }
}
export class MemberVerifiedInfo implements IMemberVerifiedInfo {
    memberVerifiedInfoId!: string;
    userId!: string;
    occupation?: string | undefined;
    gender?: string | undefined;
    usesTobacco!: boolean;
    hasUnclaimedHouseholdMember?: boolean | undefined;
    isPregnant?: boolean | undefined;
    otherCoverageEligibilities!: OtherCoverageEligibilities;
    hasInterestInFaithBasedSharingOptions?: boolean | undefined;
    hasHouseholdPreExistingConditions!: boolean;
    householdPreExistingConditions?: Note | undefined;
    preferredProviders?: Provider[] | undefined;
    drugs?: Drug[] | undefined;
    employeeIncome!: number;
    deductions?: number | undefined;
    additionalIncome?: number | undefined;
    totalEmployeeIncome!: number;
    filingStatusId!: TaxFilingStatuses;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    socialSecurityNumber?: string | undefined;
    year!: number;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    incomeVerifiedDate?: string | undefined;
    lastSurveySubmittedDate?: string | undefined;
    lastSurveySubmittedBy?: string | undefined;
    surveyTypeCompleted!: SurveyTypes;
    keyId!: string;
    affectsPathwayWageUpPaths?: string[] | undefined;

    constructor(data?: IMemberVerifiedInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.memberVerifiedInfoId = _data["memberVerifiedInfoId"];
            this.userId = _data["userId"];
            this.occupation = _data["occupation"];
            this.gender = _data["gender"];
            this.usesTobacco = _data["usesTobacco"];
            this.hasUnclaimedHouseholdMember = _data["hasUnclaimedHouseholdMember"];
            this.isPregnant = _data["isPregnant"];
            this.otherCoverageEligibilities = _data["otherCoverageEligibilities"];
            this.hasInterestInFaithBasedSharingOptions = _data["hasInterestInFaithBasedSharingOptions"];
            this.hasHouseholdPreExistingConditions = _data["hasHouseholdPreExistingConditions"];
            this.householdPreExistingConditions = _data["householdPreExistingConditions"] ? Note.fromJS(_data["householdPreExistingConditions"]) : <any>undefined;
            if (Array.isArray(_data["preferredProviders"])) {
                this.preferredProviders = [] as any;
                for (let item of _data["preferredProviders"])
                    this.preferredProviders!.push(Provider.fromJS(item));
            }
            if (Array.isArray(_data["drugs"])) {
                this.drugs = [] as any;
                for (let item of _data["drugs"])
                    this.drugs!.push(Drug.fromJS(item));
            }
            this.employeeIncome = _data["employeeIncome"];
            this.deductions = _data["deductions"];
            this.additionalIncome = _data["additionalIncome"];
            this.totalEmployeeIncome = _data["totalEmployeeIncome"];
            this.filingStatusId = _data["filingStatusId"];
            this.heightInInches = _data["heightInInches"];
            this.weightInPounds = _data["weightInPounds"];
            this.usCitizen = _data["usCitizen"];
            this.naturalizedCitizen = _data["naturalizedCitizen"];
            this.americanIndian = _data["americanIndian"];
            this.alaskanNative = _data["alaskanNative"];
            this.fullTimeStudent = _data["fullTimeStudent"];
            this.fosterCareAt18OrOlder = _data["fosterCareAt18OrOlder"];
            this.specialNeeds = _data["specialNeeds"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.year = _data["year"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.incomeVerifiedDate = _data["incomeVerifiedDate"];
            this.lastSurveySubmittedDate = _data["lastSurveySubmittedDate"];
            this.lastSurveySubmittedBy = _data["lastSurveySubmittedBy"];
            this.surveyTypeCompleted = _data["surveyTypeCompleted"];
            this.keyId = _data["keyId"];
            if (Array.isArray(_data["affectsPathwayWageUpPaths"])) {
                this.affectsPathwayWageUpPaths = [] as any;
                for (let item of _data["affectsPathwayWageUpPaths"])
                    this.affectsPathwayWageUpPaths!.push(item);
            }
        }
    }

    static fromJS(data: any): MemberVerifiedInfo {
        data = typeof data === 'object' ? data : {};
        let result = new MemberVerifiedInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["memberVerifiedInfoId"] = this.memberVerifiedInfoId;
        data["userId"] = this.userId;
        data["occupation"] = this.occupation;
        data["gender"] = this.gender;
        data["usesTobacco"] = this.usesTobacco;
        data["hasUnclaimedHouseholdMember"] = this.hasUnclaimedHouseholdMember;
        data["isPregnant"] = this.isPregnant;
        data["otherCoverageEligibilities"] = this.otherCoverageEligibilities;
        data["hasInterestInFaithBasedSharingOptions"] = this.hasInterestInFaithBasedSharingOptions;
        data["hasHouseholdPreExistingConditions"] = this.hasHouseholdPreExistingConditions;
        data["householdPreExistingConditions"] = this.householdPreExistingConditions ? this.householdPreExistingConditions.toJSON() : <any>undefined;
        if (Array.isArray(this.preferredProviders)) {
            data["preferredProviders"] = [];
            for (let item of this.preferredProviders)
                data["preferredProviders"].push(item.toJSON());
        }
        if (Array.isArray(this.drugs)) {
            data["drugs"] = [];
            for (let item of this.drugs)
                data["drugs"].push(item.toJSON());
        }
        data["employeeIncome"] = this.employeeIncome;
        data["deductions"] = this.deductions;
        data["additionalIncome"] = this.additionalIncome;
        data["totalEmployeeIncome"] = this.totalEmployeeIncome;
        data["filingStatusId"] = this.filingStatusId;
        data["heightInInches"] = this.heightInInches;
        data["weightInPounds"] = this.weightInPounds;
        data["usCitizen"] = this.usCitizen;
        data["naturalizedCitizen"] = this.naturalizedCitizen;
        data["americanIndian"] = this.americanIndian;
        data["alaskanNative"] = this.alaskanNative;
        data["fullTimeStudent"] = this.fullTimeStudent;
        data["fosterCareAt18OrOlder"] = this.fosterCareAt18OrOlder;
        data["specialNeeds"] = this.specialNeeds;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["year"] = this.year;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["incomeVerifiedDate"] = this.incomeVerifiedDate;
        data["lastSurveySubmittedDate"] = this.lastSurveySubmittedDate;
        data["lastSurveySubmittedBy"] = this.lastSurveySubmittedBy;
        data["surveyTypeCompleted"] = this.surveyTypeCompleted;
        data["keyId"] = this.keyId;
        if (Array.isArray(this.affectsPathwayWageUpPaths)) {
            data["affectsPathwayWageUpPaths"] = [];
            for (let item of this.affectsPathwayWageUpPaths)
                data["affectsPathwayWageUpPaths"].push(item);
        }
        return data; 
    }
}
export class Note implements INote {
    noteId!: string;
    title?: string | undefined;
    noteText?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    isArchived!: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    teamId!: string;
    userId!: string;
    keyId!: string;

    constructor(data?: INote) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.noteId = _data["noteId"];
            this.title = _data["title"];
            this.noteText = _data["noteText"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.isArchived = _data["isArchived"];
            this.archivedDate = _data["archivedDate"];
            this.archivedBy = _data["archivedBy"];
            this.teamId = _data["teamId"];
            this.userId = _data["userId"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): Note {
        data = typeof data === 'object' ? data : {};
        let result = new Note();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["noteId"] = this.noteId;
        data["title"] = this.title;
        data["noteText"] = this.noteText;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["isArchived"] = this.isArchived;
        data["archivedDate"] = this.archivedDate;
        data["archivedBy"] = this.archivedBy;
        data["teamId"] = this.teamId;
        data["userId"] = this.userId;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class Provider implements IProvider {
    providerId!: string;
    entityId?: string | undefined;
    nationalProviderId?: string | undefined;
    name?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    distance!: number;
    gender?: string | undefined;
    taxonomy?: string | undefined;
    type?: string | undefined;
    specialties?: string[] | undefined;
    entityIds?: string[] | undefined;
    phoneNumber?: string | undefined;
    source!: ProviderSource;

    constructor(data?: IProvider) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.providerId = _data["providerId"];
            this.entityId = _data["entityId"];
            this.nationalProviderId = _data["nationalProviderId"];
            this.name = _data["name"];
            this.addressLine1 = _data["addressLine1"];
            this.addressLine2 = _data["addressLine2"];
            this.city = _data["city"];
            this.state = _data["state"];
            this.zip = _data["zip"];
            this.distance = _data["distance"];
            this.gender = _data["gender"];
            this.taxonomy = _data["taxonomy"];
            this.type = _data["type"];
            if (Array.isArray(_data["specialties"])) {
                this.specialties = [] as any;
                for (let item of _data["specialties"])
                    this.specialties!.push(item);
            }
            if (Array.isArray(_data["entityIds"])) {
                this.entityIds = [] as any;
                for (let item of _data["entityIds"])
                    this.entityIds!.push(item);
            }
            this.phoneNumber = _data["phoneNumber"];
            this.source = _data["source"];
        }
    }

    static fromJS(data: any): Provider {
        data = typeof data === 'object' ? data : {};
        let result = new Provider();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["providerId"] = this.providerId;
        data["entityId"] = this.entityId;
        data["nationalProviderId"] = this.nationalProviderId;
        data["name"] = this.name;
        data["addressLine1"] = this.addressLine1;
        data["addressLine2"] = this.addressLine2;
        data["city"] = this.city;
        data["state"] = this.state;
        data["zip"] = this.zip;
        data["distance"] = this.distance;
        data["gender"] = this.gender;
        data["taxonomy"] = this.taxonomy;
        data["type"] = this.type;
        if (Array.isArray(this.specialties)) {
            data["specialties"] = [];
            for (let item of this.specialties)
                data["specialties"].push(item);
        }
        if (Array.isArray(this.entityIds)) {
            data["entityIds"] = [];
            for (let item of this.entityIds)
                data["entityIds"].push(item);
        }
        data["phoneNumber"] = this.phoneNumber;
        data["source"] = this.source;
        return data; 
    }
}
export class Drug implements IDrug {
    drugId!: string;
    entityId?: string | undefined;
    rxcui?: string | undefined;
    name?: string | undefined;
    strength?: string | undefined;
    route?: string | undefined;
    fullName?: string | undefined;
    entityIds?: string[] | undefined;

    constructor(data?: IDrug) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.drugId = _data["drugId"];
            this.entityId = _data["entityId"];
            this.rxcui = _data["rxcui"];
            this.name = _data["name"];
            this.strength = _data["strength"];
            this.route = _data["route"];
            this.fullName = _data["fullName"];
            if (Array.isArray(_data["entityIds"])) {
                this.entityIds = [] as any;
                for (let item of _data["entityIds"])
                    this.entityIds!.push(item);
            }
        }
    }

    static fromJS(data: any): Drug {
        data = typeof data === 'object' ? data : {};
        let result = new Drug();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["drugId"] = this.drugId;
        data["entityId"] = this.entityId;
        data["rxcui"] = this.rxcui;
        data["name"] = this.name;
        data["strength"] = this.strength;
        data["route"] = this.route;
        data["fullName"] = this.fullName;
        if (Array.isArray(this.entityIds)) {
            data["entityIds"] = [];
            for (let item of this.entityIds)
                data["entityIds"].push(item);
        }
        return data; 
    }
}
export class HouseholdMember implements IHouseholdMember {
    householdMemberId!: string;
    userId!: string;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    dateOfBirth!: string;
    gender?: string | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    socialSecurityNumber?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMemberAddresses?: HouseholdMemberAddress[] | undefined;
    householdMemberYearInfo?: HouseholdMemberYearInfo[] | undefined;
    householdMemberProviders?: HouseholdMemberProvider[] | undefined;
    householdMemberDrugs?: HouseholdMemberDrug[] | undefined;
    keyId!: string;

    constructor(data?: IHouseholdMember) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberId = _data["householdMemberId"];
            this.userId = _data["userId"];
            this.firstName = _data["firstName"];
            this.middleName = _data["middleName"];
            this.lastName = _data["lastName"];
            this.preferredName = _data["preferredName"];
            this.suffix = _data["suffix"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.gender = _data["gender"];
            this.americanIndian = _data["americanIndian"];
            this.alaskanNative = _data["alaskanNative"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            if (Array.isArray(_data["householdMemberAddresses"])) {
                this.householdMemberAddresses = [] as any;
                for (let item of _data["householdMemberAddresses"])
                    this.householdMemberAddresses!.push(HouseholdMemberAddress.fromJS(item));
            }
            if (Array.isArray(_data["householdMemberYearInfo"])) {
                this.householdMemberYearInfo = [] as any;
                for (let item of _data["householdMemberYearInfo"])
                    this.householdMemberYearInfo!.push(HouseholdMemberYearInfo.fromJS(item));
            }
            if (Array.isArray(_data["householdMemberProviders"])) {
                this.householdMemberProviders = [] as any;
                for (let item of _data["householdMemberProviders"])
                    this.householdMemberProviders!.push(HouseholdMemberProvider.fromJS(item));
            }
            if (Array.isArray(_data["householdMemberDrugs"])) {
                this.householdMemberDrugs = [] as any;
                for (let item of _data["householdMemberDrugs"])
                    this.householdMemberDrugs!.push(HouseholdMemberDrug.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): HouseholdMember {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMember();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberId"] = this.householdMemberId;
        data["userId"] = this.userId;
        data["firstName"] = this.firstName;
        data["middleName"] = this.middleName;
        data["lastName"] = this.lastName;
        data["preferredName"] = this.preferredName;
        data["suffix"] = this.suffix;
        data["dateOfBirth"] = this.dateOfBirth;
        data["gender"] = this.gender;
        data["americanIndian"] = this.americanIndian;
        data["alaskanNative"] = this.alaskanNative;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        if (Array.isArray(this.householdMemberAddresses)) {
            data["householdMemberAddresses"] = [];
            for (let item of this.householdMemberAddresses)
                data["householdMemberAddresses"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMemberYearInfo)) {
            data["householdMemberYearInfo"] = [];
            for (let item of this.householdMemberYearInfo)
                data["householdMemberYearInfo"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMemberProviders)) {
            data["householdMemberProviders"] = [];
            for (let item of this.householdMemberProviders)
                data["householdMemberProviders"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMemberDrugs)) {
            data["householdMemberDrugs"] = [];
            for (let item of this.householdMemberDrugs)
                data["householdMemberDrugs"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class HouseholdMemberAddress implements IHouseholdMemberAddress {
    id!: string;
    householdMemberId!: string;
    addressId!: string;
    year!: number;
    typeId!: AddressTypes;
    address?: Address | undefined;

    constructor(data?: IHouseholdMemberAddress) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.householdMemberId = _data["householdMemberId"];
            this.addressId = _data["addressId"];
            this.year = _data["year"];
            this.typeId = _data["typeId"];
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberAddress {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberAddress();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["householdMemberId"] = this.householdMemberId;
        data["addressId"] = this.addressId;
        data["year"] = this.year;
        data["typeId"] = this.typeId;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        return data; 
    }
}
export class Address implements IAddress {
    addressId!: string;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    county?: string | undefined;
    countyFips?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMemberAddresses?: HouseholdMemberAddress[] | undefined;
    keyId!: string;

    constructor(data?: IAddress) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.addressId = _data["addressId"];
            this.addressLine1 = _data["addressLine1"];
            this.addressLine2 = _data["addressLine2"];
            this.city = _data["city"];
            this.state = _data["state"];
            this.zip = _data["zip"];
            this.county = _data["county"];
            this.countyFips = _data["countyFips"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            if (Array.isArray(_data["householdMemberAddresses"])) {
                this.householdMemberAddresses = [] as any;
                for (let item of _data["householdMemberAddresses"])
                    this.householdMemberAddresses!.push(HouseholdMemberAddress.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): Address {
        data = typeof data === 'object' ? data : {};
        let result = new Address();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["addressId"] = this.addressId;
        data["addressLine1"] = this.addressLine1;
        data["addressLine2"] = this.addressLine2;
        data["city"] = this.city;
        data["state"] = this.state;
        data["zip"] = this.zip;
        data["county"] = this.county;
        data["countyFips"] = this.countyFips;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        if (Array.isArray(this.householdMemberAddresses)) {
            data["householdMemberAddresses"] = [];
            for (let item of this.householdMemberAddresses)
                data["householdMemberAddresses"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class HouseholdMemberYearInfo implements IHouseholdMemberYearInfo {
    id!: string;
    householdMemberId!: string;
    year!: number;
    householdMemberTypeId!: HouseholdMemberTypes;
    income?: number | undefined;
    usesTobacco!: boolean;
    isPregnant?: boolean | undefined;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    isEmployed?: boolean | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageEligibilities!: OtherCoverageEligibilities;
    otherCoverageSource?: string | undefined;
    employerName?: string | undefined;
    needsCoverage!: boolean;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;

    constructor(data?: IHouseholdMemberYearInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.householdMemberId = _data["householdMemberId"];
            this.year = _data["year"];
            this.householdMemberTypeId = _data["householdMemberTypeId"];
            this.income = _data["income"];
            this.usesTobacco = _data["usesTobacco"];
            this.isPregnant = _data["isPregnant"];
            this.heightInInches = _data["heightInInches"];
            this.weightInPounds = _data["weightInPounds"];
            this.usCitizen = _data["usCitizen"];
            this.naturalizedCitizen = _data["naturalizedCitizen"];
            this.fullTimeStudent = _data["fullTimeStudent"];
            this.fosterCareAt18OrOlder = _data["fosterCareAt18OrOlder"];
            this.specialNeeds = _data["specialNeeds"];
            this.isEmployed = _data["isEmployed"];
            this.hasSameEmployer = _data["hasSameEmployer"];
            this.otherCoverageEligibilities = _data["otherCoverageEligibilities"];
            this.otherCoverageSource = _data["otherCoverageSource"];
            this.employerName = _data["employerName"];
            this.needsCoverage = _data["needsCoverage"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): HouseholdMemberYearInfo {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberYearInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["householdMemberId"] = this.householdMemberId;
        data["year"] = this.year;
        data["householdMemberTypeId"] = this.householdMemberTypeId;
        data["income"] = this.income;
        data["usesTobacco"] = this.usesTobacco;
        data["isPregnant"] = this.isPregnant;
        data["heightInInches"] = this.heightInInches;
        data["weightInPounds"] = this.weightInPounds;
        data["usCitizen"] = this.usCitizen;
        data["naturalizedCitizen"] = this.naturalizedCitizen;
        data["fullTimeStudent"] = this.fullTimeStudent;
        data["fosterCareAt18OrOlder"] = this.fosterCareAt18OrOlder;
        data["specialNeeds"] = this.specialNeeds;
        data["isEmployed"] = this.isEmployed;
        data["hasSameEmployer"] = this.hasSameEmployer;
        data["otherCoverageEligibilities"] = this.otherCoverageEligibilities;
        data["otherCoverageSource"] = this.otherCoverageSource;
        data["employerName"] = this.employerName;
        data["needsCoverage"] = this.needsCoverage;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class HouseholdMemberProvider implements IHouseholdMemberProvider {
    householdMemberProviderId!: string;
    householdMemberId!: string;
    providerId!: string;
    year!: number;
    householdMember?: HouseholdMember | undefined;
    provider?: Provider | undefined;

    constructor(data?: IHouseholdMemberProvider) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberProviderId = _data["householdMemberProviderId"];
            this.householdMemberId = _data["householdMemberId"];
            this.providerId = _data["providerId"];
            this.year = _data["year"];
            this.householdMember = _data["householdMember"] ? HouseholdMember.fromJS(_data["householdMember"]) : <any>undefined;
            this.provider = _data["provider"] ? Provider.fromJS(_data["provider"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberProvider {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberProvider();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberProviderId"] = this.householdMemberProviderId;
        data["householdMemberId"] = this.householdMemberId;
        data["providerId"] = this.providerId;
        data["year"] = this.year;
        data["householdMember"] = this.householdMember ? this.householdMember.toJSON() : <any>undefined;
        data["provider"] = this.provider ? this.provider.toJSON() : <any>undefined;
        return data; 
    }
}
export class HouseholdMemberDrug implements IHouseholdMemberDrug {
    householdMemberDrugId!: string;
    householdMemberId!: string;
    drugId!: string;
    year!: number;
    householdMember?: HouseholdMember | undefined;
    drug?: Drug | undefined;

    constructor(data?: IHouseholdMemberDrug) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberDrugId = _data["householdMemberDrugId"];
            this.householdMemberId = _data["householdMemberId"];
            this.drugId = _data["drugId"];
            this.year = _data["year"];
            this.householdMember = _data["householdMember"] ? HouseholdMember.fromJS(_data["householdMember"]) : <any>undefined;
            this.drug = _data["drug"] ? Drug.fromJS(_data["drug"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberDrug {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberDrug();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberDrugId"] = this.householdMemberDrugId;
        data["householdMemberId"] = this.householdMemberId;
        data["drugId"] = this.drugId;
        data["year"] = this.year;
        data["householdMember"] = this.householdMember ? this.householdMember.toJSON() : <any>undefined;
        data["drug"] = this.drug ? this.drug.toJSON() : <any>undefined;
        return data; 
    }
}
export class UserRole implements IUserRole {
    userId!: string;
    roleId!: string;
    createdDate!: string;
    createdBy!: string;

    constructor(data?: IUserRole) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.roleId = _data["roleId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
        }
    }

    static fromJS(data: any): UserRole {
        data = typeof data === 'object' ? data : {};
        let result = new UserRole();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["roleId"] = this.roleId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        return data; 
    }
}
export class SelectedPlan implements ISelectedPlan {
    selectedPlanId!: string;
    userId!: string;
    planStateId!: PlanStateIds;
    planTypeId!: PlanTypeIds;
    isMedicaidChip!: boolean;
    planId?: string | undefined;
    healthCareTypeId!: HealthCareTypeIds;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered!: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;
    year!: number;
    isEnrolled!: boolean;
    enrollmentDate?: string | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    isHsaEligible?: boolean | undefined;
    comment?: string | undefined;
    isCustom!: boolean;
    networkType?: string | undefined;
    benefitsLink?: string | undefined;
    familyDeductibleCost?: number | undefined;
    familyMoopCost?: number | undefined;
    individualDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    genericDrugCost?: string | undefined;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    memberId?: string | undefined;
    binderStatus?: BinderStatuses | undefined;
    payTypeId?: PayTypeIds | undefined;
    applicationDate?: string | undefined;
    isPassiveRenewal!: boolean;
    isAddedToPaymentTracking?: boolean | undefined;
    isAddedToPayrollReport?: boolean | undefined;
    applicationLink?: string | undefined;
    exchange?: string | undefined;
    state?: string | undefined;
    isAutoEnrollEligible!: boolean;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;
    autoEnrollmentStatus?: AutoEnrollmentStatus | undefined;
    keyId!: string;

    constructor(data?: ISelectedPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.selectedPlanId = _data["selectedPlanId"];
            this.userId = _data["userId"];
            this.planStateId = _data["planStateId"];
            this.planTypeId = _data["planTypeId"];
            this.isMedicaidChip = _data["isMedicaidChip"];
            this.planId = _data["planId"];
            this.healthCareTypeId = _data["healthCareTypeId"];
            this.planName = _data["planName"];
            this.issuerName = _data["issuerName"];
            this.isPrimaryCovered = _data["isPrimaryCovered"];
            if (Array.isArray(_data["householdMembersCovered"])) {
                this.householdMembersCovered = [] as any;
                for (let item of _data["householdMembersCovered"])
                    this.householdMembersCovered!.push(item);
            }
            this.planPremium = _data["planPremium"];
            this.planPremiumWithCredits = _data["planPremiumWithCredits"];
            this.year = _data["year"];
            this.isEnrolled = _data["isEnrolled"];
            this.enrollmentDate = _data["enrollmentDate"];
            this.coverageStartDate = _data["coverageStartDate"];
            this.coverageEndDate = _data["coverageEndDate"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.comment = _data["comment"];
            this.isCustom = _data["isCustom"];
            this.networkType = _data["networkType"];
            this.benefitsLink = _data["benefitsLink"];
            this.familyDeductibleCost = _data["familyDeductibleCost"];
            this.familyMoopCost = _data["familyMoopCost"];
            this.individualDeductibleCost = _data["individualDeductibleCost"];
            this.individualMoopCost = _data["individualMoopCost"];
            this.genericDrugCost = _data["genericDrugCost"];
            this.primaryCareCost = _data["primaryCareCost"];
            this.specialistCost = _data["specialistCost"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.memberId = _data["memberId"];
            this.binderStatus = _data["binderStatus"];
            this.payTypeId = _data["payTypeId"];
            this.applicationDate = _data["applicationDate"];
            this.isPassiveRenewal = _data["isPassiveRenewal"];
            this.isAddedToPaymentTracking = _data["isAddedToPaymentTracking"];
            this.isAddedToPayrollReport = _data["isAddedToPayrollReport"];
            this.applicationLink = _data["applicationLink"];
            this.exchange = _data["exchange"];
            this.state = _data["state"];
            this.isAutoEnrollEligible = _data["isAutoEnrollEligible"];
            this.reimbursementOverlayDto = _data["reimbursementOverlayDto"] ? ReimbursementOverlayDto.fromJS(_data["reimbursementOverlayDto"]) : <any>undefined;
            this.autoEnrollmentStatus = _data["autoEnrollmentStatus"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): SelectedPlan {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["selectedPlanId"] = this.selectedPlanId;
        data["userId"] = this.userId;
        data["planStateId"] = this.planStateId;
        data["planTypeId"] = this.planTypeId;
        data["isMedicaidChip"] = this.isMedicaidChip;
        data["planId"] = this.planId;
        data["healthCareTypeId"] = this.healthCareTypeId;
        data["planName"] = this.planName;
        data["issuerName"] = this.issuerName;
        data["isPrimaryCovered"] = this.isPrimaryCovered;
        if (Array.isArray(this.householdMembersCovered)) {
            data["householdMembersCovered"] = [];
            for (let item of this.householdMembersCovered)
                data["householdMembersCovered"].push(item);
        }
        data["planPremium"] = this.planPremium;
        data["planPremiumWithCredits"] = this.planPremiumWithCredits;
        data["year"] = this.year;
        data["isEnrolled"] = this.isEnrolled;
        data["enrollmentDate"] = this.enrollmentDate;
        data["coverageStartDate"] = this.coverageStartDate;
        data["coverageEndDate"] = this.coverageEndDate;
        data["isHsaEligible"] = this.isHsaEligible;
        data["comment"] = this.comment;
        data["isCustom"] = this.isCustom;
        data["networkType"] = this.networkType;
        data["benefitsLink"] = this.benefitsLink;
        data["familyDeductibleCost"] = this.familyDeductibleCost;
        data["familyMoopCost"] = this.familyMoopCost;
        data["individualDeductibleCost"] = this.individualDeductibleCost;
        data["individualMoopCost"] = this.individualMoopCost;
        data["genericDrugCost"] = this.genericDrugCost;
        data["primaryCareCost"] = this.primaryCareCost;
        data["specialistCost"] = this.specialistCost;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["memberId"] = this.memberId;
        data["binderStatus"] = this.binderStatus;
        data["payTypeId"] = this.payTypeId;
        data["applicationDate"] = this.applicationDate;
        data["isPassiveRenewal"] = this.isPassiveRenewal;
        data["isAddedToPaymentTracking"] = this.isAddedToPaymentTracking;
        data["isAddedToPayrollReport"] = this.isAddedToPayrollReport;
        data["applicationLink"] = this.applicationLink;
        data["exchange"] = this.exchange;
        data["state"] = this.state;
        data["isAutoEnrollEligible"] = this.isAutoEnrollEligible;
        data["reimbursementOverlayDto"] = this.reimbursementOverlayDto ? this.reimbursementOverlayDto.toJSON() : <any>undefined;
        data["autoEnrollmentStatus"] = this.autoEnrollmentStatus;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ReimbursementOverlayDto implements IReimbursementOverlayDto {
    medicalReimbursementTypeId?: MedicalReimbursementTypes | undefined;
    coverageLevelId?: GroupLevels | undefined;
    displayLevelId!: ReimbursementDisplayLevels;
    individualMedicalReimbursementAmount!: number;
    familyMedicalReimbursementAmount!: number;
    individualMedicalResponsibility!: number;
    familyMedicalResponsibility!: number;
    individualMoopWithRhReimbursement!: number;
    familyMoopWithRhReimbursement!: number;
    individualMedicalResponsibilityGap!: number;
    familyMedicalResponsibilityGap!: number;
    hsaEmployerContribution?: number | undefined;
    fullFamilyMedicalReimbursementAmount!: number;
    showExtraTextExplanation!: boolean;
    teamBenefitType!: TeamBenefitTypes;

    constructor(data?: IReimbursementOverlayDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.medicalReimbursementTypeId = _data["medicalReimbursementTypeId"];
            this.coverageLevelId = _data["coverageLevelId"];
            this.displayLevelId = _data["displayLevelId"];
            this.individualMedicalReimbursementAmount = _data["individualMedicalReimbursementAmount"];
            this.familyMedicalReimbursementAmount = _data["familyMedicalReimbursementAmount"];
            this.individualMedicalResponsibility = _data["individualMedicalResponsibility"];
            this.familyMedicalResponsibility = _data["familyMedicalResponsibility"];
            this.individualMoopWithRhReimbursement = _data["individualMoopWithRhReimbursement"];
            this.familyMoopWithRhReimbursement = _data["familyMoopWithRhReimbursement"];
            this.individualMedicalResponsibilityGap = _data["individualMedicalResponsibilityGap"];
            this.familyMedicalResponsibilityGap = _data["familyMedicalResponsibilityGap"];
            this.hsaEmployerContribution = _data["hsaEmployerContribution"];
            this.fullFamilyMedicalReimbursementAmount = _data["fullFamilyMedicalReimbursementAmount"];
            this.showExtraTextExplanation = _data["showExtraTextExplanation"];
            this.teamBenefitType = _data["teamBenefitType"];
        }
    }

    static fromJS(data: any): ReimbursementOverlayDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementOverlayDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["medicalReimbursementTypeId"] = this.medicalReimbursementTypeId;
        data["coverageLevelId"] = this.coverageLevelId;
        data["displayLevelId"] = this.displayLevelId;
        data["individualMedicalReimbursementAmount"] = this.individualMedicalReimbursementAmount;
        data["familyMedicalReimbursementAmount"] = this.familyMedicalReimbursementAmount;
        data["individualMedicalResponsibility"] = this.individualMedicalResponsibility;
        data["familyMedicalResponsibility"] = this.familyMedicalResponsibility;
        data["individualMoopWithRhReimbursement"] = this.individualMoopWithRhReimbursement;
        data["familyMoopWithRhReimbursement"] = this.familyMoopWithRhReimbursement;
        data["individualMedicalResponsibilityGap"] = this.individualMedicalResponsibilityGap;
        data["familyMedicalResponsibilityGap"] = this.familyMedicalResponsibilityGap;
        data["hsaEmployerContribution"] = this.hsaEmployerContribution;
        data["fullFamilyMedicalReimbursementAmount"] = this.fullFamilyMedicalReimbursementAmount;
        data["showExtraTextExplanation"] = this.showExtraTextExplanation;
        data["teamBenefitType"] = this.teamBenefitType;
        return data; 
    }
}
export class UserTeamBenefitTermDetail implements IUserTeamBenefitTermDetail {
    id!: string;
    teamBenefitId!: string;
    userId!: string;
    user?: User | undefined;
    statusId!: UserTeamBenefitTermDetailStatuses;
    coverageLevelId?: GroupLevels | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    planCost!: number;
    employerCost!: number;
    employeeCost!: number;
    employeeCostEquation?: string | undefined;
    employeeCostVariableEquation?: string | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    employerCostEquation?: string | undefined;
    employerCostVariableEquation?: string | undefined;
    employerContribution?: number | undefined;
    teamBenefitTermDetailId!: string;
    teamBenefitTermDetail?: TeamBenefitTermDetail | undefined;
    lastErrorMessage?: string | undefined;
    lastErrorDate?: string | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    userTeamBenefitTermDetailHouseholdMembers?: UserTeamBenefitTermDetailHouseholdMember[] | undefined;
    memberHasCost!: boolean;
    teamBenefit?: TeamBenefit | undefined;
    keyId!: string;
    hasError!: boolean;
    hasActiveStatus!: boolean;

    constructor(data?: IUserTeamBenefitTermDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitId = _data["teamBenefitId"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.statusId = _data["statusId"];
            this.coverageLevelId = _data["coverageLevelId"];
            this.coverageStartDate = _data["coverageStartDate"];
            this.coverageEndDate = _data["coverageEndDate"];
            this.planCost = _data["planCost"];
            this.employerCost = _data["employerCost"];
            this.employeeCost = _data["employeeCost"];
            this.employeeCostEquation = _data["employeeCostEquation"];
            this.employeeCostVariableEquation = _data["employeeCostVariableEquation"];
            this.contributionFrequency = _data["contributionFrequency"];
            this.employerCostEquation = _data["employerCostEquation"];
            this.employerCostVariableEquation = _data["employerCostVariableEquation"];
            this.employerContribution = _data["employerContribution"];
            this.teamBenefitTermDetailId = _data["teamBenefitTermDetailId"];
            this.teamBenefitTermDetail = _data["teamBenefitTermDetail"] ? TeamBenefitTermDetail.fromJS(_data["teamBenefitTermDetail"]) : <any>undefined;
            this.lastErrorMessage = _data["lastErrorMessage"];
            this.lastErrorDate = _data["lastErrorDate"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            if (Array.isArray(_data["userTeamBenefitTermDetailHouseholdMembers"])) {
                this.userTeamBenefitTermDetailHouseholdMembers = [] as any;
                for (let item of _data["userTeamBenefitTermDetailHouseholdMembers"])
                    this.userTeamBenefitTermDetailHouseholdMembers!.push(UserTeamBenefitTermDetailHouseholdMember.fromJS(item));
            }
            this.memberHasCost = _data["memberHasCost"];
            this.teamBenefit = _data["teamBenefit"] ? TeamBenefit.fromJS(_data["teamBenefit"]) : <any>undefined;
            this.keyId = _data["keyId"];
            this.hasError = _data["hasError"];
            this.hasActiveStatus = _data["hasActiveStatus"];
        }
    }

    static fromJS(data: any): UserTeamBenefitTermDetail {
        data = typeof data === 'object' ? data : {};
        let result = new UserTeamBenefitTermDetail();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitId"] = this.teamBenefitId;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["statusId"] = this.statusId;
        data["coverageLevelId"] = this.coverageLevelId;
        data["coverageStartDate"] = this.coverageStartDate;
        data["coverageEndDate"] = this.coverageEndDate;
        data["planCost"] = this.planCost;
        data["employerCost"] = this.employerCost;
        data["employeeCost"] = this.employeeCost;
        data["employeeCostEquation"] = this.employeeCostEquation;
        data["employeeCostVariableEquation"] = this.employeeCostVariableEquation;
        data["contributionFrequency"] = this.contributionFrequency;
        data["employerCostEquation"] = this.employerCostEquation;
        data["employerCostVariableEquation"] = this.employerCostVariableEquation;
        data["employerContribution"] = this.employerContribution;
        data["teamBenefitTermDetailId"] = this.teamBenefitTermDetailId;
        data["teamBenefitTermDetail"] = this.teamBenefitTermDetail ? this.teamBenefitTermDetail.toJSON() : <any>undefined;
        data["lastErrorMessage"] = this.lastErrorMessage;
        data["lastErrorDate"] = this.lastErrorDate;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        if (Array.isArray(this.userTeamBenefitTermDetailHouseholdMembers)) {
            data["userTeamBenefitTermDetailHouseholdMembers"] = [];
            for (let item of this.userTeamBenefitTermDetailHouseholdMembers)
                data["userTeamBenefitTermDetailHouseholdMembers"].push(item.toJSON());
        }
        data["memberHasCost"] = this.memberHasCost;
        data["teamBenefit"] = this.teamBenefit ? this.teamBenefit.toJSON() : <any>undefined;
        data["keyId"] = this.keyId;
        data["hasError"] = this.hasError;
        data["hasActiveStatus"] = this.hasActiveStatus;
        return data; 
    }
}
export class TeamBenefitTermDetail implements ITeamBenefitTermDetail {
    id!: string;
    teamBenefitId!: string;
    startDate!: string;
    endDate!: string;
    employeeOnlyPlanCost!: number;
    employeeSpousePlanCost!: number;
    employeeChildrenPlanCost!: number;
    employeeFamilyPlanCost!: number;
    employerCoverEmployeePlanPercentage!: number;
    employerCoverHouseholdMembersPlanPercentage!: number;
    expenseInputs?: ExpenseInput[] | undefined;
    employeeOnlyContribution?: number | undefined;
    employeeSpouseContribution?: number | undefined;
    employeeChildrenContribution?: number | undefined;
    employeeFamilyContribution?: number | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    benefitDescription?: string | undefined;
    useHsaReimbursement?: boolean | undefined;
    onlyGrossUpReimbursementsAfterHsaLimit?: boolean | undefined;
    teamBenefitTermDetailAgeRanges?: TeamBenefitTermDetailAgeRanges[] | undefined;
    teamBenefit?: TeamBenefit | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;

    constructor(data?: ITeamBenefitTermDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitId = _data["teamBenefitId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.employeeOnlyPlanCost = _data["employeeOnlyPlanCost"];
            this.employeeSpousePlanCost = _data["employeeSpousePlanCost"];
            this.employeeChildrenPlanCost = _data["employeeChildrenPlanCost"];
            this.employeeFamilyPlanCost = _data["employeeFamilyPlanCost"];
            this.employerCoverEmployeePlanPercentage = _data["employerCoverEmployeePlanPercentage"];
            this.employerCoverHouseholdMembersPlanPercentage = _data["employerCoverHouseholdMembersPlanPercentage"];
            if (Array.isArray(_data["expenseInputs"])) {
                this.expenseInputs = [] as any;
                for (let item of _data["expenseInputs"])
                    this.expenseInputs!.push(ExpenseInput.fromJS(item));
            }
            this.employeeOnlyContribution = _data["employeeOnlyContribution"];
            this.employeeSpouseContribution = _data["employeeSpouseContribution"];
            this.employeeChildrenContribution = _data["employeeChildrenContribution"];
            this.employeeFamilyContribution = _data["employeeFamilyContribution"];
            this.contributionFrequency = _data["contributionFrequency"];
            this.benefitDescription = _data["benefitDescription"];
            this.useHsaReimbursement = _data["useHsaReimbursement"];
            this.onlyGrossUpReimbursementsAfterHsaLimit = _data["onlyGrossUpReimbursementsAfterHsaLimit"];
            if (Array.isArray(_data["teamBenefitTermDetailAgeRanges"])) {
                this.teamBenefitTermDetailAgeRanges = [] as any;
                for (let item of _data["teamBenefitTermDetailAgeRanges"])
                    this.teamBenefitTermDetailAgeRanges!.push(TeamBenefitTermDetailAgeRanges.fromJS(item));
            }
            this.teamBenefit = _data["teamBenefit"] ? TeamBenefit.fromJS(_data["teamBenefit"]) : <any>undefined;
            if (Array.isArray(_data["userTeamBenefitTermDetails"])) {
                this.userTeamBenefitTermDetails = [] as any;
                for (let item of _data["userTeamBenefitTermDetails"])
                    this.userTeamBenefitTermDetails!.push(UserTeamBenefitTermDetail.fromJS(item));
            }
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): TeamBenefitTermDetail {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTermDetail();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitId"] = this.teamBenefitId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["employeeOnlyPlanCost"] = this.employeeOnlyPlanCost;
        data["employeeSpousePlanCost"] = this.employeeSpousePlanCost;
        data["employeeChildrenPlanCost"] = this.employeeChildrenPlanCost;
        data["employeeFamilyPlanCost"] = this.employeeFamilyPlanCost;
        data["employerCoverEmployeePlanPercentage"] = this.employerCoverEmployeePlanPercentage;
        data["employerCoverHouseholdMembersPlanPercentage"] = this.employerCoverHouseholdMembersPlanPercentage;
        if (Array.isArray(this.expenseInputs)) {
            data["expenseInputs"] = [];
            for (let item of this.expenseInputs)
                data["expenseInputs"].push(item.toJSON());
        }
        data["employeeOnlyContribution"] = this.employeeOnlyContribution;
        data["employeeSpouseContribution"] = this.employeeSpouseContribution;
        data["employeeChildrenContribution"] = this.employeeChildrenContribution;
        data["employeeFamilyContribution"] = this.employeeFamilyContribution;
        data["contributionFrequency"] = this.contributionFrequency;
        data["benefitDescription"] = this.benefitDescription;
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        data["onlyGrossUpReimbursementsAfterHsaLimit"] = this.onlyGrossUpReimbursementsAfterHsaLimit;
        if (Array.isArray(this.teamBenefitTermDetailAgeRanges)) {
            data["teamBenefitTermDetailAgeRanges"] = [];
            for (let item of this.teamBenefitTermDetailAgeRanges)
                data["teamBenefitTermDetailAgeRanges"].push(item.toJSON());
        }
        data["teamBenefit"] = this.teamBenefit ? this.teamBenefit.toJSON() : <any>undefined;
        if (Array.isArray(this.userTeamBenefitTermDetails)) {
            data["userTeamBenefitTermDetails"] = [];
            for (let item of this.userTeamBenefitTermDetails)
                data["userTeamBenefitTermDetails"].push(item.toJSON());
        }
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ExpenseInput implements IExpenseInput {
    id!: string;
    teamBenefitTermDetailId!: string;
    expenseTypeId!: number;
    expenseType?: ExpenseType | undefined;
    expenseInputTypeId!: ExpenseInputTypes;
    value?: string | undefined;

    constructor(data?: IExpenseInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitTermDetailId = _data["teamBenefitTermDetailId"];
            this.expenseTypeId = _data["expenseTypeId"];
            this.expenseType = _data["expenseType"] ? ExpenseType.fromJS(_data["expenseType"]) : <any>undefined;
            this.expenseInputTypeId = _data["expenseInputTypeId"];
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): ExpenseInput {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitTermDetailId"] = this.teamBenefitTermDetailId;
        data["expenseTypeId"] = this.expenseTypeId;
        data["expenseType"] = this.expenseType ? this.expenseType.toJSON() : <any>undefined;
        data["expenseInputTypeId"] = this.expenseInputTypeId;
        data["value"] = this.value;
        return data; 
    }
}
export class ExpenseType implements IExpenseType {
    id!: number;
    name?: string | undefined;

    constructor(data?: IExpenseType) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): ExpenseType {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseType();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        return data; 
    }
}
export class TeamBenefitTermDetailAgeRanges implements ITeamBenefitTermDetailAgeRanges {
    id!: string;
    teamBenefitTermDetailId!: string;
    minimumAge!: number;
    cost!: number;

    constructor(data?: ITeamBenefitTermDetailAgeRanges) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitTermDetailId = _data["teamBenefitTermDetailId"];
            this.minimumAge = _data["minimumAge"];
            this.cost = _data["cost"];
        }
    }

    static fromJS(data: any): TeamBenefitTermDetailAgeRanges {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTermDetailAgeRanges();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitTermDetailId"] = this.teamBenefitTermDetailId;
        data["minimumAge"] = this.minimumAge;
        data["cost"] = this.cost;
        return data; 
    }
}
export class TeamBenefit implements ITeamBenefit {
    id!: string;
    teamId!: string;
    team?: Team | undefined;
    teamBenefitTypesCarrierId!: number;
    startDate?: string | undefined;
    endDate?: string | undefined;
    nextRenewableDate?: string | undefined;
    nextMemberEnrollmentStartDate?: string | undefined;
    nextMemberEnrollmentEndDate?: string | undefined;
    rhManagementDate?: string | undefined;
    eligibilityWaitingDays?: number | undefined;
    rhManagementTypeId?: RhManagementTypes | undefined;
    planMinimumCost?: number | undefined;
    planName?: string | undefined;
    groupPlanId?: string | undefined;
    rhFee?: number | undefined;
    submitExpenseUrl?: string | undefined;
    utilization?: number | undefined;
    useForReimbursementOverlay!: boolean;
    useForAncillarySelection!: boolean;
    offerLevel?: OfferLevels | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamBenefitTypesCarrier?: TeamBenefitTypesCarrier | undefined;
    rhFiles?: RhFile[] | undefined;
    teamBenefitsRhFiles?: TeamBenefitsRhFile[] | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetail[] | undefined;
    teamBenefitTermDetails?: TeamBenefitTermDetail[] | undefined;
    teamBenefitType!: TeamBenefitTypes;
    keyId!: string;

    constructor(data?: ITeamBenefit) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.teamBenefitTypesCarrierId = _data["teamBenefitTypesCarrierId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.nextRenewableDate = _data["nextRenewableDate"];
            this.nextMemberEnrollmentStartDate = _data["nextMemberEnrollmentStartDate"];
            this.nextMemberEnrollmentEndDate = _data["nextMemberEnrollmentEndDate"];
            this.rhManagementDate = _data["rhManagementDate"];
            this.eligibilityWaitingDays = _data["eligibilityWaitingDays"];
            this.rhManagementTypeId = _data["rhManagementTypeId"];
            this.planMinimumCost = _data["planMinimumCost"];
            this.planName = _data["planName"];
            this.groupPlanId = _data["groupPlanId"];
            this.rhFee = _data["rhFee"];
            this.submitExpenseUrl = _data["submitExpenseUrl"];
            this.utilization = _data["utilization"];
            this.useForReimbursementOverlay = _data["useForReimbursementOverlay"];
            this.useForAncillarySelection = _data["useForAncillarySelection"];
            this.offerLevel = _data["offerLevel"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.teamBenefitTypesCarrier = _data["teamBenefitTypesCarrier"] ? TeamBenefitTypesCarrier.fromJS(_data["teamBenefitTypesCarrier"]) : <any>undefined;
            if (Array.isArray(_data["rhFiles"])) {
                this.rhFiles = [] as any;
                for (let item of _data["rhFiles"])
                    this.rhFiles!.push(RhFile.fromJS(item));
            }
            if (Array.isArray(_data["teamBenefitsRhFiles"])) {
                this.teamBenefitsRhFiles = [] as any;
                for (let item of _data["teamBenefitsRhFiles"])
                    this.teamBenefitsRhFiles!.push(TeamBenefitsRhFile.fromJS(item));
            }
            if (Array.isArray(_data["userTeamBenefitTermDetails"])) {
                this.userTeamBenefitTermDetails = [] as any;
                for (let item of _data["userTeamBenefitTermDetails"])
                    this.userTeamBenefitTermDetails!.push(UserTeamBenefitTermDetail.fromJS(item));
            }
            if (Array.isArray(_data["teamBenefitTermDetails"])) {
                this.teamBenefitTermDetails = [] as any;
                for (let item of _data["teamBenefitTermDetails"])
                    this.teamBenefitTermDetails!.push(TeamBenefitTermDetail.fromJS(item));
            }
            this.teamBenefitType = _data["teamBenefitType"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): TeamBenefit {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefit();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["teamBenefitTypesCarrierId"] = this.teamBenefitTypesCarrierId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["nextRenewableDate"] = this.nextRenewableDate;
        data["nextMemberEnrollmentStartDate"] = this.nextMemberEnrollmentStartDate;
        data["nextMemberEnrollmentEndDate"] = this.nextMemberEnrollmentEndDate;
        data["rhManagementDate"] = this.rhManagementDate;
        data["eligibilityWaitingDays"] = this.eligibilityWaitingDays;
        data["rhManagementTypeId"] = this.rhManagementTypeId;
        data["planMinimumCost"] = this.planMinimumCost;
        data["planName"] = this.planName;
        data["groupPlanId"] = this.groupPlanId;
        data["rhFee"] = this.rhFee;
        data["submitExpenseUrl"] = this.submitExpenseUrl;
        data["utilization"] = this.utilization;
        data["useForReimbursementOverlay"] = this.useForReimbursementOverlay;
        data["useForAncillarySelection"] = this.useForAncillarySelection;
        data["offerLevel"] = this.offerLevel;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["teamBenefitTypesCarrier"] = this.teamBenefitTypesCarrier ? this.teamBenefitTypesCarrier.toJSON() : <any>undefined;
        if (Array.isArray(this.rhFiles)) {
            data["rhFiles"] = [];
            for (let item of this.rhFiles)
                data["rhFiles"].push(item.toJSON());
        }
        if (Array.isArray(this.teamBenefitsRhFiles)) {
            data["teamBenefitsRhFiles"] = [];
            for (let item of this.teamBenefitsRhFiles)
                data["teamBenefitsRhFiles"].push(item.toJSON());
        }
        if (Array.isArray(this.userTeamBenefitTermDetails)) {
            data["userTeamBenefitTermDetails"] = [];
            for (let item of this.userTeamBenefitTermDetails)
                data["userTeamBenefitTermDetails"].push(item.toJSON());
        }
        if (Array.isArray(this.teamBenefitTermDetails)) {
            data["teamBenefitTermDetails"] = [];
            for (let item of this.teamBenefitTermDetails)
                data["teamBenefitTermDetails"].push(item.toJSON());
        }
        data["teamBenefitType"] = this.teamBenefitType;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class Team implements ITeam {
    teamId!: string;
    name?: string | undefined;
    addressId!: string;
    address?: Address | undefined;
    isCustomer!: boolean;
    isPartner!: boolean;
    isAdvisor!: boolean;
    partTimeEmployeeCount?: number | undefined;
    fullTimeEmployeeCount?: number | undefined;
    ownerId?: string | undefined;
    representativeId?: string | undefined;
    advisementTeamId?: string | undefined;
    adpIntegrationKey?: string | undefined;
    adpOrganizationId?: string | undefined;
    adpClientId?: string | undefined;
    adpClientSecret?: string | undefined;
    majorMedicalWaitingPeriodId!: MajorMedicalWaitingPeriodIds;
    majorMedicalWaitingPeriodOffset?: number | undefined;
    terminationDateDelayId!: TerminationDateDelays;
    hrsIntegrationProvider?: HrsIntegrationProviders | undefined;
    adpIntegrationStatus!: AdpIntegrationStatuses;
    postTaxDeductionCode?: string | undefined;
    wageUpDeductionCode?: string | undefined;
    preTaxDeductionCode?: string | undefined;
    taxFreeReimbursementDeductionCode?: string | undefined;
    hrsEligibilityClassCodes?: string[] | undefined;
    paylocityIntegrationKey?: string | undefined;
    paylocityOrganizationId?: string | undefined;
    paylocityClientId?: string | undefined;
    paylocityClientSecret?: string | undefined;
    paylocityIntegrationStatus!: PaylocityIntegrationStatuses;
    hasPaylocityIntegration!: boolean;
    finchAccessToken?: string | undefined;
    finchProviderId?: string | undefined;
    finchIntegrationStatus!: FinchIntegrationStatuses;
    teamStateId!: TeamStateIds;
    isArchived!: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    accountId?: string | undefined;
    legalName?: string | undefined;
    fein?: string | undefined;
    primaryPhoneNumber?: string | undefined;
    secondaryPhoneNumber?: string | undefined;
    website?: string | undefined;
    originalStartDate?: string | undefined;
    memberAllowShoppingDefault!: boolean;
    wagePlusShoppingConfiguration!: ShoppingConfigurationIds;
    ichraShoppingConfiguration!: ShoppingConfigurationIds;
    usePremiumTaxCreditsDefault!: boolean;
    calendlyAdvisementLink?: string | undefined;
    medicareCalendlyLink?: string | undefined;
    vipCalendlyLink?: string | undefined;
    sbeEnrollmentCalendlyLink?: string | undefined;
    hbaLink?: string | undefined;
    isColonialContractSigned?: boolean | undefined;
    activeDate?: string | undefined;
    surveyYear?: number | undefined;
    inactiveDate?: string | undefined;
    parentTeamId!: string;
    partnerTeamId?: PartnerTeams | undefined;
    enrollmentDeadline?: string | undefined;
    useIchraLaunchFlow!: boolean;
    useReimbursementProgramManagement!: boolean;
    useMultiFactorAuthorization!: boolean;
    allowRememberDeviceForMfa!: boolean;
    allowRecurringExpensesByDefault!: boolean;
    reimbursementReportFrequencyId!: ReimbursementReportFrequencies;
    expenseLockDateOffset!: number;
    payrollReportSharingEnabled!: boolean;
    payrollReportLockDay!: number;
    payrollReportShareDay?: number | undefined;
    includeFaithBasedQuestionInSurvey!: boolean;
    includeQualitativeQuestionsInSurvey!: boolean;
    defaultSurveyTypeToSend!: SurveyTypes;
    setWageUpAndWithholdingOnPayrollReportShare!: boolean;
    isIchraExpress!: boolean;
    hasPaymentsSetup!: boolean;
    show1095CCoding!: boolean;
    teamAdminForEmailId?: string | undefined;
    brokerAgencyName?: string | undefined;
    brokerAgentName?: string | undefined;
    brokerEmail?: string | undefined;
    brokerPhoneNumber?: string | undefined;
    capitalBlueCrossPaStockId?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamNotes?: TeamNote[] | undefined;
    teamRhFiles?: TeamRhFile[] | undefined;
    users?: User[] | undefined;
    payrollReports?: PayrollReport[] | undefined;
    teamOperationsInfos?: TeamOperationsInfo[] | undefined;
    keyId!: string;
    originalParentTeamId!: string;

    constructor(data?: ITeam) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
            this.name = _data["name"];
            this.addressId = _data["addressId"];
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
            this.isCustomer = _data["isCustomer"];
            this.isPartner = _data["isPartner"];
            this.isAdvisor = _data["isAdvisor"];
            this.partTimeEmployeeCount = _data["partTimeEmployeeCount"];
            this.fullTimeEmployeeCount = _data["fullTimeEmployeeCount"];
            this.ownerId = _data["ownerId"];
            this.representativeId = _data["representativeId"];
            this.advisementTeamId = _data["advisementTeamId"];
            this.adpIntegrationKey = _data["adpIntegrationKey"];
            this.adpOrganizationId = _data["adpOrganizationId"];
            this.adpClientId = _data["adpClientId"];
            this.adpClientSecret = _data["adpClientSecret"];
            this.majorMedicalWaitingPeriodId = _data["majorMedicalWaitingPeriodId"];
            this.majorMedicalWaitingPeriodOffset = _data["majorMedicalWaitingPeriodOffset"];
            this.terminationDateDelayId = _data["terminationDateDelayId"];
            this.hrsIntegrationProvider = _data["hrsIntegrationProvider"];
            this.adpIntegrationStatus = _data["adpIntegrationStatus"];
            this.postTaxDeductionCode = _data["postTaxDeductionCode"];
            this.wageUpDeductionCode = _data["wageUpDeductionCode"];
            this.preTaxDeductionCode = _data["preTaxDeductionCode"];
            this.taxFreeReimbursementDeductionCode = _data["taxFreeReimbursementDeductionCode"];
            if (Array.isArray(_data["hrsEligibilityClassCodes"])) {
                this.hrsEligibilityClassCodes = [] as any;
                for (let item of _data["hrsEligibilityClassCodes"])
                    this.hrsEligibilityClassCodes!.push(item);
            }
            this.paylocityIntegrationKey = _data["paylocityIntegrationKey"];
            this.paylocityOrganizationId = _data["paylocityOrganizationId"];
            this.paylocityClientId = _data["paylocityClientId"];
            this.paylocityClientSecret = _data["paylocityClientSecret"];
            this.paylocityIntegrationStatus = _data["paylocityIntegrationStatus"];
            this.hasPaylocityIntegration = _data["hasPaylocityIntegration"];
            this.finchAccessToken = _data["finchAccessToken"];
            this.finchProviderId = _data["finchProviderId"];
            this.finchIntegrationStatus = _data["finchIntegrationStatus"];
            this.teamStateId = _data["teamStateId"];
            this.isArchived = _data["isArchived"];
            this.archivedDate = _data["archivedDate"];
            this.archivedBy = _data["archivedBy"];
            this.accountId = _data["accountId"];
            this.legalName = _data["legalName"];
            this.fein = _data["fein"];
            this.primaryPhoneNumber = _data["primaryPhoneNumber"];
            this.secondaryPhoneNumber = _data["secondaryPhoneNumber"];
            this.website = _data["website"];
            this.originalStartDate = _data["originalStartDate"];
            this.memberAllowShoppingDefault = _data["memberAllowShoppingDefault"];
            this.wagePlusShoppingConfiguration = _data["wagePlusShoppingConfiguration"];
            this.ichraShoppingConfiguration = _data["ichraShoppingConfiguration"];
            this.usePremiumTaxCreditsDefault = _data["usePremiumTaxCreditsDefault"];
            this.calendlyAdvisementLink = _data["calendlyAdvisementLink"];
            this.medicareCalendlyLink = _data["medicareCalendlyLink"];
            this.vipCalendlyLink = _data["vipCalendlyLink"];
            this.sbeEnrollmentCalendlyLink = _data["sbeEnrollmentCalendlyLink"];
            this.hbaLink = _data["hbaLink"];
            this.isColonialContractSigned = _data["isColonialContractSigned"];
            this.activeDate = _data["activeDate"];
            this.surveyYear = _data["surveyYear"];
            this.inactiveDate = _data["inactiveDate"];
            this.parentTeamId = _data["parentTeamId"];
            this.partnerTeamId = _data["partnerTeamId"];
            this.enrollmentDeadline = _data["enrollmentDeadline"];
            this.useIchraLaunchFlow = _data["useIchraLaunchFlow"];
            this.useReimbursementProgramManagement = _data["useReimbursementProgramManagement"];
            this.useMultiFactorAuthorization = _data["useMultiFactorAuthorization"];
            this.allowRememberDeviceForMfa = _data["allowRememberDeviceForMfa"];
            this.allowRecurringExpensesByDefault = _data["allowRecurringExpensesByDefault"];
            this.reimbursementReportFrequencyId = _data["reimbursementReportFrequencyId"];
            this.expenseLockDateOffset = _data["expenseLockDateOffset"];
            this.payrollReportSharingEnabled = _data["payrollReportSharingEnabled"];
            this.payrollReportLockDay = _data["payrollReportLockDay"];
            this.payrollReportShareDay = _data["payrollReportShareDay"];
            this.includeFaithBasedQuestionInSurvey = _data["includeFaithBasedQuestionInSurvey"];
            this.includeQualitativeQuestionsInSurvey = _data["includeQualitativeQuestionsInSurvey"];
            this.defaultSurveyTypeToSend = _data["defaultSurveyTypeToSend"];
            this.setWageUpAndWithholdingOnPayrollReportShare = _data["setWageUpAndWithholdingOnPayrollReportShare"];
            this.isIchraExpress = _data["isIchraExpress"];
            this.hasPaymentsSetup = _data["hasPaymentsSetup"];
            this.show1095CCoding = _data["show1095CCoding"];
            this.teamAdminForEmailId = _data["teamAdminForEmailId"];
            this.brokerAgencyName = _data["brokerAgencyName"];
            this.brokerAgentName = _data["brokerAgentName"];
            this.brokerEmail = _data["brokerEmail"];
            this.brokerPhoneNumber = _data["brokerPhoneNumber"];
            this.capitalBlueCrossPaStockId = _data["capitalBlueCrossPaStockId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            if (Array.isArray(_data["teamNotes"])) {
                this.teamNotes = [] as any;
                for (let item of _data["teamNotes"])
                    this.teamNotes!.push(TeamNote.fromJS(item));
            }
            if (Array.isArray(_data["teamRhFiles"])) {
                this.teamRhFiles = [] as any;
                for (let item of _data["teamRhFiles"])
                    this.teamRhFiles!.push(TeamRhFile.fromJS(item));
            }
            if (Array.isArray(_data["users"])) {
                this.users = [] as any;
                for (let item of _data["users"])
                    this.users!.push(User.fromJS(item));
            }
            if (Array.isArray(_data["payrollReports"])) {
                this.payrollReports = [] as any;
                for (let item of _data["payrollReports"])
                    this.payrollReports!.push(PayrollReport.fromJS(item));
            }
            if (Array.isArray(_data["teamOperationsInfos"])) {
                this.teamOperationsInfos = [] as any;
                for (let item of _data["teamOperationsInfos"])
                    this.teamOperationsInfos!.push(TeamOperationsInfo.fromJS(item));
            }
            this.keyId = _data["keyId"];
            this.originalParentTeamId = _data["originalParentTeamId"];
        }
    }

    static fromJS(data: any): Team {
        data = typeof data === 'object' ? data : {};
        let result = new Team();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        data["name"] = this.name;
        data["addressId"] = this.addressId;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        data["isCustomer"] = this.isCustomer;
        data["isPartner"] = this.isPartner;
        data["isAdvisor"] = this.isAdvisor;
        data["partTimeEmployeeCount"] = this.partTimeEmployeeCount;
        data["fullTimeEmployeeCount"] = this.fullTimeEmployeeCount;
        data["ownerId"] = this.ownerId;
        data["representativeId"] = this.representativeId;
        data["advisementTeamId"] = this.advisementTeamId;
        data["adpIntegrationKey"] = this.adpIntegrationKey;
        data["adpOrganizationId"] = this.adpOrganizationId;
        data["adpClientId"] = this.adpClientId;
        data["adpClientSecret"] = this.adpClientSecret;
        data["majorMedicalWaitingPeriodId"] = this.majorMedicalWaitingPeriodId;
        data["majorMedicalWaitingPeriodOffset"] = this.majorMedicalWaitingPeriodOffset;
        data["terminationDateDelayId"] = this.terminationDateDelayId;
        data["hrsIntegrationProvider"] = this.hrsIntegrationProvider;
        data["adpIntegrationStatus"] = this.adpIntegrationStatus;
        data["postTaxDeductionCode"] = this.postTaxDeductionCode;
        data["wageUpDeductionCode"] = this.wageUpDeductionCode;
        data["preTaxDeductionCode"] = this.preTaxDeductionCode;
        data["taxFreeReimbursementDeductionCode"] = this.taxFreeReimbursementDeductionCode;
        if (Array.isArray(this.hrsEligibilityClassCodes)) {
            data["hrsEligibilityClassCodes"] = [];
            for (let item of this.hrsEligibilityClassCodes)
                data["hrsEligibilityClassCodes"].push(item);
        }
        data["paylocityIntegrationKey"] = this.paylocityIntegrationKey;
        data["paylocityOrganizationId"] = this.paylocityOrganizationId;
        data["paylocityClientId"] = this.paylocityClientId;
        data["paylocityClientSecret"] = this.paylocityClientSecret;
        data["paylocityIntegrationStatus"] = this.paylocityIntegrationStatus;
        data["hasPaylocityIntegration"] = this.hasPaylocityIntegration;
        data["finchAccessToken"] = this.finchAccessToken;
        data["finchProviderId"] = this.finchProviderId;
        data["finchIntegrationStatus"] = this.finchIntegrationStatus;
        data["teamStateId"] = this.teamStateId;
        data["isArchived"] = this.isArchived;
        data["archivedDate"] = this.archivedDate;
        data["archivedBy"] = this.archivedBy;
        data["accountId"] = this.accountId;
        data["legalName"] = this.legalName;
        data["fein"] = this.fein;
        data["primaryPhoneNumber"] = this.primaryPhoneNumber;
        data["secondaryPhoneNumber"] = this.secondaryPhoneNumber;
        data["website"] = this.website;
        data["originalStartDate"] = this.originalStartDate;
        data["memberAllowShoppingDefault"] = this.memberAllowShoppingDefault;
        data["wagePlusShoppingConfiguration"] = this.wagePlusShoppingConfiguration;
        data["ichraShoppingConfiguration"] = this.ichraShoppingConfiguration;
        data["usePremiumTaxCreditsDefault"] = this.usePremiumTaxCreditsDefault;
        data["calendlyAdvisementLink"] = this.calendlyAdvisementLink;
        data["medicareCalendlyLink"] = this.medicareCalendlyLink;
        data["vipCalendlyLink"] = this.vipCalendlyLink;
        data["sbeEnrollmentCalendlyLink"] = this.sbeEnrollmentCalendlyLink;
        data["hbaLink"] = this.hbaLink;
        data["isColonialContractSigned"] = this.isColonialContractSigned;
        data["activeDate"] = this.activeDate;
        data["surveyYear"] = this.surveyYear;
        data["inactiveDate"] = this.inactiveDate;
        data["parentTeamId"] = this.parentTeamId;
        data["partnerTeamId"] = this.partnerTeamId;
        data["enrollmentDeadline"] = this.enrollmentDeadline;
        data["useIchraLaunchFlow"] = this.useIchraLaunchFlow;
        data["useReimbursementProgramManagement"] = this.useReimbursementProgramManagement;
        data["useMultiFactorAuthorization"] = this.useMultiFactorAuthorization;
        data["allowRememberDeviceForMfa"] = this.allowRememberDeviceForMfa;
        data["allowRecurringExpensesByDefault"] = this.allowRecurringExpensesByDefault;
        data["reimbursementReportFrequencyId"] = this.reimbursementReportFrequencyId;
        data["expenseLockDateOffset"] = this.expenseLockDateOffset;
        data["payrollReportSharingEnabled"] = this.payrollReportSharingEnabled;
        data["payrollReportLockDay"] = this.payrollReportLockDay;
        data["payrollReportShareDay"] = this.payrollReportShareDay;
        data["includeFaithBasedQuestionInSurvey"] = this.includeFaithBasedQuestionInSurvey;
        data["includeQualitativeQuestionsInSurvey"] = this.includeQualitativeQuestionsInSurvey;
        data["defaultSurveyTypeToSend"] = this.defaultSurveyTypeToSend;
        data["setWageUpAndWithholdingOnPayrollReportShare"] = this.setWageUpAndWithholdingOnPayrollReportShare;
        data["isIchraExpress"] = this.isIchraExpress;
        data["hasPaymentsSetup"] = this.hasPaymentsSetup;
        data["show1095CCoding"] = this.show1095CCoding;
        data["teamAdminForEmailId"] = this.teamAdminForEmailId;
        data["brokerAgencyName"] = this.brokerAgencyName;
        data["brokerAgentName"] = this.brokerAgentName;
        data["brokerEmail"] = this.brokerEmail;
        data["brokerPhoneNumber"] = this.brokerPhoneNumber;
        data["capitalBlueCrossPaStockId"] = this.capitalBlueCrossPaStockId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        if (Array.isArray(this.teamNotes)) {
            data["teamNotes"] = [];
            for (let item of this.teamNotes)
                data["teamNotes"].push(item.toJSON());
        }
        if (Array.isArray(this.teamRhFiles)) {
            data["teamRhFiles"] = [];
            for (let item of this.teamRhFiles)
                data["teamRhFiles"].push(item.toJSON());
        }
        if (Array.isArray(this.users)) {
            data["users"] = [];
            for (let item of this.users)
                data["users"].push(item.toJSON());
        }
        if (Array.isArray(this.payrollReports)) {
            data["payrollReports"] = [];
            for (let item of this.payrollReports)
                data["payrollReports"].push(item.toJSON());
        }
        if (Array.isArray(this.teamOperationsInfos)) {
            data["teamOperationsInfos"] = [];
            for (let item of this.teamOperationsInfos)
                data["teamOperationsInfos"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        data["originalParentTeamId"] = this.originalParentTeamId;
        return data; 
    }
}
export class TeamNote implements ITeamNote {
    teamNoteId!: string;
    teamId!: string;
    noteId!: string;
    note?: Note | undefined;
    keyId!: string;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: ITeamNote) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamNoteId = _data["teamNoteId"];
            this.teamId = _data["teamId"];
            this.noteId = _data["noteId"];
            this.note = _data["note"] ? Note.fromJS(_data["note"]) : <any>undefined;
            this.keyId = _data["keyId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): TeamNote {
        data = typeof data === 'object' ? data : {};
        let result = new TeamNote();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamNoteId"] = this.teamNoteId;
        data["teamId"] = this.teamId;
        data["noteId"] = this.noteId;
        data["note"] = this.note ? this.note.toJSON() : <any>undefined;
        data["keyId"] = this.keyId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class TeamRhFile implements ITeamRhFile {
    id!: string;
    teamId!: string;
    rhFileId!: string;
    rhFile?: RhFile | undefined;
    joinEntityId!: string;

    constructor(data?: ITeamRhFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.rhFileId = _data["rhFileId"];
            this.rhFile = _data["rhFile"] ? RhFile.fromJS(_data["rhFile"]) : <any>undefined;
            this.joinEntityId = _data["joinEntityId"];
        }
    }

    static fromJS(data: any): TeamRhFile {
        data = typeof data === 'object' ? data : {};
        let result = new TeamRhFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["rhFileId"] = this.rhFileId;
        data["rhFile"] = this.rhFile ? this.rhFile.toJSON() : <any>undefined;
        data["joinEntityId"] = this.joinEntityId;
        return data; 
    }
}
export class RhFile implements IRhFile {
    id!: string;
    originalFileName?: string | undefined;
    fileSizeInBytes?: number | undefined;
    fileExtension?: string | undefined;
    name?: string | undefined;
    isShared!: boolean;
    createdDate!: string;
    createdBy!: string;
    createdByUser?: User | undefined;
    isArchived!: boolean;
    archivedDate?: string | undefined;
    archivedBy?: string | undefined;
    archivedByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    rhFileCategories?: RhFileCategory[] | undefined;
    keyId!: string;

    constructor(data?: IRhFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.originalFileName = _data["originalFileName"];
            this.fileSizeInBytes = _data["fileSizeInBytes"];
            this.fileExtension = _data["fileExtension"];
            this.name = _data["name"];
            this.isShared = _data["isShared"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.isArchived = _data["isArchived"];
            this.archivedDate = _data["archivedDate"];
            this.archivedBy = _data["archivedBy"];
            this.archivedByUser = _data["archivedByUser"] ? User.fromJS(_data["archivedByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? User.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
            if (Array.isArray(_data["rhFileCategories"])) {
                this.rhFileCategories = [] as any;
                for (let item of _data["rhFileCategories"])
                    this.rhFileCategories!.push(RhFileCategory.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): RhFile {
        data = typeof data === 'object' ? data : {};
        let result = new RhFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["originalFileName"] = this.originalFileName;
        data["fileSizeInBytes"] = this.fileSizeInBytes;
        data["fileExtension"] = this.fileExtension;
        data["name"] = this.name;
        data["isShared"] = this.isShared;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["isArchived"] = this.isArchived;
        data["archivedDate"] = this.archivedDate;
        data["archivedBy"] = this.archivedBy;
        data["archivedByUser"] = this.archivedByUser ? this.archivedByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        if (Array.isArray(this.rhFileCategories)) {
            data["rhFileCategories"] = [];
            for (let item of this.rhFileCategories)
                data["rhFileCategories"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class RhFileCategory implements IRhFileCategory {
    id!: string;
    rhFileId!: string;
    categoryId!: CategoryIds;

    constructor(data?: IRhFileCategory) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.rhFileId = _data["rhFileId"];
            this.categoryId = _data["categoryId"];
        }
    }

    static fromJS(data: any): RhFileCategory {
        data = typeof data === 'object' ? data : {};
        let result = new RhFileCategory();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["rhFileId"] = this.rhFileId;
        data["categoryId"] = this.categoryId;
        return data; 
    }
}
export class PayrollReport implements IPayrollReport {
    id!: string;
    teamId!: string;
    team?: Team | undefined;
    year!: number;
    month!: number;
    isShared!: boolean;
    isLocked!: boolean;
    note?: string | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;
    payrollReportUserSnapshots?: PayrollReportUserSnapshot[] | undefined;
    changes?: PayrollReportUserChangeLog[] | undefined;
    wageUpTotal!: number;
    ichraReimbursementTotal!: number;
    teamBenefitsTotal!: number;
    expenseSummary?: ExpenseSummary | undefined;

    constructor(data?: IPayrollReport) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.year = _data["year"];
            this.month = _data["month"];
            this.isShared = _data["isShared"];
            this.isLocked = _data["isLocked"];
            this.note = _data["note"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
            if (Array.isArray(_data["payrollReportUserSnapshots"])) {
                this.payrollReportUserSnapshots = [] as any;
                for (let item of _data["payrollReportUserSnapshots"])
                    this.payrollReportUserSnapshots!.push(PayrollReportUserSnapshot.fromJS(item));
            }
            if (Array.isArray(_data["changes"])) {
                this.changes = [] as any;
                for (let item of _data["changes"])
                    this.changes!.push(PayrollReportUserChangeLog.fromJS(item));
            }
            this.wageUpTotal = _data["wageUpTotal"];
            this.ichraReimbursementTotal = _data["ichraReimbursementTotal"];
            this.teamBenefitsTotal = _data["teamBenefitsTotal"];
            this.expenseSummary = _data["expenseSummary"] ? ExpenseSummary.fromJS(_data["expenseSummary"]) : <any>undefined;
        }
    }

    static fromJS(data: any): PayrollReport {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReport();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["year"] = this.year;
        data["month"] = this.month;
        data["isShared"] = this.isShared;
        data["isLocked"] = this.isLocked;
        data["note"] = this.note;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        if (Array.isArray(this.payrollReportUserSnapshots)) {
            data["payrollReportUserSnapshots"] = [];
            for (let item of this.payrollReportUserSnapshots)
                data["payrollReportUserSnapshots"].push(item.toJSON());
        }
        if (Array.isArray(this.changes)) {
            data["changes"] = [];
            for (let item of this.changes)
                data["changes"].push(item.toJSON());
        }
        data["wageUpTotal"] = this.wageUpTotal;
        data["ichraReimbursementTotal"] = this.ichraReimbursementTotal;
        data["teamBenefitsTotal"] = this.teamBenefitsTotal;
        data["expenseSummary"] = this.expenseSummary ? this.expenseSummary.toJSON() : <any>undefined;
        return data; 
    }
}
export class PayrollReportUserSnapshot implements IPayrollReportUserSnapshot {
    id!: string;
    payrollReportId!: string;
    payrollReport?: PayrollReport | undefined;
    userId?: string | undefined;
    user?: User | undefined;
    payrollSystemId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userStatus?: UserStatus | undefined;
    groupLevel!: GroupLevels;
    wageUp?: number | undefined;
    wageUpAdjustment?: number | undefined;
    wageUpTotal!: number;
    ichraReimbursement?: number | undefined;
    ichraReimbursementAdjustment?: number | undefined;
    ichraReimbursementTotal!: number;
    isHsaEligible?: boolean | undefined;
    preTaxWithholding!: number;
    preTaxWithholdingAdjustment?: number | undefined;
    preTaxWithholdingTotal!: number;
    postTaxWithholding!: number;
    postTaxWithholdingAdjustment?: number | undefined;
    postTaxWithholdingTotal!: number;
    taxFreeReimbursement!: number;
    taxFreeReimbursementAdjustment?: number | undefined;
    taxFreeReimbursementTotal!: number;
    reimbursablePremiumsTotal!: number;
    note?: string | undefined;
    teamBenefits?: PayrollReportUserSnapshotTeamBenefit[] | undefined;
    postTaxPlans?: PayrollReportUserSnapshotPostTaxPlan[] | undefined;
    preTaxPlans?: PayrollReportUserSnapshotPreTaxPlan[] | undefined;
    majorMedicalPlans?: PayrollReportUserSnapshotMajorMedicalPlan[] | undefined;
    reimbursablePremiums?: ReimbursablePremium[] | undefined;
    taxFreeReimbursements?: PayrollReportUserSnapshotTaxFreeReimbursement[] | undefined;
    noteLastModifiedDate?: string | undefined;
    noteLastModifiedBy?: string | undefined;
    noteLastModifiedByUser?: User | undefined;
    hsaTermDetailId?: string | undefined;
    hsaEmployerContribution?: number | undefined;
    customHsaEmployerContribution?: number | undefined;
    fsaTermDetailId?: string | undefined;
    preventativeMecTeamBenefitId?: string | undefined;
    hsaEligibleSelectedPlanId?: string | undefined;
    hsaEligibleSelectedPlan?: SelectedPlan | undefined;
    fsaEmployerContribution?: number | undefined;
    customFsaEmployerContribution?: number | undefined;
    reimbursementSummary?: ReimbursementSummary | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    ficaTaxRate?: number | undefined;
    lineFourteenCode?: LineFourteenCodes | undefined;
    lineSixteenCode?: LineSixteenCodes | undefined;
    lineSeventeenCode?: string | undefined;
    isHrsAssociateIdSet!: boolean;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshot) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportId = _data["payrollReportId"];
            this.payrollReport = _data["payrollReport"] ? PayrollReport.fromJS(_data["payrollReport"]) : <any>undefined;
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.payrollSystemId = _data["payrollSystemId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.userStatus = _data["userStatus"];
            this.groupLevel = _data["groupLevel"];
            this.wageUp = _data["wageUp"];
            this.wageUpAdjustment = _data["wageUpAdjustment"];
            this.wageUpTotal = _data["wageUpTotal"];
            this.ichraReimbursement = _data["ichraReimbursement"];
            this.ichraReimbursementAdjustment = _data["ichraReimbursementAdjustment"];
            this.ichraReimbursementTotal = _data["ichraReimbursementTotal"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.preTaxWithholding = _data["preTaxWithholding"];
            this.preTaxWithholdingAdjustment = _data["preTaxWithholdingAdjustment"];
            this.preTaxWithholdingTotal = _data["preTaxWithholdingTotal"];
            this.postTaxWithholding = _data["postTaxWithholding"];
            this.postTaxWithholdingAdjustment = _data["postTaxWithholdingAdjustment"];
            this.postTaxWithholdingTotal = _data["postTaxWithholdingTotal"];
            this.taxFreeReimbursement = _data["taxFreeReimbursement"];
            this.taxFreeReimbursementAdjustment = _data["taxFreeReimbursementAdjustment"];
            this.taxFreeReimbursementTotal = _data["taxFreeReimbursementTotal"];
            this.reimbursablePremiumsTotal = _data["reimbursablePremiumsTotal"];
            this.note = _data["note"];
            if (Array.isArray(_data["teamBenefits"])) {
                this.teamBenefits = [] as any;
                for (let item of _data["teamBenefits"])
                    this.teamBenefits!.push(PayrollReportUserSnapshotTeamBenefit.fromJS(item));
            }
            if (Array.isArray(_data["postTaxPlans"])) {
                this.postTaxPlans = [] as any;
                for (let item of _data["postTaxPlans"])
                    this.postTaxPlans!.push(PayrollReportUserSnapshotPostTaxPlan.fromJS(item));
            }
            if (Array.isArray(_data["preTaxPlans"])) {
                this.preTaxPlans = [] as any;
                for (let item of _data["preTaxPlans"])
                    this.preTaxPlans!.push(PayrollReportUserSnapshotPreTaxPlan.fromJS(item));
            }
            if (Array.isArray(_data["majorMedicalPlans"])) {
                this.majorMedicalPlans = [] as any;
                for (let item of _data["majorMedicalPlans"])
                    this.majorMedicalPlans!.push(PayrollReportUserSnapshotMajorMedicalPlan.fromJS(item));
            }
            if (Array.isArray(_data["reimbursablePremiums"])) {
                this.reimbursablePremiums = [] as any;
                for (let item of _data["reimbursablePremiums"])
                    this.reimbursablePremiums!.push(ReimbursablePremium.fromJS(item));
            }
            if (Array.isArray(_data["taxFreeReimbursements"])) {
                this.taxFreeReimbursements = [] as any;
                for (let item of _data["taxFreeReimbursements"])
                    this.taxFreeReimbursements!.push(PayrollReportUserSnapshotTaxFreeReimbursement.fromJS(item));
            }
            this.noteLastModifiedDate = _data["noteLastModifiedDate"];
            this.noteLastModifiedBy = _data["noteLastModifiedBy"];
            this.noteLastModifiedByUser = _data["noteLastModifiedByUser"] ? User.fromJS(_data["noteLastModifiedByUser"]) : <any>undefined;
            this.hsaTermDetailId = _data["hsaTermDetailId"];
            this.hsaEmployerContribution = _data["hsaEmployerContribution"];
            this.customHsaEmployerContribution = _data["customHsaEmployerContribution"];
            this.fsaTermDetailId = _data["fsaTermDetailId"];
            this.preventativeMecTeamBenefitId = _data["preventativeMecTeamBenefitId"];
            this.hsaEligibleSelectedPlanId = _data["hsaEligibleSelectedPlanId"];
            this.hsaEligibleSelectedPlan = _data["hsaEligibleSelectedPlan"] ? SelectedPlan.fromJS(_data["hsaEligibleSelectedPlan"]) : <any>undefined;
            this.fsaEmployerContribution = _data["fsaEmployerContribution"];
            this.customFsaEmployerContribution = _data["customFsaEmployerContribution"];
            this.reimbursementSummary = _data["reimbursementSummary"] ? ReimbursementSummary.fromJS(_data["reimbursementSummary"]) : <any>undefined;
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.ficaTaxRate = _data["ficaTaxRate"];
            this.lineFourteenCode = _data["lineFourteenCode"];
            this.lineSixteenCode = _data["lineSixteenCode"];
            this.lineSeventeenCode = _data["lineSeventeenCode"];
            this.isHrsAssociateIdSet = _data["isHrsAssociateIdSet"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshot {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshot();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportId"] = this.payrollReportId;
        data["payrollReport"] = this.payrollReport ? this.payrollReport.toJSON() : <any>undefined;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["payrollSystemId"] = this.payrollSystemId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["userStatus"] = this.userStatus;
        data["groupLevel"] = this.groupLevel;
        data["wageUp"] = this.wageUp;
        data["wageUpAdjustment"] = this.wageUpAdjustment;
        data["wageUpTotal"] = this.wageUpTotal;
        data["ichraReimbursement"] = this.ichraReimbursement;
        data["ichraReimbursementAdjustment"] = this.ichraReimbursementAdjustment;
        data["ichraReimbursementTotal"] = this.ichraReimbursementTotal;
        data["isHsaEligible"] = this.isHsaEligible;
        data["preTaxWithholding"] = this.preTaxWithholding;
        data["preTaxWithholdingAdjustment"] = this.preTaxWithholdingAdjustment;
        data["preTaxWithholdingTotal"] = this.preTaxWithholdingTotal;
        data["postTaxWithholding"] = this.postTaxWithholding;
        data["postTaxWithholdingAdjustment"] = this.postTaxWithholdingAdjustment;
        data["postTaxWithholdingTotal"] = this.postTaxWithholdingTotal;
        data["taxFreeReimbursement"] = this.taxFreeReimbursement;
        data["taxFreeReimbursementAdjustment"] = this.taxFreeReimbursementAdjustment;
        data["taxFreeReimbursementTotal"] = this.taxFreeReimbursementTotal;
        data["reimbursablePremiumsTotal"] = this.reimbursablePremiumsTotal;
        data["note"] = this.note;
        if (Array.isArray(this.teamBenefits)) {
            data["teamBenefits"] = [];
            for (let item of this.teamBenefits)
                data["teamBenefits"].push(item.toJSON());
        }
        if (Array.isArray(this.postTaxPlans)) {
            data["postTaxPlans"] = [];
            for (let item of this.postTaxPlans)
                data["postTaxPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.preTaxPlans)) {
            data["preTaxPlans"] = [];
            for (let item of this.preTaxPlans)
                data["preTaxPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.majorMedicalPlans)) {
            data["majorMedicalPlans"] = [];
            for (let item of this.majorMedicalPlans)
                data["majorMedicalPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.reimbursablePremiums)) {
            data["reimbursablePremiums"] = [];
            for (let item of this.reimbursablePremiums)
                data["reimbursablePremiums"].push(item.toJSON());
        }
        if (Array.isArray(this.taxFreeReimbursements)) {
            data["taxFreeReimbursements"] = [];
            for (let item of this.taxFreeReimbursements)
                data["taxFreeReimbursements"].push(item.toJSON());
        }
        data["noteLastModifiedDate"] = this.noteLastModifiedDate;
        data["noteLastModifiedBy"] = this.noteLastModifiedBy;
        data["noteLastModifiedByUser"] = this.noteLastModifiedByUser ? this.noteLastModifiedByUser.toJSON() : <any>undefined;
        data["hsaTermDetailId"] = this.hsaTermDetailId;
        data["hsaEmployerContribution"] = this.hsaEmployerContribution;
        data["customHsaEmployerContribution"] = this.customHsaEmployerContribution;
        data["fsaTermDetailId"] = this.fsaTermDetailId;
        data["preventativeMecTeamBenefitId"] = this.preventativeMecTeamBenefitId;
        data["hsaEligibleSelectedPlanId"] = this.hsaEligibleSelectedPlanId;
        data["hsaEligibleSelectedPlan"] = this.hsaEligibleSelectedPlan ? this.hsaEligibleSelectedPlan.toJSON() : <any>undefined;
        data["fsaEmployerContribution"] = this.fsaEmployerContribution;
        data["customFsaEmployerContribution"] = this.customFsaEmployerContribution;
        data["reimbursementSummary"] = this.reimbursementSummary ? this.reimbursementSummary.toJSON() : <any>undefined;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["ficaTaxRate"] = this.ficaTaxRate;
        data["lineFourteenCode"] = this.lineFourteenCode;
        data["lineSixteenCode"] = this.lineSixteenCode;
        data["lineSeventeenCode"] = this.lineSeventeenCode;
        data["isHrsAssociateIdSet"] = this.isHrsAssociateIdSet;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PayrollReportUserSnapshotTeamBenefit implements IPayrollReportUserSnapshotTeamBenefit {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    userTeamBenefitTermDetailId?: string | undefined;
    userTeamBenefitTermDetail?: UserTeamBenefitTermDetail | undefined;
    teamBenefitType!: TeamBenefitTypes;
    employeeCost!: number;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshotTeamBenefit) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.userTeamBenefitTermDetailId = _data["userTeamBenefitTermDetailId"];
            this.userTeamBenefitTermDetail = _data["userTeamBenefitTermDetail"] ? UserTeamBenefitTermDetail.fromJS(_data["userTeamBenefitTermDetail"]) : <any>undefined;
            this.teamBenefitType = _data["teamBenefitType"];
            this.employeeCost = _data["employeeCost"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotTeamBenefit {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotTeamBenefit();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["userTeamBenefitTermDetailId"] = this.userTeamBenefitTermDetailId;
        data["userTeamBenefitTermDetail"] = this.userTeamBenefitTermDetail ? this.userTeamBenefitTermDetail.toJSON() : <any>undefined;
        data["teamBenefitType"] = this.teamBenefitType;
        data["employeeCost"] = this.employeeCost;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PayrollReportUserSnapshotPostTaxPlan implements IPayrollReportUserSnapshotPostTaxPlan {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType!: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    carrier?: string | undefined;
    planName?: string | undefined;
    premium!: number;
    premiumWithCredits!: number;
    appliedReimbursement!: number;
    healthCareTypeId?: HealthCareTypeIds | undefined;
    withheldAmount!: number;
    withholdingOrContribution!: number;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshotPostTaxPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.selectedPlanId = _data["selectedPlanId"];
            this.selectedPlan = _data["selectedPlan"] ? SelectedPlan.fromJS(_data["selectedPlan"]) : <any>undefined;
            this.reimbursablePremiumId = _data["reimbursablePremiumId"];
            this.reimbursablePremium = _data["reimbursablePremium"] ? ReimbursablePremium.fromJS(_data["reimbursablePremium"]) : <any>undefined;
            this.planType = _data["planType"];
            this.payTypeId = _data["payTypeId"];
            this.carrier = _data["carrier"];
            this.planName = _data["planName"];
            this.premium = _data["premium"];
            this.premiumWithCredits = _data["premiumWithCredits"];
            this.appliedReimbursement = _data["appliedReimbursement"];
            this.healthCareTypeId = _data["healthCareTypeId"];
            this.withheldAmount = _data["withheldAmount"];
            this.withholdingOrContribution = _data["withholdingOrContribution"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotPostTaxPlan {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotPostTaxPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["selectedPlanId"] = this.selectedPlanId;
        data["selectedPlan"] = this.selectedPlan ? this.selectedPlan.toJSON() : <any>undefined;
        data["reimbursablePremiumId"] = this.reimbursablePremiumId;
        data["reimbursablePremium"] = this.reimbursablePremium ? this.reimbursablePremium.toJSON() : <any>undefined;
        data["planType"] = this.planType;
        data["payTypeId"] = this.payTypeId;
        data["carrier"] = this.carrier;
        data["planName"] = this.planName;
        data["premium"] = this.premium;
        data["premiumWithCredits"] = this.premiumWithCredits;
        data["appliedReimbursement"] = this.appliedReimbursement;
        data["healthCareTypeId"] = this.healthCareTypeId;
        data["withheldAmount"] = this.withheldAmount;
        data["withholdingOrContribution"] = this.withholdingOrContribution;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ReimbursablePremium implements IReimbursablePremium {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    planType!: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    healthCareTypeId?: HealthCareTypeIds | undefined;
    planPremium!: number;
    adjustment?: number | undefined;
    total!: number;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IReimbursablePremium) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.selectedPlanId = _data["selectedPlanId"];
            this.selectedPlan = _data["selectedPlan"] ? SelectedPlan.fromJS(_data["selectedPlan"]) : <any>undefined;
            this.planType = _data["planType"];
            this.payTypeId = _data["payTypeId"];
            this.healthCareTypeId = _data["healthCareTypeId"];
            this.planPremium = _data["planPremium"];
            this.adjustment = _data["adjustment"];
            this.total = _data["total"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): ReimbursablePremium {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursablePremium();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["selectedPlanId"] = this.selectedPlanId;
        data["selectedPlan"] = this.selectedPlan ? this.selectedPlan.toJSON() : <any>undefined;
        data["planType"] = this.planType;
        data["payTypeId"] = this.payTypeId;
        data["healthCareTypeId"] = this.healthCareTypeId;
        data["planPremium"] = this.planPremium;
        data["adjustment"] = this.adjustment;
        data["total"] = this.total;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PayrollReportUserSnapshotPreTaxPlan implements IPayrollReportUserSnapshotPreTaxPlan {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType!: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    healthCareType?: HealthCareTypeIds | undefined;
    premium!: number;
    appliedReimbursement!: number;
    withheldAmount!: number;
    withholdingOrContribution!: number;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshotPreTaxPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.selectedPlanId = _data["selectedPlanId"];
            this.selectedPlan = _data["selectedPlan"] ? SelectedPlan.fromJS(_data["selectedPlan"]) : <any>undefined;
            this.reimbursablePremiumId = _data["reimbursablePremiumId"];
            this.reimbursablePremium = _data["reimbursablePremium"] ? ReimbursablePremium.fromJS(_data["reimbursablePremium"]) : <any>undefined;
            this.planType = _data["planType"];
            this.payTypeId = _data["payTypeId"];
            this.healthCareType = _data["healthCareType"];
            this.premium = _data["premium"];
            this.appliedReimbursement = _data["appliedReimbursement"];
            this.withheldAmount = _data["withheldAmount"];
            this.withholdingOrContribution = _data["withholdingOrContribution"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotPreTaxPlan {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotPreTaxPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["selectedPlanId"] = this.selectedPlanId;
        data["selectedPlan"] = this.selectedPlan ? this.selectedPlan.toJSON() : <any>undefined;
        data["reimbursablePremiumId"] = this.reimbursablePremiumId;
        data["reimbursablePremium"] = this.reimbursablePremium ? this.reimbursablePremium.toJSON() : <any>undefined;
        data["planType"] = this.planType;
        data["payTypeId"] = this.payTypeId;
        data["healthCareType"] = this.healthCareType;
        data["premium"] = this.premium;
        data["appliedReimbursement"] = this.appliedReimbursement;
        data["withheldAmount"] = this.withheldAmount;
        data["withholdingOrContribution"] = this.withholdingOrContribution;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PayrollReportUserSnapshotMajorMedicalPlan implements IPayrollReportUserSnapshotMajorMedicalPlan {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    planType!: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshotMajorMedicalPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.selectedPlanId = _data["selectedPlanId"];
            this.selectedPlan = _data["selectedPlan"] ? SelectedPlan.fromJS(_data["selectedPlan"]) : <any>undefined;
            this.planType = _data["planType"];
            this.payTypeId = _data["payTypeId"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotMajorMedicalPlan {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotMajorMedicalPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["selectedPlanId"] = this.selectedPlanId;
        data["selectedPlan"] = this.selectedPlan ? this.selectedPlan.toJSON() : <any>undefined;
        data["planType"] = this.planType;
        data["payTypeId"] = this.payTypeId;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PayrollReportUserSnapshotTaxFreeReimbursement implements IPayrollReportUserSnapshotTaxFreeReimbursement {
    id!: string;
    payrollReportUserSnapshotId!: string;
    payrollReportUserSnapshot?: PayrollReportUserSnapshot | undefined;
    selectedPlanId?: string | undefined;
    selectedPlan?: SelectedPlan | undefined;
    reimbursablePremiumId?: string | undefined;
    reimbursablePremium?: ReimbursablePremium | undefined;
    planType!: PlanTypeIds;
    payTypeId?: PayTypeIds | undefined;
    planPremium!: number;
    appliedReimbursement!: number;
    withholdingOrContribution!: number;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IPayrollReportUserSnapshotTaxFreeReimbursement) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.payrollReportUserSnapshot = _data["payrollReportUserSnapshot"] ? PayrollReportUserSnapshot.fromJS(_data["payrollReportUserSnapshot"]) : <any>undefined;
            this.selectedPlanId = _data["selectedPlanId"];
            this.selectedPlan = _data["selectedPlan"] ? SelectedPlan.fromJS(_data["selectedPlan"]) : <any>undefined;
            this.reimbursablePremiumId = _data["reimbursablePremiumId"];
            this.reimbursablePremium = _data["reimbursablePremium"] ? ReimbursablePremium.fromJS(_data["reimbursablePremium"]) : <any>undefined;
            this.planType = _data["planType"];
            this.payTypeId = _data["payTypeId"];
            this.planPremium = _data["planPremium"];
            this.appliedReimbursement = _data["appliedReimbursement"];
            this.withholdingOrContribution = _data["withholdingOrContribution"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotTaxFreeReimbursement {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotTaxFreeReimbursement();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["payrollReportUserSnapshot"] = this.payrollReportUserSnapshot ? this.payrollReportUserSnapshot.toJSON() : <any>undefined;
        data["selectedPlanId"] = this.selectedPlanId;
        data["selectedPlan"] = this.selectedPlan ? this.selectedPlan.toJSON() : <any>undefined;
        data["reimbursablePremiumId"] = this.reimbursablePremiumId;
        data["reimbursablePremium"] = this.reimbursablePremium ? this.reimbursablePremium.toJSON() : <any>undefined;
        data["planType"] = this.planType;
        data["payTypeId"] = this.payTypeId;
        data["planPremium"] = this.planPremium;
        data["appliedReimbursement"] = this.appliedReimbursement;
        data["withholdingOrContribution"] = this.withholdingOrContribution;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ReimbursementSummary implements IReimbursementSummary {
    postTaxWithholding?: ReimbursementSummarySection | undefined;
    taxFreeReimbursements?: ReimbursementSummarySection | undefined;
    preTaxWithholding?: ReimbursementSummarySection | undefined;
    reimbursement!: number;

    constructor(data?: IReimbursementSummary) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.postTaxWithholding = _data["postTaxWithholding"] ? ReimbursementSummarySection.fromJS(_data["postTaxWithholding"]) : <any>undefined;
            this.taxFreeReimbursements = _data["taxFreeReimbursements"] ? ReimbursementSummarySection.fromJS(_data["taxFreeReimbursements"]) : <any>undefined;
            this.preTaxWithholding = _data["preTaxWithholding"] ? ReimbursementSummarySection.fromJS(_data["preTaxWithholding"]) : <any>undefined;
            this.reimbursement = _data["reimbursement"];
        }
    }

    static fromJS(data: any): ReimbursementSummary {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementSummary();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["postTaxWithholding"] = this.postTaxWithholding ? this.postTaxWithholding.toJSON() : <any>undefined;
        data["taxFreeReimbursements"] = this.taxFreeReimbursements ? this.taxFreeReimbursements.toJSON() : <any>undefined;
        data["preTaxWithholding"] = this.preTaxWithholding ? this.preTaxWithholding.toJSON() : <any>undefined;
        data["reimbursement"] = this.reimbursement;
        return data; 
    }
}
export class ReimbursementSummarySection implements IReimbursementSummarySection {
    title?: string | undefined;
    adjustment!: number;
    total!: number;
    remainingReimbursement!: number;
    reimbursableLineItems?: ReimbursableLineItem[] | undefined;
    nonReimbursableLineItems?: NonReimbursableLineItem[] | undefined;

    constructor(data?: IReimbursementSummarySection) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data["title"];
            this.adjustment = _data["adjustment"];
            this.total = _data["total"];
            this.remainingReimbursement = _data["remainingReimbursement"];
            if (Array.isArray(_data["reimbursableLineItems"])) {
                this.reimbursableLineItems = [] as any;
                for (let item of _data["reimbursableLineItems"])
                    this.reimbursableLineItems!.push(ReimbursableLineItem.fromJS(item));
            }
            if (Array.isArray(_data["nonReimbursableLineItems"])) {
                this.nonReimbursableLineItems = [] as any;
                for (let item of _data["nonReimbursableLineItems"])
                    this.nonReimbursableLineItems!.push(NonReimbursableLineItem.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ReimbursementSummarySection {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementSummarySection();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["title"] = this.title;
        data["adjustment"] = this.adjustment;
        data["total"] = this.total;
        data["remainingReimbursement"] = this.remainingReimbursement;
        if (Array.isArray(this.reimbursableLineItems)) {
            data["reimbursableLineItems"] = [];
            for (let item of this.reimbursableLineItems)
                data["reimbursableLineItems"].push(item.toJSON());
        }
        if (Array.isArray(this.nonReimbursableLineItems)) {
            data["nonReimbursableLineItems"] = [];
            for (let item of this.nonReimbursableLineItems)
                data["nonReimbursableLineItems"].push(item.toJSON());
        }
        return data; 
    }
}
export class ReimbursableLineItem implements IReimbursableLineItem {
    title?: string | undefined;
    amount!: number;
    premium!: number;
    premiumAdjustment!: number;
    appliedReimbursement!: number;

    constructor(data?: IReimbursableLineItem) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data["title"];
            this.amount = _data["amount"];
            this.premium = _data["premium"];
            this.premiumAdjustment = _data["premiumAdjustment"];
            this.appliedReimbursement = _data["appliedReimbursement"];
        }
    }

    static fromJS(data: any): ReimbursableLineItem {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursableLineItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["title"] = this.title;
        data["amount"] = this.amount;
        data["premium"] = this.premium;
        data["premiumAdjustment"] = this.premiumAdjustment;
        data["appliedReimbursement"] = this.appliedReimbursement;
        return data; 
    }
}
export class NonReimbursableLineItem implements INonReimbursableLineItem {
    title?: string | undefined;
    amount!: number;

    constructor(data?: INonReimbursableLineItem) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data["title"];
            this.amount = _data["amount"];
        }
    }

    static fromJS(data: any): NonReimbursableLineItem {
        data = typeof data === 'object' ? data : {};
        let result = new NonReimbursableLineItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["title"] = this.title;
        data["amount"] = this.amount;
        return data; 
    }
}
export class PayrollReportUserChangeLog implements IPayrollReportUserChangeLog {
    payrollReportUserSnapshotId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    diffType!: PayrollReportChangeDiffType;
    changes?: PayrollReportUserChange[] | undefined;

    constructor(data?: IPayrollReportUserChangeLog) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.payrollReportUserSnapshotId = _data["payrollReportUserSnapshotId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.diffType = _data["diffType"];
            if (Array.isArray(_data["changes"])) {
                this.changes = [] as any;
                for (let item of _data["changes"])
                    this.changes!.push(PayrollReportUserChange.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PayrollReportUserChangeLog {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserChangeLog();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["payrollReportUserSnapshotId"] = this.payrollReportUserSnapshotId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["diffType"] = this.diffType;
        if (Array.isArray(this.changes)) {
            data["changes"] = [];
            for (let item of this.changes)
                data["changes"].push(item.toJSON());
        }
        return data; 
    }
}
export class PayrollReportUserChange implements IPayrollReportUserChange {
    property?: string | undefined;
    benefitType?: TeamBenefitTypes | undefined;
    planType?: PlanTypeIds | undefined;
    healthCareType?: HealthCareTypeIds | undefined;
    oldValue?: string | undefined;
    newValue?: string | undefined;
    diffType?: PayrollReportChangeDiffType | undefined;
    changes?: PayrollReportUserChange[] | undefined;

    constructor(data?: IPayrollReportUserChange) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.property = _data["property"];
            this.benefitType = _data["benefitType"];
            this.planType = _data["planType"];
            this.healthCareType = _data["healthCareType"];
            this.oldValue = _data["oldValue"];
            this.newValue = _data["newValue"];
            this.diffType = _data["diffType"];
            if (Array.isArray(_data["changes"])) {
                this.changes = [] as any;
                for (let item of _data["changes"])
                    this.changes!.push(PayrollReportUserChange.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PayrollReportUserChange {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserChange();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["property"] = this.property;
        data["benefitType"] = this.benefitType;
        data["planType"] = this.planType;
        data["healthCareType"] = this.healthCareType;
        data["oldValue"] = this.oldValue;
        data["newValue"] = this.newValue;
        data["diffType"] = this.diffType;
        if (Array.isArray(this.changes)) {
            data["changes"] = [];
            for (let item of this.changes)
                data["changes"].push(item.toJSON());
        }
        return data; 
    }
}
export class ExpenseSummary implements IExpenseSummary {
    id!: string;
    payrollReportId!: string;
    monthlySubscriptionFee!: number;
    sharedResponsibility?: number | undefined;
    sharedResponsibilityEquation?: string | undefined;
    teamIsAle?: boolean | undefined;
    ficaEmployerExpense!: number;
    hraUtilization?: number | undefined;
    reimbursementProgramUtilization?: number | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    customExpenses?: ExpenseSummaryCustomExpense[] | undefined;
    expenseSummaryTeamBenefits?: ExpenseSummaryTeamBenefit[] | undefined;
    expenseSummaryTeamBenefitsTotal?: number | undefined;
    total!: number;

    constructor(data?: IExpenseSummary) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportId = _data["payrollReportId"];
            this.monthlySubscriptionFee = _data["monthlySubscriptionFee"];
            this.sharedResponsibility = _data["sharedResponsibility"];
            this.sharedResponsibilityEquation = _data["sharedResponsibilityEquation"];
            this.teamIsAle = _data["teamIsAle"];
            this.ficaEmployerExpense = _data["ficaEmployerExpense"];
            this.hraUtilization = _data["hraUtilization"];
            this.reimbursementProgramUtilization = _data["reimbursementProgramUtilization"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            if (Array.isArray(_data["customExpenses"])) {
                this.customExpenses = [] as any;
                for (let item of _data["customExpenses"])
                    this.customExpenses!.push(ExpenseSummaryCustomExpense.fromJS(item));
            }
            if (Array.isArray(_data["expenseSummaryTeamBenefits"])) {
                this.expenseSummaryTeamBenefits = [] as any;
                for (let item of _data["expenseSummaryTeamBenefits"])
                    this.expenseSummaryTeamBenefits!.push(ExpenseSummaryTeamBenefit.fromJS(item));
            }
            this.expenseSummaryTeamBenefitsTotal = _data["expenseSummaryTeamBenefitsTotal"];
            this.total = _data["total"];
        }
    }

    static fromJS(data: any): ExpenseSummary {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseSummary();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportId"] = this.payrollReportId;
        data["monthlySubscriptionFee"] = this.monthlySubscriptionFee;
        data["sharedResponsibility"] = this.sharedResponsibility;
        data["sharedResponsibilityEquation"] = this.sharedResponsibilityEquation;
        data["teamIsAle"] = this.teamIsAle;
        data["ficaEmployerExpense"] = this.ficaEmployerExpense;
        data["hraUtilization"] = this.hraUtilization;
        data["reimbursementProgramUtilization"] = this.reimbursementProgramUtilization;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        if (Array.isArray(this.customExpenses)) {
            data["customExpenses"] = [];
            for (let item of this.customExpenses)
                data["customExpenses"].push(item.toJSON());
        }
        if (Array.isArray(this.expenseSummaryTeamBenefits)) {
            data["expenseSummaryTeamBenefits"] = [];
            for (let item of this.expenseSummaryTeamBenefits)
                data["expenseSummaryTeamBenefits"].push(item.toJSON());
        }
        data["expenseSummaryTeamBenefitsTotal"] = this.expenseSummaryTeamBenefitsTotal;
        data["total"] = this.total;
        return data; 
    }
}
export class ExpenseSummaryCustomExpense implements IExpenseSummaryCustomExpense {
    id!: string;
    expenseSummaryId!: string;
    name?: string | undefined;
    amount!: number;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;

    constructor(data?: IExpenseSummaryCustomExpense) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.expenseSummaryId = _data["expenseSummaryId"];
            this.name = _data["name"];
            this.amount = _data["amount"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): ExpenseSummaryCustomExpense {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseSummaryCustomExpense();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["expenseSummaryId"] = this.expenseSummaryId;
        data["name"] = this.name;
        data["amount"] = this.amount;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ExpenseSummaryTeamBenefit implements IExpenseSummaryTeamBenefit {
    id!: string;
    expenseSummaryId!: string;
    teamBenefitId?: string | undefined;
    teamBenefitTypeId!: TeamBenefitTypes;
    total!: number;
    keyId!: string;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: IExpenseSummaryTeamBenefit) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.expenseSummaryId = _data["expenseSummaryId"];
            this.teamBenefitId = _data["teamBenefitId"];
            this.teamBenefitTypeId = _data["teamBenefitTypeId"];
            this.total = _data["total"];
            this.keyId = _data["keyId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): ExpenseSummaryTeamBenefit {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseSummaryTeamBenefit();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["expenseSummaryId"] = this.expenseSummaryId;
        data["teamBenefitId"] = this.teamBenefitId;
        data["teamBenefitTypeId"] = this.teamBenefitTypeId;
        data["total"] = this.total;
        data["keyId"] = this.keyId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class TeamOperationsInfo implements ITeamOperationsInfo {
    id!: string;
    teamId!: string;
    year!: number;
    monthlyServiceFeeTypeId?: MonthlyFeeTypes | undefined;
    monthlyServiceFee?: number | undefined;
    employeePerMonthRate?: number | undefined;
    annualServiceFee?: number | undefined;
    hraAdminFee?: number | undefined;
    estimatedWithholding?: number | undefined;
    payrollDeduct?: boolean | undefined;
    rhCares?: boolean | undefined;
    pathwayNote?: string | undefined;
    spouseParentContributionNote?: string | undefined;
    medicaidChipContributionNote?: string | undefined;
    medicareContributionNote?: string | undefined;
    hsaHraContributionNote?: string | undefined;
    isIchra?: boolean | undefined;
    ichraOfferLevelId?: IchraOfferLevels | undefined;
    isAle?: boolean | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;

    constructor(data?: ITeamOperationsInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.year = _data["year"];
            this.monthlyServiceFeeTypeId = _data["monthlyServiceFeeTypeId"];
            this.monthlyServiceFee = _data["monthlyServiceFee"];
            this.employeePerMonthRate = _data["employeePerMonthRate"];
            this.annualServiceFee = _data["annualServiceFee"];
            this.hraAdminFee = _data["hraAdminFee"];
            this.estimatedWithholding = _data["estimatedWithholding"];
            this.payrollDeduct = _data["payrollDeduct"];
            this.rhCares = _data["rhCares"];
            this.pathwayNote = _data["pathwayNote"];
            this.spouseParentContributionNote = _data["spouseParentContributionNote"];
            this.medicaidChipContributionNote = _data["medicaidChipContributionNote"];
            this.medicareContributionNote = _data["medicareContributionNote"];
            this.hsaHraContributionNote = _data["hsaHraContributionNote"];
            this.isIchra = _data["isIchra"];
            this.ichraOfferLevelId = _data["ichraOfferLevelId"];
            this.isAle = _data["isAle"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): TeamOperationsInfo {
        data = typeof data === 'object' ? data : {};
        let result = new TeamOperationsInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["year"] = this.year;
        data["monthlyServiceFeeTypeId"] = this.monthlyServiceFeeTypeId;
        data["monthlyServiceFee"] = this.monthlyServiceFee;
        data["employeePerMonthRate"] = this.employeePerMonthRate;
        data["annualServiceFee"] = this.annualServiceFee;
        data["hraAdminFee"] = this.hraAdminFee;
        data["estimatedWithholding"] = this.estimatedWithholding;
        data["payrollDeduct"] = this.payrollDeduct;
        data["rhCares"] = this.rhCares;
        data["pathwayNote"] = this.pathwayNote;
        data["spouseParentContributionNote"] = this.spouseParentContributionNote;
        data["medicaidChipContributionNote"] = this.medicaidChipContributionNote;
        data["medicareContributionNote"] = this.medicareContributionNote;
        data["hsaHraContributionNote"] = this.hsaHraContributionNote;
        data["isIchra"] = this.isIchra;
        data["ichraOfferLevelId"] = this.ichraOfferLevelId;
        data["isAle"] = this.isAle;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class TeamBenefitTypesCarrier implements ITeamBenefitTypesCarrier {
    id!: number;
    teamBenefitTypeId!: TeamBenefitTypes;
    teamBenefitCarrierId!: number;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    teamBenefitType?: TeamBenefitType | undefined;
    teamBenefitCarrier?: TeamBenefitCarrier | undefined;

    constructor(data?: ITeamBenefitTypesCarrier) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitTypeId = _data["teamBenefitTypeId"];
            this.teamBenefitCarrierId = _data["teamBenefitCarrierId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.teamBenefitType = _data["teamBenefitType"] ? TeamBenefitType.fromJS(_data["teamBenefitType"]) : <any>undefined;
            this.teamBenefitCarrier = _data["teamBenefitCarrier"] ? TeamBenefitCarrier.fromJS(_data["teamBenefitCarrier"]) : <any>undefined;
        }
    }

    static fromJS(data: any): TeamBenefitTypesCarrier {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTypesCarrier();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitTypeId"] = this.teamBenefitTypeId;
        data["teamBenefitCarrierId"] = this.teamBenefitCarrierId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["teamBenefitType"] = this.teamBenefitType ? this.teamBenefitType.toJSON() : <any>undefined;
        data["teamBenefitCarrier"] = this.teamBenefitCarrier ? this.teamBenefitCarrier.toJSON() : <any>undefined;
        return data; 
    }
}
export class TeamBenefitType implements ITeamBenefitType {
    id!: TeamBenefitTypes;
    name?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: ITeamBenefitType) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): TeamBenefitType {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitType();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class TeamBenefitCarrier implements ITeamBenefitCarrier {
    id!: number;
    name?: string | undefined;
    submitExpenseUrlDefault?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: ITeamBenefitCarrier) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.submitExpenseUrlDefault = _data["submitExpenseUrlDefault"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): TeamBenefitCarrier {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitCarrier();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["submitExpenseUrlDefault"] = this.submitExpenseUrlDefault;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class TeamBenefitsRhFile implements ITeamBenefitsRhFile {
    id!: string;
    teamBenefitId!: string;
    rhFileId!: string;
    rhFile?: RhFile | undefined;
    joinEntityId!: string;

    constructor(data?: ITeamBenefitsRhFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitId = _data["teamBenefitId"];
            this.rhFileId = _data["rhFileId"];
            this.rhFile = _data["rhFile"] ? RhFile.fromJS(_data["rhFile"]) : <any>undefined;
            this.joinEntityId = _data["joinEntityId"];
        }
    }

    static fromJS(data: any): TeamBenefitsRhFile {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitsRhFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitId"] = this.teamBenefitId;
        data["rhFileId"] = this.rhFileId;
        data["rhFile"] = this.rhFile ? this.rhFile.toJSON() : <any>undefined;
        data["joinEntityId"] = this.joinEntityId;
        return data; 
    }
}
export class BasicHouseholdMemberDto implements IBasicHouseholdMemberDto {
    householdMemberId!: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    preferredName?: string | undefined;
    preferredNameWithFallback?: string | undefined;

    constructor(data?: IBasicHouseholdMemberDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberId = _data["householdMemberId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.preferredName = _data["preferredName"];
            this.preferredNameWithFallback = _data["preferredNameWithFallback"];
        }
    }

    static fromJS(data: any): BasicHouseholdMemberDto {
        data = typeof data === 'object' ? data : {};
        let result = new BasicHouseholdMemberDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberId"] = this.householdMemberId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["dateOfBirth"] = this.dateOfBirth;
        data["preferredName"] = this.preferredName;
        data["preferredNameWithFallback"] = this.preferredNameWithFallback;
        return data; 
    }
}
export class UserTeamBenefitTermDetailHouseholdMember implements IUserTeamBenefitTermDetailHouseholdMember {
    id!: string;
    householdMemberId!: string;
    userTeamBenefitTermDetailId!: string;
    householdMember?: HouseholdMember | undefined;

    constructor(data?: IUserTeamBenefitTermDetailHouseholdMember) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.householdMemberId = _data["householdMemberId"];
            this.userTeamBenefitTermDetailId = _data["userTeamBenefitTermDetailId"];
            this.householdMember = _data["householdMember"] ? HouseholdMember.fromJS(_data["householdMember"]) : <any>undefined;
        }
    }

    static fromJS(data: any): UserTeamBenefitTermDetailHouseholdMember {
        data = typeof data === 'object' ? data : {};
        let result = new UserTeamBenefitTermDetailHouseholdMember();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["householdMemberId"] = this.householdMemberId;
        data["userTeamBenefitTermDetailId"] = this.userTeamBenefitTermDetailId;
        data["householdMember"] = this.householdMember ? this.householdMember.toJSON() : <any>undefined;
        return data; 
    }
}
export class UserAddress implements IUserAddress {
    userAddressId!: string;
    userId!: string;
    addressId!: string;
    year!: number;
    typeId!: AddressTypes;
    address?: Address | undefined;

    constructor(data?: IUserAddress) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userAddressId = _data["userAddressId"];
            this.userId = _data["userId"];
            this.addressId = _data["addressId"];
            this.year = _data["year"];
            this.typeId = _data["typeId"];
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
        }
    }

    static fromJS(data: any): UserAddress {
        data = typeof data === 'object' ? data : {};
        let result = new UserAddress();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userAddressId"] = this.userAddressId;
        data["userId"] = this.userId;
        data["addressId"] = this.addressId;
        data["year"] = this.year;
        data["typeId"] = this.typeId;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        return data; 
    }
}
export class EnrollmentVerification implements IEnrollmentVerification {
    id!: string;
    userId!: string;
    includePrimary!: boolean;
    type!: EnrollmentVerificationTypes;
    year!: number;
    dueDate!: string;
    status!: EnrollmentVerificationStatuses;
    receivedDate?: string | undefined;
    submittedDate?: string | undefined;
    verifiedDate?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    householdMemberEnrollmentVerifications?: HouseholdMemberEnrollmentVerification[] | undefined;
    rhFiles?: RhFile[] | undefined;
    enrollmentVerificationRhFiles?: EnrollmentVerificationRhFile[] | undefined;
    keyId!: string;

    constructor(data?: IEnrollmentVerification) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.includePrimary = _data["includePrimary"];
            this.type = _data["type"];
            this.year = _data["year"];
            this.dueDate = _data["dueDate"];
            this.status = _data["status"];
            this.receivedDate = _data["receivedDate"];
            this.submittedDate = _data["submittedDate"];
            this.verifiedDate = _data["verifiedDate"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            if (Array.isArray(_data["householdMemberEnrollmentVerifications"])) {
                this.householdMemberEnrollmentVerifications = [] as any;
                for (let item of _data["householdMemberEnrollmentVerifications"])
                    this.householdMemberEnrollmentVerifications!.push(HouseholdMemberEnrollmentVerification.fromJS(item));
            }
            if (Array.isArray(_data["rhFiles"])) {
                this.rhFiles = [] as any;
                for (let item of _data["rhFiles"])
                    this.rhFiles!.push(RhFile.fromJS(item));
            }
            if (Array.isArray(_data["enrollmentVerificationRhFiles"])) {
                this.enrollmentVerificationRhFiles = [] as any;
                for (let item of _data["enrollmentVerificationRhFiles"])
                    this.enrollmentVerificationRhFiles!.push(EnrollmentVerificationRhFile.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): EnrollmentVerification {
        data = typeof data === 'object' ? data : {};
        let result = new EnrollmentVerification();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["includePrimary"] = this.includePrimary;
        data["type"] = this.type;
        data["year"] = this.year;
        data["dueDate"] = this.dueDate;
        data["status"] = this.status;
        data["receivedDate"] = this.receivedDate;
        data["submittedDate"] = this.submittedDate;
        data["verifiedDate"] = this.verifiedDate;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMemberEnrollmentVerifications)) {
            data["householdMemberEnrollmentVerifications"] = [];
            for (let item of this.householdMemberEnrollmentVerifications)
                data["householdMemberEnrollmentVerifications"].push(item.toJSON());
        }
        if (Array.isArray(this.rhFiles)) {
            data["rhFiles"] = [];
            for (let item of this.rhFiles)
                data["rhFiles"].push(item.toJSON());
        }
        if (Array.isArray(this.enrollmentVerificationRhFiles)) {
            data["enrollmentVerificationRhFiles"] = [];
            for (let item of this.enrollmentVerificationRhFiles)
                data["enrollmentVerificationRhFiles"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class HouseholdMemberEnrollmentVerification implements IHouseholdMemberEnrollmentVerification {
    id!: string;
    householdMemberId!: string;
    enrollmentVerificationId!: string;
    householdMember?: HouseholdMember | undefined;
    enrollmentVerification?: EnrollmentVerification | undefined;

    constructor(data?: IHouseholdMemberEnrollmentVerification) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.householdMemberId = _data["householdMemberId"];
            this.enrollmentVerificationId = _data["enrollmentVerificationId"];
            this.householdMember = _data["householdMember"] ? HouseholdMember.fromJS(_data["householdMember"]) : <any>undefined;
            this.enrollmentVerification = _data["enrollmentVerification"] ? EnrollmentVerification.fromJS(_data["enrollmentVerification"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberEnrollmentVerification {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberEnrollmentVerification();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["householdMemberId"] = this.householdMemberId;
        data["enrollmentVerificationId"] = this.enrollmentVerificationId;
        data["householdMember"] = this.householdMember ? this.householdMember.toJSON() : <any>undefined;
        data["enrollmentVerification"] = this.enrollmentVerification ? this.enrollmentVerification.toJSON() : <any>undefined;
        return data; 
    }
}
export class EnrollmentVerificationRhFile implements IEnrollmentVerificationRhFile {
    id!: string;
    enrollmentVerificationId!: string;
    rhFileId!: string;
    enrollmentVerification?: EnrollmentVerification | undefined;
    rhFile?: RhFile | undefined;

    constructor(data?: IEnrollmentVerificationRhFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.enrollmentVerificationId = _data["enrollmentVerificationId"];
            this.rhFileId = _data["rhFileId"];
            this.enrollmentVerification = _data["enrollmentVerification"] ? EnrollmentVerification.fromJS(_data["enrollmentVerification"]) : <any>undefined;
            this.rhFile = _data["rhFile"] ? RhFile.fromJS(_data["rhFile"]) : <any>undefined;
        }
    }

    static fromJS(data: any): EnrollmentVerificationRhFile {
        data = typeof data === 'object' ? data : {};
        let result = new EnrollmentVerificationRhFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["enrollmentVerificationId"] = this.enrollmentVerificationId;
        data["rhFileId"] = this.rhFileId;
        data["enrollmentVerification"] = this.enrollmentVerification ? this.enrollmentVerification.toJSON() : <any>undefined;
        data["rhFile"] = this.rhFile ? this.rhFile.toJSON() : <any>undefined;
        return data; 
    }
}
export class SubmittedExpense implements ISubmittedExpense {
    id!: number;
    expenseId?: string | undefined;
    globalId!: string;
    userId!: string;
    user?: User | undefined;
    coveredMemberId!: string;
    isCoveredMemberPrimary!: boolean;
    expenseTypeId!: number;
    expenseType?: ExpenseType | undefined;
    expenseStatus!: ExpenseStatus;
    expenseAmount!: number;
    amountEligibleForReimbursement?: number | undefined;
    reimbursementToWages?: number | undefined;
    submittedDate!: string;
    dateOfService!: string;
    approvalDate?: string | undefined;
    reimbursementReportId?: number | undefined;
    reimbursementReport?: ReimbursementReport | undefined;
    expenseSource?: string | undefined;
    decisionSummary?: string | undefined;
    submittedExpenseRhFiles?: SubmittedExpenseRhFile[] | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    reimburseToHsa!: boolean;
    reimbursementToHsa?: number | undefined;
    wagesReimbursementSummary?: string | undefined;
    hsaReimbursementSummary?: string | undefined;
    isRecurring!: boolean;
    isArchived!: boolean;
    keyId!: string;

    constructor(data?: ISubmittedExpense) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.expenseId = _data["expenseId"];
            this.globalId = _data["globalId"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.coveredMemberId = _data["coveredMemberId"];
            this.isCoveredMemberPrimary = _data["isCoveredMemberPrimary"];
            this.expenseTypeId = _data["expenseTypeId"];
            this.expenseType = _data["expenseType"] ? ExpenseType.fromJS(_data["expenseType"]) : <any>undefined;
            this.expenseStatus = _data["expenseStatus"];
            this.expenseAmount = _data["expenseAmount"];
            this.amountEligibleForReimbursement = _data["amountEligibleForReimbursement"];
            this.reimbursementToWages = _data["reimbursementToWages"];
            this.submittedDate = _data["submittedDate"];
            this.dateOfService = _data["dateOfService"];
            this.approvalDate = _data["approvalDate"];
            this.reimbursementReportId = _data["reimbursementReportId"];
            this.reimbursementReport = _data["reimbursementReport"] ? ReimbursementReport.fromJS(_data["reimbursementReport"]) : <any>undefined;
            this.expenseSource = _data["expenseSource"];
            this.decisionSummary = _data["decisionSummary"];
            if (Array.isArray(_data["submittedExpenseRhFiles"])) {
                this.submittedExpenseRhFiles = [] as any;
                for (let item of _data["submittedExpenseRhFiles"])
                    this.submittedExpenseRhFiles!.push(SubmittedExpenseRhFile.fromJS(item));
            }
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.reimburseToHsa = _data["reimburseToHsa"];
            this.reimbursementToHsa = _data["reimbursementToHsa"];
            this.wagesReimbursementSummary = _data["wagesReimbursementSummary"];
            this.hsaReimbursementSummary = _data["hsaReimbursementSummary"];
            this.isRecurring = _data["isRecurring"];
            this.isArchived = _data["isArchived"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): SubmittedExpense {
        data = typeof data === 'object' ? data : {};
        let result = new SubmittedExpense();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["expenseId"] = this.expenseId;
        data["globalId"] = this.globalId;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["coveredMemberId"] = this.coveredMemberId;
        data["isCoveredMemberPrimary"] = this.isCoveredMemberPrimary;
        data["expenseTypeId"] = this.expenseTypeId;
        data["expenseType"] = this.expenseType ? this.expenseType.toJSON() : <any>undefined;
        data["expenseStatus"] = this.expenseStatus;
        data["expenseAmount"] = this.expenseAmount;
        data["amountEligibleForReimbursement"] = this.amountEligibleForReimbursement;
        data["reimbursementToWages"] = this.reimbursementToWages;
        data["submittedDate"] = this.submittedDate;
        data["dateOfService"] = this.dateOfService;
        data["approvalDate"] = this.approvalDate;
        data["reimbursementReportId"] = this.reimbursementReportId;
        data["reimbursementReport"] = this.reimbursementReport ? this.reimbursementReport.toJSON() : <any>undefined;
        data["expenseSource"] = this.expenseSource;
        data["decisionSummary"] = this.decisionSummary;
        if (Array.isArray(this.submittedExpenseRhFiles)) {
            data["submittedExpenseRhFiles"] = [];
            for (let item of this.submittedExpenseRhFiles)
                data["submittedExpenseRhFiles"].push(item.toJSON());
        }
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["reimburseToHsa"] = this.reimburseToHsa;
        data["reimbursementToHsa"] = this.reimbursementToHsa;
        data["wagesReimbursementSummary"] = this.wagesReimbursementSummary;
        data["hsaReimbursementSummary"] = this.hsaReimbursementSummary;
        data["isRecurring"] = this.isRecurring;
        data["isArchived"] = this.isArchived;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class ReimbursementReport implements IReimbursementReport {
    id!: number;
    globalId!: string;
    teamId!: string;
    team?: Team | undefined;
    startDate!: string;
    endDate!: string;
    isShared!: boolean;
    submittedExpenses?: SubmittedExpense[] | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    termLabel?: string | undefined;

    constructor(data?: IReimbursementReport) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.globalId = _data["globalId"];
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.isShared = _data["isShared"];
            if (Array.isArray(_data["submittedExpenses"])) {
                this.submittedExpenses = [] as any;
                for (let item of _data["submittedExpenses"])
                    this.submittedExpenses!.push(SubmittedExpense.fromJS(item));
            }
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            this.termLabel = _data["termLabel"];
        }
    }

    static fromJS(data: any): ReimbursementReport {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementReport();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["globalId"] = this.globalId;
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["isShared"] = this.isShared;
        if (Array.isArray(this.submittedExpenses)) {
            data["submittedExpenses"] = [];
            for (let item of this.submittedExpenses)
                data["submittedExpenses"].push(item.toJSON());
        }
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        data["termLabel"] = this.termLabel;
        return data; 
    }
}
export class SubmittedExpenseRhFile implements ISubmittedExpenseRhFile {
    id!: number;
    submittedExpenseId!: number;
    submittedExpense?: SubmittedExpense | undefined;
    rhFileId!: string;
    rhFile?: RhFile | undefined;
    joinEntityId!: number;

    constructor(data?: ISubmittedExpenseRhFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.submittedExpenseId = _data["submittedExpenseId"];
            this.submittedExpense = _data["submittedExpense"] ? SubmittedExpense.fromJS(_data["submittedExpense"]) : <any>undefined;
            this.rhFileId = _data["rhFileId"];
            this.rhFile = _data["rhFile"] ? RhFile.fromJS(_data["rhFile"]) : <any>undefined;
            this.joinEntityId = _data["joinEntityId"];
        }
    }

    static fromJS(data: any): SubmittedExpenseRhFile {
        data = typeof data === 'object' ? data : {};
        let result = new SubmittedExpenseRhFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["submittedExpenseId"] = this.submittedExpenseId;
        data["submittedExpense"] = this.submittedExpense ? this.submittedExpense.toJSON() : <any>undefined;
        data["rhFileId"] = this.rhFileId;
        data["rhFile"] = this.rhFile ? this.rhFile.toJSON() : <any>undefined;
        data["joinEntityId"] = this.joinEntityId;
        return data; 
    }
}
export class AccountLockout implements IAccountLockout {
    lastFailedLoginDate?: string | undefined;
    failedLoginAttemptsCount!: number;
    lastFailedMfaDate?: string | undefined;
    failedMfaAttemptsCount!: number;

    constructor(data?: IAccountLockout) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.lastFailedLoginDate = _data["lastFailedLoginDate"];
            this.failedLoginAttemptsCount = _data["failedLoginAttemptsCount"];
            this.lastFailedMfaDate = _data["lastFailedMfaDate"];
            this.failedMfaAttemptsCount = _data["failedMfaAttemptsCount"];
        }
    }

    static fromJS(data: any): AccountLockout {
        data = typeof data === 'object' ? data : {};
        let result = new AccountLockout();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["lastFailedLoginDate"] = this.lastFailedLoginDate;
        data["failedLoginAttemptsCount"] = this.failedLoginAttemptsCount;
        data["lastFailedMfaDate"] = this.lastFailedMfaDate;
        data["failedMfaAttemptsCount"] = this.failedMfaAttemptsCount;
        return data; 
    }
}
export class LoginInfo extends AccountLockout implements ILoginInfo {
    userId!: string;
    user?: User | undefined;
    passwordHash?: string | undefined;
    enabled!: boolean;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    multiFactorAuthorizationCode?: string | undefined;
    mfaCodeExpirationDateTime?: string | undefined;
    keyId!: string;

    constructor(data?: ILoginInfo) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.passwordHash = _data["passwordHash"];
            this.enabled = _data["enabled"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.multiFactorAuthorizationCode = _data["multiFactorAuthorizationCode"];
            this.mfaCodeExpirationDateTime = _data["mfaCodeExpirationDateTime"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): LoginInfo {
        data = typeof data === 'object' ? data : {};
        let result = new LoginInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["passwordHash"] = this.passwordHash;
        data["enabled"] = this.enabled;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["multiFactorAuthorizationCode"] = this.multiFactorAuthorizationCode;
        data["mfaCodeExpirationDateTime"] = this.mfaCodeExpirationDateTime;
        data["keyId"] = this.keyId;
        super.toJSON(data);
        return data; 
    }
}
export class PathwayUser implements IPathwayUser {
    id!: string;
    userId!: string;
    user?: User | undefined;
    pathwayBlueprintId!: string;
    pathwayBlueprint?: PathwayBlueprint | undefined;
    pathwayId?: string | undefined;
    pathway?: Pathway | undefined;
    planCost?: number | undefined;
    planCostWithoutTaxCredits?: number | undefined;
    benchmarkPlanCost?: number | undefined;
    projectedWageUp?: number | undefined;
    customProjectedWageUp?: number | undefined;
    customProjectedWageUpNote?: string | undefined;
    projectedReimbursement?: number | undefined;
    customProjectedReimbursement?: number | undefined;
    customProjectedReimbursementNote?: string | undefined;
    customPlanCost?: number | undefined;
    customPlanCostNote?: string | undefined;
    employeeCost?: number | undefined;
    tags!: PathwayUserTags;
    calculationSummary?: PathwayCalculationSummary | undefined;
    fullYearPlanCost?: number | undefined;
    fullYearProjectedWageUp?: number | undefined;
    fullYearProjectedReimbursement?: number | undefined;
    fullYearEmployeeCost?: number | undefined;
    fullYearTags!: PathwayUserTags;
    fullYearCalculationSummary?: PathwayCalculationSummary | undefined;
    totalTaxRate!: number;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    customChipCost?: number | undefined;
    customMedicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    otherCostsNote?: string | undefined;
    hasOtherCosts!: boolean;
    totalCosts!: number;
    taxedUpTotalCosts!: number;
    totalFullYearCosts!: number;
    fullYearTaxedUpTotalCosts!: number;
    lastWageUpAffectedDate?: string | undefined;
    lastCalculatedDate?: string | undefined;
    lastCalculatedBy?: string | undefined;
    lastCalculatedByUser?: User | undefined;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    currentEmployeeCost?: number | undefined;
    currentEmployerCost?: number | undefined;
    renewalEmployeeCost?: number | undefined;
    renewalEmployerCost?: number | undefined;
    lastCalculatedByBaseUser?: BaseUser | undefined;
    isStale!: boolean;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    hasError!: boolean;
    errorMessage?: string | undefined;
    ineligibleRecommendedPlanNames?: string[] | undefined;
    hasMismatchedContributions!: boolean;
    keyId!: string;

    constructor(data?: IPathwayUser) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.pathwayBlueprintId = _data["pathwayBlueprintId"];
            this.pathwayBlueprint = _data["pathwayBlueprint"] ? PathwayBlueprint.fromJS(_data["pathwayBlueprint"]) : <any>undefined;
            this.pathwayId = _data["pathwayId"];
            this.pathway = _data["pathway"] ? Pathway.fromJS(_data["pathway"]) : <any>undefined;
            this.planCost = _data["planCost"];
            this.planCostWithoutTaxCredits = _data["planCostWithoutTaxCredits"];
            this.benchmarkPlanCost = _data["benchmarkPlanCost"];
            this.projectedWageUp = _data["projectedWageUp"];
            this.customProjectedWageUp = _data["customProjectedWageUp"];
            this.customProjectedWageUpNote = _data["customProjectedWageUpNote"];
            this.projectedReimbursement = _data["projectedReimbursement"];
            this.customProjectedReimbursement = _data["customProjectedReimbursement"];
            this.customProjectedReimbursementNote = _data["customProjectedReimbursementNote"];
            this.customPlanCost = _data["customPlanCost"];
            this.customPlanCostNote = _data["customPlanCostNote"];
            this.employeeCost = _data["employeeCost"];
            this.tags = _data["tags"];
            this.calculationSummary = _data["calculationSummary"] ? PathwayCalculationSummary.fromJS(_data["calculationSummary"]) : <any>undefined;
            this.fullYearPlanCost = _data["fullYearPlanCost"];
            this.fullYearProjectedWageUp = _data["fullYearProjectedWageUp"];
            this.fullYearProjectedReimbursement = _data["fullYearProjectedReimbursement"];
            this.fullYearEmployeeCost = _data["fullYearEmployeeCost"];
            this.fullYearTags = _data["fullYearTags"];
            this.fullYearCalculationSummary = _data["fullYearCalculationSummary"] ? PathwayCalculationSummary.fromJS(_data["fullYearCalculationSummary"]) : <any>undefined;
            this.totalTaxRate = _data["totalTaxRate"];
            this.chipCost = _data["chipCost"];
            this.medicareCost = _data["medicareCost"];
            this.customChipCost = _data["customChipCost"];
            this.customMedicareCost = _data["customMedicareCost"];
            this.unclaimedHouseholdMemberCost = _data["unclaimedHouseholdMemberCost"];
            this.additionalCost = _data["additionalCost"];
            this.otherCostsNote = _data["otherCostsNote"];
            this.hasOtherCosts = _data["hasOtherCosts"];
            this.totalCosts = _data["totalCosts"];
            this.taxedUpTotalCosts = _data["taxedUpTotalCosts"];
            this.totalFullYearCosts = _data["totalFullYearCosts"];
            this.fullYearTaxedUpTotalCosts = _data["fullYearTaxedUpTotalCosts"];
            this.lastWageUpAffectedDate = _data["lastWageUpAffectedDate"];
            this.lastCalculatedDate = _data["lastCalculatedDate"];
            this.lastCalculatedBy = _data["lastCalculatedBy"];
            this.lastCalculatedByUser = _data["lastCalculatedByUser"] ? User.fromJS(_data["lastCalculatedByUser"]) : <any>undefined;
            this.memberQuoteInfo = _data["memberQuoteInfo"] ? MemberQuoteInfo.fromJS(_data["memberQuoteInfo"]) : <any>undefined;
            this.currentEmployeeCost = _data["currentEmployeeCost"];
            this.currentEmployerCost = _data["currentEmployerCost"];
            this.renewalEmployeeCost = _data["renewalEmployeeCost"];
            this.renewalEmployerCost = _data["renewalEmployerCost"];
            this.lastCalculatedByBaseUser = _data["lastCalculatedByBaseUser"] ? BaseUser.fromJS(_data["lastCalculatedByBaseUser"]) : <any>undefined;
            this.isStale = _data["isStale"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.hasError = _data["hasError"];
            this.errorMessage = _data["errorMessage"];
            if (Array.isArray(_data["ineligibleRecommendedPlanNames"])) {
                this.ineligibleRecommendedPlanNames = [] as any;
                for (let item of _data["ineligibleRecommendedPlanNames"])
                    this.ineligibleRecommendedPlanNames!.push(item);
            }
            this.hasMismatchedContributions = _data["hasMismatchedContributions"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PathwayUser {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayUser();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["pathwayBlueprintId"] = this.pathwayBlueprintId;
        data["pathwayBlueprint"] = this.pathwayBlueprint ? this.pathwayBlueprint.toJSON() : <any>undefined;
        data["pathwayId"] = this.pathwayId;
        data["pathway"] = this.pathway ? this.pathway.toJSON() : <any>undefined;
        data["planCost"] = this.planCost;
        data["planCostWithoutTaxCredits"] = this.planCostWithoutTaxCredits;
        data["benchmarkPlanCost"] = this.benchmarkPlanCost;
        data["projectedWageUp"] = this.projectedWageUp;
        data["customProjectedWageUp"] = this.customProjectedWageUp;
        data["customProjectedWageUpNote"] = this.customProjectedWageUpNote;
        data["projectedReimbursement"] = this.projectedReimbursement;
        data["customProjectedReimbursement"] = this.customProjectedReimbursement;
        data["customProjectedReimbursementNote"] = this.customProjectedReimbursementNote;
        data["customPlanCost"] = this.customPlanCost;
        data["customPlanCostNote"] = this.customPlanCostNote;
        data["employeeCost"] = this.employeeCost;
        data["tags"] = this.tags;
        data["calculationSummary"] = this.calculationSummary ? this.calculationSummary.toJSON() : <any>undefined;
        data["fullYearPlanCost"] = this.fullYearPlanCost;
        data["fullYearProjectedWageUp"] = this.fullYearProjectedWageUp;
        data["fullYearProjectedReimbursement"] = this.fullYearProjectedReimbursement;
        data["fullYearEmployeeCost"] = this.fullYearEmployeeCost;
        data["fullYearTags"] = this.fullYearTags;
        data["fullYearCalculationSummary"] = this.fullYearCalculationSummary ? this.fullYearCalculationSummary.toJSON() : <any>undefined;
        data["totalTaxRate"] = this.totalTaxRate;
        data["chipCost"] = this.chipCost;
        data["medicareCost"] = this.medicareCost;
        data["customChipCost"] = this.customChipCost;
        data["customMedicareCost"] = this.customMedicareCost;
        data["unclaimedHouseholdMemberCost"] = this.unclaimedHouseholdMemberCost;
        data["additionalCost"] = this.additionalCost;
        data["otherCostsNote"] = this.otherCostsNote;
        data["hasOtherCosts"] = this.hasOtherCosts;
        data["totalCosts"] = this.totalCosts;
        data["taxedUpTotalCosts"] = this.taxedUpTotalCosts;
        data["totalFullYearCosts"] = this.totalFullYearCosts;
        data["fullYearTaxedUpTotalCosts"] = this.fullYearTaxedUpTotalCosts;
        data["lastWageUpAffectedDate"] = this.lastWageUpAffectedDate;
        data["lastCalculatedDate"] = this.lastCalculatedDate;
        data["lastCalculatedBy"] = this.lastCalculatedBy;
        data["lastCalculatedByUser"] = this.lastCalculatedByUser ? this.lastCalculatedByUser.toJSON() : <any>undefined;
        data["memberQuoteInfo"] = this.memberQuoteInfo ? this.memberQuoteInfo.toJSON() : <any>undefined;
        data["currentEmployeeCost"] = this.currentEmployeeCost;
        data["currentEmployerCost"] = this.currentEmployerCost;
        data["renewalEmployeeCost"] = this.renewalEmployeeCost;
        data["renewalEmployerCost"] = this.renewalEmployerCost;
        data["lastCalculatedByBaseUser"] = this.lastCalculatedByBaseUser ? this.lastCalculatedByBaseUser.toJSON() : <any>undefined;
        data["isStale"] = this.isStale;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["hasError"] = this.hasError;
        data["errorMessage"] = this.errorMessage;
        if (Array.isArray(this.ineligibleRecommendedPlanNames)) {
            data["ineligibleRecommendedPlanNames"] = [];
            for (let item of this.ineligibleRecommendedPlanNames)
                data["ineligibleRecommendedPlanNames"].push(item);
        }
        data["hasMismatchedContributions"] = this.hasMismatchedContributions;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PathwayBlueprint implements IPathwayBlueprint {
    id!: string;
    teamId!: string;
    team?: Team | undefined;
    name?: string | undefined;
    year!: number;
    isActive!: boolean;
    softwareFee?: number | undefined;
    hraCost?: number | undefined;
    mecCost?: number | undefined;
    adjustments?: number | undefined;
    wageUpFloor!: number;
    lastBulkCalculatedDate?: string | undefined;
    lastBulkCalculatedBy?: string | undefined;
    lastBulkCalculatedByUser?: User | undefined;
    pathways?: Pathway[] | undefined;
    ichraClasses?: IchraClass[] | undefined;
    isCalculating!: boolean;
    isProjection!: boolean;
    createdDate!: string;
    createdBy!: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    pathwayUsersWithLostEligibilities?: BasicPathwayUserDto[] | undefined;
    pathwayUsersWithUnaffordableIchra?: BasicPathwayUserDto[] | undefined;
    pathwayUsersWithIneligiblePlans?: BasicPathwayUserDto[] | undefined;
    numberOfUnassignedUsers?: number | undefined;
    hasMismatchedContributionsUsers!: boolean;
    keyId!: string;
    isStale!: boolean;
    annualEmployeeCost!: number;
    annualProjectedWageUpCost!: number;
    annualProjectedReimbursementCost!: number;
    annualEmployerCost!: number;
    annualEmployerCostEquation?: string | undefined;
    annualCost!: number;
    annualOldEmployeeCost!: number;
    annualOldEmployerCost!: number;
    annualOldRenewalEmployeeCost!: number;
    annualOldRenewalEmployerCost!: number;
    annualOldCost!: number;
    annualOldRenewalCost!: number;
    projectedSavings!: number;
    projectedRenewalSavings!: number;
    projectedSavingsEquation?: string | undefined;
    projectedRenewalSavingsEquation?: string | undefined;
    additionalFicaCost!: number;
    additionalFicaCostEquation?: string | undefined;
    employeesEligibleForTaxCredits!: number;
    sharedResponsibilityEstimate!: number;
    sharedResponsibilityEstimateEquation?: string | undefined;
    sharedResponsibilityFeeYear!: number;
    hasIchraPathway!: boolean;

    constructor(data?: IPathwayBlueprint) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.name = _data["name"];
            this.year = _data["year"];
            this.isActive = _data["isActive"];
            this.softwareFee = _data["softwareFee"];
            this.hraCost = _data["hraCost"];
            this.mecCost = _data["mecCost"];
            this.adjustments = _data["adjustments"];
            this.wageUpFloor = _data["wageUpFloor"];
            this.lastBulkCalculatedDate = _data["lastBulkCalculatedDate"];
            this.lastBulkCalculatedBy = _data["lastBulkCalculatedBy"];
            this.lastBulkCalculatedByUser = _data["lastBulkCalculatedByUser"] ? User.fromJS(_data["lastBulkCalculatedByUser"]) : <any>undefined;
            if (Array.isArray(_data["pathways"])) {
                this.pathways = [] as any;
                for (let item of _data["pathways"])
                    this.pathways!.push(Pathway.fromJS(item));
            }
            if (Array.isArray(_data["ichraClasses"])) {
                this.ichraClasses = [] as any;
                for (let item of _data["ichraClasses"])
                    this.ichraClasses!.push(IchraClass.fromJS(item));
            }
            this.isCalculating = _data["isCalculating"];
            this.isProjection = _data["isProjection"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? User.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
            if (Array.isArray(_data["pathwayUsers"])) {
                this.pathwayUsers = [] as any;
                for (let item of _data["pathwayUsers"])
                    this.pathwayUsers!.push(PathwayUser.fromJS(item));
            }
            if (Array.isArray(_data["pathwayUsersWithLostEligibilities"])) {
                this.pathwayUsersWithLostEligibilities = [] as any;
                for (let item of _data["pathwayUsersWithLostEligibilities"])
                    this.pathwayUsersWithLostEligibilities!.push(BasicPathwayUserDto.fromJS(item));
            }
            if (Array.isArray(_data["pathwayUsersWithUnaffordableIchra"])) {
                this.pathwayUsersWithUnaffordableIchra = [] as any;
                for (let item of _data["pathwayUsersWithUnaffordableIchra"])
                    this.pathwayUsersWithUnaffordableIchra!.push(BasicPathwayUserDto.fromJS(item));
            }
            if (Array.isArray(_data["pathwayUsersWithIneligiblePlans"])) {
                this.pathwayUsersWithIneligiblePlans = [] as any;
                for (let item of _data["pathwayUsersWithIneligiblePlans"])
                    this.pathwayUsersWithIneligiblePlans!.push(BasicPathwayUserDto.fromJS(item));
            }
            this.numberOfUnassignedUsers = _data["numberOfUnassignedUsers"];
            this.hasMismatchedContributionsUsers = _data["hasMismatchedContributionsUsers"];
            this.keyId = _data["keyId"];
            this.isStale = _data["isStale"];
            this.annualEmployeeCost = _data["annualEmployeeCost"];
            this.annualProjectedWageUpCost = _data["annualProjectedWageUpCost"];
            this.annualProjectedReimbursementCost = _data["annualProjectedReimbursementCost"];
            this.annualEmployerCost = _data["annualEmployerCost"];
            this.annualEmployerCostEquation = _data["annualEmployerCostEquation"];
            this.annualCost = _data["annualCost"];
            this.annualOldEmployeeCost = _data["annualOldEmployeeCost"];
            this.annualOldEmployerCost = _data["annualOldEmployerCost"];
            this.annualOldRenewalEmployeeCost = _data["annualOldRenewalEmployeeCost"];
            this.annualOldRenewalEmployerCost = _data["annualOldRenewalEmployerCost"];
            this.annualOldCost = _data["annualOldCost"];
            this.annualOldRenewalCost = _data["annualOldRenewalCost"];
            this.projectedSavings = _data["projectedSavings"];
            this.projectedRenewalSavings = _data["projectedRenewalSavings"];
            this.projectedSavingsEquation = _data["projectedSavingsEquation"];
            this.projectedRenewalSavingsEquation = _data["projectedRenewalSavingsEquation"];
            this.additionalFicaCost = _data["additionalFicaCost"];
            this.additionalFicaCostEquation = _data["additionalFicaCostEquation"];
            this.employeesEligibleForTaxCredits = _data["employeesEligibleForTaxCredits"];
            this.sharedResponsibilityEstimate = _data["sharedResponsibilityEstimate"];
            this.sharedResponsibilityEstimateEquation = _data["sharedResponsibilityEstimateEquation"];
            this.sharedResponsibilityFeeYear = _data["sharedResponsibilityFeeYear"];
            this.hasIchraPathway = _data["hasIchraPathway"];
        }
    }

    static fromJS(data: any): PathwayBlueprint {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayBlueprint();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["name"] = this.name;
        data["year"] = this.year;
        data["isActive"] = this.isActive;
        data["softwareFee"] = this.softwareFee;
        data["hraCost"] = this.hraCost;
        data["mecCost"] = this.mecCost;
        data["adjustments"] = this.adjustments;
        data["wageUpFloor"] = this.wageUpFloor;
        data["lastBulkCalculatedDate"] = this.lastBulkCalculatedDate;
        data["lastBulkCalculatedBy"] = this.lastBulkCalculatedBy;
        data["lastBulkCalculatedByUser"] = this.lastBulkCalculatedByUser ? this.lastBulkCalculatedByUser.toJSON() : <any>undefined;
        if (Array.isArray(this.pathways)) {
            data["pathways"] = [];
            for (let item of this.pathways)
                data["pathways"].push(item.toJSON());
        }
        if (Array.isArray(this.ichraClasses)) {
            data["ichraClasses"] = [];
            for (let item of this.ichraClasses)
                data["ichraClasses"].push(item.toJSON());
        }
        data["isCalculating"] = this.isCalculating;
        data["isProjection"] = this.isProjection;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        if (Array.isArray(this.pathwayUsers)) {
            data["pathwayUsers"] = [];
            for (let item of this.pathwayUsers)
                data["pathwayUsers"].push(item.toJSON());
        }
        if (Array.isArray(this.pathwayUsersWithLostEligibilities)) {
            data["pathwayUsersWithLostEligibilities"] = [];
            for (let item of this.pathwayUsersWithLostEligibilities)
                data["pathwayUsersWithLostEligibilities"].push(item.toJSON());
        }
        if (Array.isArray(this.pathwayUsersWithUnaffordableIchra)) {
            data["pathwayUsersWithUnaffordableIchra"] = [];
            for (let item of this.pathwayUsersWithUnaffordableIchra)
                data["pathwayUsersWithUnaffordableIchra"].push(item.toJSON());
        }
        if (Array.isArray(this.pathwayUsersWithIneligiblePlans)) {
            data["pathwayUsersWithIneligiblePlans"] = [];
            for (let item of this.pathwayUsersWithIneligiblePlans)
                data["pathwayUsersWithIneligiblePlans"].push(item.toJSON());
        }
        data["numberOfUnassignedUsers"] = this.numberOfUnassignedUsers;
        data["hasMismatchedContributionsUsers"] = this.hasMismatchedContributionsUsers;
        data["keyId"] = this.keyId;
        data["isStale"] = this.isStale;
        data["annualEmployeeCost"] = this.annualEmployeeCost;
        data["annualProjectedWageUpCost"] = this.annualProjectedWageUpCost;
        data["annualProjectedReimbursementCost"] = this.annualProjectedReimbursementCost;
        data["annualEmployerCost"] = this.annualEmployerCost;
        data["annualEmployerCostEquation"] = this.annualEmployerCostEquation;
        data["annualCost"] = this.annualCost;
        data["annualOldEmployeeCost"] = this.annualOldEmployeeCost;
        data["annualOldEmployerCost"] = this.annualOldEmployerCost;
        data["annualOldRenewalEmployeeCost"] = this.annualOldRenewalEmployeeCost;
        data["annualOldRenewalEmployerCost"] = this.annualOldRenewalEmployerCost;
        data["annualOldCost"] = this.annualOldCost;
        data["annualOldRenewalCost"] = this.annualOldRenewalCost;
        data["projectedSavings"] = this.projectedSavings;
        data["projectedRenewalSavings"] = this.projectedRenewalSavings;
        data["projectedSavingsEquation"] = this.projectedSavingsEquation;
        data["projectedRenewalSavingsEquation"] = this.projectedRenewalSavingsEquation;
        data["additionalFicaCost"] = this.additionalFicaCost;
        data["additionalFicaCostEquation"] = this.additionalFicaCostEquation;
        data["employeesEligibleForTaxCredits"] = this.employeesEligibleForTaxCredits;
        data["sharedResponsibilityEstimate"] = this.sharedResponsibilityEstimate;
        data["sharedResponsibilityEstimateEquation"] = this.sharedResponsibilityEstimateEquation;
        data["sharedResponsibilityFeeYear"] = this.sharedResponsibilityFeeYear;
        data["hasIchraPathway"] = this.hasIchraPathway;
        return data; 
    }
}
export class Pathway implements IPathway {
    id!: string;
    name?: string | undefined;
    pathwayBlueprintId!: string;
    pathwayTypeId!: PathwayTypes;
    ichraClassId?: number | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    pathwayInputs?: PathwayInput[] | undefined;
    pathwayUsers?: PathwayUser[] | undefined;
    blueprint?: PathwayBlueprint | undefined;
    ichraClass?: IchraClass | undefined;
    pathwayRecommendedPlans?: PathwayRecommendedPlan[] | undefined;
    keyId!: string;

    constructor(data?: IPathway) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.pathwayBlueprintId = _data["pathwayBlueprintId"];
            this.pathwayTypeId = _data["pathwayTypeId"];
            this.ichraClassId = _data["ichraClassId"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            if (Array.isArray(_data["pathwayInputs"])) {
                this.pathwayInputs = [] as any;
                for (let item of _data["pathwayInputs"])
                    this.pathwayInputs!.push(PathwayInput.fromJS(item));
            }
            if (Array.isArray(_data["pathwayUsers"])) {
                this.pathwayUsers = [] as any;
                for (let item of _data["pathwayUsers"])
                    this.pathwayUsers!.push(PathwayUser.fromJS(item));
            }
            this.blueprint = _data["blueprint"] ? PathwayBlueprint.fromJS(_data["blueprint"]) : <any>undefined;
            this.ichraClass = _data["ichraClass"] ? IchraClass.fromJS(_data["ichraClass"]) : <any>undefined;
            if (Array.isArray(_data["pathwayRecommendedPlans"])) {
                this.pathwayRecommendedPlans = [] as any;
                for (let item of _data["pathwayRecommendedPlans"])
                    this.pathwayRecommendedPlans!.push(PathwayRecommendedPlan.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): Pathway {
        data = typeof data === 'object' ? data : {};
        let result = new Pathway();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["pathwayBlueprintId"] = this.pathwayBlueprintId;
        data["pathwayTypeId"] = this.pathwayTypeId;
        data["ichraClassId"] = this.ichraClassId;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        if (Array.isArray(this.pathwayInputs)) {
            data["pathwayInputs"] = [];
            for (let item of this.pathwayInputs)
                data["pathwayInputs"].push(item.toJSON());
        }
        if (Array.isArray(this.pathwayUsers)) {
            data["pathwayUsers"] = [];
            for (let item of this.pathwayUsers)
                data["pathwayUsers"].push(item.toJSON());
        }
        data["blueprint"] = this.blueprint ? this.blueprint.toJSON() : <any>undefined;
        data["ichraClass"] = this.ichraClass ? this.ichraClass.toJSON() : <any>undefined;
        if (Array.isArray(this.pathwayRecommendedPlans)) {
            data["pathwayRecommendedPlans"] = [];
            for (let item of this.pathwayRecommendedPlans)
                data["pathwayRecommendedPlans"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PathwayInput implements IPathwayInput {
    id!: string;
    pathwayId!: string;
    pathwayInputTypeId!: PathwayInputTypes;
    value?: string | undefined;

    constructor(data?: IPathwayInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.pathwayId = _data["pathwayId"];
            this.pathwayInputTypeId = _data["pathwayInputTypeId"];
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): PathwayInput {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["pathwayId"] = this.pathwayId;
        data["pathwayInputTypeId"] = this.pathwayInputTypeId;
        data["value"] = this.value;
        return data; 
    }
}
export class IchraClass implements IIchraClass {
    id!: number;
    globalId!: string;
    pathwayBlueprintId!: string;
    name?: string | undefined;
    topLineReimbursementStrategyId!: TopLineReimbursementStrategies;
    ageBandSizeId!: AgeBandSizes;
    ee64?: number | undefined;
    eeRatio?: number | undefined;
    es64?: number | undefined;
    esRatio?: number | undefined;
    ec64?: number | undefined;
    ecRatio?: number | undefined;
    ef64?: number | undefined;
    efRatio?: number | undefined;
    dependentKickerThreshold!: number;
    additionalDependentKicker!: number;
    benchmarkPlanType?: PlanListing | undefined;
    zipCode?: string | undefined;
    county?: string | undefined;
    state?: string | undefined;
    benchmarkCarrier?: string | undefined;
    benchmarkPlanId?: string | undefined;
    dependentKickerCoveragePercentage?: number | undefined;
    eeCoveragePercentage?: number | undefined;
    esCoveragePercentage?: number | undefined;
    ecCoveragePercentage?: number | undefined;
    efCoveragePercentage?: number | undefined;
    otherHouseholdMemberCoveragePercentage?: number | undefined;
    importedAgeBandedTable?: PathwayIchraAgeBandedTableDto[] | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    isStale!: boolean;
    keyId!: number;

    constructor(data?: IIchraClass) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.globalId = _data["globalId"];
            this.pathwayBlueprintId = _data["pathwayBlueprintId"];
            this.name = _data["name"];
            this.topLineReimbursementStrategyId = _data["topLineReimbursementStrategyId"];
            this.ageBandSizeId = _data["ageBandSizeId"];
            this.ee64 = _data["ee64"];
            this.eeRatio = _data["eeRatio"];
            this.es64 = _data["es64"];
            this.esRatio = _data["esRatio"];
            this.ec64 = _data["ec64"];
            this.ecRatio = _data["ecRatio"];
            this.ef64 = _data["ef64"];
            this.efRatio = _data["efRatio"];
            this.dependentKickerThreshold = _data["dependentKickerThreshold"];
            this.additionalDependentKicker = _data["additionalDependentKicker"];
            this.benchmarkPlanType = _data["benchmarkPlanType"];
            this.zipCode = _data["zipCode"];
            this.county = _data["county"];
            this.state = _data["state"];
            this.benchmarkCarrier = _data["benchmarkCarrier"];
            this.benchmarkPlanId = _data["benchmarkPlanId"];
            this.dependentKickerCoveragePercentage = _data["dependentKickerCoveragePercentage"];
            this.eeCoveragePercentage = _data["eeCoveragePercentage"];
            this.esCoveragePercentage = _data["esCoveragePercentage"];
            this.ecCoveragePercentage = _data["ecCoveragePercentage"];
            this.efCoveragePercentage = _data["efCoveragePercentage"];
            this.otherHouseholdMemberCoveragePercentage = _data["otherHouseholdMemberCoveragePercentage"];
            if (Array.isArray(_data["importedAgeBandedTable"])) {
                this.importedAgeBandedTable = [] as any;
                for (let item of _data["importedAgeBandedTable"])
                    this.importedAgeBandedTable!.push(PathwayIchraAgeBandedTableDto.fromJS(item));
            }
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.isStale = _data["isStale"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): IchraClass {
        data = typeof data === 'object' ? data : {};
        let result = new IchraClass();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["globalId"] = this.globalId;
        data["pathwayBlueprintId"] = this.pathwayBlueprintId;
        data["name"] = this.name;
        data["topLineReimbursementStrategyId"] = this.topLineReimbursementStrategyId;
        data["ageBandSizeId"] = this.ageBandSizeId;
        data["ee64"] = this.ee64;
        data["eeRatio"] = this.eeRatio;
        data["es64"] = this.es64;
        data["esRatio"] = this.esRatio;
        data["ec64"] = this.ec64;
        data["ecRatio"] = this.ecRatio;
        data["ef64"] = this.ef64;
        data["efRatio"] = this.efRatio;
        data["dependentKickerThreshold"] = this.dependentKickerThreshold;
        data["additionalDependentKicker"] = this.additionalDependentKicker;
        data["benchmarkPlanType"] = this.benchmarkPlanType;
        data["zipCode"] = this.zipCode;
        data["county"] = this.county;
        data["state"] = this.state;
        data["benchmarkCarrier"] = this.benchmarkCarrier;
        data["benchmarkPlanId"] = this.benchmarkPlanId;
        data["dependentKickerCoveragePercentage"] = this.dependentKickerCoveragePercentage;
        data["eeCoveragePercentage"] = this.eeCoveragePercentage;
        data["esCoveragePercentage"] = this.esCoveragePercentage;
        data["ecCoveragePercentage"] = this.ecCoveragePercentage;
        data["efCoveragePercentage"] = this.efCoveragePercentage;
        data["otherHouseholdMemberCoveragePercentage"] = this.otherHouseholdMemberCoveragePercentage;
        if (Array.isArray(this.importedAgeBandedTable)) {
            data["importedAgeBandedTable"] = [];
            for (let item of this.importedAgeBandedTable)
                data["importedAgeBandedTable"].push(item.toJSON());
        }
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["isStale"] = this.isStale;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class PathwayIchraAgeBandedTableDto implements IPathwayIchraAgeBandedTableDto {
    bandLabel?: string | undefined;
    eeAmount!: number;
    esAmount!: number;
    ecAmount!: number;
    efAmount!: number;

    constructor(data?: IPathwayIchraAgeBandedTableDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.bandLabel = _data["bandLabel"];
            this.eeAmount = _data["eeAmount"];
            this.esAmount = _data["esAmount"];
            this.ecAmount = _data["ecAmount"];
            this.efAmount = _data["efAmount"];
        }
    }

    static fromJS(data: any): PathwayIchraAgeBandedTableDto {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayIchraAgeBandedTableDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["bandLabel"] = this.bandLabel;
        data["eeAmount"] = this.eeAmount;
        data["esAmount"] = this.esAmount;
        data["ecAmount"] = this.ecAmount;
        data["efAmount"] = this.efAmount;
        return data; 
    }
}
export class PathwayRecommendedPlan implements IPathwayRecommendedPlan {
    id!: number;
    globalId!: string;
    pathwayId!: string;
    pathway?: Pathway | undefined;
    planId?: string | undefined;
    planName?: string | undefined;
    carrierId?: string | undefined;
    planLabel?: string | undefined;
    description?: string | undefined;
    order!: number;
    planDetails?: BasicMarketplacePlanDto | undefined;
    benchmarkType?: PlanListing | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: number;

    constructor(data?: IPathwayRecommendedPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.globalId = _data["globalId"];
            this.pathwayId = _data["pathwayId"];
            this.pathway = _data["pathway"] ? Pathway.fromJS(_data["pathway"]) : <any>undefined;
            this.planId = _data["planId"];
            this.planName = _data["planName"];
            this.carrierId = _data["carrierId"];
            this.planLabel = _data["planLabel"];
            this.description = _data["description"];
            this.order = _data["order"];
            this.planDetails = _data["planDetails"] ? BasicMarketplacePlanDto.fromJS(_data["planDetails"]) : <any>undefined;
            this.benchmarkType = _data["benchmarkType"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): PathwayRecommendedPlan {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayRecommendedPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["globalId"] = this.globalId;
        data["pathwayId"] = this.pathwayId;
        data["pathway"] = this.pathway ? this.pathway.toJSON() : <any>undefined;
        data["planId"] = this.planId;
        data["planName"] = this.planName;
        data["carrierId"] = this.carrierId;
        data["planLabel"] = this.planLabel;
        data["description"] = this.description;
        data["order"] = this.order;
        data["planDetails"] = this.planDetails ? this.planDetails.toJSON() : <any>undefined;
        data["benchmarkType"] = this.benchmarkType;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class BasicMarketplacePlanDto implements IBasicMarketplacePlanDto {
    id?: string | undefined;
    name?: string | undefined;
    issuer?: Issuer | undefined;
    exchange?: string | undefined;
    state?: string | undefined;
    metalLevel?: string | undefined;
    isHsaEligible!: boolean;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    genericDrugCost?: string | undefined;
    individualDeductibleCost?: number | undefined;
    familyDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    familyMoopCost?: number | undefined;

    constructor(data?: IBasicMarketplacePlanDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.issuer = _data["issuer"] ? Issuer.fromJS(_data["issuer"]) : <any>undefined;
            this.exchange = _data["exchange"];
            this.state = _data["state"];
            this.metalLevel = _data["metalLevel"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.primaryCareCost = _data["primaryCareCost"];
            this.specialistCost = _data["specialistCost"];
            this.genericDrugCost = _data["genericDrugCost"];
            this.individualDeductibleCost = _data["individualDeductibleCost"];
            this.familyDeductibleCost = _data["familyDeductibleCost"];
            this.individualMoopCost = _data["individualMoopCost"];
            this.familyMoopCost = _data["familyMoopCost"];
        }
    }

    static fromJS(data: any): BasicMarketplacePlanDto {
        data = typeof data === 'object' ? data : {};
        let result = new BasicMarketplacePlanDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["issuer"] = this.issuer ? this.issuer.toJSON() : <any>undefined;
        data["exchange"] = this.exchange;
        data["state"] = this.state;
        data["metalLevel"] = this.metalLevel;
        data["isHsaEligible"] = this.isHsaEligible;
        data["primaryCareCost"] = this.primaryCareCost;
        data["specialistCost"] = this.specialistCost;
        data["genericDrugCost"] = this.genericDrugCost;
        data["individualDeductibleCost"] = this.individualDeductibleCost;
        data["familyDeductibleCost"] = this.familyDeductibleCost;
        data["individualMoopCost"] = this.individualMoopCost;
        data["familyMoopCost"] = this.familyMoopCost;
        return data; 
    }
}
export class Issuer implements IIssuer {
    id?: string | undefined;
    name?: string | undefined;

    constructor(data?: IIssuer) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): Issuer {
        data = typeof data === 'object' ? data : {};
        let result = new Issuer();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        return data; 
    }
}
export class BasicPathwayUserDto implements IBasicPathwayUserDto {
    userFirstName?: string | undefined;
    userLastName?: string | undefined;
    pathwayName?: string | undefined;

    constructor(data?: IBasicPathwayUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userFirstName = _data["userFirstName"];
            this.userLastName = _data["userLastName"];
            this.pathwayName = _data["pathwayName"];
        }
    }

    static fromJS(data: any): BasicPathwayUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new BasicPathwayUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userFirstName"] = this.userFirstName;
        data["userLastName"] = this.userLastName;
        data["pathwayName"] = this.pathwayName;
        return data; 
    }
}
export class PathwayCalculationSummary implements IPathwayCalculationSummary {
    summarySections?: SummarySection[] | undefined;

    constructor(data?: IPathwayCalculationSummary) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["summarySections"])) {
                this.summarySections = [] as any;
                for (let item of _data["summarySections"])
                    this.summarySections!.push(SummarySection.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PathwayCalculationSummary {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayCalculationSummary();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.summarySections)) {
            data["summarySections"] = [];
            for (let item of this.summarySections)
                data["summarySections"].push(item.toJSON());
        }
        return data; 
    }
}
export class SummarySection implements ISummarySection {
    title?: string | undefined;
    columns?: ContentColumn[] | undefined;

    constructor(data?: ISummarySection) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data["title"];
            if (Array.isArray(_data["columns"])) {
                this.columns = [] as any;
                for (let item of _data["columns"])
                    this.columns!.push(ContentColumn.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SummarySection {
        data = typeof data === 'object' ? data : {};
        let result = new SummarySection();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["title"] = this.title;
        if (Array.isArray(this.columns)) {
            data["columns"] = [];
            for (let item of this.columns)
                data["columns"].push(item.toJSON());
        }
        return data; 
    }
}
export class ContentColumn implements IContentColumn {
    sections?: ContentSection[] | undefined;

    constructor(data?: IContentColumn) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["sections"])) {
                this.sections = [] as any;
                for (let item of _data["sections"])
                    this.sections!.push(ContentSection.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ContentColumn {
        data = typeof data === 'object' ? data : {};
        let result = new ContentColumn();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.sections)) {
            data["sections"] = [];
            for (let item of this.sections)
                data["sections"].push(item.toJSON());
        }
        return data; 
    }
}
export class ContentSection implements IContentSection {
    title?: string | undefined;
    content?: string[] | undefined;

    constructor(data?: IContentSection) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.title = _data["title"];
            if (Array.isArray(_data["content"])) {
                this.content = [] as any;
                for (let item of _data["content"])
                    this.content!.push(item);
            }
        }
    }

    static fromJS(data: any): ContentSection {
        data = typeof data === 'object' ? data : {};
        let result = new ContentSection();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["title"] = this.title;
        if (Array.isArray(this.content)) {
            data["content"] = [];
            for (let item of this.content)
                data["content"].push(item);
        }
        return data; 
    }
}
export class BaseUser implements IBaseUser {
    userId!: string;
    teamId!: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    displayName?: string | undefined;

    constructor(data?: IBaseUser) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.teamId = _data["teamId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.email = _data["email"];
            this.displayName = _data["displayName"];
        }
    }

    static fromJS(data: any): BaseUser {
        data = typeof data === 'object' ? data : {};
        let result = new BaseUser();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["teamId"] = this.teamId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["email"] = this.email;
        data["displayName"] = this.displayName;
        return data; 
    }
}
export class ActiveWageUp implements IActiveWageUp {
    id!: string;
    userId!: string;
    user?: User | undefined;
    wageUp?: number | undefined;
    netWageUp!: number;
    reimbursement?: number | undefined;
    year!: number;
    note?: string | undefined;
    reimbursementNote?: string | undefined;
    isCustom!: boolean;
    isReimbursementCustom!: boolean;
    employeeCost!: number;
    planCost!: number;
    totalTaxRate!: number;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    otherCostsNote?: string | undefined;
    tags!: PathwayUserTags;
    calculationSummary?: PathwayCalculationSummary | undefined;
    benchmarkType?: PlanListing | undefined;
    isIchra!: boolean;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;
    wasSetFromIchraPathway!: boolean;

    constructor(data?: IActiveWageUp) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.wageUp = _data["wageUp"];
            this.netWageUp = _data["netWageUp"];
            this.reimbursement = _data["reimbursement"];
            this.year = _data["year"];
            this.note = _data["note"];
            this.reimbursementNote = _data["reimbursementNote"];
            this.isCustom = _data["isCustom"];
            this.isReimbursementCustom = _data["isReimbursementCustom"];
            this.employeeCost = _data["employeeCost"];
            this.planCost = _data["planCost"];
            this.totalTaxRate = _data["totalTaxRate"];
            this.chipCost = _data["chipCost"];
            this.medicareCost = _data["medicareCost"];
            this.unclaimedHouseholdMemberCost = _data["unclaimedHouseholdMemberCost"];
            this.additionalCost = _data["additionalCost"];
            this.otherCostsNote = _data["otherCostsNote"];
            this.tags = _data["tags"];
            this.calculationSummary = _data["calculationSummary"] ? PathwayCalculationSummary.fromJS(_data["calculationSummary"]) : <any>undefined;
            this.benchmarkType = _data["benchmarkType"];
            this.isIchra = _data["isIchra"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
            this.wasSetFromIchraPathway = _data["wasSetFromIchraPathway"];
        }
    }

    static fromJS(data: any): ActiveWageUp {
        data = typeof data === 'object' ? data : {};
        let result = new ActiveWageUp();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["wageUp"] = this.wageUp;
        data["netWageUp"] = this.netWageUp;
        data["reimbursement"] = this.reimbursement;
        data["year"] = this.year;
        data["note"] = this.note;
        data["reimbursementNote"] = this.reimbursementNote;
        data["isCustom"] = this.isCustom;
        data["isReimbursementCustom"] = this.isReimbursementCustom;
        data["employeeCost"] = this.employeeCost;
        data["planCost"] = this.planCost;
        data["totalTaxRate"] = this.totalTaxRate;
        data["chipCost"] = this.chipCost;
        data["medicareCost"] = this.medicareCost;
        data["unclaimedHouseholdMemberCost"] = this.unclaimedHouseholdMemberCost;
        data["additionalCost"] = this.additionalCost;
        data["otherCostsNote"] = this.otherCostsNote;
        data["tags"] = this.tags;
        data["calculationSummary"] = this.calculationSummary ? this.calculationSummary.toJSON() : <any>undefined;
        data["benchmarkType"] = this.benchmarkType;
        data["isIchra"] = this.isIchra;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        data["wasSetFromIchraPathway"] = this.wasSetFromIchraPathway;
        return data; 
    }
}
export class TaxData implements ITaxData {
    id!: string;
    userId!: string;
    taxBracketId!: number;
    taxBracket?: TaxBracket | undefined;
    stateTaxRateId!: string;
    stateTaxRate?: StateTaxRate | undefined;
    ficaTaxRate!: number;
    year!: number;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    totalRate!: number;

    constructor(data?: ITaxData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.taxBracketId = _data["taxBracketId"];
            this.taxBracket = _data["taxBracket"] ? TaxBracket.fromJS(_data["taxBracket"]) : <any>undefined;
            this.stateTaxRateId = _data["stateTaxRateId"];
            this.stateTaxRate = _data["stateTaxRate"] ? StateTaxRate.fromJS(_data["stateTaxRate"]) : <any>undefined;
            this.ficaTaxRate = _data["ficaTaxRate"];
            this.year = _data["year"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            this.totalRate = _data["totalRate"];
        }
    }

    static fromJS(data: any): TaxData {
        data = typeof data === 'object' ? data : {};
        let result = new TaxData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["taxBracketId"] = this.taxBracketId;
        data["taxBracket"] = this.taxBracket ? this.taxBracket.toJSON() : <any>undefined;
        data["stateTaxRateId"] = this.stateTaxRateId;
        data["stateTaxRate"] = this.stateTaxRate ? this.stateTaxRate.toJSON() : <any>undefined;
        data["ficaTaxRate"] = this.ficaTaxRate;
        data["year"] = this.year;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        data["totalRate"] = this.totalRate;
        return data; 
    }
}
export class TaxBracket implements ITaxBracket {
    taxBracketId!: number;
    taxYear!: number;
    filingStatusId!: TaxFilingStatuses;
    incomeMinimum!: number;
    taxRate!: number;

    constructor(data?: ITaxBracket) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.taxBracketId = _data["taxBracketId"];
            this.taxYear = _data["taxYear"];
            this.filingStatusId = _data["filingStatusId"];
            this.incomeMinimum = _data["incomeMinimum"];
            this.taxRate = _data["taxRate"];
        }
    }

    static fromJS(data: any): TaxBracket {
        data = typeof data === 'object' ? data : {};
        let result = new TaxBracket();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["taxBracketId"] = this.taxBracketId;
        data["taxYear"] = this.taxYear;
        data["filingStatusId"] = this.filingStatusId;
        data["incomeMinimum"] = this.incomeMinimum;
        data["taxRate"] = this.taxRate;
        return data; 
    }
}
export class StateTaxRate implements IStateTaxRate {
    stateTaxRateId!: string;
    state?: string | undefined;
    taxRate!: number;
    taxYear!: number;

    constructor(data?: IStateTaxRate) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.stateTaxRateId = _data["stateTaxRateId"];
            this.state = _data["state"];
            this.taxRate = _data["taxRate"];
            this.taxYear = _data["taxYear"];
        }
    }

    static fromJS(data: any): StateTaxRate {
        data = typeof data === 'object' ? data : {};
        let result = new StateTaxRate();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["stateTaxRateId"] = this.stateTaxRateId;
        data["state"] = this.state;
        data["taxRate"] = this.taxRate;
        data["taxYear"] = this.taxYear;
        return data; 
    }
}
export class UserUserRelationship implements IUserUserRelationship {
    id!: string;
    year!: number;
    userId!: string;
    representativeId!: string;
    userRelationshipTypeId!: UserRelationshipTypeIds;
    representative?: User | undefined;
    user?: User | undefined;

    constructor(data?: IUserUserRelationship) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.year = _data["year"];
            this.userId = _data["userId"];
            this.representativeId = _data["representativeId"];
            this.userRelationshipTypeId = _data["userRelationshipTypeId"];
            this.representative = _data["representative"] ? User.fromJS(_data["representative"]) : <any>undefined;
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
        }
    }

    static fromJS(data: any): UserUserRelationship {
        data = typeof data === 'object' ? data : {};
        let result = new UserUserRelationship();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["year"] = this.year;
        data["userId"] = this.userId;
        data["representativeId"] = this.representativeId;
        data["userRelationshipTypeId"] = this.userRelationshipTypeId;
        data["representative"] = this.representative ? this.representative.toJSON() : <any>undefined;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        return data; 
    }
}
export class YearlyUserInfo implements IYearlyUserInfo {
    id!: string;
    userId!: string;
    user?: User | undefined;
    year!: number;
    propertyTypeId!: YearlyUserInfoTypes;
    value?: string | undefined;
    createdBy!: string;
    createdByUser?: User | undefined;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;

    constructor(data?: IYearlyUserInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.year = _data["year"];
            this.propertyTypeId = _data["propertyTypeId"];
            this.value = _data["value"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? User.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): YearlyUserInfo {
        data = typeof data === 'object' ? data : {};
        let result = new YearlyUserInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["year"] = this.year;
        data["propertyTypeId"] = this.propertyTypeId;
        data["value"] = this.value;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class Invite implements IInvite {
    teamId!: string;
    name?: string | undefined;
    email?: string | undefined;
    isTeamAdmin!: boolean;
    isAdvisor!: boolean;

    constructor(data?: IInvite) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
            this.name = _data["name"];
            this.email = _data["email"];
            this.isTeamAdmin = _data["isTeamAdmin"];
            this.isAdvisor = _data["isAdvisor"];
        }
    }

    static fromJS(data: any): Invite {
        data = typeof data === 'object' ? data : {};
        let result = new Invite();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        data["name"] = this.name;
        data["email"] = this.email;
        data["isTeamAdmin"] = this.isTeamAdmin;
        data["isAdvisor"] = this.isAdvisor;
        return data; 
    }
}
export class UserProfile implements IUserProfile {
    year!: number;
    user?: User | undefined;
    address?: Address | undefined;
    mailingAddress?: Address | undefined;
    memberQuoteInfo?: MemberQuoteInfo | undefined;
    memberVerifiedInfo?: MemberVerifiedInfo | undefined;
    userUserRelationships?: UserUserRelationship[] | undefined;
    yearlyUserInfo?: YearlyUserInfoDto | undefined;
    userNotes?: Note[] | undefined;
    hasLoginInfo!: boolean;
    isLockedOut!: boolean;
    loginCreatedDate?: string | undefined;
    signUpToken?: string | undefined;
    majorMedicalCoverageLevel!: GroupLevels;
    areVerificationRhFilesNeeded!: boolean;
    employeeAnnualIncome!: number;
    spouseIncome!: number;
    totalDependentIncome!: number;
    activeWageUp?: ActiveWageUp | undefined;
    canOpenUpShopping!: boolean;
    dateOfBirth?: string | undefined;
    singleFilerStandardDeduction!: number;
    filingStatusId!: TaxFilingStatuses;
    householdIncome!: number;
    baseHouseholdIncome!: number;
    employeeIncome!: number;

    constructor(data?: IUserProfile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.year = _data["year"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
            this.mailingAddress = _data["mailingAddress"] ? Address.fromJS(_data["mailingAddress"]) : <any>undefined;
            this.memberQuoteInfo = _data["memberQuoteInfo"] ? MemberQuoteInfo.fromJS(_data["memberQuoteInfo"]) : <any>undefined;
            this.memberVerifiedInfo = _data["memberVerifiedInfo"] ? MemberVerifiedInfo.fromJS(_data["memberVerifiedInfo"]) : <any>undefined;
            if (Array.isArray(_data["userUserRelationships"])) {
                this.userUserRelationships = [] as any;
                for (let item of _data["userUserRelationships"])
                    this.userUserRelationships!.push(UserUserRelationship.fromJS(item));
            }
            this.yearlyUserInfo = _data["yearlyUserInfo"] ? YearlyUserInfoDto.fromJS(_data["yearlyUserInfo"]) : <any>undefined;
            if (Array.isArray(_data["userNotes"])) {
                this.userNotes = [] as any;
                for (let item of _data["userNotes"])
                    this.userNotes!.push(Note.fromJS(item));
            }
            this.hasLoginInfo = _data["hasLoginInfo"];
            this.isLockedOut = _data["isLockedOut"];
            this.loginCreatedDate = _data["loginCreatedDate"];
            this.signUpToken = _data["signUpToken"];
            this.majorMedicalCoverageLevel = _data["majorMedicalCoverageLevel"];
            this.areVerificationRhFilesNeeded = _data["areVerificationRhFilesNeeded"];
            this.employeeAnnualIncome = _data["employeeAnnualIncome"];
            this.spouseIncome = _data["spouseIncome"];
            this.totalDependentIncome = _data["totalDependentIncome"];
            this.activeWageUp = _data["activeWageUp"] ? ActiveWageUp.fromJS(_data["activeWageUp"]) : <any>undefined;
            this.canOpenUpShopping = _data["canOpenUpShopping"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.singleFilerStandardDeduction = _data["singleFilerStandardDeduction"];
            this.filingStatusId = _data["filingStatusId"];
            this.householdIncome = _data["householdIncome"];
            this.baseHouseholdIncome = _data["baseHouseholdIncome"];
            this.employeeIncome = _data["employeeIncome"];
        }
    }

    static fromJS(data: any): UserProfile {
        data = typeof data === 'object' ? data : {};
        let result = new UserProfile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["year"] = this.year;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        data["mailingAddress"] = this.mailingAddress ? this.mailingAddress.toJSON() : <any>undefined;
        data["memberQuoteInfo"] = this.memberQuoteInfo ? this.memberQuoteInfo.toJSON() : <any>undefined;
        data["memberVerifiedInfo"] = this.memberVerifiedInfo ? this.memberVerifiedInfo.toJSON() : <any>undefined;
        if (Array.isArray(this.userUserRelationships)) {
            data["userUserRelationships"] = [];
            for (let item of this.userUserRelationships)
                data["userUserRelationships"].push(item.toJSON());
        }
        data["yearlyUserInfo"] = this.yearlyUserInfo ? this.yearlyUserInfo.toJSON() : <any>undefined;
        if (Array.isArray(this.userNotes)) {
            data["userNotes"] = [];
            for (let item of this.userNotes)
                data["userNotes"].push(item.toJSON());
        }
        data["hasLoginInfo"] = this.hasLoginInfo;
        data["isLockedOut"] = this.isLockedOut;
        data["loginCreatedDate"] = this.loginCreatedDate;
        data["signUpToken"] = this.signUpToken;
        data["majorMedicalCoverageLevel"] = this.majorMedicalCoverageLevel;
        data["areVerificationRhFilesNeeded"] = this.areVerificationRhFilesNeeded;
        data["employeeAnnualIncome"] = this.employeeAnnualIncome;
        data["spouseIncome"] = this.spouseIncome;
        data["totalDependentIncome"] = this.totalDependentIncome;
        data["activeWageUp"] = this.activeWageUp ? this.activeWageUp.toJSON() : <any>undefined;
        data["canOpenUpShopping"] = this.canOpenUpShopping;
        data["dateOfBirth"] = this.dateOfBirth;
        data["singleFilerStandardDeduction"] = this.singleFilerStandardDeduction;
        data["filingStatusId"] = this.filingStatusId;
        data["householdIncome"] = this.householdIncome;
        data["baseHouseholdIncome"] = this.baseHouseholdIncome;
        data["employeeIncome"] = this.employeeIncome;
        return data; 
    }
}
export class YearlyUserInfoDto implements IYearlyUserInfoDto {
    userId!: string;
    year!: number;
    enrollmentStatus?: YearlyUserPropertyDtoOfEnrollmentStatuses | undefined;
    allowShopping?: boolean | undefined;
    acceptedPathway?: boolean | undefined;
    advisementCompleted?: YearlyUserPropertyDtoOfNullableBoolean | undefined;
    finalReviewCompleted?: YearlyUserPropertyDtoOfBoolean | undefined;
    renewalDecision?: RenewalDecisions | undefined;
    isMinister?: boolean | undefined;
    applicationSubmittedBy?: string | undefined;
    usePremiumTaxCredits!: boolean;
    majorMedicalCoverageLevel!: GroupLevels;
    needsMajorMedicalCoverage!: boolean;
    hsaContributionLimitOverride?: number | undefined;
    otherCoverageSource?: string | undefined;
    allowRecurringExpenses!: boolean;

    constructor(data?: IYearlyUserInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.year = _data["year"];
            this.enrollmentStatus = _data["enrollmentStatus"] ? YearlyUserPropertyDtoOfEnrollmentStatuses.fromJS(_data["enrollmentStatus"]) : <any>undefined;
            this.allowShopping = _data["allowShopping"];
            this.acceptedPathway = _data["acceptedPathway"];
            this.advisementCompleted = _data["advisementCompleted"] ? YearlyUserPropertyDtoOfNullableBoolean.fromJS(_data["advisementCompleted"]) : <any>undefined;
            this.finalReviewCompleted = _data["finalReviewCompleted"] ? YearlyUserPropertyDtoOfBoolean.fromJS(_data["finalReviewCompleted"]) : <any>undefined;
            this.renewalDecision = _data["renewalDecision"];
            this.isMinister = _data["isMinister"];
            this.applicationSubmittedBy = _data["applicationSubmittedBy"];
            this.usePremiumTaxCredits = _data["usePremiumTaxCredits"];
            this.majorMedicalCoverageLevel = _data["majorMedicalCoverageLevel"];
            this.needsMajorMedicalCoverage = _data["needsMajorMedicalCoverage"];
            this.hsaContributionLimitOverride = _data["hsaContributionLimitOverride"];
            this.otherCoverageSource = _data["otherCoverageSource"];
            this.allowRecurringExpenses = _data["allowRecurringExpenses"];
        }
    }

    static fromJS(data: any): YearlyUserInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new YearlyUserInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["year"] = this.year;
        data["enrollmentStatus"] = this.enrollmentStatus ? this.enrollmentStatus.toJSON() : <any>undefined;
        data["allowShopping"] = this.allowShopping;
        data["acceptedPathway"] = this.acceptedPathway;
        data["advisementCompleted"] = this.advisementCompleted ? this.advisementCompleted.toJSON() : <any>undefined;
        data["finalReviewCompleted"] = this.finalReviewCompleted ? this.finalReviewCompleted.toJSON() : <any>undefined;
        data["renewalDecision"] = this.renewalDecision;
        data["isMinister"] = this.isMinister;
        data["applicationSubmittedBy"] = this.applicationSubmittedBy;
        data["usePremiumTaxCredits"] = this.usePremiumTaxCredits;
        data["majorMedicalCoverageLevel"] = this.majorMedicalCoverageLevel;
        data["needsMajorMedicalCoverage"] = this.needsMajorMedicalCoverage;
        data["hsaContributionLimitOverride"] = this.hsaContributionLimitOverride;
        data["otherCoverageSource"] = this.otherCoverageSource;
        data["allowRecurringExpenses"] = this.allowRecurringExpenses;
        return data; 
    }
}
export class YearlyUserPropertyDtoOfEnrollmentStatuses implements IYearlyUserPropertyDtoOfEnrollmentStatuses {
    value!: EnrollmentStatuses;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;

    constructor(data?: IYearlyUserPropertyDtoOfEnrollmentStatuses) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.value = _data["value"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? BaseUser.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
        }
    }

    static fromJS(data: any): YearlyUserPropertyDtoOfEnrollmentStatuses {
        data = typeof data === 'object' ? data : {};
        let result = new YearlyUserPropertyDtoOfEnrollmentStatuses();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["value"] = this.value;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        return data; 
    }
}
export class YearlyUserPropertyDtoOfNullableBoolean implements IYearlyUserPropertyDtoOfNullableBoolean {
    value?: boolean | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;

    constructor(data?: IYearlyUserPropertyDtoOfNullableBoolean) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.value = _data["value"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? BaseUser.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
        }
    }

    static fromJS(data: any): YearlyUserPropertyDtoOfNullableBoolean {
        data = typeof data === 'object' ? data : {};
        let result = new YearlyUserPropertyDtoOfNullableBoolean();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["value"] = this.value;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        return data; 
    }
}
export class YearlyUserPropertyDtoOfBoolean implements IYearlyUserPropertyDtoOfBoolean {
    value!: boolean;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;

    constructor(data?: IYearlyUserPropertyDtoOfBoolean) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.value = _data["value"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? BaseUser.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
        }
    }

    static fromJS(data: any): YearlyUserPropertyDtoOfBoolean {
        data = typeof data === 'object' ? data : {};
        let result = new YearlyUserPropertyDtoOfBoolean();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["value"] = this.value;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        return data; 
    }
}
export class Token implements IToken {
    accessToken?: string | undefined;
    refreshToken?: string | undefined;
    intermediateToken?: string | undefined;

    constructor(data?: IToken) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accessToken = _data["accessToken"];
            this.refreshToken = _data["refreshToken"];
            this.intermediateToken = _data["intermediateToken"];
        }
    }

    static fromJS(data: any): Token {
        data = typeof data === 'object' ? data : {};
        let result = new Token();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["accessToken"] = this.accessToken;
        data["refreshToken"] = this.refreshToken;
        data["intermediateToken"] = this.intermediateToken;
        return data; 
    }
}
export class SignUp implements ISignUp {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    password?: string | undefined;
    token?: string | undefined;

    constructor(data?: ISignUp) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.email = _data["email"];
            this.password = _data["password"];
            this.token = _data["token"];
        }
    }

    static fromJS(data: any): SignUp {
        data = typeof data === 'object' ? data : {};
        let result = new SignUp();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["email"] = this.email;
        data["password"] = this.password;
        data["token"] = this.token;
        return data; 
    }
}
export class ForgotPassword implements IForgotPassword {
    email?: string | undefined;

    constructor(data?: IForgotPassword) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.email = _data["email"];
        }
    }

    static fromJS(data: any): ForgotPassword {
        data = typeof data === 'object' ? data : {};
        let result = new ForgotPassword();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["email"] = this.email;
        return data; 
    }
}
export class ResetPassword implements IResetPassword {
    password?: string | undefined;
    token?: string | undefined;

    constructor(data?: IResetPassword) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.password = _data["password"];
            this.token = _data["token"];
        }
    }

    static fromJS(data: any): ResetPassword {
        data = typeof data === 'object' ? data : {};
        let result = new ResetPassword();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["password"] = this.password;
        data["token"] = this.token;
        return data; 
    }
}
export class RepresentativeOption implements IRepresentativeOption {
    userId!: string;
    name?: string | undefined;

    constructor(data?: IRepresentativeOption) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): RepresentativeOption {
        data = typeof data === 'object' ? data : {};
        let result = new RepresentativeOption();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["name"] = this.name;
        return data; 
    }
}
export class PaginatedResultsDtoOfUserProfile implements IPaginatedResultsDtoOfUserProfile {
    results?: UserProfile[] | undefined;
    pageSize!: number;
    page!: number;
    totalCount!: number;

    constructor(data?: IPaginatedResultsDtoOfUserProfile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["results"])) {
                this.results = [] as any;
                for (let item of _data["results"])
                    this.results!.push(UserProfile.fromJS(item));
            }
            this.pageSize = _data["pageSize"];
            this.page = _data["page"];
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): PaginatedResultsDtoOfUserProfile {
        data = typeof data === 'object' ? data : {};
        let result = new PaginatedResultsDtoOfUserProfile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.results)) {
            data["results"] = [];
            for (let item of this.results)
                data["results"].push(item.toJSON());
        }
        data["pageSize"] = this.pageSize;
        data["page"] = this.page;
        data["totalCount"] = this.totalCount;
        return data; 
    }
}
export class Ordering implements IOrdering {
    property?: string | undefined;
    sortOrder!: SortOrder;

    constructor(data?: IOrdering) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.property = _data["property"];
            this.sortOrder = _data["sortOrder"];
        }
    }

    static fromJS(data: any): Ordering {
        data = typeof data === 'object' ? data : {};
        let result = new Ordering();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["property"] = this.property;
        data["sortOrder"] = this.sortOrder;
        return data; 
    }
}
export class UserProfilesMetadataDto implements IUserProfilesMetadataDto {
    hasVerifiedInfoCount!: number;
    needSurveyCount!: number;
    hasBothSurveyTypes!: boolean;
    hasAnyReimbursements!: boolean;
    invitableMemberCount!: number;

    constructor(data?: IUserProfilesMetadataDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.hasVerifiedInfoCount = _data["hasVerifiedInfoCount"];
            this.needSurveyCount = _data["needSurveyCount"];
            this.hasBothSurveyTypes = _data["hasBothSurveyTypes"];
            this.hasAnyReimbursements = _data["hasAnyReimbursements"];
            this.invitableMemberCount = _data["invitableMemberCount"];
        }
    }

    static fromJS(data: any): UserProfilesMetadataDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserProfilesMetadataDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["hasVerifiedInfoCount"] = this.hasVerifiedInfoCount;
        data["needSurveyCount"] = this.needSurveyCount;
        data["hasBothSurveyTypes"] = this.hasBothSurveyTypes;
        data["hasAnyReimbursements"] = this.hasAnyReimbursements;
        data["invitableMemberCount"] = this.invitableMemberCount;
        return data; 
    }
}
export class Household implements IHousehold {
    userBudget?: UserBudget | undefined;
    userProfile?: UserProfile | undefined;
    householdMembers?: HouseholdMemberDto[] | undefined;
    spouseBirthDate?: string | undefined;

    constructor(data?: IHousehold) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userBudget = _data["userBudget"] ? UserBudget.fromJS(_data["userBudget"]) : <any>undefined;
            this.userProfile = _data["userProfile"] ? UserProfile.fromJS(_data["userProfile"]) : <any>undefined;
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(HouseholdMemberDto.fromJS(item));
            }
            this.spouseBirthDate = _data["spouseBirthDate"];
        }
    }

    static fromJS(data: any): Household {
        data = typeof data === 'object' ? data : {};
        let result = new Household();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userBudget"] = this.userBudget ? this.userBudget.toJSON() : <any>undefined;
        data["userProfile"] = this.userProfile ? this.userProfile.toJSON() : <any>undefined;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        data["spouseBirthDate"] = this.spouseBirthDate;
        return data; 
    }
}
export class UserBudget implements IUserBudget {
    userBudgetId!: string;
    userId!: string;
    amount!: number;
    supplementalWageIncrease!: number;
    taxBracketId!: number;
    year!: number;
    isCustom!: boolean;
    note?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    stateTaxRate!: number;
    ficaTaxRate!: number;
    taxBracket?: TaxBracket | undefined;
    federalTaxRate?: number | undefined;
    taxData?: TaxDataDto | undefined;
    user?: User | undefined;

    constructor(data?: IUserBudget) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userBudgetId = _data["userBudgetId"];
            this.userId = _data["userId"];
            this.amount = _data["amount"];
            this.supplementalWageIncrease = _data["supplementalWageIncrease"];
            this.taxBracketId = _data["taxBracketId"];
            this.year = _data["year"];
            this.isCustom = _data["isCustom"];
            this.note = _data["note"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.stateTaxRate = _data["stateTaxRate"];
            this.ficaTaxRate = _data["ficaTaxRate"];
            this.taxBracket = _data["taxBracket"] ? TaxBracket.fromJS(_data["taxBracket"]) : <any>undefined;
            this.federalTaxRate = _data["federalTaxRate"];
            this.taxData = _data["taxData"] ? TaxDataDto.fromJS(_data["taxData"]) : <any>undefined;
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
        }
    }

    static fromJS(data: any): UserBudget {
        data = typeof data === 'object' ? data : {};
        let result = new UserBudget();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userBudgetId"] = this.userBudgetId;
        data["userId"] = this.userId;
        data["amount"] = this.amount;
        data["supplementalWageIncrease"] = this.supplementalWageIncrease;
        data["taxBracketId"] = this.taxBracketId;
        data["year"] = this.year;
        data["isCustom"] = this.isCustom;
        data["note"] = this.note;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["stateTaxRate"] = this.stateTaxRate;
        data["ficaTaxRate"] = this.ficaTaxRate;
        data["taxBracket"] = this.taxBracket ? this.taxBracket.toJSON() : <any>undefined;
        data["federalTaxRate"] = this.federalTaxRate;
        data["taxData"] = this.taxData ? this.taxData.toJSON() : <any>undefined;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        return data; 
    }
}
export class TaxDataDto implements ITaxDataDto {
    id!: string;
    userId!: string;
    year!: number;
    stateTaxRate!: number;
    ficaTaxRate!: number;
    federalTaxRate!: number;
    totalTaxRate!: number;
    taxBracket?: TaxBracket | undefined;

    constructor(data?: ITaxDataDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.year = _data["year"];
            this.stateTaxRate = _data["stateTaxRate"];
            this.ficaTaxRate = _data["ficaTaxRate"];
            this.federalTaxRate = _data["federalTaxRate"];
            this.totalTaxRate = _data["totalTaxRate"];
            this.taxBracket = _data["taxBracket"] ? TaxBracket.fromJS(_data["taxBracket"]) : <any>undefined;
        }
    }

    static fromJS(data: any): TaxDataDto {
        data = typeof data === 'object' ? data : {};
        let result = new TaxDataDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["year"] = this.year;
        data["stateTaxRate"] = this.stateTaxRate;
        data["ficaTaxRate"] = this.ficaTaxRate;
        data["federalTaxRate"] = this.federalTaxRate;
        data["totalTaxRate"] = this.totalTaxRate;
        data["taxBracket"] = this.taxBracket ? this.taxBracket.toJSON() : <any>undefined;
        return data; 
    }
}
export class HouseholdMemberDto implements IHouseholdMemberDto {
    householdMemberId!: string;
    userId!: string;
    householdMemberTypeId!: HouseholdMemberTypes;
    isSpouse!: boolean;
    isDependent!: boolean;
    isUnclaimed!: boolean;
    isIchraChild!: boolean;
    displayName?: string | undefined;
    displayNameWithLabel?: string | undefined;
    preferredNameWithFallback?: string | undefined;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    suffix?: string | undefined;
    dateOfBirth!: string;
    gender?: string | undefined;
    usesTobacco!: boolean;
    isPregnant?: boolean | undefined;
    heightInInches?: number | undefined;
    weightInPounds?: number | undefined;
    usCitizen?: boolean | undefined;
    naturalizedCitizen?: boolean | undefined;
    americanIndian?: boolean | undefined;
    alaskanNative?: boolean | undefined;
    fullTimeStudent?: boolean | undefined;
    fosterCareAt18OrOlder?: boolean | undefined;
    specialNeeds?: boolean | undefined;
    isEmployed?: boolean | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageEligibilities!: OtherCoverageEligibilities;
    otherCoverageSource?: string | undefined;
    employerName?: string | undefined;
    needsCoverage!: boolean;
    socialSecurityNumber?: string | undefined;
    year?: number | undefined;
    haveDataForYear!: boolean;
    income?: number | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    addressId?: string | undefined;
    address?: Address | undefined;
    mailingAddressId?: string | undefined;
    mailingAddress?: Address | undefined;
    householdMemberProviders?: HouseholdMemberProvider[] | undefined;
    householdMemberDrugs?: HouseholdMemberDrug[] | undefined;
    affectsBudgetPaths?: string[] | undefined;
    affectsPathwayWageUpPaths?: string[] | undefined;
    keyId!: string;

    constructor(data?: IHouseholdMemberDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberId = _data["householdMemberId"];
            this.userId = _data["userId"];
            this.householdMemberTypeId = _data["householdMemberTypeId"];
            this.isSpouse = _data["isSpouse"];
            this.isDependent = _data["isDependent"];
            this.isUnclaimed = _data["isUnclaimed"];
            this.isIchraChild = _data["isIchraChild"];
            this.displayName = _data["displayName"];
            this.displayNameWithLabel = _data["displayNameWithLabel"];
            this.preferredNameWithFallback = _data["preferredNameWithFallback"];
            this.firstName = _data["firstName"];
            this.middleName = _data["middleName"];
            this.lastName = _data["lastName"];
            this.preferredName = _data["preferredName"];
            this.suffix = _data["suffix"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.gender = _data["gender"];
            this.usesTobacco = _data["usesTobacco"];
            this.isPregnant = _data["isPregnant"];
            this.heightInInches = _data["heightInInches"];
            this.weightInPounds = _data["weightInPounds"];
            this.usCitizen = _data["usCitizen"];
            this.naturalizedCitizen = _data["naturalizedCitizen"];
            this.americanIndian = _data["americanIndian"];
            this.alaskanNative = _data["alaskanNative"];
            this.fullTimeStudent = _data["fullTimeStudent"];
            this.fosterCareAt18OrOlder = _data["fosterCareAt18OrOlder"];
            this.specialNeeds = _data["specialNeeds"];
            this.isEmployed = _data["isEmployed"];
            this.hasSameEmployer = _data["hasSameEmployer"];
            this.otherCoverageEligibilities = _data["otherCoverageEligibilities"];
            this.otherCoverageSource = _data["otherCoverageSource"];
            this.employerName = _data["employerName"];
            this.needsCoverage = _data["needsCoverage"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.year = _data["year"];
            this.haveDataForYear = _data["haveDataForYear"];
            this.income = _data["income"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.addressId = _data["addressId"];
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
            this.mailingAddressId = _data["mailingAddressId"];
            this.mailingAddress = _data["mailingAddress"] ? Address.fromJS(_data["mailingAddress"]) : <any>undefined;
            if (Array.isArray(_data["householdMemberProviders"])) {
                this.householdMemberProviders = [] as any;
                for (let item of _data["householdMemberProviders"])
                    this.householdMemberProviders!.push(HouseholdMemberProvider.fromJS(item));
            }
            if (Array.isArray(_data["householdMemberDrugs"])) {
                this.householdMemberDrugs = [] as any;
                for (let item of _data["householdMemberDrugs"])
                    this.householdMemberDrugs!.push(HouseholdMemberDrug.fromJS(item));
            }
            if (Array.isArray(_data["affectsBudgetPaths"])) {
                this.affectsBudgetPaths = [] as any;
                for (let item of _data["affectsBudgetPaths"])
                    this.affectsBudgetPaths!.push(item);
            }
            if (Array.isArray(_data["affectsPathwayWageUpPaths"])) {
                this.affectsPathwayWageUpPaths = [] as any;
                for (let item of _data["affectsPathwayWageUpPaths"])
                    this.affectsPathwayWageUpPaths!.push(item);
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): HouseholdMemberDto {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberId"] = this.householdMemberId;
        data["userId"] = this.userId;
        data["householdMemberTypeId"] = this.householdMemberTypeId;
        data["isSpouse"] = this.isSpouse;
        data["isDependent"] = this.isDependent;
        data["isUnclaimed"] = this.isUnclaimed;
        data["isIchraChild"] = this.isIchraChild;
        data["displayName"] = this.displayName;
        data["displayNameWithLabel"] = this.displayNameWithLabel;
        data["preferredNameWithFallback"] = this.preferredNameWithFallback;
        data["firstName"] = this.firstName;
        data["middleName"] = this.middleName;
        data["lastName"] = this.lastName;
        data["preferredName"] = this.preferredName;
        data["suffix"] = this.suffix;
        data["dateOfBirth"] = this.dateOfBirth;
        data["gender"] = this.gender;
        data["usesTobacco"] = this.usesTobacco;
        data["isPregnant"] = this.isPregnant;
        data["heightInInches"] = this.heightInInches;
        data["weightInPounds"] = this.weightInPounds;
        data["usCitizen"] = this.usCitizen;
        data["naturalizedCitizen"] = this.naturalizedCitizen;
        data["americanIndian"] = this.americanIndian;
        data["alaskanNative"] = this.alaskanNative;
        data["fullTimeStudent"] = this.fullTimeStudent;
        data["fosterCareAt18OrOlder"] = this.fosterCareAt18OrOlder;
        data["specialNeeds"] = this.specialNeeds;
        data["isEmployed"] = this.isEmployed;
        data["hasSameEmployer"] = this.hasSameEmployer;
        data["otherCoverageEligibilities"] = this.otherCoverageEligibilities;
        data["otherCoverageSource"] = this.otherCoverageSource;
        data["employerName"] = this.employerName;
        data["needsCoverage"] = this.needsCoverage;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["year"] = this.year;
        data["haveDataForYear"] = this.haveDataForYear;
        data["income"] = this.income;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["addressId"] = this.addressId;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        data["mailingAddressId"] = this.mailingAddressId;
        data["mailingAddress"] = this.mailingAddress ? this.mailingAddress.toJSON() : <any>undefined;
        if (Array.isArray(this.householdMemberProviders)) {
            data["householdMemberProviders"] = [];
            for (let item of this.householdMemberProviders)
                data["householdMemberProviders"].push(item.toJSON());
        }
        if (Array.isArray(this.householdMemberDrugs)) {
            data["householdMemberDrugs"] = [];
            for (let item of this.householdMemberDrugs)
                data["householdMemberDrugs"].push(item.toJSON());
        }
        if (Array.isArray(this.affectsBudgetPaths)) {
            data["affectsBudgetPaths"] = [];
            for (let item of this.affectsBudgetPaths)
                data["affectsBudgetPaths"].push(item);
        }
        if (Array.isArray(this.affectsPathwayWageUpPaths)) {
            data["affectsPathwayWageUpPaths"] = [];
            for (let item of this.affectsPathwayWageUpPaths)
                data["affectsPathwayWageUpPaths"].push(item);
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class OperationBase implements IOperationBase {
    path?: string | undefined;
    op?: string | undefined;
    from?: string | undefined;

    constructor(data?: IOperationBase) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.path = _data["path"];
            this.op = _data["op"];
            this.from = _data["from"];
        }
    }

    static fromJS(data: any): OperationBase {
        data = typeof data === 'object' ? data : {};
        let result = new OperationBase();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["path"] = this.path;
        data["op"] = this.op;
        data["from"] = this.from;
        return data; 
    }
}
export class Operation extends OperationBase implements IOperation {
    value?: any | undefined;

    constructor(data?: IOperation) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): Operation {
        data = typeof data === 'object' ? data : {};
        let result = new Operation();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["value"] = this.value;
        super.toJSON(data);
        return data; 
    }
}
export class VerifiedInfoComparison implements IVerifiedInfoComparison {
    differences?: Difference[] | undefined;
    hasVerifiedInfoForSourceYear!: boolean;
    hasVerifiedInfoForDestinationYear!: boolean;

    constructor(data?: IVerifiedInfoComparison) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["differences"])) {
                this.differences = [] as any;
                for (let item of _data["differences"])
                    this.differences!.push(Difference.fromJS(item));
            }
            this.hasVerifiedInfoForSourceYear = _data["hasVerifiedInfoForSourceYear"];
            this.hasVerifiedInfoForDestinationYear = _data["hasVerifiedInfoForDestinationYear"];
        }
    }

    static fromJS(data: any): VerifiedInfoComparison {
        data = typeof data === 'object' ? data : {};
        let result = new VerifiedInfoComparison();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.differences)) {
            data["differences"] = [];
            for (let item of this.differences)
                data["differences"].push(item.toJSON());
        }
        data["hasVerifiedInfoForSourceYear"] = this.hasVerifiedInfoForSourceYear;
        data["hasVerifiedInfoForDestinationYear"] = this.hasVerifiedInfoForDestinationYear;
        return data; 
    }
}
export class Difference implements IDifference {
    name?: string | undefined;
    before?: string | undefined;
    after?: string | undefined;

    constructor(data?: IDifference) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.before = _data["before"];
            this.after = _data["after"];
        }
    }

    static fromJS(data: any): Difference {
        data = typeof data === 'object' ? data : {};
        let result = new Difference();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["before"] = this.before;
        data["after"] = this.after;
        return data; 
    }
}
export class HouseholdMemberPatchRecalculateDto implements IHouseholdMemberPatchRecalculateDto {
    householdMember?: HouseholdMemberDto | undefined;
    activeWageUp?: ActiveWageUp | undefined;

    constructor(data?: IHouseholdMemberPatchRecalculateDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMember = _data["householdMember"] ? HouseholdMemberDto.fromJS(_data["householdMember"]) : <any>undefined;
            this.activeWageUp = _data["activeWageUp"] ? ActiveWageUp.fromJS(_data["activeWageUp"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberPatchRecalculateDto {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberPatchRecalculateDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMember"] = this.householdMember ? this.householdMember.toJSON() : <any>undefined;
        data["activeWageUp"] = this.activeWageUp ? this.activeWageUp.toJSON() : <any>undefined;
        return data; 
    }
}
export class MemberEligibility implements IMemberEligibility {
    memberId!: string;
    eligibilityStatus!: HouseholdEligibilityStatus;
    otherCoverageEligibility!: OtherCoverageEligibilities;

    constructor(data?: IMemberEligibility) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.memberId = _data["memberId"];
            this.eligibilityStatus = _data["eligibilityStatus"];
            this.otherCoverageEligibility = _data["otherCoverageEligibility"];
        }
    }

    static fromJS(data: any): MemberEligibility {
        data = typeof data === 'object' ? data : {};
        let result = new MemberEligibility();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["memberId"] = this.memberId;
        data["eligibilityStatus"] = this.eligibilityStatus;
        data["otherCoverageEligibility"] = this.otherCoverageEligibility;
        return data; 
    }
}
export class CopyTeamInfoRequest implements ICopyTeamInfoRequest {
    teamId!: string;
    sourceYear!: number;
    targetYear!: number;

    constructor(data?: ICopyTeamInfoRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
            this.sourceYear = _data["sourceYear"];
            this.targetYear = _data["targetYear"];
        }
    }

    static fromJS(data: any): CopyTeamInfoRequest {
        data = typeof data === 'object' ? data : {};
        let result = new CopyTeamInfoRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        data["sourceYear"] = this.sourceYear;
        data["targetYear"] = this.targetYear;
        return data; 
    }
}
export class SocialSecurityRequest implements ISocialSecurityRequest {
    entityId!: string;
    socialSecurityNumber?: string | undefined;
    entityType!: EntityType;

    constructor(data?: ISocialSecurityRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.entityId = _data["entityId"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.entityType = _data["entityType"];
        }
    }

    static fromJS(data: any): SocialSecurityRequest {
        data = typeof data === 'object' ? data : {};
        let result = new SocialSecurityRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["entityId"] = this.entityId;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["entityType"] = this.entityType;
        return data; 
    }
}
export class QualitativeAnswers implements IQualitativeAnswers {
    id!: string;
    userId!: string;
    likeAboutCurrentInsuranceAnswer?: string | undefined;
    improveCurrentInsuranceAnswer?: string | undefined;
    anyFeedbackAnswer?: string | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    keyId!: string;
    healthInsuranceAnswers?: QualitativeHealthInsuranceAnswers[] | undefined;
    otherBenefitsAnswers?: QualitativeOtherBenefitsAnswers[] | undefined;

    constructor(data?: IQualitativeAnswers) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.likeAboutCurrentInsuranceAnswer = _data["likeAboutCurrentInsuranceAnswer"];
            this.improveCurrentInsuranceAnswer = _data["improveCurrentInsuranceAnswer"];
            this.anyFeedbackAnswer = _data["anyFeedbackAnswer"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.keyId = _data["keyId"];
            if (Array.isArray(_data["healthInsuranceAnswers"])) {
                this.healthInsuranceAnswers = [] as any;
                for (let item of _data["healthInsuranceAnswers"])
                    this.healthInsuranceAnswers!.push(QualitativeHealthInsuranceAnswers.fromJS(item));
            }
            if (Array.isArray(_data["otherBenefitsAnswers"])) {
                this.otherBenefitsAnswers = [] as any;
                for (let item of _data["otherBenefitsAnswers"])
                    this.otherBenefitsAnswers!.push(QualitativeOtherBenefitsAnswers.fromJS(item));
            }
        }
    }

    static fromJS(data: any): QualitativeAnswers {
        data = typeof data === 'object' ? data : {};
        let result = new QualitativeAnswers();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["likeAboutCurrentInsuranceAnswer"] = this.likeAboutCurrentInsuranceAnswer;
        data["improveCurrentInsuranceAnswer"] = this.improveCurrentInsuranceAnswer;
        data["anyFeedbackAnswer"] = this.anyFeedbackAnswer;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["keyId"] = this.keyId;
        if (Array.isArray(this.healthInsuranceAnswers)) {
            data["healthInsuranceAnswers"] = [];
            for (let item of this.healthInsuranceAnswers)
                data["healthInsuranceAnswers"].push(item.toJSON());
        }
        if (Array.isArray(this.otherBenefitsAnswers)) {
            data["otherBenefitsAnswers"] = [];
            for (let item of this.otherBenefitsAnswers)
                data["otherBenefitsAnswers"].push(item.toJSON());
        }
        return data; 
    }
}
export class QualitativeHealthInsuranceAnswers implements IQualitativeHealthInsuranceAnswers {
    id!: string;
    qualitativeAnswerId!: string;
    healthInsurancePartId!: HealthInsuranceParts;
    rank!: number;
    qualitativeAnswers?: QualitativeAnswers | undefined;

    constructor(data?: IQualitativeHealthInsuranceAnswers) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.qualitativeAnswerId = _data["qualitativeAnswerId"];
            this.healthInsurancePartId = _data["healthInsurancePartId"];
            this.rank = _data["rank"];
            this.qualitativeAnswers = _data["qualitativeAnswers"] ? QualitativeAnswers.fromJS(_data["qualitativeAnswers"]) : <any>undefined;
        }
    }

    static fromJS(data: any): QualitativeHealthInsuranceAnswers {
        data = typeof data === 'object' ? data : {};
        let result = new QualitativeHealthInsuranceAnswers();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["qualitativeAnswerId"] = this.qualitativeAnswerId;
        data["healthInsurancePartId"] = this.healthInsurancePartId;
        data["rank"] = this.rank;
        data["qualitativeAnswers"] = this.qualitativeAnswers ? this.qualitativeAnswers.toJSON() : <any>undefined;
        return data; 
    }
}
export class QualitativeOtherBenefitsAnswers implements IQualitativeOtherBenefitsAnswers {
    id!: string;
    qualitativeAnswerId!: string;
    otherBenefitId!: OtherBenefits;
    qualitativeAnswers?: QualitativeAnswers | undefined;

    constructor(data?: IQualitativeOtherBenefitsAnswers) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.qualitativeAnswerId = _data["qualitativeAnswerId"];
            this.otherBenefitId = _data["otherBenefitId"];
            this.qualitativeAnswers = _data["qualitativeAnswers"] ? QualitativeAnswers.fromJS(_data["qualitativeAnswers"]) : <any>undefined;
        }
    }

    static fromJS(data: any): QualitativeOtherBenefitsAnswers {
        data = typeof data === 'object' ? data : {};
        let result = new QualitativeOtherBenefitsAnswers();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["qualitativeAnswerId"] = this.qualitativeAnswerId;
        data["otherBenefitId"] = this.otherBenefitId;
        data["qualitativeAnswers"] = this.qualitativeAnswers ? this.qualitativeAnswers.toJSON() : <any>undefined;
        return data; 
    }
}
export class CustomQuestionAnswer implements ICustomQuestionAnswer {
    id!: string;
    customQuestionId!: string;
    userId!: string;
    answer?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    customQuestion?: CustomQuestion | undefined;

    constructor(data?: ICustomQuestionAnswer) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.customQuestionId = _data["customQuestionId"];
            this.userId = _data["userId"];
            this.answer = _data["answer"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            this.customQuestion = _data["customQuestion"] ? CustomQuestion.fromJS(_data["customQuestion"]) : <any>undefined;
        }
    }

    static fromJS(data: any): CustomQuestionAnswer {
        data = typeof data === 'object' ? data : {};
        let result = new CustomQuestionAnswer();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["customQuestionId"] = this.customQuestionId;
        data["userId"] = this.userId;
        data["answer"] = this.answer;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        data["customQuestion"] = this.customQuestion ? this.customQuestion.toJSON() : <any>undefined;
        return data; 
    }
}
export class CustomQuestion implements ICustomQuestion {
    id!: string;
    teamId!: string;
    text?: string | undefined;
    order!: number;
    isArchived!: boolean;
    archivedDate?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    keyId!: string;
    hasAnswers!: boolean;
    answers?: CustomQuestionAnswer[] | undefined;

    constructor(data?: ICustomQuestion) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.text = _data["text"];
            this.order = _data["order"];
            this.isArchived = _data["isArchived"];
            this.archivedDate = _data["archivedDate"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.keyId = _data["keyId"];
            this.hasAnswers = _data["hasAnswers"];
            if (Array.isArray(_data["answers"])) {
                this.answers = [] as any;
                for (let item of _data["answers"])
                    this.answers!.push(CustomQuestionAnswer.fromJS(item));
            }
        }
    }

    static fromJS(data: any): CustomQuestion {
        data = typeof data === 'object' ? data : {};
        let result = new CustomQuestion();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["text"] = this.text;
        data["order"] = this.order;
        data["isArchived"] = this.isArchived;
        data["archivedDate"] = this.archivedDate;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["keyId"] = this.keyId;
        data["hasAnswers"] = this.hasAnswers;
        if (Array.isArray(this.answers)) {
            data["answers"] = [];
            for (let item of this.answers)
                data["answers"].push(item.toJSON());
        }
        return data; 
    }
}
export class DataForEnrollmentDto implements IDataForEnrollmentDto {
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;

    constructor(data?: IDataForEnrollmentDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            if (Array.isArray(_data["selectedPlans"])) {
                this.selectedPlans = [] as any;
                for (let item of _data["selectedPlans"])
                    this.selectedPlans!.push(SelectedPlan.fromJS(item));
            }
        }
    }

    static fromJS(data: any): DataForEnrollmentDto {
        data = typeof data === 'object' ? data : {};
        let result = new DataForEnrollmentDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        if (Array.isArray(this.selectedPlans)) {
            data["selectedPlans"] = [];
            for (let item of this.selectedPlans)
                data["selectedPlans"].push(item.toJSON());
        }
        return data; 
    }
}
export class PaginatedResultsDtoOfUserTermDetailManageMemberDto implements IPaginatedResultsDtoOfUserTermDetailManageMemberDto {
    results?: UserTermDetailManageMemberDto[] | undefined;
    pageSize!: number;
    page!: number;
    totalCount!: number;

    constructor(data?: IPaginatedResultsDtoOfUserTermDetailManageMemberDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["results"])) {
                this.results = [] as any;
                for (let item of _data["results"])
                    this.results!.push(UserTermDetailManageMemberDto.fromJS(item));
            }
            this.pageSize = _data["pageSize"];
            this.page = _data["page"];
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): PaginatedResultsDtoOfUserTermDetailManageMemberDto {
        data = typeof data === 'object' ? data : {};
        let result = new PaginatedResultsDtoOfUserTermDetailManageMemberDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.results)) {
            data["results"] = [];
            for (let item of this.results)
                data["results"].push(item.toJSON());
        }
        data["pageSize"] = this.pageSize;
        data["page"] = this.page;
        data["totalCount"] = this.totalCount;
        return data; 
    }
}
export class UserTermDetailManageMemberPaginatedResults extends PaginatedResultsDtoOfUserTermDetailManageMemberDto implements IUserTermDetailManageMemberPaginatedResults {
    allPossibleUserIds?: string[] | undefined;

    constructor(data?: IUserTermDetailManageMemberPaginatedResults) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            if (Array.isArray(_data["allPossibleUserIds"])) {
                this.allPossibleUserIds = [] as any;
                for (let item of _data["allPossibleUserIds"])
                    this.allPossibleUserIds!.push(item);
            }
        }
    }

    static fromJS(data: any): UserTermDetailManageMemberPaginatedResults {
        data = typeof data === 'object' ? data : {};
        let result = new UserTermDetailManageMemberPaginatedResults();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.allPossibleUserIds)) {
            data["allPossibleUserIds"] = [];
            for (let item of this.allPossibleUserIds)
                data["allPossibleUserIds"].push(item);
        }
        super.toJSON(data);
        return data; 
    }
}
export class UserTermDetailManageMemberDto implements IUserTermDetailManageMemberDto {
    userTermDetailId?: string | undefined;
    userId!: string;
    coverageLevelId?: GroupLevels | undefined;
    statusId?: UserTeamBenefitTermDetailStatuses | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    planCost?: number | undefined;
    employerCost?: number | undefined;
    employeeCost?: number | undefined;
    employeeCostEquation?: string | undefined;
    employeeCostVariableEquation?: string | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    employerCostEquation?: string | undefined;
    employerCostVariableEquation?: string | undefined;
    employerContribution?: number | undefined;
    teamBenefitTermDetailId!: string;
    user?: ManageMemberUserDto | undefined;
    coveredHouseholdMembers?: BasicHouseholdMemberDto[] | undefined;
    allHouseholdMembers?: BasicHouseholdMemberDto[] | undefined;
    lastErrorMessage?: string | undefined;
    lastErrorDate?: string | undefined;

    constructor(data?: IUserTermDetailManageMemberDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTermDetailId = _data["userTermDetailId"];
            this.userId = _data["userId"];
            this.coverageLevelId = _data["coverageLevelId"];
            this.statusId = _data["statusId"];
            this.coverageStartDate = _data["coverageStartDate"];
            this.coverageEndDate = _data["coverageEndDate"];
            this.planCost = _data["planCost"];
            this.employerCost = _data["employerCost"];
            this.employeeCost = _data["employeeCost"];
            this.employeeCostEquation = _data["employeeCostEquation"];
            this.employeeCostVariableEquation = _data["employeeCostVariableEquation"];
            this.contributionFrequency = _data["contributionFrequency"];
            this.employerCostEquation = _data["employerCostEquation"];
            this.employerCostVariableEquation = _data["employerCostVariableEquation"];
            this.employerContribution = _data["employerContribution"];
            this.teamBenefitTermDetailId = _data["teamBenefitTermDetailId"];
            this.user = _data["user"] ? ManageMemberUserDto.fromJS(_data["user"]) : <any>undefined;
            if (Array.isArray(_data["coveredHouseholdMembers"])) {
                this.coveredHouseholdMembers = [] as any;
                for (let item of _data["coveredHouseholdMembers"])
                    this.coveredHouseholdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            if (Array.isArray(_data["allHouseholdMembers"])) {
                this.allHouseholdMembers = [] as any;
                for (let item of _data["allHouseholdMembers"])
                    this.allHouseholdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            this.lastErrorMessage = _data["lastErrorMessage"];
            this.lastErrorDate = _data["lastErrorDate"];
        }
    }

    static fromJS(data: any): UserTermDetailManageMemberDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserTermDetailManageMemberDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTermDetailId"] = this.userTermDetailId;
        data["userId"] = this.userId;
        data["coverageLevelId"] = this.coverageLevelId;
        data["statusId"] = this.statusId;
        data["coverageStartDate"] = this.coverageStartDate;
        data["coverageEndDate"] = this.coverageEndDate;
        data["planCost"] = this.planCost;
        data["employerCost"] = this.employerCost;
        data["employeeCost"] = this.employeeCost;
        data["employeeCostEquation"] = this.employeeCostEquation;
        data["employeeCostVariableEquation"] = this.employeeCostVariableEquation;
        data["contributionFrequency"] = this.contributionFrequency;
        data["employerCostEquation"] = this.employerCostEquation;
        data["employerCostVariableEquation"] = this.employerCostVariableEquation;
        data["employerContribution"] = this.employerContribution;
        data["teamBenefitTermDetailId"] = this.teamBenefitTermDetailId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        if (Array.isArray(this.coveredHouseholdMembers)) {
            data["coveredHouseholdMembers"] = [];
            for (let item of this.coveredHouseholdMembers)
                data["coveredHouseholdMembers"].push(item.toJSON());
        }
        if (Array.isArray(this.allHouseholdMembers)) {
            data["allHouseholdMembers"] = [];
            for (let item of this.allHouseholdMembers)
                data["allHouseholdMembers"].push(item.toJSON());
        }
        data["lastErrorMessage"] = this.lastErrorMessage;
        data["lastErrorDate"] = this.lastErrorDate;
        return data; 
    }
}
export class ManageMemberUserDto extends BaseUser implements IManageMemberUserDto {
    hireDate?: string | undefined;

    constructor(data?: IManageMemberUserDto) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.hireDate = _data["hireDate"];
        }
    }

    static fromJS(data: any): ManageMemberUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new ManageMemberUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["hireDate"] = this.hireDate;
        super.toJSON(data);
        return data; 
    }
}
export class EditUserTeamBenefitTermDetailsRequest implements IEditUserTeamBenefitTermDetailsRequest {
    userIds?: string[] | undefined;
    status?: UserTeamBenefitTermDetailStatuses | undefined;
    allHouseholdMembersCovered?: boolean | undefined;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;

    constructor(data?: IEditUserTeamBenefitTermDetailsRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["userIds"])) {
                this.userIds = [] as any;
                for (let item of _data["userIds"])
                    this.userIds!.push(item);
            }
            this.status = _data["status"];
            this.allHouseholdMembersCovered = _data["allHouseholdMembersCovered"];
            this.coverageStartDate = _data["coverageStartDate"];
            this.coverageEndDate = _data["coverageEndDate"];
        }
    }

    static fromJS(data: any): EditUserTeamBenefitTermDetailsRequest {
        data = typeof data === 'object' ? data : {};
        let result = new EditUserTeamBenefitTermDetailsRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.userIds)) {
            data["userIds"] = [];
            for (let item of this.userIds)
                data["userIds"].push(item);
        }
        data["status"] = this.status;
        data["allHouseholdMembersCovered"] = this.allHouseholdMembersCovered;
        data["coverageStartDate"] = this.coverageStartDate;
        data["coverageEndDate"] = this.coverageEndDate;
        return data; 
    }
}
export class TeamBenefitDto implements ITeamBenefitDto {
    id!: string;
    teamId!: string;
    startDate?: string | undefined;
    endDate?: string | undefined;
    nextRenewableDate?: string | undefined;
    nextMemberEnrollmentStartDate?: string | undefined;
    nextMemberEnrollmentEndDate?: string | undefined;
    rhManagementDate?: string | undefined;
    eligibilityWaitingDays?: number | undefined;
    rhManagementTypeId?: RhManagementTypes | undefined;
    planMinimumCost?: number | undefined;
    planName?: string | undefined;
    groupPlanId?: string | undefined;
    rhFee?: number | undefined;
    submitExpenseUrl?: string | undefined;
    utilization?: number | undefined;
    useForReimbursementOverlay!: boolean;
    useForAncillarySelection!: boolean;
    offerLevel?: OfferLevels | undefined;
    rhFiles?: RhFileDto[] | undefined;
    teamBenefitType!: TeamBenefitTypes;
    teamBenefitTypesCarrier?: TeamBenefitTypesCarrierDto | undefined;
    createdDate!: string;

    constructor(data?: ITeamBenefitDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.nextRenewableDate = _data["nextRenewableDate"];
            this.nextMemberEnrollmentStartDate = _data["nextMemberEnrollmentStartDate"];
            this.nextMemberEnrollmentEndDate = _data["nextMemberEnrollmentEndDate"];
            this.rhManagementDate = _data["rhManagementDate"];
            this.eligibilityWaitingDays = _data["eligibilityWaitingDays"];
            this.rhManagementTypeId = _data["rhManagementTypeId"];
            this.planMinimumCost = _data["planMinimumCost"];
            this.planName = _data["planName"];
            this.groupPlanId = _data["groupPlanId"];
            this.rhFee = _data["rhFee"];
            this.submitExpenseUrl = _data["submitExpenseUrl"];
            this.utilization = _data["utilization"];
            this.useForReimbursementOverlay = _data["useForReimbursementOverlay"];
            this.useForAncillarySelection = _data["useForAncillarySelection"];
            this.offerLevel = _data["offerLevel"];
            if (Array.isArray(_data["rhFiles"])) {
                this.rhFiles = [] as any;
                for (let item of _data["rhFiles"])
                    this.rhFiles!.push(RhFileDto.fromJS(item));
            }
            this.teamBenefitType = _data["teamBenefitType"];
            this.teamBenefitTypesCarrier = _data["teamBenefitTypesCarrier"] ? TeamBenefitTypesCarrierDto.fromJS(_data["teamBenefitTypesCarrier"]) : <any>undefined;
            this.createdDate = _data["createdDate"];
        }
    }

    static fromJS(data: any): TeamBenefitDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["nextRenewableDate"] = this.nextRenewableDate;
        data["nextMemberEnrollmentStartDate"] = this.nextMemberEnrollmentStartDate;
        data["nextMemberEnrollmentEndDate"] = this.nextMemberEnrollmentEndDate;
        data["rhManagementDate"] = this.rhManagementDate;
        data["eligibilityWaitingDays"] = this.eligibilityWaitingDays;
        data["rhManagementTypeId"] = this.rhManagementTypeId;
        data["planMinimumCost"] = this.planMinimumCost;
        data["planName"] = this.planName;
        data["groupPlanId"] = this.groupPlanId;
        data["rhFee"] = this.rhFee;
        data["submitExpenseUrl"] = this.submitExpenseUrl;
        data["utilization"] = this.utilization;
        data["useForReimbursementOverlay"] = this.useForReimbursementOverlay;
        data["useForAncillarySelection"] = this.useForAncillarySelection;
        data["offerLevel"] = this.offerLevel;
        if (Array.isArray(this.rhFiles)) {
            data["rhFiles"] = [];
            for (let item of this.rhFiles)
                data["rhFiles"].push(item.toJSON());
        }
        data["teamBenefitType"] = this.teamBenefitType;
        data["teamBenefitTypesCarrier"] = this.teamBenefitTypesCarrier ? this.teamBenefitTypesCarrier.toJSON() : <any>undefined;
        data["createdDate"] = this.createdDate;
        return data; 
    }
}
export class TeamBenefitWithTermDetailsDto extends TeamBenefitDto implements ITeamBenefitWithTermDetailsDto {
    teamBenefitTermDetails?: TeamBenefitTermDetailDto[] | undefined;

    constructor(data?: ITeamBenefitWithTermDetailsDto) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            if (Array.isArray(_data["teamBenefitTermDetails"])) {
                this.teamBenefitTermDetails = [] as any;
                for (let item of _data["teamBenefitTermDetails"])
                    this.teamBenefitTermDetails!.push(TeamBenefitTermDetailDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): TeamBenefitWithTermDetailsDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitWithTermDetailsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.teamBenefitTermDetails)) {
            data["teamBenefitTermDetails"] = [];
            for (let item of this.teamBenefitTermDetails)
                data["teamBenefitTermDetails"].push(item.toJSON());
        }
        super.toJSON(data);
        return data; 
    }
}
export class TeamBenefitTermDetailDto implements ITeamBenefitTermDetailDto {
    id!: string;
    startDate!: string;
    endDate!: string;
    employeeOnlyPlanCost!: number;
    employeeSpousePlanCost!: number;
    employeeChildrenPlanCost!: number;
    employeeFamilyPlanCost!: number;
    employerCoverEmployeePlanPercentage!: number;
    employerCoverHouseholdMembersPlanPercentage!: number;
    expenseInputs?: ExpenseInput[] | undefined;
    employeeOnlyContribution?: number | undefined;
    employeeSpouseContribution?: number | undefined;
    employeeChildrenContribution?: number | undefined;
    employeeFamilyContribution?: number | undefined;
    contributionFrequency?: ContributionFrequency | undefined;
    teamBenefitTermDetailAgeRanges?: TeamBenefitTermDetailAgeRanges[] | undefined;
    benefitDescription?: string | undefined;
    useHsaReimbursement?: boolean | undefined;
    onlyGrossUpReimbursementsAfterHsaLimit?: boolean | undefined;

    constructor(data?: ITeamBenefitTermDetailDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.employeeOnlyPlanCost = _data["employeeOnlyPlanCost"];
            this.employeeSpousePlanCost = _data["employeeSpousePlanCost"];
            this.employeeChildrenPlanCost = _data["employeeChildrenPlanCost"];
            this.employeeFamilyPlanCost = _data["employeeFamilyPlanCost"];
            this.employerCoverEmployeePlanPercentage = _data["employerCoverEmployeePlanPercentage"];
            this.employerCoverHouseholdMembersPlanPercentage = _data["employerCoverHouseholdMembersPlanPercentage"];
            if (Array.isArray(_data["expenseInputs"])) {
                this.expenseInputs = [] as any;
                for (let item of _data["expenseInputs"])
                    this.expenseInputs!.push(ExpenseInput.fromJS(item));
            }
            this.employeeOnlyContribution = _data["employeeOnlyContribution"];
            this.employeeSpouseContribution = _data["employeeSpouseContribution"];
            this.employeeChildrenContribution = _data["employeeChildrenContribution"];
            this.employeeFamilyContribution = _data["employeeFamilyContribution"];
            this.contributionFrequency = _data["contributionFrequency"];
            if (Array.isArray(_data["teamBenefitTermDetailAgeRanges"])) {
                this.teamBenefitTermDetailAgeRanges = [] as any;
                for (let item of _data["teamBenefitTermDetailAgeRanges"])
                    this.teamBenefitTermDetailAgeRanges!.push(TeamBenefitTermDetailAgeRanges.fromJS(item));
            }
            this.benefitDescription = _data["benefitDescription"];
            this.useHsaReimbursement = _data["useHsaReimbursement"];
            this.onlyGrossUpReimbursementsAfterHsaLimit = _data["onlyGrossUpReimbursementsAfterHsaLimit"];
        }
    }

    static fromJS(data: any): TeamBenefitTermDetailDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTermDetailDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["employeeOnlyPlanCost"] = this.employeeOnlyPlanCost;
        data["employeeSpousePlanCost"] = this.employeeSpousePlanCost;
        data["employeeChildrenPlanCost"] = this.employeeChildrenPlanCost;
        data["employeeFamilyPlanCost"] = this.employeeFamilyPlanCost;
        data["employerCoverEmployeePlanPercentage"] = this.employerCoverEmployeePlanPercentage;
        data["employerCoverHouseholdMembersPlanPercentage"] = this.employerCoverHouseholdMembersPlanPercentage;
        if (Array.isArray(this.expenseInputs)) {
            data["expenseInputs"] = [];
            for (let item of this.expenseInputs)
                data["expenseInputs"].push(item.toJSON());
        }
        data["employeeOnlyContribution"] = this.employeeOnlyContribution;
        data["employeeSpouseContribution"] = this.employeeSpouseContribution;
        data["employeeChildrenContribution"] = this.employeeChildrenContribution;
        data["employeeFamilyContribution"] = this.employeeFamilyContribution;
        data["contributionFrequency"] = this.contributionFrequency;
        if (Array.isArray(this.teamBenefitTermDetailAgeRanges)) {
            data["teamBenefitTermDetailAgeRanges"] = [];
            for (let item of this.teamBenefitTermDetailAgeRanges)
                data["teamBenefitTermDetailAgeRanges"].push(item.toJSON());
        }
        data["benefitDescription"] = this.benefitDescription;
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        data["onlyGrossUpReimbursementsAfterHsaLimit"] = this.onlyGrossUpReimbursementsAfterHsaLimit;
        return data; 
    }
}
export class RhFileDto implements IRhFileDto {
    id!: string;
    originalFileName?: string | undefined;
    fileSizeInBytes?: number | undefined;
    fileExtension?: string | undefined;
    name?: string | undefined;
    isShared!: boolean;
    createdDate!: string;
    createdByUser?: BaseUser | undefined;

    constructor(data?: IRhFileDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.originalFileName = _data["originalFileName"];
            this.fileSizeInBytes = _data["fileSizeInBytes"];
            this.fileExtension = _data["fileExtension"];
            this.name = _data["name"];
            this.isShared = _data["isShared"];
            this.createdDate = _data["createdDate"];
            this.createdByUser = _data["createdByUser"] ? BaseUser.fromJS(_data["createdByUser"]) : <any>undefined;
        }
    }

    static fromJS(data: any): RhFileDto {
        data = typeof data === 'object' ? data : {};
        let result = new RhFileDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["originalFileName"] = this.originalFileName;
        data["fileSizeInBytes"] = this.fileSizeInBytes;
        data["fileExtension"] = this.fileExtension;
        data["name"] = this.name;
        data["isShared"] = this.isShared;
        data["createdDate"] = this.createdDate;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        return data; 
    }
}
export class TeamBenefitTypesCarrierDto implements ITeamBenefitTypesCarrierDto {
    id!: number;
    teamBenefitTypeId!: TeamBenefitTypes;
    teamBenefitType?: TeamBenefitTypeDto | undefined;
    teamBenefitCarrier?: TeamBenefitCarrierDto | undefined;

    constructor(data?: ITeamBenefitTypesCarrierDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamBenefitTypeId = _data["teamBenefitTypeId"];
            this.teamBenefitType = _data["teamBenefitType"] ? TeamBenefitTypeDto.fromJS(_data["teamBenefitType"]) : <any>undefined;
            this.teamBenefitCarrier = _data["teamBenefitCarrier"] ? TeamBenefitCarrierDto.fromJS(_data["teamBenefitCarrier"]) : <any>undefined;
        }
    }

    static fromJS(data: any): TeamBenefitTypesCarrierDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTypesCarrierDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamBenefitTypeId"] = this.teamBenefitTypeId;
        data["teamBenefitType"] = this.teamBenefitType ? this.teamBenefitType.toJSON() : <any>undefined;
        data["teamBenefitCarrier"] = this.teamBenefitCarrier ? this.teamBenefitCarrier.toJSON() : <any>undefined;
        return data; 
    }
}
export class TeamBenefitTypeDto implements ITeamBenefitTypeDto {
    id!: TeamBenefitTypes;
    name?: string | undefined;

    constructor(data?: ITeamBenefitTypeDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): TeamBenefitTypeDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitTypeDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        return data; 
    }
}
export class TeamBenefitCarrierDto implements ITeamBenefitCarrierDto {
    id!: number;
    name?: string | undefined;

    constructor(data?: ITeamBenefitCarrierDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): TeamBenefitCarrierDto {
        data = typeof data === 'object' ? data : {};
        let result = new TeamBenefitCarrierDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        return data; 
    }
}
export class AncillaryBenefitDto implements IAncillaryBenefitDto {
    userTermDetailId!: string;
    teamBenefitId!: string;
    userId!: string;
    initiallyCoveredHouseholdMemberIds?: string[] | undefined;
    rhFiles?: RhFileDto[] | undefined;
    teamBenefitTypeId!: TeamBenefitTypes;
    teamBenefitType?: string | undefined;
    carrierName?: string | undefined;
    planName?: string | undefined;
    statusId!: UserTeamBenefitTermDetailStatuses;
    offerLevel!: OfferLevels;
    costData?: { [key: string]: number; } | undefined;
    canShowCost!: boolean;

    constructor(data?: IAncillaryBenefitDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTermDetailId = _data["userTermDetailId"];
            this.teamBenefitId = _data["teamBenefitId"];
            this.userId = _data["userId"];
            if (Array.isArray(_data["initiallyCoveredHouseholdMemberIds"])) {
                this.initiallyCoveredHouseholdMemberIds = [] as any;
                for (let item of _data["initiallyCoveredHouseholdMemberIds"])
                    this.initiallyCoveredHouseholdMemberIds!.push(item);
            }
            if (Array.isArray(_data["rhFiles"])) {
                this.rhFiles = [] as any;
                for (let item of _data["rhFiles"])
                    this.rhFiles!.push(RhFileDto.fromJS(item));
            }
            this.teamBenefitTypeId = _data["teamBenefitTypeId"];
            this.teamBenefitType = _data["teamBenefitType"];
            this.carrierName = _data["carrierName"];
            this.planName = _data["planName"];
            this.statusId = _data["statusId"];
            this.offerLevel = _data["offerLevel"];
            if (_data["costData"]) {
                this.costData = {} as any;
                for (let key in _data["costData"]) {
                    if (_data["costData"].hasOwnProperty(key))
                        (<any>this.costData)![key] = _data["costData"][key];
                }
            }
            this.canShowCost = _data["canShowCost"];
        }
    }

    static fromJS(data: any): AncillaryBenefitDto {
        data = typeof data === 'object' ? data : {};
        let result = new AncillaryBenefitDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTermDetailId"] = this.userTermDetailId;
        data["teamBenefitId"] = this.teamBenefitId;
        data["userId"] = this.userId;
        if (Array.isArray(this.initiallyCoveredHouseholdMemberIds)) {
            data["initiallyCoveredHouseholdMemberIds"] = [];
            for (let item of this.initiallyCoveredHouseholdMemberIds)
                data["initiallyCoveredHouseholdMemberIds"].push(item);
        }
        if (Array.isArray(this.rhFiles)) {
            data["rhFiles"] = [];
            for (let item of this.rhFiles)
                data["rhFiles"].push(item.toJSON());
        }
        data["teamBenefitTypeId"] = this.teamBenefitTypeId;
        data["teamBenefitType"] = this.teamBenefitType;
        data["carrierName"] = this.carrierName;
        data["planName"] = this.planName;
        data["statusId"] = this.statusId;
        data["offerLevel"] = this.offerLevel;
        if (this.costData) {
            data["costData"] = {};
            for (let key in this.costData) {
                if (this.costData.hasOwnProperty(key))
                    (<any>data["costData"])[key] = this.costData[key];
            }
        }
        data["canShowCost"] = this.canShowCost;
        return data; 
    }
}
export class SelectAncillaryBenefitRequest implements ISelectAncillaryBenefitRequest {
    userTermDetailId!: string;
    statusId!: UserTeamBenefitTermDetailStatuses;
    teamBenefitType!: TeamBenefitTypes;
    householdMemberIds?: string[] | undefined;

    constructor(data?: ISelectAncillaryBenefitRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTermDetailId = _data["userTermDetailId"];
            this.statusId = _data["statusId"];
            this.teamBenefitType = _data["teamBenefitType"];
            if (Array.isArray(_data["householdMemberIds"])) {
                this.householdMemberIds = [] as any;
                for (let item of _data["householdMemberIds"])
                    this.householdMemberIds!.push(item);
            }
        }
    }

    static fromJS(data: any): SelectAncillaryBenefitRequest {
        data = typeof data === 'object' ? data : {};
        let result = new SelectAncillaryBenefitRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTermDetailId"] = this.userTermDetailId;
        data["statusId"] = this.statusId;
        data["teamBenefitType"] = this.teamBenefitType;
        if (Array.isArray(this.householdMemberIds)) {
            data["householdMemberIds"] = [];
            for (let item of this.householdMemberIds)
                data["householdMemberIds"].push(item);
        }
        return data; 
    }
}
export class UserFlowDto implements IUserFlowDto {
    globalId!: string;
    flowId!: Flows;
    userId!: string;
    year!: number;
    tasks?: TaskDto[] | undefined;
    isVisible!: boolean;

    constructor(data?: IUserFlowDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.globalId = _data["globalId"];
            this.flowId = _data["flowId"];
            this.userId = _data["userId"];
            this.year = _data["year"];
            if (Array.isArray(_data["tasks"])) {
                this.tasks = [] as any;
                for (let item of _data["tasks"])
                    this.tasks!.push(TaskDto.fromJS(item));
            }
            this.isVisible = _data["isVisible"];
        }
    }

    static fromJS(data: any): UserFlowDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserFlowDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["globalId"] = this.globalId;
        data["flowId"] = this.flowId;
        data["userId"] = this.userId;
        data["year"] = this.year;
        if (Array.isArray(this.tasks)) {
            data["tasks"] = [];
            for (let item of this.tasks)
                data["tasks"].push(item.toJSON());
        }
        data["isVisible"] = this.isVisible;
        return data; 
    }
}
export class TaskDto implements ITaskDto {
    globalId!: string;
    taskStatusId!: TaskStatuses;
    taskId!: Tasks;
    childTasks?: TaskDto[] | undefined;
    response?: any | undefined;

    constructor(data?: ITaskDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.globalId = _data["globalId"];
            this.taskStatusId = _data["taskStatusId"];
            this.taskId = _data["taskId"];
            if (Array.isArray(_data["childTasks"])) {
                this.childTasks = [] as any;
                for (let item of _data["childTasks"])
                    this.childTasks!.push(TaskDto.fromJS(item));
            }
            this.response = _data["response"];
        }
    }

    static fromJS(data: any): TaskDto {
        data = typeof data === 'object' ? data : {};
        let result = new TaskDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["globalId"] = this.globalId;
        data["taskStatusId"] = this.taskStatusId;
        data["taskId"] = this.taskId;
        if (Array.isArray(this.childTasks)) {
            data["childTasks"] = [];
            for (let item of this.childTasks)
                data["childTasks"].push(item.toJSON());
        }
        data["response"] = this.response;
        return data; 
    }
}
export class EditTaskStatusRequest implements IEditTaskStatusRequest {
    userFlowTaskStatusGlobalId!: string;
    taskStatusId!: TaskStatuses;

    constructor(data?: IEditTaskStatusRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userFlowTaskStatusGlobalId = _data["userFlowTaskStatusGlobalId"];
            this.taskStatusId = _data["taskStatusId"];
        }
    }

    static fromJS(data: any): EditTaskStatusRequest {
        data = typeof data === 'object' ? data : {};
        let result = new EditTaskStatusRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userFlowTaskStatusGlobalId"] = this.userFlowTaskStatusGlobalId;
        data["taskStatusId"] = this.taskStatusId;
        return data; 
    }
}
export class CompleteTaskRequest implements ICompleteTaskRequest {
    userFlowTaskStatusGlobalId!: string;
    response?: any | undefined;

    constructor(data?: ICompleteTaskRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userFlowTaskStatusGlobalId = _data["userFlowTaskStatusGlobalId"];
            this.response = _data["response"];
        }
    }

    static fromJS(data: any): CompleteTaskRequest {
        data = typeof data === 'object' ? data : {};
        let result = new CompleteTaskRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userFlowTaskStatusGlobalId"] = this.userFlowTaskStatusGlobalId;
        data["response"] = this.response;
        return data; 
    }
}
export class SocialSecurityNumbersDto implements ISocialSecurityNumbersDto {
    socialSecurityNumbers?: SsnDto[] | undefined;

    constructor(data?: ISocialSecurityNumbersDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["socialSecurityNumbers"])) {
                this.socialSecurityNumbers = [] as any;
                for (let item of _data["socialSecurityNumbers"])
                    this.socialSecurityNumbers!.push(SsnDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SocialSecurityNumbersDto {
        data = typeof data === 'object' ? data : {};
        let result = new SocialSecurityNumbersDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.socialSecurityNumbers)) {
            data["socialSecurityNumbers"] = [];
            for (let item of this.socialSecurityNumbers)
                data["socialSecurityNumbers"].push(item.toJSON());
        }
        return data; 
    }
}
export class SsnDto implements ISsnDto {
    entityKeyId!: string;
    socialSecurityNumber?: string | undefined;
    entityType!: EntityType;

    constructor(data?: ISsnDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.entityKeyId = _data["entityKeyId"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.entityType = _data["entityType"];
        }
    }

    static fromJS(data: any): SsnDto {
        data = typeof data === 'object' ? data : {};
        let result = new SsnDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["entityKeyId"] = this.entityKeyId;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["entityType"] = this.entityType;
        return data; 
    }
}
export class TeamMembersStats implements ITeamMembersStats {
    countQuote!: number;
    countVerified!: number;
    statusCount?: { [key in keyof typeof EnrollmentStatuses]?: number; } | undefined;
    year!: number;

    constructor(data?: ITeamMembersStats) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.countQuote = _data["countQuote"];
            this.countVerified = _data["countVerified"];
            if (_data["statusCount"]) {
                this.statusCount = {} as any;
                for (let key in _data["statusCount"]) {
                    if (_data["statusCount"].hasOwnProperty(key))
                        (<any>this.statusCount)![key] = _data["statusCount"][key];
                }
            }
            this.year = _data["year"];
        }
    }

    static fromJS(data: any): TeamMembersStats {
        data = typeof data === 'object' ? data : {};
        let result = new TeamMembersStats();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["countQuote"] = this.countQuote;
        data["countVerified"] = this.countVerified;
        if (this.statusCount) {
            data["statusCount"] = {};
            for (let key in this.statusCount) {
                if (this.statusCount.hasOwnProperty(key))
                    (<any>data["statusCount"])[key] = this.statusCount[key];
            }
        }
        data["year"] = this.year;
        return data; 
    }
}
export class ProvidersListDto implements IProvidersListDto {
    hasError!: boolean;
    providerPlans?: ProviderPlan[] | undefined;

    constructor(data?: IProvidersListDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.hasError = _data["hasError"];
            if (Array.isArray(_data["providerPlans"])) {
                this.providerPlans = [] as any;
                for (let item of _data["providerPlans"])
                    this.providerPlans!.push(ProviderPlan.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ProvidersListDto {
        data = typeof data === 'object' ? data : {};
        let result = new ProvidersListDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["hasError"] = this.hasError;
        if (Array.isArray(this.providerPlans)) {
            data["providerPlans"] = [];
            for (let item of this.providerPlans)
                data["providerPlans"].push(item.toJSON());
        }
        return data; 
    }
}
export class ProviderPlan implements IProviderPlan {
    carrier?: Carrier | undefined;
    carrierName?: string | undefined;
    externalIds?: ExternalId[] | undefined;
    id?: string | undefined;
    lineOfCoverage?: string | undefined;
    market?: string | undefined;
    name?: string | undefined;
    networks?: Network[] | undefined;

    constructor(data?: IProviderPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.carrier = _data["carrier"] ? Carrier.fromJS(_data["carrier"]) : <any>undefined;
            this.carrierName = _data["carrierName"];
            if (Array.isArray(_data["externalIds"])) {
                this.externalIds = [] as any;
                for (let item of _data["externalIds"])
                    this.externalIds!.push(ExternalId.fromJS(item));
            }
            this.id = _data["id"];
            this.lineOfCoverage = _data["lineOfCoverage"];
            this.market = _data["market"];
            this.name = _data["name"];
            if (Array.isArray(_data["networks"])) {
                this.networks = [] as any;
                for (let item of _data["networks"])
                    this.networks!.push(Network.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ProviderPlan {
        data = typeof data === 'object' ? data : {};
        let result = new ProviderPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["carrier"] = this.carrier ? this.carrier.toJSON() : <any>undefined;
        data["carrierName"] = this.carrierName;
        if (Array.isArray(this.externalIds)) {
            data["externalIds"] = [];
            for (let item of this.externalIds)
                data["externalIds"].push(item.toJSON());
        }
        data["id"] = this.id;
        data["lineOfCoverage"] = this.lineOfCoverage;
        data["market"] = this.market;
        data["name"] = this.name;
        if (Array.isArray(this.networks)) {
            data["networks"] = [];
            for (let item of this.networks)
                data["networks"].push(item.toJSON());
        }
        return data; 
    }
}
export class Carrier implements ICarrier {
    issuerId?: string | undefined;
    id?: string | undefined;
    name?: string | undefined;
    logoUrl?: string | undefined;

    constructor(data?: ICarrier) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.issuerId = _data["issuerId"];
            this.id = _data["id"];
            this.name = _data["name"];
            this.logoUrl = _data["logoUrl"];
        }
    }

    static fromJS(data: any): Carrier {
        data = typeof data === 'object' ? data : {};
        let result = new Carrier();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["issuerId"] = this.issuerId;
        data["id"] = this.id;
        data["name"] = this.name;
        data["logoUrl"] = this.logoUrl;
        return data; 
    }
}
export class ExternalId implements IExternalId {
    type?: string | undefined;
    value?: string | undefined;

    constructor(data?: IExternalId) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.type = _data["type"];
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): ExternalId {
        data = typeof data === 'object' ? data : {};
        let result = new ExternalId();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["type"] = this.type;
        data["value"] = this.value;
        return data; 
    }
}
export class Network implements INetwork {
    addresses?: Address[] | undefined;
    carrierName?: string | undefined;
    carriers?: Carrier[] | undefined;
    groupAffiliations?: GroupAffiliation[] | undefined;
    hospitalAffiliations?: HospitalAffiliation[] | undefined;
    id!: number;
    languages?: Language[] | undefined;
    lastImportedAt?: string | undefined;
    name?: string | undefined;
    pcp?: boolean | undefined;
    pcpId?: string | undefined;
    source?: string | undefined;
    specialties?: Specialty[] | undefined;
    tier?: string | undefined;

    constructor(data?: INetwork) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["addresses"])) {
                this.addresses = [] as any;
                for (let item of _data["addresses"])
                    this.addresses!.push(Address.fromJS(item));
            }
            this.carrierName = _data["carrierName"];
            if (Array.isArray(_data["carriers"])) {
                this.carriers = [] as any;
                for (let item of _data["carriers"])
                    this.carriers!.push(Carrier.fromJS(item));
            }
            if (Array.isArray(_data["groupAffiliations"])) {
                this.groupAffiliations = [] as any;
                for (let item of _data["groupAffiliations"])
                    this.groupAffiliations!.push(GroupAffiliation.fromJS(item));
            }
            if (Array.isArray(_data["hospitalAffiliations"])) {
                this.hospitalAffiliations = [] as any;
                for (let item of _data["hospitalAffiliations"])
                    this.hospitalAffiliations!.push(HospitalAffiliation.fromJS(item));
            }
            this.id = _data["id"];
            if (Array.isArray(_data["languages"])) {
                this.languages = [] as any;
                for (let item of _data["languages"])
                    this.languages!.push(Language.fromJS(item));
            }
            this.lastImportedAt = _data["lastImportedAt"];
            this.name = _data["name"];
            this.pcp = _data["pcp"];
            this.pcpId = _data["pcpId"];
            this.source = _data["source"];
            if (Array.isArray(_data["specialties"])) {
                this.specialties = [] as any;
                for (let item of _data["specialties"])
                    this.specialties!.push(Specialty.fromJS(item));
            }
            this.tier = _data["tier"];
        }
    }

    static fromJS(data: any): Network {
        data = typeof data === 'object' ? data : {};
        let result = new Network();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.addresses)) {
            data["addresses"] = [];
            for (let item of this.addresses)
                data["addresses"].push(item.toJSON());
        }
        data["carrierName"] = this.carrierName;
        if (Array.isArray(this.carriers)) {
            data["carriers"] = [];
            for (let item of this.carriers)
                data["carriers"].push(item.toJSON());
        }
        if (Array.isArray(this.groupAffiliations)) {
            data["groupAffiliations"] = [];
            for (let item of this.groupAffiliations)
                data["groupAffiliations"].push(item.toJSON());
        }
        if (Array.isArray(this.hospitalAffiliations)) {
            data["hospitalAffiliations"] = [];
            for (let item of this.hospitalAffiliations)
                data["hospitalAffiliations"].push(item.toJSON());
        }
        data["id"] = this.id;
        if (Array.isArray(this.languages)) {
            data["languages"] = [];
            for (let item of this.languages)
                data["languages"].push(item.toJSON());
        }
        data["lastImportedAt"] = this.lastImportedAt;
        data["name"] = this.name;
        data["pcp"] = this.pcp;
        data["pcpId"] = this.pcpId;
        data["source"] = this.source;
        if (Array.isArray(this.specialties)) {
            data["specialties"] = [];
            for (let item of this.specialties)
                data["specialties"].push(item.toJSON());
        }
        data["tier"] = this.tier;
        return data; 
    }
}
export class GroupAffiliation implements IGroupAffiliation {
    name?: string | undefined;

    constructor(data?: IGroupAffiliation) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): GroupAffiliation {
        data = typeof data === 'object' ? data : {};
        let result = new GroupAffiliation();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        return data; 
    }
}
export class HospitalAffiliation implements IHospitalAffiliation {
    name?: string | undefined;

    constructor(data?: IHospitalAffiliation) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): HospitalAffiliation {
        data = typeof data === 'object' ? data : {};
        let result = new HospitalAffiliation();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        return data; 
    }
}
export class Language implements ILanguage {
    id?: string | undefined;
    iso6392?: string | undefined;
    iso6393?: string | undefined;
    name?: string | undefined;

    constructor(data?: ILanguage) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.iso6392 = _data["iso6392"];
            this.iso6393 = _data["iso6393"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): Language {
        data = typeof data === 'object' ? data : {};
        let result = new Language();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["iso6392"] = this.iso6392;
        data["iso6393"] = this.iso6393;
        data["name"] = this.name;
        return data; 
    }
}
export class Specialty implements ISpecialty {
    id?: string | undefined;
    taxonomyCode?: string | undefined;
    name?: string | undefined;
    subSpecialty?: string | undefined;

    constructor(data?: ISpecialty) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.taxonomyCode = _data["taxonomyCode"];
            this.name = _data["name"];
            this.subSpecialty = _data["subSpecialty"];
        }
    }

    static fromJS(data: any): Specialty {
        data = typeof data === 'object' ? data : {};
        let result = new Specialty();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["taxonomyCode"] = this.taxonomyCode;
        data["name"] = this.name;
        data["subSpecialty"] = this.subSpecialty;
        return data; 
    }
}
export class SelectedPlansForReviewDto implements ISelectedPlansForReviewDto {
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    selectedPlans?: SelectedPlanDto[] | undefined;

    constructor(data?: ISelectedPlansForReviewDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.majorMedicalBenefitCost = _data["majorMedicalBenefitCost"] ? MajorMedicalBenefitCostDto.fromJS(_data["majorMedicalBenefitCost"]) : <any>undefined;
            if (Array.isArray(_data["selectedPlans"])) {
                this.selectedPlans = [] as any;
                for (let item of _data["selectedPlans"])
                    this.selectedPlans!.push(SelectedPlanDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SelectedPlansForReviewDto {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlansForReviewDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["majorMedicalBenefitCost"] = this.majorMedicalBenefitCost ? this.majorMedicalBenefitCost.toJSON() : <any>undefined;
        if (Array.isArray(this.selectedPlans)) {
            data["selectedPlans"] = [];
            for (let item of this.selectedPlans)
                data["selectedPlans"].push(item.toJSON());
        }
        return data; 
    }
}
export class MajorMedicalBenefitCostDto implements IMajorMedicalBenefitCostDto {
    preTaxWagePlus?: number | undefined;
    totalTaxRate!: number;
    postTaxWagePlus!: number;
    preTaxTotalPlanCost!: number;
    postTaxTotalPlanCost?: number | undefined;
    postTaxTotalMajorMedicalBenefitCost!: number;
    preTaxTotalMajorMedicalBenefitCost!: number;
    reimbursementAmount?: number | undefined;
    isIchra!: boolean;
    ichraMajorMedicalBenefitCost?: number | undefined;

    constructor(data?: IMajorMedicalBenefitCostDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.preTaxWagePlus = _data["preTaxWagePlus"];
            this.totalTaxRate = _data["totalTaxRate"];
            this.postTaxWagePlus = _data["postTaxWagePlus"];
            this.preTaxTotalPlanCost = _data["preTaxTotalPlanCost"];
            this.postTaxTotalPlanCost = _data["postTaxTotalPlanCost"];
            this.postTaxTotalMajorMedicalBenefitCost = _data["postTaxTotalMajorMedicalBenefitCost"];
            this.preTaxTotalMajorMedicalBenefitCost = _data["preTaxTotalMajorMedicalBenefitCost"];
            this.reimbursementAmount = _data["reimbursementAmount"];
            this.isIchra = _data["isIchra"];
            this.ichraMajorMedicalBenefitCost = _data["ichraMajorMedicalBenefitCost"];
        }
    }

    static fromJS(data: any): MajorMedicalBenefitCostDto {
        data = typeof data === 'object' ? data : {};
        let result = new MajorMedicalBenefitCostDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["preTaxWagePlus"] = this.preTaxWagePlus;
        data["totalTaxRate"] = this.totalTaxRate;
        data["postTaxWagePlus"] = this.postTaxWagePlus;
        data["preTaxTotalPlanCost"] = this.preTaxTotalPlanCost;
        data["postTaxTotalPlanCost"] = this.postTaxTotalPlanCost;
        data["postTaxTotalMajorMedicalBenefitCost"] = this.postTaxTotalMajorMedicalBenefitCost;
        data["preTaxTotalMajorMedicalBenefitCost"] = this.preTaxTotalMajorMedicalBenefitCost;
        data["reimbursementAmount"] = this.reimbursementAmount;
        data["isIchra"] = this.isIchra;
        data["ichraMajorMedicalBenefitCost"] = this.ichraMajorMedicalBenefitCost;
        return data; 
    }
}
export class SelectedPlanDto implements ISelectedPlanDto {
    selectedPlanId!: string;
    userId!: string;
    planTypeId!: PlanTypeIds;
    planId?: string | undefined;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered!: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;

    constructor(data?: ISelectedPlanDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.selectedPlanId = _data["selectedPlanId"];
            this.userId = _data["userId"];
            this.planTypeId = _data["planTypeId"];
            this.planId = _data["planId"];
            this.planName = _data["planName"];
            this.issuerName = _data["issuerName"];
            this.isPrimaryCovered = _data["isPrimaryCovered"];
            if (Array.isArray(_data["householdMembersCovered"])) {
                this.householdMembersCovered = [] as any;
                for (let item of _data["householdMembersCovered"])
                    this.householdMembersCovered!.push(item);
            }
            this.planPremium = _data["planPremium"];
            this.planPremiumWithCredits = _data["planPremiumWithCredits"];
        }
    }

    static fromJS(data: any): SelectedPlanDto {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlanDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["selectedPlanId"] = this.selectedPlanId;
        data["userId"] = this.userId;
        data["planTypeId"] = this.planTypeId;
        data["planId"] = this.planId;
        data["planName"] = this.planName;
        data["issuerName"] = this.issuerName;
        data["isPrimaryCovered"] = this.isPrimaryCovered;
        if (Array.isArray(this.householdMembersCovered)) {
            data["householdMembersCovered"] = [];
            for (let item of this.householdMembersCovered)
                data["householdMembersCovered"].push(item);
        }
        data["planPremium"] = this.planPremium;
        data["planPremiumWithCredits"] = this.planPremiumWithCredits;
        return data; 
    }
}
export class SelectedPlansForRenewalDto implements ISelectedPlansForRenewalDto {
    effectiveMajorMedicalSelectedPlans?: SelectedPlan[] | undefined;
    comparedMarketplaceAndOffExchangeSelectedPlans?: ComparedSelectedPlan[] | undefined;
    selectedPlansToDelete?: SelectedPlan[] | undefined;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    effectiveAncillarySelectedPlans?: SelectedPlan[] | undefined;
    estimatedEffectiveAncillaryCost!: number;
    hasEffectivePlans!: boolean;
    allPlansParentOrSpouse!: boolean;

    constructor(data?: ISelectedPlansForRenewalDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["effectiveMajorMedicalSelectedPlans"])) {
                this.effectiveMajorMedicalSelectedPlans = [] as any;
                for (let item of _data["effectiveMajorMedicalSelectedPlans"])
                    this.effectiveMajorMedicalSelectedPlans!.push(SelectedPlan.fromJS(item));
            }
            if (Array.isArray(_data["comparedMarketplaceAndOffExchangeSelectedPlans"])) {
                this.comparedMarketplaceAndOffExchangeSelectedPlans = [] as any;
                for (let item of _data["comparedMarketplaceAndOffExchangeSelectedPlans"])
                    this.comparedMarketplaceAndOffExchangeSelectedPlans!.push(ComparedSelectedPlan.fromJS(item));
            }
            if (Array.isArray(_data["selectedPlansToDelete"])) {
                this.selectedPlansToDelete = [] as any;
                for (let item of _data["selectedPlansToDelete"])
                    this.selectedPlansToDelete!.push(SelectedPlan.fromJS(item));
            }
            this.majorMedicalBenefitCost = _data["majorMedicalBenefitCost"] ? MajorMedicalBenefitCostDto.fromJS(_data["majorMedicalBenefitCost"]) : <any>undefined;
            if (Array.isArray(_data["effectiveAncillarySelectedPlans"])) {
                this.effectiveAncillarySelectedPlans = [] as any;
                for (let item of _data["effectiveAncillarySelectedPlans"])
                    this.effectiveAncillarySelectedPlans!.push(SelectedPlan.fromJS(item));
            }
            this.estimatedEffectiveAncillaryCost = _data["estimatedEffectiveAncillaryCost"];
            this.hasEffectivePlans = _data["hasEffectivePlans"];
            this.allPlansParentOrSpouse = _data["allPlansParentOrSpouse"];
        }
    }

    static fromJS(data: any): SelectedPlansForRenewalDto {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlansForRenewalDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.effectiveMajorMedicalSelectedPlans)) {
            data["effectiveMajorMedicalSelectedPlans"] = [];
            for (let item of this.effectiveMajorMedicalSelectedPlans)
                data["effectiveMajorMedicalSelectedPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.comparedMarketplaceAndOffExchangeSelectedPlans)) {
            data["comparedMarketplaceAndOffExchangeSelectedPlans"] = [];
            for (let item of this.comparedMarketplaceAndOffExchangeSelectedPlans)
                data["comparedMarketplaceAndOffExchangeSelectedPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.selectedPlansToDelete)) {
            data["selectedPlansToDelete"] = [];
            for (let item of this.selectedPlansToDelete)
                data["selectedPlansToDelete"].push(item.toJSON());
        }
        data["majorMedicalBenefitCost"] = this.majorMedicalBenefitCost ? this.majorMedicalBenefitCost.toJSON() : <any>undefined;
        if (Array.isArray(this.effectiveAncillarySelectedPlans)) {
            data["effectiveAncillarySelectedPlans"] = [];
            for (let item of this.effectiveAncillarySelectedPlans)
                data["effectiveAncillarySelectedPlans"].push(item.toJSON());
        }
        data["estimatedEffectiveAncillaryCost"] = this.estimatedEffectiveAncillaryCost;
        data["hasEffectivePlans"] = this.hasEffectivePlans;
        data["allPlansParentOrSpouse"] = this.allPlansParentOrSpouse;
        return data; 
    }
}
export class ComparedSelectedPlan extends SelectedPlan implements IComparedSelectedPlan {
    previousPlanId?: string | undefined;
    previousSelectedPlanId!: string;
    isPlanCrosswalked!: boolean;
    planChangeReason?: string | undefined;
    comparisons?: SelectedPlanComparison[] | undefined;
    ineligibleToKeepReason?: IneligibleToKeepReasons | undefined;

    constructor(data?: IComparedSelectedPlan) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.previousPlanId = _data["previousPlanId"];
            this.previousSelectedPlanId = _data["previousSelectedPlanId"];
            this.isPlanCrosswalked = _data["isPlanCrosswalked"];
            this.planChangeReason = _data["planChangeReason"];
            if (Array.isArray(_data["comparisons"])) {
                this.comparisons = [] as any;
                for (let item of _data["comparisons"])
                    this.comparisons!.push(SelectedPlanComparison.fromJS(item));
            }
            this.ineligibleToKeepReason = _data["ineligibleToKeepReason"];
        }
    }

    static fromJS(data: any): ComparedSelectedPlan {
        data = typeof data === 'object' ? data : {};
        let result = new ComparedSelectedPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["previousPlanId"] = this.previousPlanId;
        data["previousSelectedPlanId"] = this.previousSelectedPlanId;
        data["isPlanCrosswalked"] = this.isPlanCrosswalked;
        data["planChangeReason"] = this.planChangeReason;
        if (Array.isArray(this.comparisons)) {
            data["comparisons"] = [];
            for (let item of this.comparisons)
                data["comparisons"].push(item.toJSON());
        }
        data["ineligibleToKeepReason"] = this.ineligibleToKeepReason;
        super.toJSON(data);
        return data; 
    }
}
export class Comparison implements IComparison {
    name?: string | undefined;
    before?: string | undefined;
    after?: string | undefined;

    constructor(data?: IComparison) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.before = _data["before"];
            this.after = _data["after"];
        }
    }

    static fromJS(data: any): Comparison {
        data = typeof data === 'object' ? data : {};
        let result = new Comparison();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["before"] = this.before;
        data["after"] = this.after;
        return data; 
    }
}
export class SelectedPlanComparison extends Comparison implements ISelectedPlanComparison {
    difference?: string | undefined;
    changeType!: ChangeTypes;
    comparisonType!: SelectedPlanComparisonTypes;

    constructor(data?: ISelectedPlanComparison) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.difference = _data["difference"];
            this.changeType = _data["changeType"];
            this.comparisonType = _data["comparisonType"];
        }
    }

    static fromJS(data: any): SelectedPlanComparison {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlanComparison();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["difference"] = this.difference;
        data["changeType"] = this.changeType;
        data["comparisonType"] = this.comparisonType;
        super.toJSON(data);
        return data; 
    }
}
export class IdeonProvider implements IIdeonProvider {
    addresses?: IdeonProviderAddress[] | undefined;
    doingBusinessAsName?: string | undefined;
    firstName?: string | undefined;
    gender?: string | undefined;
    id!: number;
    lastName?: string | undefined;
    middleName?: string | undefined;
    networkIds?: number[] | undefined;
    networks?: Network[] | undefined;
    npiSpecialty?: Specialty | undefined;
    npis?: number[] | undefined;
    organizationName?: string | undefined;
    phone?: string | undefined;
    presentationName?: string | undefined;
    specialty?: string | undefined;
    suffix?: string | undefined;
    title?: string | undefined;
    type?: string | undefined;

    constructor(data?: IIdeonProvider) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["addresses"])) {
                this.addresses = [] as any;
                for (let item of _data["addresses"])
                    this.addresses!.push(IdeonProviderAddress.fromJS(item));
            }
            this.doingBusinessAsName = _data["doingBusinessAsName"];
            this.firstName = _data["firstName"];
            this.gender = _data["gender"];
            this.id = _data["id"];
            this.lastName = _data["lastName"];
            this.middleName = _data["middleName"];
            if (Array.isArray(_data["networkIds"])) {
                this.networkIds = [] as any;
                for (let item of _data["networkIds"])
                    this.networkIds!.push(item);
            }
            if (Array.isArray(_data["networks"])) {
                this.networks = [] as any;
                for (let item of _data["networks"])
                    this.networks!.push(Network.fromJS(item));
            }
            this.npiSpecialty = _data["npiSpecialty"] ? Specialty.fromJS(_data["npiSpecialty"]) : <any>undefined;
            if (Array.isArray(_data["npis"])) {
                this.npis = [] as any;
                for (let item of _data["npis"])
                    this.npis!.push(item);
            }
            this.organizationName = _data["organizationName"];
            this.phone = _data["phone"];
            this.presentationName = _data["presentationName"];
            this.specialty = _data["specialty"];
            this.suffix = _data["suffix"];
            this.title = _data["title"];
            this.type = _data["type"];
        }
    }

    static fromJS(data: any): IdeonProvider {
        data = typeof data === 'object' ? data : {};
        let result = new IdeonProvider();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.addresses)) {
            data["addresses"] = [];
            for (let item of this.addresses)
                data["addresses"].push(item.toJSON());
        }
        data["doingBusinessAsName"] = this.doingBusinessAsName;
        data["firstName"] = this.firstName;
        data["gender"] = this.gender;
        data["id"] = this.id;
        data["lastName"] = this.lastName;
        data["middleName"] = this.middleName;
        if (Array.isArray(this.networkIds)) {
            data["networkIds"] = [];
            for (let item of this.networkIds)
                data["networkIds"].push(item);
        }
        if (Array.isArray(this.networks)) {
            data["networks"] = [];
            for (let item of this.networks)
                data["networks"].push(item.toJSON());
        }
        data["npiSpecialty"] = this.npiSpecialty ? this.npiSpecialty.toJSON() : <any>undefined;
        if (Array.isArray(this.npis)) {
            data["npis"] = [];
            for (let item of this.npis)
                data["npis"].push(item);
        }
        data["organizationName"] = this.organizationName;
        data["phone"] = this.phone;
        data["presentationName"] = this.presentationName;
        data["specialty"] = this.specialty;
        data["suffix"] = this.suffix;
        data["title"] = this.title;
        data["type"] = this.type;
        return data; 
    }
}
export class IdeonProviderAddress implements IIdeonProviderAddress {
    acceptingNewPatients!: boolean;
    city?: string | undefined;
    externalId?: string | undefined;
    id?: string | undefined;
    latitude!: number;
    longitude!: number;
    pcp!: boolean;
    pcpId?: string | undefined;
    phoneNumbers?: string[] | undefined;
    state?: string | undefined;
    street_line_1?: string | undefined;
    street_line_2?: string | undefined;
    zipCode?: string | undefined;

    constructor(data?: IIdeonProviderAddress) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.acceptingNewPatients = _data["acceptingNewPatients"];
            this.city = _data["city"];
            this.externalId = _data["externalId"];
            this.id = _data["id"];
            this.latitude = _data["latitude"];
            this.longitude = _data["longitude"];
            this.pcp = _data["pcp"];
            this.pcpId = _data["pcpId"];
            if (Array.isArray(_data["phoneNumbers"])) {
                this.phoneNumbers = [] as any;
                for (let item of _data["phoneNumbers"])
                    this.phoneNumbers!.push(item);
            }
            this.state = _data["state"];
            this.street_line_1 = _data["street_line_1"];
            this.street_line_2 = _data["street_line_2"];
            this.zipCode = _data["zipCode"];
        }
    }

    static fromJS(data: any): IdeonProviderAddress {
        data = typeof data === 'object' ? data : {};
        let result = new IdeonProviderAddress();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["acceptingNewPatients"] = this.acceptingNewPatients;
        data["city"] = this.city;
        data["externalId"] = this.externalId;
        data["id"] = this.id;
        data["latitude"] = this.latitude;
        data["longitude"] = this.longitude;
        data["pcp"] = this.pcp;
        data["pcpId"] = this.pcpId;
        if (Array.isArray(this.phoneNumbers)) {
            data["phoneNumbers"] = [];
            for (let item of this.phoneNumbers)
                data["phoneNumbers"].push(item);
        }
        data["state"] = this.state;
        data["street_line_1"] = this.street_line_1;
        data["street_line_2"] = this.street_line_2;
        data["zipCode"] = this.zipCode;
        return data; 
    }
}
export class SearchCommand implements ISearchCommand {
    searchTerm?: string | undefined;
    planYear!: number;
    zipCode?: string | undefined;
    token?: string | undefined;

    constructor(data?: ISearchCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.searchTerm = _data["searchTerm"];
            this.planYear = _data["planYear"];
            this.zipCode = _data["zipCode"];
            this.token = _data["token"];
        }
    }

    static fromJS(data: any): SearchCommand {
        data = typeof data === 'object' ? data : {};
        let result = new SearchCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["searchTerm"] = this.searchTerm;
        data["planYear"] = this.planYear;
        data["zipCode"] = this.zipCode;
        data["token"] = this.token;
        return data; 
    }
}
export class InNetworkProviderDto implements IInNetworkProviderDto {
    planId?: string | undefined;
    planYear!: number;
    providers?: IdeonProvider[] | undefined;

    constructor(data?: IInNetworkProviderDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.planId = _data["planId"];
            this.planYear = _data["planYear"];
            if (Array.isArray(_data["providers"])) {
                this.providers = [] as any;
                for (let item of _data["providers"])
                    this.providers!.push(IdeonProvider.fromJS(item));
            }
        }
    }

    static fromJS(data: any): InNetworkProviderDto {
        data = typeof data === 'object' ? data : {};
        let result = new InNetworkProviderDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["planId"] = this.planId;
        data["planYear"] = this.planYear;
        if (Array.isArray(this.providers)) {
            data["providers"] = [];
            for (let item of this.providers)
                data["providers"].push(item.toJSON());
        }
        return data; 
    }
}
export class GetProvidersInPlanNetworkQuery implements IGetProvidersInPlanNetworkQuery {
    planId?: string | undefined;
    planYear!: number;
    providerIds?: number[] | undefined;

    constructor(data?: IGetProvidersInPlanNetworkQuery) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.planId = _data["planId"];
            this.planYear = _data["planYear"];
            if (Array.isArray(_data["providerIds"])) {
                this.providerIds = [] as any;
                for (let item of _data["providerIds"])
                    this.providerIds!.push(item);
            }
        }
    }

    static fromJS(data: any): GetProvidersInPlanNetworkQuery {
        data = typeof data === 'object' ? data : {};
        let result = new GetProvidersInPlanNetworkQuery();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["planId"] = this.planId;
        data["planYear"] = this.planYear;
        if (Array.isArray(this.providerIds)) {
            data["providerIds"] = [];
            for (let item of this.providerIds)
                data["providerIds"].push(item);
        }
        return data; 
    }
}
export class PaymentsInfoDto implements IPaymentsInfoDto {
    accountSnapshotDto?: AccountSnapshotDto | undefined;
    canSetupInPayments!: boolean;

    constructor(data?: IPaymentsInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountSnapshotDto = _data["accountSnapshotDto"] ? AccountSnapshotDto.fromJS(_data["accountSnapshotDto"]) : <any>undefined;
            this.canSetupInPayments = _data["canSetupInPayments"];
        }
    }

    static fromJS(data: any): PaymentsInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new PaymentsInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["accountSnapshotDto"] = this.accountSnapshotDto ? this.accountSnapshotDto.toJSON() : <any>undefined;
        data["canSetupInPayments"] = this.canSetupInPayments;
        return data; 
    }
}
export class AccountSnapshotDto implements IAccountSnapshotDto {
    accountNumber?: string | undefined;
    routingNumber?: string | undefined;
    debitLastFour?: string | undefined;
    accountApplicationStatus?: string | undefined;
    currentBalance?: number | undefined;
    availableBalance?: number | undefined;

    constructor(data?: IAccountSnapshotDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountNumber = _data["accountNumber"];
            this.routingNumber = _data["routingNumber"];
            this.debitLastFour = _data["debitLastFour"];
            this.accountApplicationStatus = _data["accountApplicationStatus"];
            this.currentBalance = _data["currentBalance"];
            this.availableBalance = _data["availableBalance"];
        }
    }

    static fromJS(data: any): AccountSnapshotDto {
        data = typeof data === 'object' ? data : {};
        let result = new AccountSnapshotDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["accountNumber"] = this.accountNumber;
        data["routingNumber"] = this.routingNumber;
        data["debitLastFour"] = this.debitLastFour;
        data["accountApplicationStatus"] = this.accountApplicationStatus;
        data["currentBalance"] = this.currentBalance;
        data["availableBalance"] = this.availableBalance;
        return data; 
    }
}
export class ApplyIchraClassStrategyResponseDto implements IApplyIchraClassStrategyResponseDto {
    sixtyFourEeReimbursement!: number;
    sixtyFourEsReimbursement!: number;
    sixtyFourEcReimbursement!: number;
    sixtyFourEfReimbursement!: number;
    twentyThreeEeReimbursement!: number;
    twentyThreeEsReimbursement!: number;
    twentyThreeEcReimbursement!: number;
    twentyThreeEfReimbursement!: number;
    strategyEeRatio!: number;
    strategyEsRatio!: number;
    strategyEcRatio!: number;
    strategyEfRatio!: number;
    additionalDependentKicker!: number;

    constructor(data?: IApplyIchraClassStrategyResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.sixtyFourEeReimbursement = _data["sixtyFourEeReimbursement"];
            this.sixtyFourEsReimbursement = _data["sixtyFourEsReimbursement"];
            this.sixtyFourEcReimbursement = _data["sixtyFourEcReimbursement"];
            this.sixtyFourEfReimbursement = _data["sixtyFourEfReimbursement"];
            this.twentyThreeEeReimbursement = _data["twentyThreeEeReimbursement"];
            this.twentyThreeEsReimbursement = _data["twentyThreeEsReimbursement"];
            this.twentyThreeEcReimbursement = _data["twentyThreeEcReimbursement"];
            this.twentyThreeEfReimbursement = _data["twentyThreeEfReimbursement"];
            this.strategyEeRatio = _data["strategyEeRatio"];
            this.strategyEsRatio = _data["strategyEsRatio"];
            this.strategyEcRatio = _data["strategyEcRatio"];
            this.strategyEfRatio = _data["strategyEfRatio"];
            this.additionalDependentKicker = _data["additionalDependentKicker"];
        }
    }

    static fromJS(data: any): ApplyIchraClassStrategyResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new ApplyIchraClassStrategyResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["sixtyFourEeReimbursement"] = this.sixtyFourEeReimbursement;
        data["sixtyFourEsReimbursement"] = this.sixtyFourEsReimbursement;
        data["sixtyFourEcReimbursement"] = this.sixtyFourEcReimbursement;
        data["sixtyFourEfReimbursement"] = this.sixtyFourEfReimbursement;
        data["twentyThreeEeReimbursement"] = this.twentyThreeEeReimbursement;
        data["twentyThreeEsReimbursement"] = this.twentyThreeEsReimbursement;
        data["twentyThreeEcReimbursement"] = this.twentyThreeEcReimbursement;
        data["twentyThreeEfReimbursement"] = this.twentyThreeEfReimbursement;
        data["strategyEeRatio"] = this.strategyEeRatio;
        data["strategyEsRatio"] = this.strategyEsRatio;
        data["strategyEcRatio"] = this.strategyEcRatio;
        data["strategyEfRatio"] = this.strategyEfRatio;
        data["additionalDependentKicker"] = this.additionalDependentKicker;
        return data; 
    }
}
export class ImportAgeBandedTableResponseDto implements IImportAgeBandedTableResponseDto {
    errors?: string[] | undefined;
    ageBandedRows?: PathwayIchraAgeBandedTableDto[] | undefined;

    constructor(data?: IImportAgeBandedTableResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["errors"])) {
                this.errors = [] as any;
                for (let item of _data["errors"])
                    this.errors!.push(item);
            }
            if (Array.isArray(_data["ageBandedRows"])) {
                this.ageBandedRows = [] as any;
                for (let item of _data["ageBandedRows"])
                    this.ageBandedRows!.push(PathwayIchraAgeBandedTableDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ImportAgeBandedTableResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new ImportAgeBandedTableResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.errors)) {
            data["errors"] = [];
            for (let item of this.errors)
                data["errors"].push(item);
        }
        if (Array.isArray(this.ageBandedRows)) {
            data["ageBandedRows"] = [];
            for (let item of this.ageBandedRows)
                data["ageBandedRows"].push(item.toJSON());
        }
        return data; 
    }
}
export class PathwayBlueprintDto implements IPathwayBlueprintDto {
    id!: string;
    teamId!: string;
    name?: string | undefined;
    year!: number;
    isActive!: boolean;
    isCalculating!: boolean;
    isProjection!: boolean;
    lastBulkCalculatedDate?: string | undefined;
    lastBulkCalculatedByUser?: BaseUser | undefined;
    createdDate!: string;
    createdByUser?: BaseUser | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedByUser?: BaseUser | undefined;
    numberOfUnassignedUsers?: number | undefined;
    hasMismatchedContributionsUsers!: boolean;
    isStale!: boolean;
    projectedSavings!: number;
    ichraClasses?: IchraClass[] | undefined;

    constructor(data?: IPathwayBlueprintDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.name = _data["name"];
            this.year = _data["year"];
            this.isActive = _data["isActive"];
            this.isCalculating = _data["isCalculating"];
            this.isProjection = _data["isProjection"];
            this.lastBulkCalculatedDate = _data["lastBulkCalculatedDate"];
            this.lastBulkCalculatedByUser = _data["lastBulkCalculatedByUser"] ? BaseUser.fromJS(_data["lastBulkCalculatedByUser"]) : <any>undefined;
            this.createdDate = _data["createdDate"];
            this.createdByUser = _data["createdByUser"] ? BaseUser.fromJS(_data["createdByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? BaseUser.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
            this.numberOfUnassignedUsers = _data["numberOfUnassignedUsers"];
            this.hasMismatchedContributionsUsers = _data["hasMismatchedContributionsUsers"];
            this.isStale = _data["isStale"];
            this.projectedSavings = _data["projectedSavings"];
            if (Array.isArray(_data["ichraClasses"])) {
                this.ichraClasses = [] as any;
                for (let item of _data["ichraClasses"])
                    this.ichraClasses!.push(IchraClass.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PathwayBlueprintDto {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayBlueprintDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["name"] = this.name;
        data["year"] = this.year;
        data["isActive"] = this.isActive;
        data["isCalculating"] = this.isCalculating;
        data["isProjection"] = this.isProjection;
        data["lastBulkCalculatedDate"] = this.lastBulkCalculatedDate;
        data["lastBulkCalculatedByUser"] = this.lastBulkCalculatedByUser ? this.lastBulkCalculatedByUser.toJSON() : <any>undefined;
        data["createdDate"] = this.createdDate;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        data["numberOfUnassignedUsers"] = this.numberOfUnassignedUsers;
        data["hasMismatchedContributionsUsers"] = this.hasMismatchedContributionsUsers;
        data["isStale"] = this.isStale;
        data["projectedSavings"] = this.projectedSavings;
        if (Array.isArray(this.ichraClasses)) {
            data["ichraClasses"] = [];
            for (let item of this.ichraClasses)
                data["ichraClasses"].push(item.toJSON());
        }
        return data; 
    }
}
export class UserPathwayBlueprintDto implements IUserPathwayBlueprintDto {
    pathwayUserDto?: PathwayUserDto | undefined;
    pathwayBlueprintDto?: PathwayBlueprintDto | undefined;

    constructor(data?: IUserPathwayBlueprintDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.pathwayUserDto = _data["pathwayUserDto"] ? PathwayUserDto.fromJS(_data["pathwayUserDto"]) : <any>undefined;
            this.pathwayBlueprintDto = _data["pathwayBlueprintDto"] ? PathwayBlueprintDto.fromJS(_data["pathwayBlueprintDto"]) : <any>undefined;
        }
    }

    static fromJS(data: any): UserPathwayBlueprintDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserPathwayBlueprintDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["pathwayUserDto"] = this.pathwayUserDto ? this.pathwayUserDto.toJSON() : <any>undefined;
        data["pathwayBlueprintDto"] = this.pathwayBlueprintDto ? this.pathwayBlueprintDto.toJSON() : <any>undefined;
        return data; 
    }
}
export class PathwayUserDto implements IPathwayUserDto {
    id!: string;
    userId!: string;
    pathwayBlueprintId!: string;
    pathwayId?: string | undefined;
    planId?: string | undefined;
    extraBenefitText?: string | undefined;
    displayNameOverride?: string | undefined;
    planCost?: number | undefined;
    projectedWageUp?: number | undefined;
    customProjectedWageUp?: number | undefined;
    customProjectedWageUpNote?: string | undefined;
    projectedReimbursement?: number | undefined;
    customProjectedReimbursement?: number | undefined;
    customProjectedReimbursementNote?: string | undefined;
    employeeCost?: number | undefined;
    tags!: PathwayUserTags;
    chipCost?: number | undefined;
    medicareCost?: number | undefined;
    unclaimedHouseholdMemberCost?: number | undefined;
    additionalCost?: number | undefined;
    hasOtherCosts!: boolean;
    pathwayType?: PathwayTypes | undefined;
    pathwayName?: string | undefined;
    lastWageUpAffectedDate?: string | undefined;
    lastCalculatedDate?: string | undefined;
    lastCalculatedBy?: string | undefined;
    isStale!: boolean;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    ichraBenchmarkType?: PlanListing | undefined;
    useRestrategizedRenewalFlow!: boolean;
    hasMismatchedContributions!: boolean;

    constructor(data?: IPathwayUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.pathwayBlueprintId = _data["pathwayBlueprintId"];
            this.pathwayId = _data["pathwayId"];
            this.planId = _data["planId"];
            this.extraBenefitText = _data["extraBenefitText"];
            this.displayNameOverride = _data["displayNameOverride"];
            this.planCost = _data["planCost"];
            this.projectedWageUp = _data["projectedWageUp"];
            this.customProjectedWageUp = _data["customProjectedWageUp"];
            this.customProjectedWageUpNote = _data["customProjectedWageUpNote"];
            this.projectedReimbursement = _data["projectedReimbursement"];
            this.customProjectedReimbursement = _data["customProjectedReimbursement"];
            this.customProjectedReimbursementNote = _data["customProjectedReimbursementNote"];
            this.employeeCost = _data["employeeCost"];
            this.tags = _data["tags"];
            this.chipCost = _data["chipCost"];
            this.medicareCost = _data["medicareCost"];
            this.unclaimedHouseholdMemberCost = _data["unclaimedHouseholdMemberCost"];
            this.additionalCost = _data["additionalCost"];
            this.hasOtherCosts = _data["hasOtherCosts"];
            this.pathwayType = _data["pathwayType"];
            this.pathwayName = _data["pathwayName"];
            this.lastWageUpAffectedDate = _data["lastWageUpAffectedDate"];
            this.lastCalculatedDate = _data["lastCalculatedDate"];
            this.lastCalculatedBy = _data["lastCalculatedBy"];
            this.isStale = _data["isStale"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.ichraBenchmarkType = _data["ichraBenchmarkType"];
            this.useRestrategizedRenewalFlow = _data["useRestrategizedRenewalFlow"];
            this.hasMismatchedContributions = _data["hasMismatchedContributions"];
        }
    }

    static fromJS(data: any): PathwayUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["pathwayBlueprintId"] = this.pathwayBlueprintId;
        data["pathwayId"] = this.pathwayId;
        data["planId"] = this.planId;
        data["extraBenefitText"] = this.extraBenefitText;
        data["displayNameOverride"] = this.displayNameOverride;
        data["planCost"] = this.planCost;
        data["projectedWageUp"] = this.projectedWageUp;
        data["customProjectedWageUp"] = this.customProjectedWageUp;
        data["customProjectedWageUpNote"] = this.customProjectedWageUpNote;
        data["projectedReimbursement"] = this.projectedReimbursement;
        data["customProjectedReimbursement"] = this.customProjectedReimbursement;
        data["customProjectedReimbursementNote"] = this.customProjectedReimbursementNote;
        data["employeeCost"] = this.employeeCost;
        data["tags"] = this.tags;
        data["chipCost"] = this.chipCost;
        data["medicareCost"] = this.medicareCost;
        data["unclaimedHouseholdMemberCost"] = this.unclaimedHouseholdMemberCost;
        data["additionalCost"] = this.additionalCost;
        data["hasOtherCosts"] = this.hasOtherCosts;
        data["pathwayType"] = this.pathwayType;
        data["pathwayName"] = this.pathwayName;
        data["lastWageUpAffectedDate"] = this.lastWageUpAffectedDate;
        data["lastCalculatedDate"] = this.lastCalculatedDate;
        data["lastCalculatedBy"] = this.lastCalculatedBy;
        data["isStale"] = this.isStale;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["ichraBenchmarkType"] = this.ichraBenchmarkType;
        data["useRestrategizedRenewalFlow"] = this.useRestrategizedRenewalFlow;
        data["hasMismatchedContributions"] = this.hasMismatchedContributions;
        return data; 
    }
}
export class PathwayMarketplacePlanMigration implements IPathwayMarketplacePlanMigration {
    pathway?: Pathway | undefined;
    ichraClass?: IchraClass | undefined;
    nextYearPlans?: BasicMarketplacePlanDto[] | undefined;
    currentPlan?: BasicMarketplacePlanDto | undefined;
    crosswalk?: MarketplacePlanCrosswalk | undefined;

    constructor(data?: IPathwayMarketplacePlanMigration) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.pathway = _data["pathway"] ? Pathway.fromJS(_data["pathway"]) : <any>undefined;
            this.ichraClass = _data["ichraClass"] ? IchraClass.fromJS(_data["ichraClass"]) : <any>undefined;
            if (Array.isArray(_data["nextYearPlans"])) {
                this.nextYearPlans = [] as any;
                for (let item of _data["nextYearPlans"])
                    this.nextYearPlans!.push(BasicMarketplacePlanDto.fromJS(item));
            }
            this.currentPlan = _data["currentPlan"] ? BasicMarketplacePlanDto.fromJS(_data["currentPlan"]) : <any>undefined;
            this.crosswalk = _data["crosswalk"] ? MarketplacePlanCrosswalk.fromJS(_data["crosswalk"]) : <any>undefined;
        }
    }

    static fromJS(data: any): PathwayMarketplacePlanMigration {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayMarketplacePlanMigration();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["pathway"] = this.pathway ? this.pathway.toJSON() : <any>undefined;
        data["ichraClass"] = this.ichraClass ? this.ichraClass.toJSON() : <any>undefined;
        if (Array.isArray(this.nextYearPlans)) {
            data["nextYearPlans"] = [];
            for (let item of this.nextYearPlans)
                data["nextYearPlans"].push(item.toJSON());
        }
        data["currentPlan"] = this.currentPlan ? this.currentPlan.toJSON() : <any>undefined;
        data["crosswalk"] = this.crosswalk ? this.crosswalk.toJSON() : <any>undefined;
        return data; 
    }
}
export class MarketplacePlanCrosswalk implements IMarketplacePlanCrosswalk {
    newPlanId?: string | undefined;
    level?: string | undefined;
    reason?: string | undefined;
    isDiscontinued!: boolean;
    bronzeToSilver!: boolean;

    constructor(data?: IMarketplacePlanCrosswalk) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.newPlanId = _data["newPlanId"];
            this.level = _data["level"];
            this.reason = _data["reason"];
            this.isDiscontinued = _data["isDiscontinued"];
            this.bronzeToSilver = _data["bronzeToSilver"];
        }
    }

    static fromJS(data: any): MarketplacePlanCrosswalk {
        data = typeof data === 'object' ? data : {};
        let result = new MarketplacePlanCrosswalk();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["newPlanId"] = this.newPlanId;
        data["level"] = this.level;
        data["reason"] = this.reason;
        data["isDiscontinued"] = this.isDiscontinued;
        data["bronzeToSilver"] = this.bronzeToSilver;
        return data; 
    }
}
export class PathwayDecisionDto implements IPathwayDecisionDto {
    userId!: string;
    pathwayId!: string;
    decision!: PathwayDecisions;
    advisementScheduled!: boolean;
    requestedWageUp?: number | undefined;
    planData?: any | undefined;

    constructor(data?: IPathwayDecisionDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.pathwayId = _data["pathwayId"];
            this.decision = _data["decision"];
            this.advisementScheduled = _data["advisementScheduled"];
            this.requestedWageUp = _data["requestedWageUp"];
            this.planData = _data["planData"];
        }
    }

    static fromJS(data: any): PathwayDecisionDto {
        data = typeof data === 'object' ? data : {};
        let result = new PathwayDecisionDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["pathwayId"] = this.pathwayId;
        data["decision"] = this.decision;
        data["advisementScheduled"] = this.advisementScheduled;
        data["requestedWageUp"] = this.requestedWageUp;
        data["planData"] = this.planData;
        return data; 
    }
}
export class IchraOfferDto implements IIchraOfferDto {
    activeReimbursementAmount!: number;
    groupLevel!: GroupLevels;

    constructor(data?: IIchraOfferDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.activeReimbursementAmount = _data["activeReimbursementAmount"];
            this.groupLevel = _data["groupLevel"];
        }
    }

    static fromJS(data: any): IchraOfferDto {
        data = typeof data === 'object' ? data : {};
        let result = new IchraOfferDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["activeReimbursementAmount"] = this.activeReimbursementAmount;
        data["groupLevel"] = this.groupLevel;
        return data; 
    }
}
export class ValidateOtpCommand implements IValidateOtpCommand {
    mfaCode?: string | undefined;
    rememberDevice!: boolean;

    constructor(data?: IValidateOtpCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.mfaCode = _data["mfaCode"];
            this.rememberDevice = _data["rememberDevice"];
        }
    }

    static fromJS(data: any): ValidateOtpCommand {
        data = typeof data === 'object' ? data : {};
        let result = new ValidateOtpCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["mfaCode"] = this.mfaCode;
        data["rememberDevice"] = this.rememberDevice;
        return data; 
    }
}
export class CreateAccessTokenCommand implements ICreateAccessTokenCommand {
    code?: string | undefined;
    teamId!: string;

    constructor(data?: ICreateAccessTokenCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.code = _data["code"];
            this.teamId = _data["teamId"];
        }
    }

    static fromJS(data: any): CreateAccessTokenCommand {
        data = typeof data === 'object' ? data : {};
        let result = new CreateAccessTokenCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["code"] = this.code;
        data["teamId"] = this.teamId;
        return data; 
    }
}
export class FavoriteTeamDto implements IFavoriteTeamDto {
    id!: string;
    userId!: string;
    teamId!: string;
    teamName?: string | undefined;
    teamStateId!: TeamStateIds;
    activeDate?: string | undefined;
    isArchived!: boolean;
    adminUsers?: AdminUserDto[] | undefined;

    constructor(data?: IFavoriteTeamDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.teamId = _data["teamId"];
            this.teamName = _data["teamName"];
            this.teamStateId = _data["teamStateId"];
            this.activeDate = _data["activeDate"];
            this.isArchived = _data["isArchived"];
            if (Array.isArray(_data["adminUsers"])) {
                this.adminUsers = [] as any;
                for (let item of _data["adminUsers"])
                    this.adminUsers!.push(AdminUserDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): FavoriteTeamDto {
        data = typeof data === 'object' ? data : {};
        let result = new FavoriteTeamDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["teamId"] = this.teamId;
        data["teamName"] = this.teamName;
        data["teamStateId"] = this.teamStateId;
        data["activeDate"] = this.activeDate;
        data["isArchived"] = this.isArchived;
        if (Array.isArray(this.adminUsers)) {
            data["adminUsers"] = [];
            for (let item of this.adminUsers)
                data["adminUsers"].push(item.toJSON());
        }
        return data; 
    }
}
export class AdminUserDto implements IAdminUserDto {
    userId!: string;
    displayName?: string | undefined;
    isTeamAdmin!: boolean;
    isPayrollAdmin!: boolean;

    constructor(data?: IAdminUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.displayName = _data["displayName"];
            this.isTeamAdmin = _data["isTeamAdmin"];
            this.isPayrollAdmin = _data["isPayrollAdmin"];
        }
    }

    static fromJS(data: any): AdminUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new AdminUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["displayName"] = this.displayName;
        data["isTeamAdmin"] = this.isTeamAdmin;
        data["isPayrollAdmin"] = this.isPayrollAdmin;
        return data; 
    }
}
export class MemberBenefitsDto implements IMemberBenefitsDto {
    year!: number;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    userTeamBenefitTermDetails?: UserTeamBenefitTermDetailDto[] | undefined;
    selectedPlans?: SelectedPlan[] | undefined;

    constructor(data?: IMemberBenefitsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.year = _data["year"];
            this.majorMedicalBenefitCost = _data["majorMedicalBenefitCost"] ? MajorMedicalBenefitCostDto.fromJS(_data["majorMedicalBenefitCost"]) : <any>undefined;
            if (Array.isArray(_data["userTeamBenefitTermDetails"])) {
                this.userTeamBenefitTermDetails = [] as any;
                for (let item of _data["userTeamBenefitTermDetails"])
                    this.userTeamBenefitTermDetails!.push(UserTeamBenefitTermDetailDto.fromJS(item));
            }
            if (Array.isArray(_data["selectedPlans"])) {
                this.selectedPlans = [] as any;
                for (let item of _data["selectedPlans"])
                    this.selectedPlans!.push(SelectedPlan.fromJS(item));
            }
        }
    }

    static fromJS(data: any): MemberBenefitsDto {
        data = typeof data === 'object' ? data : {};
        let result = new MemberBenefitsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["year"] = this.year;
        data["majorMedicalBenefitCost"] = this.majorMedicalBenefitCost ? this.majorMedicalBenefitCost.toJSON() : <any>undefined;
        if (Array.isArray(this.userTeamBenefitTermDetails)) {
            data["userTeamBenefitTermDetails"] = [];
            for (let item of this.userTeamBenefitTermDetails)
                data["userTeamBenefitTermDetails"].push(item.toJSON());
        }
        if (Array.isArray(this.selectedPlans)) {
            data["selectedPlans"] = [];
            for (let item of this.selectedPlans)
                data["selectedPlans"].push(item.toJSON());
        }
        return data; 
    }
}
export class UserTeamBenefitTermDetailDto implements IUserTeamBenefitTermDetailDto {
    id!: string;
    userId!: string;
    coverageLevelId?: GroupLevels | undefined;
    statusId!: UserTeamBenefitTermDetailStatuses;
    coverageStartDate?: string | undefined;
    coverageEndDate?: string | undefined;
    teamBenefit?: TeamBenefitDto | undefined;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;

    constructor(data?: IUserTeamBenefitTermDetailDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.coverageLevelId = _data["coverageLevelId"];
            this.statusId = _data["statusId"];
            this.coverageStartDate = _data["coverageStartDate"];
            this.coverageEndDate = _data["coverageEndDate"];
            this.teamBenefit = _data["teamBenefit"] ? TeamBenefitDto.fromJS(_data["teamBenefit"]) : <any>undefined;
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): UserTeamBenefitTermDetailDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserTeamBenefitTermDetailDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["coverageLevelId"] = this.coverageLevelId;
        data["statusId"] = this.statusId;
        data["coverageStartDate"] = this.coverageStartDate;
        data["coverageEndDate"] = this.coverageEndDate;
        data["teamBenefit"] = this.teamBenefit ? this.teamBenefit.toJSON() : <any>undefined;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        return data; 
    }
}
export class PaginatedResultsDtoOfBasicSubmittedExpenseDto implements IPaginatedResultsDtoOfBasicSubmittedExpenseDto {
    results?: BasicSubmittedExpenseDto[] | undefined;
    pageSize!: number;
    page!: number;
    totalCount!: number;

    constructor(data?: IPaginatedResultsDtoOfBasicSubmittedExpenseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["results"])) {
                this.results = [] as any;
                for (let item of _data["results"])
                    this.results!.push(BasicSubmittedExpenseDto.fromJS(item));
            }
            this.pageSize = _data["pageSize"];
            this.page = _data["page"];
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): PaginatedResultsDtoOfBasicSubmittedExpenseDto {
        data = typeof data === 'object' ? data : {};
        let result = new PaginatedResultsDtoOfBasicSubmittedExpenseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.results)) {
            data["results"] = [];
            for (let item of this.results)
                data["results"].push(item.toJSON());
        }
        data["pageSize"] = this.pageSize;
        data["page"] = this.page;
        data["totalCount"] = this.totalCount;
        return data; 
    }
}
export class BasicSubmittedExpenseDto implements IBasicSubmittedExpenseDto {
    globalId!: string;
    expenseId?: string | undefined;
    userId!: string;
    teamId!: string;
    memberName?: string | undefined;
    teamName?: string | undefined;
    submittedDate!: string;
    expenseTypeId!: number;
    expenseType?: string | undefined;
    expenseStatus!: ExpenseStatus;
    dateOfService!: string;
    isArchived!: boolean;

    constructor(data?: IBasicSubmittedExpenseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.globalId = _data["globalId"];
            this.expenseId = _data["expenseId"];
            this.userId = _data["userId"];
            this.teamId = _data["teamId"];
            this.memberName = _data["memberName"];
            this.teamName = _data["teamName"];
            this.submittedDate = _data["submittedDate"];
            this.expenseTypeId = _data["expenseTypeId"];
            this.expenseType = _data["expenseType"];
            this.expenseStatus = _data["expenseStatus"];
            this.dateOfService = _data["dateOfService"];
            this.isArchived = _data["isArchived"];
        }
    }

    static fromJS(data: any): BasicSubmittedExpenseDto {
        data = typeof data === 'object' ? data : {};
        let result = new BasicSubmittedExpenseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["globalId"] = this.globalId;
        data["expenseId"] = this.expenseId;
        data["userId"] = this.userId;
        data["teamId"] = this.teamId;
        data["memberName"] = this.memberName;
        data["teamName"] = this.teamName;
        data["submittedDate"] = this.submittedDate;
        data["expenseTypeId"] = this.expenseTypeId;
        data["expenseType"] = this.expenseType;
        data["expenseStatus"] = this.expenseStatus;
        data["dateOfService"] = this.dateOfService;
        data["isArchived"] = this.isArchived;
        return data; 
    }
}
export class SubmittedExpenseDto extends BasicSubmittedExpenseDto implements ISubmittedExpenseDto {
    expenseFor?: string | undefined;
    expenseAmount!: number;
    expenseSource?: string | undefined;
    amountEligibleForReimbursement?: number | undefined;
    reimbursementToWages?: number | undefined;
    decisionSummary?: string | undefined;
    reimburseToHsa!: boolean;
    reimbursementToHsa?: number | undefined;
    files?: RhFileDto[] | undefined;
    useHsaReimbursement!: boolean;
    isHsaEligible!: boolean;
    hsaReimbursementSummary?: string | undefined;
    wagesReimbursementSummary?: string | undefined;
    isLocked!: boolean;
    reimbursementReportGlobalId?: string | undefined;
    isRecurring!: boolean;
    recurringAllowed!: boolean;

    constructor(data?: ISubmittedExpenseDto) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.expenseFor = _data["expenseFor"];
            this.expenseAmount = _data["expenseAmount"];
            this.expenseSource = _data["expenseSource"];
            this.amountEligibleForReimbursement = _data["amountEligibleForReimbursement"];
            this.reimbursementToWages = _data["reimbursementToWages"];
            this.decisionSummary = _data["decisionSummary"];
            this.reimburseToHsa = _data["reimburseToHsa"];
            this.reimbursementToHsa = _data["reimbursementToHsa"];
            if (Array.isArray(_data["files"])) {
                this.files = [] as any;
                for (let item of _data["files"])
                    this.files!.push(RhFileDto.fromJS(item));
            }
            this.useHsaReimbursement = _data["useHsaReimbursement"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.hsaReimbursementSummary = _data["hsaReimbursementSummary"];
            this.wagesReimbursementSummary = _data["wagesReimbursementSummary"];
            this.isLocked = _data["isLocked"];
            this.reimbursementReportGlobalId = _data["reimbursementReportGlobalId"];
            this.isRecurring = _data["isRecurring"];
            this.recurringAllowed = _data["recurringAllowed"];
        }
    }

    static fromJS(data: any): SubmittedExpenseDto {
        data = typeof data === 'object' ? data : {};
        let result = new SubmittedExpenseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["expenseFor"] = this.expenseFor;
        data["expenseAmount"] = this.expenseAmount;
        data["expenseSource"] = this.expenseSource;
        data["amountEligibleForReimbursement"] = this.amountEligibleForReimbursement;
        data["reimbursementToWages"] = this.reimbursementToWages;
        data["decisionSummary"] = this.decisionSummary;
        data["reimburseToHsa"] = this.reimburseToHsa;
        data["reimbursementToHsa"] = this.reimbursementToHsa;
        if (Array.isArray(this.files)) {
            data["files"] = [];
            for (let item of this.files)
                data["files"].push(item.toJSON());
        }
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        data["isHsaEligible"] = this.isHsaEligible;
        data["hsaReimbursementSummary"] = this.hsaReimbursementSummary;
        data["wagesReimbursementSummary"] = this.wagesReimbursementSummary;
        data["isLocked"] = this.isLocked;
        data["reimbursementReportGlobalId"] = this.reimbursementReportGlobalId;
        data["isRecurring"] = this.isRecurring;
        data["recurringAllowed"] = this.recurringAllowed;
        super.toJSON(data);
        return data; 
    }
}
export class CalculateReimbursementResponseDto implements ICalculateReimbursementResponseDto {
    hsaReimbursement!: number;
    wageReimbursement!: number;
    hsaReimbursementSummary?: string | undefined;
    wageReimbursementSummary?: string | undefined;
    reimburseToHsa!: boolean;

    constructor(data?: ICalculateReimbursementResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.hsaReimbursement = _data["hsaReimbursement"];
            this.wageReimbursement = _data["wageReimbursement"];
            this.hsaReimbursementSummary = _data["hsaReimbursementSummary"];
            this.wageReimbursementSummary = _data["wageReimbursementSummary"];
            this.reimburseToHsa = _data["reimburseToHsa"];
        }
    }

    static fromJS(data: any): CalculateReimbursementResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new CalculateReimbursementResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["hsaReimbursement"] = this.hsaReimbursement;
        data["wageReimbursement"] = this.wageReimbursement;
        data["hsaReimbursementSummary"] = this.hsaReimbursementSummary;
        data["wageReimbursementSummary"] = this.wageReimbursementSummary;
        data["reimburseToHsa"] = this.reimburseToHsa;
        return data; 
    }
}
export class ListReimbursementReportsResponseDto implements IListReimbursementReportsResponseDto {
    years?: number[] | undefined;
    reimbursementReports?: BasicReimbursementReportDto[] | undefined;

    constructor(data?: IListReimbursementReportsResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["years"])) {
                this.years = [] as any;
                for (let item of _data["years"])
                    this.years!.push(item);
            }
            if (Array.isArray(_data["reimbursementReports"])) {
                this.reimbursementReports = [] as any;
                for (let item of _data["reimbursementReports"])
                    this.reimbursementReports!.push(BasicReimbursementReportDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ListReimbursementReportsResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new ListReimbursementReportsResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.years)) {
            data["years"] = [];
            for (let item of this.years)
                data["years"].push(item);
        }
        if (Array.isArray(this.reimbursementReports)) {
            data["reimbursementReports"] = [];
            for (let item of this.reimbursementReports)
                data["reimbursementReports"].push(item.toJSON());
        }
        return data; 
    }
}
export class BasicReimbursementReportDto implements IBasicReimbursementReportDto {
    globalId!: string;
    teamId!: string;
    startDate!: string;
    endDate!: string;
    isShared!: boolean;
    termLabel?: string | undefined;

    constructor(data?: IBasicReimbursementReportDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.globalId = _data["globalId"];
            this.teamId = _data["teamId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.isShared = _data["isShared"];
            this.termLabel = _data["termLabel"];
        }
    }

    static fromJS(data: any): BasicReimbursementReportDto {
        data = typeof data === 'object' ? data : {};
        let result = new BasicReimbursementReportDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["globalId"] = this.globalId;
        data["teamId"] = this.teamId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["isShared"] = this.isShared;
        data["termLabel"] = this.termLabel;
        return data; 
    }
}
export class ReimbursementReportDto extends BasicReimbursementReportDto implements IReimbursementReportDto {
    reimbursementReportUsers?: ReimbursementReportUserDto[] | undefined;
    useHsaReimbursement!: boolean;

    constructor(data?: IReimbursementReportDto) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            if (Array.isArray(_data["reimbursementReportUsers"])) {
                this.reimbursementReportUsers = [] as any;
                for (let item of _data["reimbursementReportUsers"])
                    this.reimbursementReportUsers!.push(ReimbursementReportUserDto.fromJS(item));
            }
            this.useHsaReimbursement = _data["useHsaReimbursement"];
        }
    }

    static fromJS(data: any): ReimbursementReportDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementReportDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.reimbursementReportUsers)) {
            data["reimbursementReportUsers"] = [];
            for (let item of this.reimbursementReportUsers)
                data["reimbursementReportUsers"].push(item.toJSON());
        }
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        super.toJSON(data);
        return data; 
    }
}
export class ReimbursementReportUserDto implements IReimbursementReportUserDto {
    userId!: string;
    memberName?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    payrollSystemId?: string | undefined;
    submittedExpenses?: ReimbursementReportUserSubmittedExpenseDto[] | undefined;
    reportYear!: number;
    reportYearTotalReimbursementToHsa!: number;
    previousYearTotalReimbursementToHsa!: number;
    totalReimbursementToWages!: number;
    totalReimbursement!: number;

    constructor(data?: IReimbursementReportUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.memberName = _data["memberName"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.payrollSystemId = _data["payrollSystemId"];
            if (Array.isArray(_data["submittedExpenses"])) {
                this.submittedExpenses = [] as any;
                for (let item of _data["submittedExpenses"])
                    this.submittedExpenses!.push(ReimbursementReportUserSubmittedExpenseDto.fromJS(item));
            }
            this.reportYear = _data["reportYear"];
            this.reportYearTotalReimbursementToHsa = _data["reportYearTotalReimbursementToHsa"];
            this.previousYearTotalReimbursementToHsa = _data["previousYearTotalReimbursementToHsa"];
            this.totalReimbursementToWages = _data["totalReimbursementToWages"];
            this.totalReimbursement = _data["totalReimbursement"];
        }
    }

    static fromJS(data: any): ReimbursementReportUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementReportUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["memberName"] = this.memberName;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["payrollSystemId"] = this.payrollSystemId;
        if (Array.isArray(this.submittedExpenses)) {
            data["submittedExpenses"] = [];
            for (let item of this.submittedExpenses)
                data["submittedExpenses"].push(item.toJSON());
        }
        data["reportYear"] = this.reportYear;
        data["reportYearTotalReimbursementToHsa"] = this.reportYearTotalReimbursementToHsa;
        data["previousYearTotalReimbursementToHsa"] = this.previousYearTotalReimbursementToHsa;
        data["totalReimbursementToWages"] = this.totalReimbursementToWages;
        data["totalReimbursement"] = this.totalReimbursement;
        return data; 
    }
}
export class ReimbursementReportUserSubmittedExpenseDto implements IReimbursementReportUserSubmittedExpenseDto {
    expenseId?: string | undefined;
    expenseYear!: number;
    expenseType?: ExpenseType | undefined;
    reimburseToHsa!: boolean;
    reimbursementToWages!: number;
    reimbursementToHsa!: number;
    amountEligibleForReimbursement!: number;
    hsaReimbursementSummary?: string | undefined;
    wagesReimbursementSummary?: string | undefined;
    totalReimbursement!: number;

    constructor(data?: IReimbursementReportUserSubmittedExpenseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.expenseId = _data["expenseId"];
            this.expenseYear = _data["expenseYear"];
            this.expenseType = _data["expenseType"] ? ExpenseType.fromJS(_data["expenseType"]) : <any>undefined;
            this.reimburseToHsa = _data["reimburseToHsa"];
            this.reimbursementToWages = _data["reimbursementToWages"];
            this.reimbursementToHsa = _data["reimbursementToHsa"];
            this.amountEligibleForReimbursement = _data["amountEligibleForReimbursement"];
            this.hsaReimbursementSummary = _data["hsaReimbursementSummary"];
            this.wagesReimbursementSummary = _data["wagesReimbursementSummary"];
            this.totalReimbursement = _data["totalReimbursement"];
        }
    }

    static fromJS(data: any): ReimbursementReportUserSubmittedExpenseDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementReportUserSubmittedExpenseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["expenseId"] = this.expenseId;
        data["expenseYear"] = this.expenseYear;
        data["expenseType"] = this.expenseType ? this.expenseType.toJSON() : <any>undefined;
        data["reimburseToHsa"] = this.reimburseToHsa;
        data["reimbursementToWages"] = this.reimbursementToWages;
        data["reimbursementToHsa"] = this.reimbursementToHsa;
        data["amountEligibleForReimbursement"] = this.amountEligibleForReimbursement;
        data["hsaReimbursementSummary"] = this.hsaReimbursementSummary;
        data["wagesReimbursementSummary"] = this.wagesReimbursementSummary;
        data["totalReimbursement"] = this.totalReimbursement;
        return data; 
    }
}
export class ReimbursementReportsYearlyDto implements IReimbursementReportsYearlyDto {
    members?: MemberYearlyReimbursementDto[] | undefined;
    useHsaReimbursement!: boolean;

    constructor(data?: IReimbursementReportsYearlyDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["members"])) {
                this.members = [] as any;
                for (let item of _data["members"])
                    this.members!.push(MemberYearlyReimbursementDto.fromJS(item));
            }
            this.useHsaReimbursement = _data["useHsaReimbursement"];
        }
    }

    static fromJS(data: any): ReimbursementReportsYearlyDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursementReportsYearlyDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.members)) {
            data["members"] = [];
            for (let item of this.members)
                data["members"].push(item.toJSON());
        }
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        return data; 
    }
}
export class MemberYearlyReimbursementDto implements IMemberYearlyReimbursementDto {
    userId!: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    name?: string | undefined;
    reimbursementCoverageLevel?: GroupLevels | undefined;
    medicalAmountEligibleForReimbursement!: number;
    medicalReimbursementLimit!: number;
    counselingReimbursementLimit!: number;
    otherReimbursementLimits!: number;
    totalAmountEligibleForReimbursement!: number;
    totalReimbursementLimit!: number;
    totalReimbursementToWages!: number;
    totalReimbursementToHsa!: number;
    useHsaReimbursement!: boolean;
    grandTotalReimbursement!: number;

    constructor(data?: IMemberYearlyReimbursementDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.name = _data["name"];
            this.reimbursementCoverageLevel = _data["reimbursementCoverageLevel"];
            this.medicalAmountEligibleForReimbursement = _data["medicalAmountEligibleForReimbursement"];
            this.medicalReimbursementLimit = _data["medicalReimbursementLimit"];
            this.counselingReimbursementLimit = _data["counselingReimbursementLimit"];
            this.otherReimbursementLimits = _data["otherReimbursementLimits"];
            this.totalAmountEligibleForReimbursement = _data["totalAmountEligibleForReimbursement"];
            this.totalReimbursementLimit = _data["totalReimbursementLimit"];
            this.totalReimbursementToWages = _data["totalReimbursementToWages"];
            this.totalReimbursementToHsa = _data["totalReimbursementToHsa"];
            this.useHsaReimbursement = _data["useHsaReimbursement"];
            this.grandTotalReimbursement = _data["grandTotalReimbursement"];
        }
    }

    static fromJS(data: any): MemberYearlyReimbursementDto {
        data = typeof data === 'object' ? data : {};
        let result = new MemberYearlyReimbursementDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["name"] = this.name;
        data["reimbursementCoverageLevel"] = this.reimbursementCoverageLevel;
        data["medicalAmountEligibleForReimbursement"] = this.medicalAmountEligibleForReimbursement;
        data["medicalReimbursementLimit"] = this.medicalReimbursementLimit;
        data["counselingReimbursementLimit"] = this.counselingReimbursementLimit;
        data["otherReimbursementLimits"] = this.otherReimbursementLimits;
        data["totalAmountEligibleForReimbursement"] = this.totalAmountEligibleForReimbursement;
        data["totalReimbursementLimit"] = this.totalReimbursementLimit;
        data["totalReimbursementToWages"] = this.totalReimbursementToWages;
        data["totalReimbursementToHsa"] = this.totalReimbursementToHsa;
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        data["grandTotalReimbursement"] = this.grandTotalReimbursement;
        return data; 
    }
}
export class UserInfoToSubmitExpenseDto implements IUserInfoToSubmitExpenseDto {
    isEligibleForReimbursementProgram!: boolean;
    isHsaEligible!: boolean;
    useHsaForReimbursement!: boolean;
    expenseTypes?: ExpenseType[] | undefined;
    allowRecurring!: boolean;
    householdMembers?: BasicHouseholdMemberDto[] | undefined;
    isAtHsaContributionLimit!: boolean;

    constructor(data?: IUserInfoToSubmitExpenseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.isEligibleForReimbursementProgram = _data["isEligibleForReimbursementProgram"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.useHsaForReimbursement = _data["useHsaForReimbursement"];
            if (Array.isArray(_data["expenseTypes"])) {
                this.expenseTypes = [] as any;
                for (let item of _data["expenseTypes"])
                    this.expenseTypes!.push(ExpenseType.fromJS(item));
            }
            this.allowRecurring = _data["allowRecurring"];
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(BasicHouseholdMemberDto.fromJS(item));
            }
            this.isAtHsaContributionLimit = _data["isAtHsaContributionLimit"];
        }
    }

    static fromJS(data: any): UserInfoToSubmitExpenseDto {
        data = typeof data === 'object' ? data : {};
        let result = new UserInfoToSubmitExpenseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["isEligibleForReimbursementProgram"] = this.isEligibleForReimbursementProgram;
        data["isHsaEligible"] = this.isHsaEligible;
        data["useHsaForReimbursement"] = this.useHsaForReimbursement;
        if (Array.isArray(this.expenseTypes)) {
            data["expenseTypes"] = [];
            for (let item of this.expenseTypes)
                data["expenseTypes"].push(item.toJSON());
        }
        data["allowRecurring"] = this.allowRecurring;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        data["isAtHsaContributionLimit"] = this.isAtHsaContributionLimit;
        return data; 
    }
}
export class MemberReimbursementUtilizationDto implements IMemberReimbursementUtilizationDto {
    expenseTypeId!: number;
    coverageLevelId!: GroupLevels;
    individualMedicalResponsibility?: number | undefined;
    individualMedicalReimbursementAmount?: number | undefined;
    familyMedicalResponsibility?: number | undefined;
    familyMedicalReimbursementAmount?: number | undefined;
    medicalReimbursementType?: MedicalReimbursementTypes | undefined;
    individualReimbursementUsed?: number | undefined;
    individualReimbursementRemaining?: number | undefined;
    familyReimbursementUsed?: number | undefined;
    familyReimbursementRemaining?: number | undefined;
    householdMemberReimbursementUtilizations?: HouseholdMemberReimbursementUtilizationDto[] | undefined;
    counselingUsedVisits?: number | undefined;
    counselingMaximumVisits?: number | undefined;
    counselingPerVisitReimbursementAmount?: number | undefined;
    defaultReimbursementUsed?: number | undefined;
    defaultMaximumReimbursement?: number | undefined;
    totalHsaReimbursements!: number;
    hsaContributionLimitValue!: number;
    hsaContributionLimitEligibility!: HsaEligibility;
    useHsaReimbursement?: boolean | undefined;
    hasHsaUtilizations?: boolean | undefined;

    constructor(data?: IMemberReimbursementUtilizationDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.expenseTypeId = _data["expenseTypeId"];
            this.coverageLevelId = _data["coverageLevelId"];
            this.individualMedicalResponsibility = _data["individualMedicalResponsibility"];
            this.individualMedicalReimbursementAmount = _data["individualMedicalReimbursementAmount"];
            this.familyMedicalResponsibility = _data["familyMedicalResponsibility"];
            this.familyMedicalReimbursementAmount = _data["familyMedicalReimbursementAmount"];
            this.medicalReimbursementType = _data["medicalReimbursementType"];
            this.individualReimbursementUsed = _data["individualReimbursementUsed"];
            this.individualReimbursementRemaining = _data["individualReimbursementRemaining"];
            this.familyReimbursementUsed = _data["familyReimbursementUsed"];
            this.familyReimbursementRemaining = _data["familyReimbursementRemaining"];
            if (Array.isArray(_data["householdMemberReimbursementUtilizations"])) {
                this.householdMemberReimbursementUtilizations = [] as any;
                for (let item of _data["householdMemberReimbursementUtilizations"])
                    this.householdMemberReimbursementUtilizations!.push(HouseholdMemberReimbursementUtilizationDto.fromJS(item));
            }
            this.counselingUsedVisits = _data["counselingUsedVisits"];
            this.counselingMaximumVisits = _data["counselingMaximumVisits"];
            this.counselingPerVisitReimbursementAmount = _data["counselingPerVisitReimbursementAmount"];
            this.defaultReimbursementUsed = _data["defaultReimbursementUsed"];
            this.defaultMaximumReimbursement = _data["defaultMaximumReimbursement"];
            this.totalHsaReimbursements = _data["totalHsaReimbursements"];
            this.hsaContributionLimitValue = _data["hsaContributionLimitValue"];
            this.hsaContributionLimitEligibility = _data["hsaContributionLimitEligibility"];
            this.useHsaReimbursement = _data["useHsaReimbursement"];
            this.hasHsaUtilizations = _data["hasHsaUtilizations"];
        }
    }

    static fromJS(data: any): MemberReimbursementUtilizationDto {
        data = typeof data === 'object' ? data : {};
        let result = new MemberReimbursementUtilizationDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["expenseTypeId"] = this.expenseTypeId;
        data["coverageLevelId"] = this.coverageLevelId;
        data["individualMedicalResponsibility"] = this.individualMedicalResponsibility;
        data["individualMedicalReimbursementAmount"] = this.individualMedicalReimbursementAmount;
        data["familyMedicalResponsibility"] = this.familyMedicalResponsibility;
        data["familyMedicalReimbursementAmount"] = this.familyMedicalReimbursementAmount;
        data["medicalReimbursementType"] = this.medicalReimbursementType;
        data["individualReimbursementUsed"] = this.individualReimbursementUsed;
        data["individualReimbursementRemaining"] = this.individualReimbursementRemaining;
        data["familyReimbursementUsed"] = this.familyReimbursementUsed;
        data["familyReimbursementRemaining"] = this.familyReimbursementRemaining;
        if (Array.isArray(this.householdMemberReimbursementUtilizations)) {
            data["householdMemberReimbursementUtilizations"] = [];
            for (let item of this.householdMemberReimbursementUtilizations)
                data["householdMemberReimbursementUtilizations"].push(item.toJSON());
        }
        data["counselingUsedVisits"] = this.counselingUsedVisits;
        data["counselingMaximumVisits"] = this.counselingMaximumVisits;
        data["counselingPerVisitReimbursementAmount"] = this.counselingPerVisitReimbursementAmount;
        data["defaultReimbursementUsed"] = this.defaultReimbursementUsed;
        data["defaultMaximumReimbursement"] = this.defaultMaximumReimbursement;
        data["totalHsaReimbursements"] = this.totalHsaReimbursements;
        data["hsaContributionLimitValue"] = this.hsaContributionLimitValue;
        data["hsaContributionLimitEligibility"] = this.hsaContributionLimitEligibility;
        data["useHsaReimbursement"] = this.useHsaReimbursement;
        data["hasHsaUtilizations"] = this.hasHsaUtilizations;
        return data; 
    }
}
export class HouseholdMemberReimbursementUtilizationDto implements IHouseholdMemberReimbursementUtilizationDto {
    name?: string | undefined;
    reimbursementUsed!: number;

    constructor(data?: IHouseholdMemberReimbursementUtilizationDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.reimbursementUsed = _data["reimbursementUsed"];
        }
    }

    static fromJS(data: any): HouseholdMemberReimbursementUtilizationDto {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberReimbursementUtilizationDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["reimbursementUsed"] = this.reimbursementUsed;
        return data; 
    }
}
export class AssignableReportDto implements IAssignableReportDto {
    reimbursementReportGlobalId?: string | undefined;
    termLabel?: string | undefined;
    assignableReportOption!: AssignableReportOptions;
    startDate!: string;
    endDate!: string;

    constructor(data?: IAssignableReportDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.reimbursementReportGlobalId = _data["reimbursementReportGlobalId"];
            this.termLabel = _data["termLabel"];
            this.assignableReportOption = _data["assignableReportOption"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
        }
    }

    static fromJS(data: any): AssignableReportDto {
        data = typeof data === 'object' ? data : {};
        let result = new AssignableReportDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["reimbursementReportGlobalId"] = this.reimbursementReportGlobalId;
        data["termLabel"] = this.termLabel;
        data["assignableReportOption"] = this.assignableReportOption;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        return data; 
    }
}
export class AdpUserConfigurationDto implements IAdpUserConfigurationDto {
    userId!: string;
    isAdpSetWithholdingAndWageUp!: boolean;

    constructor(data?: IAdpUserConfigurationDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.isAdpSetWithholdingAndWageUp = _data["isAdpSetWithholdingAndWageUp"];
        }
    }

    static fromJS(data: any): AdpUserConfigurationDto {
        data = typeof data === 'object' ? data : {};
        let result = new AdpUserConfigurationDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["isAdpSetWithholdingAndWageUp"] = this.isAdpSetWithholdingAndWageUp;
        return data; 
    }
}
export class CcmEnrollmentInfo implements ICcmEnrollmentInfo {
    userId!: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    status?: UserStatus | undefined;
    enrollmentStatus?: EnrollmentStatuses | undefined;
    activeDate?: string | undefined;
    email?: string | undefined;
    socialSecurityNumber?: string | undefined;
    gender?: string | undefined;
    isMarried!: boolean;
    programType?: string | undefined;
    dateOfBirth?: string | undefined;
    ahp?: string | undefined;
    premium?: number | undefined;
    programEffectiveDate?: string | undefined;
    isPrimaryCovered?: boolean | undefined;
    phone?: string | undefined;
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    county?: string | undefined;
    householdMembers?: CcmEnrollmentInfoHouseholdMember[] | undefined;

    constructor(data?: ICcmEnrollmentInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.status = _data["status"];
            this.enrollmentStatus = _data["enrollmentStatus"];
            this.activeDate = _data["activeDate"];
            this.email = _data["email"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.gender = _data["gender"];
            this.isMarried = _data["isMarried"];
            this.programType = _data["programType"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.ahp = _data["ahp"];
            this.premium = _data["premium"];
            this.programEffectiveDate = _data["programEffectiveDate"];
            this.isPrimaryCovered = _data["isPrimaryCovered"];
            this.phone = _data["phone"];
            this.addressLine1 = _data["addressLine1"];
            this.addressLine2 = _data["addressLine2"];
            this.city = _data["city"];
            this.state = _data["state"];
            this.zip = _data["zip"];
            this.county = _data["county"];
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(CcmEnrollmentInfoHouseholdMember.fromJS(item));
            }
        }
    }

    static fromJS(data: any): CcmEnrollmentInfo {
        data = typeof data === 'object' ? data : {};
        let result = new CcmEnrollmentInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["status"] = this.status;
        data["enrollmentStatus"] = this.enrollmentStatus;
        data["activeDate"] = this.activeDate;
        data["email"] = this.email;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["gender"] = this.gender;
        data["isMarried"] = this.isMarried;
        data["programType"] = this.programType;
        data["dateOfBirth"] = this.dateOfBirth;
        data["ahp"] = this.ahp;
        data["premium"] = this.premium;
        data["programEffectiveDate"] = this.programEffectiveDate;
        data["isPrimaryCovered"] = this.isPrimaryCovered;
        data["phone"] = this.phone;
        data["addressLine1"] = this.addressLine1;
        data["addressLine2"] = this.addressLine2;
        data["city"] = this.city;
        data["state"] = this.state;
        data["zip"] = this.zip;
        data["county"] = this.county;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        return data; 
    }
}
export class CcmEnrollmentInfoHouseholdMember implements ICcmEnrollmentInfoHouseholdMember {
    firstName?: string | undefined;
    lastName?: string | undefined;
    socialSecurityNumber?: string | undefined;
    gender?: string | undefined;
    dateOfBirth!: string;

    constructor(data?: ICcmEnrollmentInfoHouseholdMember) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.socialSecurityNumber = _data["socialSecurityNumber"];
            this.gender = _data["gender"];
            this.dateOfBirth = _data["dateOfBirth"];
        }
    }

    static fromJS(data: any): CcmEnrollmentInfoHouseholdMember {
        data = typeof data === 'object' ? data : {};
        let result = new CcmEnrollmentInfoHouseholdMember();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["socialSecurityNumber"] = this.socialSecurityNumber;
        data["gender"] = this.gender;
        data["dateOfBirth"] = this.dateOfBirth;
        return data; 
    }
}
export class AddEnrollmentVerification extends EnrollmentVerification implements IAddEnrollmentVerification {
    householdMemberIds?: string[] | undefined;

    constructor(data?: IAddEnrollmentVerification) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            if (Array.isArray(_data["householdMemberIds"])) {
                this.householdMemberIds = [] as any;
                for (let item of _data["householdMemberIds"])
                    this.householdMemberIds!.push(item);
            }
        }
    }

    static fromJS(data: any): AddEnrollmentVerification {
        data = typeof data === 'object' ? data : {};
        let result = new AddEnrollmentVerification();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.householdMemberIds)) {
            data["householdMemberIds"] = [];
            for (let item of this.householdMemberIds)
                data["householdMemberIds"].push(item);
        }
        super.toJSON(data);
        return data; 
    }
}
export class County implements ICounty {
    name?: string | undefined;
    zipCode?: string | undefined;
    stateAbbrev?: string | undefined;
    fips?: string | undefined;

    constructor(data?: ICounty) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.zipCode = _data["zipCode"];
            this.stateAbbrev = _data["stateAbbrev"];
            this.fips = _data["fips"];
        }
    }

    static fromJS(data: any): County {
        data = typeof data === 'object' ? data : {};
        let result = new County();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["zipCode"] = this.zipCode;
        data["stateAbbrev"] = this.stateAbbrev;
        data["fips"] = this.fips;
        return data; 
    }
}
export class MarketplacePlanDto extends BasicMarketplacePlanDto implements IMarketplacePlanDto {
    planType?: string | undefined;
    benefitsUrl?: string | undefined;
    premium?: number | undefined;
    premiumWithCredits?: number | undefined;
    isCsrEligible!: boolean;
    hasError!: boolean;
    isIneligible!: boolean;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;

    constructor(data?: IMarketplacePlanDto) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.planType = _data["planType"];
            this.benefitsUrl = _data["benefitsUrl"];
            this.premium = _data["premium"];
            this.premiumWithCredits = _data["premiumWithCredits"];
            this.isCsrEligible = _data["isCsrEligible"];
            this.hasError = _data["hasError"];
            this.isIneligible = _data["isIneligible"];
            this.majorMedicalBenefitCost = _data["majorMedicalBenefitCost"] ? MajorMedicalBenefitCostDto.fromJS(_data["majorMedicalBenefitCost"]) : <any>undefined;
            this.reimbursementOverlayDto = _data["reimbursementOverlayDto"] ? ReimbursementOverlayDto.fromJS(_data["reimbursementOverlayDto"]) : <any>undefined;
        }
    }

    static fromJS(data: any): MarketplacePlanDto {
        data = typeof data === 'object' ? data : {};
        let result = new MarketplacePlanDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["planType"] = this.planType;
        data["benefitsUrl"] = this.benefitsUrl;
        data["premium"] = this.premium;
        data["premiumWithCredits"] = this.premiumWithCredits;
        data["isCsrEligible"] = this.isCsrEligible;
        data["hasError"] = this.hasError;
        data["isIneligible"] = this.isIneligible;
        data["majorMedicalBenefitCost"] = this.majorMedicalBenefitCost ? this.majorMedicalBenefitCost.toJSON() : <any>undefined;
        data["reimbursementOverlayDto"] = this.reimbursementOverlayDto ? this.reimbursementOverlayDto.toJSON() : <any>undefined;
        super.toJSON(data);
        return data; 
    }
}
export class PlanDetailsAndRatesRequest implements IPlanDetailsAndRatesRequest {
    planIds?: string[] | undefined;
    excludingMembers?: string[] | undefined;
    isOffExchange!: boolean;
    year!: number;
    overrideNeedsCoverage!: boolean;

    constructor(data?: IPlanDetailsAndRatesRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["planIds"])) {
                this.planIds = [] as any;
                for (let item of _data["planIds"])
                    this.planIds!.push(item);
            }
            if (Array.isArray(_data["excludingMembers"])) {
                this.excludingMembers = [] as any;
                for (let item of _data["excludingMembers"])
                    this.excludingMembers!.push(item);
            }
            this.isOffExchange = _data["isOffExchange"];
            this.year = _data["year"];
            this.overrideNeedsCoverage = _data["overrideNeedsCoverage"];
        }
    }

    static fromJS(data: any): PlanDetailsAndRatesRequest {
        data = typeof data === 'object' ? data : {};
        let result = new PlanDetailsAndRatesRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.planIds)) {
            data["planIds"] = [];
            for (let item of this.planIds)
                data["planIds"].push(item);
        }
        if (Array.isArray(this.excludingMembers)) {
            data["excludingMembers"] = [];
            for (let item of this.excludingMembers)
                data["excludingMembers"].push(item);
        }
        data["isOffExchange"] = this.isOffExchange;
        data["year"] = this.year;
        data["overrideNeedsCoverage"] = this.overrideNeedsCoverage;
        return data; 
    }
}
export class MedicaidDetails implements IMedicaidDetails {
    userId!: string;
    applicationDate?: string | undefined;
    applicationStatus?: MedicaidApplicationStatuses | undefined;
    eligibleFamilyMembers?: FamilyMemberTypes | undefined;
    lastTouchPointDate?: string | undefined;
    note?: string | undefined;
    isAuthFormSubmitted?: boolean | undefined;
    acceptDenyDate?: string | undefined;
    createdBy!: string;
    createdDate!: string;
    lastModifiedBy?: string | undefined;
    lastModifiedDate?: string | undefined;
    renewalDetails?: MedicaidRenewalDetails[] | undefined;
    keyId!: string;

    constructor(data?: IMedicaidDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.applicationDate = _data["applicationDate"];
            this.applicationStatus = _data["applicationStatus"];
            this.eligibleFamilyMembers = _data["eligibleFamilyMembers"];
            this.lastTouchPointDate = _data["lastTouchPointDate"];
            this.note = _data["note"];
            this.isAuthFormSubmitted = _data["isAuthFormSubmitted"];
            this.acceptDenyDate = _data["acceptDenyDate"];
            this.createdBy = _data["createdBy"];
            this.createdDate = _data["createdDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            if (Array.isArray(_data["renewalDetails"])) {
                this.renewalDetails = [] as any;
                for (let item of _data["renewalDetails"])
                    this.renewalDetails!.push(MedicaidRenewalDetails.fromJS(item));
            }
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): MedicaidDetails {
        data = typeof data === 'object' ? data : {};
        let result = new MedicaidDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["applicationDate"] = this.applicationDate;
        data["applicationStatus"] = this.applicationStatus;
        data["eligibleFamilyMembers"] = this.eligibleFamilyMembers;
        data["lastTouchPointDate"] = this.lastTouchPointDate;
        data["note"] = this.note;
        data["isAuthFormSubmitted"] = this.isAuthFormSubmitted;
        data["acceptDenyDate"] = this.acceptDenyDate;
        data["createdBy"] = this.createdBy;
        data["createdDate"] = this.createdDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        if (Array.isArray(this.renewalDetails)) {
            data["renewalDetails"] = [];
            for (let item of this.renewalDetails)
                data["renewalDetails"].push(item.toJSON());
        }
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class MedicaidRenewalDetails implements IMedicaidRenewalDetails {
    id!: string;
    userId!: string;
    year!: number;
    renewalSentDate?: string | undefined;
    renewalStatus?: MedicaidRenewalStatuses | undefined;

    constructor(data?: IMedicaidRenewalDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.userId = _data["userId"];
            this.year = _data["year"];
            this.renewalSentDate = _data["renewalSentDate"];
            this.renewalStatus = _data["renewalStatus"];
        }
    }

    static fromJS(data: any): MedicaidRenewalDetails {
        data = typeof data === 'object' ? data : {};
        let result = new MedicaidRenewalDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["userId"] = this.userId;
        data["year"] = this.year;
        data["renewalSentDate"] = this.renewalSentDate;
        data["renewalStatus"] = this.renewalStatus;
        return data; 
    }
}
export class MediSharePlan implements IMediSharePlan {
    ahp?: string | undefined;
    productDescription?: string | undefined;
    totalAmount!: number;
    originalTotalAmount!: number;
    userId!: string;
    hasError!: boolean;
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto | undefined;

    constructor(data?: IMediSharePlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.ahp = _data["ahp"];
            this.productDescription = _data["productDescription"];
            this.totalAmount = _data["totalAmount"];
            this.originalTotalAmount = _data["originalTotalAmount"];
            this.userId = _data["userId"];
            this.hasError = _data["hasError"];
            this.majorMedicalBenefitCost = _data["majorMedicalBenefitCost"] ? MajorMedicalBenefitCostDto.fromJS(_data["majorMedicalBenefitCost"]) : <any>undefined;
        }
    }

    static fromJS(data: any): MediSharePlan {
        data = typeof data === 'object' ? data : {};
        let result = new MediSharePlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["ahp"] = this.ahp;
        data["productDescription"] = this.productDescription;
        data["totalAmount"] = this.totalAmount;
        data["originalTotalAmount"] = this.originalTotalAmount;
        data["userId"] = this.userId;
        data["hasError"] = this.hasError;
        data["majorMedicalBenefitCost"] = this.majorMedicalBenefitCost ? this.majorMedicalBenefitCost.toJSON() : <any>undefined;
        return data; 
    }
}
export class PayrollReportDto implements IPayrollReportDto {
    id!: string;
    teamId!: string;
    year!: number;
    month!: number;
    isShared!: boolean;
    isLocked!: boolean;
    note?: string | undefined;
    expenseSummary?: ExpenseSummary | undefined;
    wageUpTotal!: number;
    ichraReimbursementTotal!: number;
    individualBenefitsTotal!: number;
    teamBenefitsTotal!: number;
    payrollReportUserSnapshots?: PayrollReportUserSnapshotDto[] | undefined;
    changes?: PayrollReportUserChangeLog[] | undefined;

    constructor(data?: IPayrollReportDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.year = _data["year"];
            this.month = _data["month"];
            this.isShared = _data["isShared"];
            this.isLocked = _data["isLocked"];
            this.note = _data["note"];
            this.expenseSummary = _data["expenseSummary"] ? ExpenseSummary.fromJS(_data["expenseSummary"]) : <any>undefined;
            this.wageUpTotal = _data["wageUpTotal"];
            this.ichraReimbursementTotal = _data["ichraReimbursementTotal"];
            this.individualBenefitsTotal = _data["individualBenefitsTotal"];
            this.teamBenefitsTotal = _data["teamBenefitsTotal"];
            if (Array.isArray(_data["payrollReportUserSnapshots"])) {
                this.payrollReportUserSnapshots = [] as any;
                for (let item of _data["payrollReportUserSnapshots"])
                    this.payrollReportUserSnapshots!.push(PayrollReportUserSnapshotDto.fromJS(item));
            }
            if (Array.isArray(_data["changes"])) {
                this.changes = [] as any;
                for (let item of _data["changes"])
                    this.changes!.push(PayrollReportUserChangeLog.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PayrollReportDto {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["year"] = this.year;
        data["month"] = this.month;
        data["isShared"] = this.isShared;
        data["isLocked"] = this.isLocked;
        data["note"] = this.note;
        data["expenseSummary"] = this.expenseSummary ? this.expenseSummary.toJSON() : <any>undefined;
        data["wageUpTotal"] = this.wageUpTotal;
        data["ichraReimbursementTotal"] = this.ichraReimbursementTotal;
        data["individualBenefitsTotal"] = this.individualBenefitsTotal;
        data["teamBenefitsTotal"] = this.teamBenefitsTotal;
        if (Array.isArray(this.payrollReportUserSnapshots)) {
            data["payrollReportUserSnapshots"] = [];
            for (let item of this.payrollReportUserSnapshots)
                data["payrollReportUserSnapshots"].push(item.toJSON());
        }
        if (Array.isArray(this.changes)) {
            data["changes"] = [];
            for (let item of this.changes)
                data["changes"].push(item.toJSON());
        }
        return data; 
    }
}
export class PayrollReportUserSnapshotDto implements IPayrollReportUserSnapshotDto {
    id!: string;
    payrollReportId!: string;
    userId?: string | undefined;
    payrollSystemId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    userStatus?: UserStatus | undefined;
    groupLevel!: GroupLevels;
    hsaEmployerContribution?: number | undefined;
    customHsaEmployerContribution?: number | undefined;
    fsaEmployerContribution?: number | undefined;
    customFsaEmployerContribution?: number | undefined;
    wageUp?: number | undefined;
    wageUpAdjustment?: number | undefined;
    wageUpTotal!: number;
    ichraReimbursement?: number | undefined;
    ichraReimbursementAdjustment?: number | undefined;
    ichraReimbursementTotal!: number;
    isHsaEligible?: boolean | undefined;
    preTaxWithholding!: number;
    preTaxWithholdingAdjustment?: number | undefined;
    preTaxWithholdingTotal!: number;
    postTaxWithholding!: number;
    postTaxWithholdingAdjustment?: number | undefined;
    postTaxWithholdingTotal!: number;
    taxFreeReimbursement?: number | undefined;
    taxFreeReimbursementAdjustment?: number | undefined;
    taxFreeReimbursementTotal!: number;
    lineFourteenCode?: LineFourteenCodes | undefined;
    lineSixteenCode?: LineSixteenCodes | undefined;
    lineSeventeenCode?: string | undefined;
    note?: string | undefined;
    noteLastModifiedDate?: string | undefined;
    noteLastModifiedByUser?: BaseUser | undefined;
    majorMedicalPlans?: PayrollReportUserSnapshotMajorMedicalPlan[] | undefined;
    postTaxWithholdings?: PostTaxWithholdingDto[] | undefined;
    preTaxWithholdings?: PreTaxWithholdingsDto[] | undefined;
    reimbursablePremiums?: ReimbursablePremiumDto[] | undefined;
    taxFreeReimbursements?: TaxFreeReimbursementsDto[] | undefined;
    reimbursementSummary?: ReimbursementSummary | undefined;
    isHrsAssociateIdSet!: boolean;

    constructor(data?: IPayrollReportUserSnapshotDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.payrollReportId = _data["payrollReportId"];
            this.userId = _data["userId"];
            this.payrollSystemId = _data["payrollSystemId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.userStatus = _data["userStatus"];
            this.groupLevel = _data["groupLevel"];
            this.hsaEmployerContribution = _data["hsaEmployerContribution"];
            this.customHsaEmployerContribution = _data["customHsaEmployerContribution"];
            this.fsaEmployerContribution = _data["fsaEmployerContribution"];
            this.customFsaEmployerContribution = _data["customFsaEmployerContribution"];
            this.wageUp = _data["wageUp"];
            this.wageUpAdjustment = _data["wageUpAdjustment"];
            this.wageUpTotal = _data["wageUpTotal"];
            this.ichraReimbursement = _data["ichraReimbursement"];
            this.ichraReimbursementAdjustment = _data["ichraReimbursementAdjustment"];
            this.ichraReimbursementTotal = _data["ichraReimbursementTotal"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.preTaxWithholding = _data["preTaxWithholding"];
            this.preTaxWithholdingAdjustment = _data["preTaxWithholdingAdjustment"];
            this.preTaxWithholdingTotal = _data["preTaxWithholdingTotal"];
            this.postTaxWithholding = _data["postTaxWithholding"];
            this.postTaxWithholdingAdjustment = _data["postTaxWithholdingAdjustment"];
            this.postTaxWithholdingTotal = _data["postTaxWithholdingTotal"];
            this.taxFreeReimbursement = _data["taxFreeReimbursement"];
            this.taxFreeReimbursementAdjustment = _data["taxFreeReimbursementAdjustment"];
            this.taxFreeReimbursementTotal = _data["taxFreeReimbursementTotal"];
            this.lineFourteenCode = _data["lineFourteenCode"];
            this.lineSixteenCode = _data["lineSixteenCode"];
            this.lineSeventeenCode = _data["lineSeventeenCode"];
            this.note = _data["note"];
            this.noteLastModifiedDate = _data["noteLastModifiedDate"];
            this.noteLastModifiedByUser = _data["noteLastModifiedByUser"] ? BaseUser.fromJS(_data["noteLastModifiedByUser"]) : <any>undefined;
            if (Array.isArray(_data["majorMedicalPlans"])) {
                this.majorMedicalPlans = [] as any;
                for (let item of _data["majorMedicalPlans"])
                    this.majorMedicalPlans!.push(PayrollReportUserSnapshotMajorMedicalPlan.fromJS(item));
            }
            if (Array.isArray(_data["postTaxWithholdings"])) {
                this.postTaxWithholdings = [] as any;
                for (let item of _data["postTaxWithholdings"])
                    this.postTaxWithholdings!.push(PostTaxWithholdingDto.fromJS(item));
            }
            if (Array.isArray(_data["preTaxWithholdings"])) {
                this.preTaxWithholdings = [] as any;
                for (let item of _data["preTaxWithholdings"])
                    this.preTaxWithholdings!.push(PreTaxWithholdingsDto.fromJS(item));
            }
            if (Array.isArray(_data["reimbursablePremiums"])) {
                this.reimbursablePremiums = [] as any;
                for (let item of _data["reimbursablePremiums"])
                    this.reimbursablePremiums!.push(ReimbursablePremiumDto.fromJS(item));
            }
            if (Array.isArray(_data["taxFreeReimbursements"])) {
                this.taxFreeReimbursements = [] as any;
                for (let item of _data["taxFreeReimbursements"])
                    this.taxFreeReimbursements!.push(TaxFreeReimbursementsDto.fromJS(item));
            }
            this.reimbursementSummary = _data["reimbursementSummary"] ? ReimbursementSummary.fromJS(_data["reimbursementSummary"]) : <any>undefined;
            this.isHrsAssociateIdSet = _data["isHrsAssociateIdSet"];
        }
    }

    static fromJS(data: any): PayrollReportUserSnapshotDto {
        data = typeof data === 'object' ? data : {};
        let result = new PayrollReportUserSnapshotDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["payrollReportId"] = this.payrollReportId;
        data["userId"] = this.userId;
        data["payrollSystemId"] = this.payrollSystemId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["userStatus"] = this.userStatus;
        data["groupLevel"] = this.groupLevel;
        data["hsaEmployerContribution"] = this.hsaEmployerContribution;
        data["customHsaEmployerContribution"] = this.customHsaEmployerContribution;
        data["fsaEmployerContribution"] = this.fsaEmployerContribution;
        data["customFsaEmployerContribution"] = this.customFsaEmployerContribution;
        data["wageUp"] = this.wageUp;
        data["wageUpAdjustment"] = this.wageUpAdjustment;
        data["wageUpTotal"] = this.wageUpTotal;
        data["ichraReimbursement"] = this.ichraReimbursement;
        data["ichraReimbursementAdjustment"] = this.ichraReimbursementAdjustment;
        data["ichraReimbursementTotal"] = this.ichraReimbursementTotal;
        data["isHsaEligible"] = this.isHsaEligible;
        data["preTaxWithholding"] = this.preTaxWithholding;
        data["preTaxWithholdingAdjustment"] = this.preTaxWithholdingAdjustment;
        data["preTaxWithholdingTotal"] = this.preTaxWithholdingTotal;
        data["postTaxWithholding"] = this.postTaxWithholding;
        data["postTaxWithholdingAdjustment"] = this.postTaxWithholdingAdjustment;
        data["postTaxWithholdingTotal"] = this.postTaxWithholdingTotal;
        data["taxFreeReimbursement"] = this.taxFreeReimbursement;
        data["taxFreeReimbursementAdjustment"] = this.taxFreeReimbursementAdjustment;
        data["taxFreeReimbursementTotal"] = this.taxFreeReimbursementTotal;
        data["lineFourteenCode"] = this.lineFourteenCode;
        data["lineSixteenCode"] = this.lineSixteenCode;
        data["lineSeventeenCode"] = this.lineSeventeenCode;
        data["note"] = this.note;
        data["noteLastModifiedDate"] = this.noteLastModifiedDate;
        data["noteLastModifiedByUser"] = this.noteLastModifiedByUser ? this.noteLastModifiedByUser.toJSON() : <any>undefined;
        if (Array.isArray(this.majorMedicalPlans)) {
            data["majorMedicalPlans"] = [];
            for (let item of this.majorMedicalPlans)
                data["majorMedicalPlans"].push(item.toJSON());
        }
        if (Array.isArray(this.postTaxWithholdings)) {
            data["postTaxWithholdings"] = [];
            for (let item of this.postTaxWithholdings)
                data["postTaxWithholdings"].push(item.toJSON());
        }
        if (Array.isArray(this.preTaxWithholdings)) {
            data["preTaxWithholdings"] = [];
            for (let item of this.preTaxWithholdings)
                data["preTaxWithholdings"].push(item.toJSON());
        }
        if (Array.isArray(this.reimbursablePremiums)) {
            data["reimbursablePremiums"] = [];
            for (let item of this.reimbursablePremiums)
                data["reimbursablePremiums"].push(item.toJSON());
        }
        if (Array.isArray(this.taxFreeReimbursements)) {
            data["taxFreeReimbursements"] = [];
            for (let item of this.taxFreeReimbursements)
                data["taxFreeReimbursements"].push(item.toJSON());
        }
        data["reimbursementSummary"] = this.reimbursementSummary ? this.reimbursementSummary.toJSON() : <any>undefined;
        data["isHrsAssociateIdSet"] = this.isHrsAssociateIdSet;
        return data; 
    }
}
export class PostTaxWithholdingDto implements IPostTaxWithholdingDto {
    id!: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount!: number;

    constructor(data?: IPostTaxWithholdingDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.selectedPlanId = _data["selectedPlanId"];
            this.benefitType = _data["benefitType"];
            this.amount = _data["amount"];
        }
    }

    static fromJS(data: any): PostTaxWithholdingDto {
        data = typeof data === 'object' ? data : {};
        let result = new PostTaxWithholdingDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["selectedPlanId"] = this.selectedPlanId;
        data["benefitType"] = this.benefitType;
        data["amount"] = this.amount;
        return data; 
    }
}
export class PreTaxWithholdingsDto implements IPreTaxWithholdingsDto {
    id!: string;
    sourceId?: string | undefined;
    sourceType!: SourceTypes;
    benefitType?: string | undefined;
    amount!: number;

    constructor(data?: IPreTaxWithholdingsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.sourceId = _data["sourceId"];
            this.sourceType = _data["sourceType"];
            this.benefitType = _data["benefitType"];
            this.amount = _data["amount"];
        }
    }

    static fromJS(data: any): PreTaxWithholdingsDto {
        data = typeof data === 'object' ? data : {};
        let result = new PreTaxWithholdingsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["sourceId"] = this.sourceId;
        data["sourceType"] = this.sourceType;
        data["benefitType"] = this.benefitType;
        data["amount"] = this.amount;
        return data; 
    }
}
export class ReimbursablePremiumDto implements IReimbursablePremiumDto {
    id!: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount!: number;
    adjustment?: number | undefined;
    total!: number;

    constructor(data?: IReimbursablePremiumDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.selectedPlanId = _data["selectedPlanId"];
            this.benefitType = _data["benefitType"];
            this.amount = _data["amount"];
            this.adjustment = _data["adjustment"];
            this.total = _data["total"];
        }
    }

    static fromJS(data: any): ReimbursablePremiumDto {
        data = typeof data === 'object' ? data : {};
        let result = new ReimbursablePremiumDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["selectedPlanId"] = this.selectedPlanId;
        data["benefitType"] = this.benefitType;
        data["amount"] = this.amount;
        data["adjustment"] = this.adjustment;
        data["total"] = this.total;
        return data; 
    }
}
export class TaxFreeReimbursementsDto implements ITaxFreeReimbursementsDto {
    id!: string;
    selectedPlanId?: string | undefined;
    benefitType?: string | undefined;
    amount!: number;

    constructor(data?: ITaxFreeReimbursementsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.selectedPlanId = _data["selectedPlanId"];
            this.benefitType = _data["benefitType"];
            this.amount = _data["amount"];
        }
    }

    static fromJS(data: any): TaxFreeReimbursementsDto {
        data = typeof data === 'object' ? data : {};
        let result = new TaxFreeReimbursementsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["selectedPlanId"] = this.selectedPlanId;
        data["benefitType"] = this.benefitType;
        data["amount"] = this.amount;
        return data; 
    }
}
export class EditReimbursablePremiumAdjustmentRequest implements IEditReimbursablePremiumAdjustmentRequest {
    adjustment?: number | undefined;
    note?: string | undefined;

    constructor(data?: IEditReimbursablePremiumAdjustmentRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.adjustment = _data["adjustment"];
            this.note = _data["note"];
        }
    }

    static fromJS(data: any): EditReimbursablePremiumAdjustmentRequest {
        data = typeof data === 'object' ? data : {};
        let result = new EditReimbursablePremiumAdjustmentRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["adjustment"] = this.adjustment;
        data["note"] = this.note;
        return data; 
    }
}
export class YearMonthDto implements IYearMonthDto {
    year!: number;
    month!: number;

    constructor(data?: IYearMonthDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.year = _data["year"];
            this.month = _data["month"];
        }
    }

    static fromJS(data: any): YearMonthDto {
        data = typeof data === 'object' ? data : {};
        let result = new YearMonthDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["year"] = this.year;
        data["month"] = this.month;
        return data; 
    }
}
export class Permission implements IPermission {
    permissionId?: string | undefined;
    name?: string | undefined;
    description?: string | undefined;
    hasPermission!: boolean;

    constructor(data?: IPermission) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.permissionId = _data["permissionId"];
            this.name = _data["name"];
            this.description = _data["description"];
            this.hasPermission = _data["hasPermission"];
        }
    }

    static fromJS(data: any): Permission {
        data = typeof data === 'object' ? data : {};
        let result = new Permission();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["permissionId"] = this.permissionId;
        data["name"] = this.name;
        data["description"] = this.description;
        data["hasPermission"] = this.hasPermission;
        return data; 
    }
}
export class PermissionRole implements IPermissionRole {
    permissionId?: string | undefined;
    roleId!: string;
    createdDate!: string;
    createdBy!: string;

    constructor(data?: IPermissionRole) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.permissionId = _data["permissionId"];
            this.roleId = _data["roleId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
        }
    }

    static fromJS(data: any): PermissionRole {
        data = typeof data === 'object' ? data : {};
        let result = new PermissionRole();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["permissionId"] = this.permissionId;
        data["roleId"] = this.roleId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        return data; 
    }
}
export class Resource implements IResource {
    id!: string;
    title?: string | undefined;
    url?: string | undefined;
    isSharedToAllMembers?: boolean | undefined;
    isSharedWithAllTeams?: boolean | undefined;
    isPinnedToTop!: boolean;
    teamResources?: TeamResource[] | undefined;
    sharingEndDate?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    createdByUser?: User | undefined;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    lastModifiedByUser?: User | undefined;
    shouldNotify!: boolean;
    keyId!: string;

    constructor(data?: IResource) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.title = _data["title"];
            this.url = _data["url"];
            this.isSharedToAllMembers = _data["isSharedToAllMembers"];
            this.isSharedWithAllTeams = _data["isSharedWithAllTeams"];
            this.isPinnedToTop = _data["isPinnedToTop"];
            if (Array.isArray(_data["teamResources"])) {
                this.teamResources = [] as any;
                for (let item of _data["teamResources"])
                    this.teamResources!.push(TeamResource.fromJS(item));
            }
            this.sharingEndDate = _data["sharingEndDate"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.createdByUser = _data["createdByUser"] ? User.fromJS(_data["createdByUser"]) : <any>undefined;
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.lastModifiedByUser = _data["lastModifiedByUser"] ? User.fromJS(_data["lastModifiedByUser"]) : <any>undefined;
            this.shouldNotify = _data["shouldNotify"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): Resource {
        data = typeof data === 'object' ? data : {};
        let result = new Resource();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["title"] = this.title;
        data["url"] = this.url;
        data["isSharedToAllMembers"] = this.isSharedToAllMembers;
        data["isSharedWithAllTeams"] = this.isSharedWithAllTeams;
        data["isPinnedToTop"] = this.isPinnedToTop;
        if (Array.isArray(this.teamResources)) {
            data["teamResources"] = [];
            for (let item of this.teamResources)
                data["teamResources"].push(item.toJSON());
        }
        data["sharingEndDate"] = this.sharingEndDate;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["createdByUser"] = this.createdByUser ? this.createdByUser.toJSON() : <any>undefined;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["lastModifiedByUser"] = this.lastModifiedByUser ? this.lastModifiedByUser.toJSON() : <any>undefined;
        data["shouldNotify"] = this.shouldNotify;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class TeamResource implements ITeamResource {
    id!: string;
    teamId!: string;
    team?: Team | undefined;
    resourceId!: string;

    constructor(data?: ITeamResource) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.teamId = _data["teamId"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.resourceId = _data["resourceId"];
        }
    }

    static fromJS(data: any): TeamResource {
        data = typeof data === 'object' ? data : {};
        let result = new TeamResource();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["teamId"] = this.teamId;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["resourceId"] = this.resourceId;
        return data; 
    }
}
export class Role implements IRole {
    roleId!: string;
    name?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;
    userCount!: number;
    keyId!: string;

    constructor(data?: IRole) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.roleId = _data["roleId"];
            this.name = _data["name"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
            this.userCount = _data["userCount"];
            this.keyId = _data["keyId"];
        }
    }

    static fromJS(data: any): Role {
        data = typeof data === 'object' ? data : {};
        let result = new Role();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["roleId"] = this.roleId;
        data["name"] = this.name;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        data["userCount"] = this.userCount;
        data["keyId"] = this.keyId;
        return data; 
    }
}
export class SearchResults implements ISearchResults {
    results?: SearchResult[] | undefined;
    totalCount!: number;

    constructor(data?: ISearchResults) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["results"])) {
                this.results = [] as any;
                for (let item of _data["results"])
                    this.results!.push(SearchResult.fromJS(item));
            }
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): SearchResults {
        data = typeof data === 'object' ? data : {};
        let result = new SearchResults();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.results)) {
            data["results"] = [];
            for (let item of this.results)
                data["results"].push(item.toJSON());
        }
        data["totalCount"] = this.totalCount;
        return data; 
    }
}
export class SearchResult implements ISearchResult {
    id!: string;
    name?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    metadata?: { [key: string]: string; } | undefined;
    type!: SearchResultType;

    constructor(data?: ISearchResult) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.name = _data["name"];
            this.phone = _data["phone"];
            this.email = _data["email"];
            if (_data["metadata"]) {
                this.metadata = {} as any;
                for (let key in _data["metadata"]) {
                    if (_data["metadata"].hasOwnProperty(key))
                        (<any>this.metadata)![key] = _data["metadata"][key];
                }
            }
            this.type = _data["type"];
        }
    }

    static fromJS(data: any): SearchResult {
        data = typeof data === 'object' ? data : {};
        let result = new SearchResult();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["phone"] = this.phone;
        data["email"] = this.email;
        if (this.metadata) {
            data["metadata"] = {};
            for (let key in this.metadata) {
                if (this.metadata.hasOwnProperty(key))
                    (<any>data["metadata"])[key] = this.metadata[key];
            }
        }
        data["type"] = this.type;
        return data; 
    }
}
export class RhPasswordOptions implements IRhPasswordOptions {
    requireDigit!: boolean;
    requireLowercase!: boolean;
    requireNonAlphanumeric!: boolean;
    requireUppercase!: boolean;
    requiredLength!: number;
    requiredUniqueChars!: number;
    lockoutAttemptThreshold!: number;
    lockoutDurationMinutes!: number;

    constructor(data?: IRhPasswordOptions) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.requireDigit = _data["requireDigit"];
            this.requireLowercase = _data["requireLowercase"];
            this.requireNonAlphanumeric = _data["requireNonAlphanumeric"];
            this.requireUppercase = _data["requireUppercase"];
            this.requiredLength = _data["requiredLength"];
            this.requiredUniqueChars = _data["requiredUniqueChars"];
            this.lockoutAttemptThreshold = _data["lockoutAttemptThreshold"];
            this.lockoutDurationMinutes = _data["lockoutDurationMinutes"];
        }
    }

    static fromJS(data: any): RhPasswordOptions {
        data = typeof data === 'object' ? data : {};
        let result = new RhPasswordOptions();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["requireDigit"] = this.requireDigit;
        data["requireLowercase"] = this.requireLowercase;
        data["requireNonAlphanumeric"] = this.requireNonAlphanumeric;
        data["requireUppercase"] = this.requireUppercase;
        data["requiredLength"] = this.requiredLength;
        data["requiredUniqueChars"] = this.requiredUniqueChars;
        data["lockoutAttemptThreshold"] = this.lockoutAttemptThreshold;
        data["lockoutDurationMinutes"] = this.lockoutDurationMinutes;
        return data; 
    }
}
export class SurveyInfo implements ISurveyInfo {
    userProfile?: UserProfile | undefined;
    incomeYear!: number;
    singleFilerStandardDeduction!: number;
    team?: Team | undefined;
    householdMembers?: SurveyHouseholdMemberDto[] | undefined;

    constructor(data?: ISurveyInfo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userProfile = _data["userProfile"] ? UserProfile.fromJS(_data["userProfile"]) : <any>undefined;
            this.incomeYear = _data["incomeYear"];
            this.singleFilerStandardDeduction = _data["singleFilerStandardDeduction"];
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(SurveyHouseholdMemberDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SurveyInfo {
        data = typeof data === 'object' ? data : {};
        let result = new SurveyInfo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userProfile"] = this.userProfile ? this.userProfile.toJSON() : <any>undefined;
        data["incomeYear"] = this.incomeYear;
        data["singleFilerStandardDeduction"] = this.singleFilerStandardDeduction;
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        return data; 
    }
}
export class SurveyHouseholdMemberDto implements ISurveyHouseholdMemberDto {
    householdMemberId!: string;
    householdMemberTypeId!: HouseholdMemberTypes;
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    preferredName?: string | undefined;
    gender?: string | undefined;
    address?: Address | undefined;
    needsCoverage!: boolean;
    isEmployed?: boolean | undefined;
    usesTobacco!: boolean;
    isPregnant?: boolean | undefined;
    hasParentOtherCoverage!: boolean;
    hasEmployerOtherCoverage!: boolean;
    employerName?: string | undefined;
    hasSameEmployer?: boolean | undefined;
    otherCoverageSource?: string | undefined;

    constructor(data?: ISurveyHouseholdMemberDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberId = _data["householdMemberId"];
            this.householdMemberTypeId = _data["householdMemberTypeId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.dateOfBirth = _data["dateOfBirth"];
            this.preferredName = _data["preferredName"];
            this.gender = _data["gender"];
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
            this.needsCoverage = _data["needsCoverage"];
            this.isEmployed = _data["isEmployed"];
            this.usesTobacco = _data["usesTobacco"];
            this.isPregnant = _data["isPregnant"];
            this.hasParentOtherCoverage = _data["hasParentOtherCoverage"];
            this.hasEmployerOtherCoverage = _data["hasEmployerOtherCoverage"];
            this.employerName = _data["employerName"];
            this.hasSameEmployer = _data["hasSameEmployer"];
            this.otherCoverageSource = _data["otherCoverageSource"];
        }
    }

    static fromJS(data: any): SurveyHouseholdMemberDto {
        data = typeof data === 'object' ? data : {};
        let result = new SurveyHouseholdMemberDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberId"] = this.householdMemberId;
        data["householdMemberTypeId"] = this.householdMemberTypeId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["dateOfBirth"] = this.dateOfBirth;
        data["preferredName"] = this.preferredName;
        data["gender"] = this.gender;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        data["needsCoverage"] = this.needsCoverage;
        data["isEmployed"] = this.isEmployed;
        data["usesTobacco"] = this.usesTobacco;
        data["isPregnant"] = this.isPregnant;
        data["hasParentOtherCoverage"] = this.hasParentOtherCoverage;
        data["hasEmployerOtherCoverage"] = this.hasEmployerOtherCoverage;
        data["employerName"] = this.employerName;
        data["hasSameEmployer"] = this.hasSameEmployer;
        data["otherCoverageSource"] = this.otherCoverageSource;
        return data; 
    }
}
export class SurveyAuthentication implements ISurveyAuthentication {
    token?: string | undefined;
    email?: string | undefined;

    constructor(data?: ISurveyAuthentication) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.token = _data["token"];
            this.email = _data["email"];
        }
    }

    static fromJS(data: any): SurveyAuthentication {
        data = typeof data === 'object' ? data : {};
        let result = new SurveyAuthentication();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["token"] = this.token;
        data["email"] = this.email;
        return data; 
    }
}
export class SendSurveyRequest implements ISendSurveyRequest {
    userId!: string;

    constructor(data?: ISendSurveyRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
        }
    }

    static fromJS(data: any): SendSurveyRequest {
        data = typeof data === 'object' ? data : {};
        let result = new SendSurveyRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        return data; 
    }
}
export class Survey implements ISurvey {
    userProfile?: UserProfile | undefined;
    householdMembers?: HouseholdMemberDto[] | undefined;
    qualitativeAnswers?: QualitativeAnswers | undefined;
    customQuestionAnswers?: CustomQuestionAnswer[] | undefined;
    token?: string | undefined;

    constructor(data?: ISurvey) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userProfile = _data["userProfile"] ? UserProfile.fromJS(_data["userProfile"]) : <any>undefined;
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(HouseholdMemberDto.fromJS(item));
            }
            this.qualitativeAnswers = _data["qualitativeAnswers"] ? QualitativeAnswers.fromJS(_data["qualitativeAnswers"]) : <any>undefined;
            if (Array.isArray(_data["customQuestionAnswers"])) {
                this.customQuestionAnswers = [] as any;
                for (let item of _data["customQuestionAnswers"])
                    this.customQuestionAnswers!.push(CustomQuestionAnswer.fromJS(item));
            }
            this.token = _data["token"];
        }
    }

    static fromJS(data: any): Survey {
        data = typeof data === 'object' ? data : {};
        let result = new Survey();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userProfile"] = this.userProfile ? this.userProfile.toJSON() : <any>undefined;
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        data["qualitativeAnswers"] = this.qualitativeAnswers ? this.qualitativeAnswers.toJSON() : <any>undefined;
        if (Array.isArray(this.customQuestionAnswers)) {
            data["customQuestionAnswers"] = [];
            for (let item of this.customQuestionAnswers)
                data["customQuestionAnswers"].push(item.toJSON());
        }
        data["token"] = this.token;
        return data; 
    }
}
export class TaskItem implements ITaskItem {
    taskItemId!: TaskItems;
    taskGroupId!: TaskGroups;
    order!: number;
    name?: string | undefined;
    description?: string | undefined;

    constructor(data?: ITaskItem) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.taskItemId = _data["taskItemId"];
            this.taskGroupId = _data["taskGroupId"];
            this.order = _data["order"];
            this.name = _data["name"];
            this.description = _data["description"];
        }
    }

    static fromJS(data: any): TaskItem {
        data = typeof data === 'object' ? data : {};
        let result = new TaskItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["taskItemId"] = this.taskItemId;
        data["taskGroupId"] = this.taskGroupId;
        data["order"] = this.order;
        data["name"] = this.name;
        data["description"] = this.description;
        return data; 
    }
}
export class CompletedTeamTask implements ICompletedTeamTask {
    teamId!: string;
    taskItemId!: TaskItems;
    taskItem?: TaskItem | undefined;

    constructor(data?: ICompletedTeamTask) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
            this.taskItemId = _data["taskItemId"];
            this.taskItem = _data["taskItem"] ? TaskItem.fromJS(_data["taskItem"]) : <any>undefined;
        }
    }

    static fromJS(data: any): CompletedTeamTask {
        data = typeof data === 'object' ? data : {};
        let result = new CompletedTeamTask();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        data["taskItemId"] = this.taskItemId;
        data["taskItem"] = this.taskItem ? this.taskItem.toJSON() : <any>undefined;
        return data; 
    }
}
export class CompletedUserTask implements ICompletedUserTask {
    userId!: string;
    taskItemId!: TaskItems;
    createdDate?: string | undefined;
    taskItem?: TaskItem | undefined;

    constructor(data?: ICompletedUserTask) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.taskItemId = _data["taskItemId"];
            this.createdDate = _data["createdDate"];
            this.taskItem = _data["taskItem"] ? TaskItem.fromJS(_data["taskItem"]) : <any>undefined;
        }
    }

    static fromJS(data: any): CompletedUserTask {
        data = typeof data === 'object' ? data : {};
        let result = new CompletedUserTask();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["taskItemId"] = this.taskItemId;
        data["createdDate"] = this.createdDate;
        data["taskItem"] = this.taskItem ? this.taskItem.toJSON() : <any>undefined;
        return data; 
    }
}
export class LaunchDetails implements ILaunchDetails {
    teamId!: string;
    advisementDeadlineDate!: string;
    meetingStartDateTime!: string;
    meetingEndDateTime!: string;
    meetingLink?: string | undefined;
    meetingPhoneNumber?: string | undefined;
    meetingConferenceId?: string | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: ILaunchDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
            this.advisementDeadlineDate = _data["advisementDeadlineDate"];
            this.meetingStartDateTime = _data["meetingStartDateTime"];
            this.meetingEndDateTime = _data["meetingEndDateTime"];
            this.meetingLink = _data["meetingLink"];
            this.meetingPhoneNumber = _data["meetingPhoneNumber"];
            this.meetingConferenceId = _data["meetingConferenceId"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): LaunchDetails {
        data = typeof data === 'object' ? data : {};
        let result = new LaunchDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        data["advisementDeadlineDate"] = this.advisementDeadlineDate;
        data["meetingStartDateTime"] = this.meetingStartDateTime;
        data["meetingEndDateTime"] = this.meetingEndDateTime;
        data["meetingLink"] = this.meetingLink;
        data["meetingPhoneNumber"] = this.meetingPhoneNumber;
        data["meetingConferenceId"] = this.meetingConferenceId;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class PaginatedResultsDtoOfTeamProfile implements IPaginatedResultsDtoOfTeamProfile {
    results?: TeamProfile[] | undefined;
    pageSize!: number;
    page!: number;
    totalCount!: number;

    constructor(data?: IPaginatedResultsDtoOfTeamProfile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["results"])) {
                this.results = [] as any;
                for (let item of _data["results"])
                    this.results!.push(TeamProfile.fromJS(item));
            }
            this.pageSize = _data["pageSize"];
            this.page = _data["page"];
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): PaginatedResultsDtoOfTeamProfile {
        data = typeof data === 'object' ? data : {};
        let result = new PaginatedResultsDtoOfTeamProfile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.results)) {
            data["results"] = [];
            for (let item of this.results)
                data["results"].push(item.toJSON());
        }
        data["pageSize"] = this.pageSize;
        data["page"] = this.page;
        data["totalCount"] = this.totalCount;
        return data; 
    }
}
export class TeamProfile implements ITeamProfile {
    team?: Team | undefined;
    address?: Address | undefined;
    createdBy?: string | undefined;
    adminEmail?: string | undefined;

    constructor(data?: ITeamProfile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.team = _data["team"] ? Team.fromJS(_data["team"]) : <any>undefined;
            this.address = _data["address"] ? Address.fromJS(_data["address"]) : <any>undefined;
            this.createdBy = _data["createdBy"];
            this.adminEmail = _data["adminEmail"];
        }
    }

    static fromJS(data: any): TeamProfile {
        data = typeof data === 'object' ? data : {};
        let result = new TeamProfile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["team"] = this.team ? this.team.toJSON() : <any>undefined;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        data["createdBy"] = this.createdBy;
        data["adminEmail"] = this.adminEmail;
        return data; 
    }
}
export class TeamPlan implements ITeamPlan {
    teamPlanId!: string;
    teamId!: string;
    carrier?: string | undefined;
    planName?: string | undefined;
    deductible?: number | undefined;
    familyDeductible?: number | undefined;
    outOfPocketMax?: number | undefined;
    familyOutOfPocketMax?: number | undefined;
    primaryVisitCopay?: number | undefined;
    specialistVisitCopay?: number | undefined;
    genericDrugsCopay?: number | undefined;
    createdDate!: string;
    createdBy!: string;
    lastModifiedDate?: string | undefined;
    lastModifiedBy?: string | undefined;

    constructor(data?: ITeamPlan) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamPlanId = _data["teamPlanId"];
            this.teamId = _data["teamId"];
            this.carrier = _data["carrier"];
            this.planName = _data["planName"];
            this.deductible = _data["deductible"];
            this.familyDeductible = _data["familyDeductible"];
            this.outOfPocketMax = _data["outOfPocketMax"];
            this.familyOutOfPocketMax = _data["familyOutOfPocketMax"];
            this.primaryVisitCopay = _data["primaryVisitCopay"];
            this.specialistVisitCopay = _data["specialistVisitCopay"];
            this.genericDrugsCopay = _data["genericDrugsCopay"];
            this.createdDate = _data["createdDate"];
            this.createdBy = _data["createdBy"];
            this.lastModifiedDate = _data["lastModifiedDate"];
            this.lastModifiedBy = _data["lastModifiedBy"];
        }
    }

    static fromJS(data: any): TeamPlan {
        data = typeof data === 'object' ? data : {};
        let result = new TeamPlan();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamPlanId"] = this.teamPlanId;
        data["teamId"] = this.teamId;
        data["carrier"] = this.carrier;
        data["planName"] = this.planName;
        data["deductible"] = this.deductible;
        data["familyDeductible"] = this.familyDeductible;
        data["outOfPocketMax"] = this.outOfPocketMax;
        data["familyOutOfPocketMax"] = this.familyOutOfPocketMax;
        data["primaryVisitCopay"] = this.primaryVisitCopay;
        data["specialistVisitCopay"] = this.specialistVisitCopay;
        data["genericDrugsCopay"] = this.genericDrugsCopay;
        data["createdDate"] = this.createdDate;
        data["createdBy"] = this.createdBy;
        data["lastModifiedDate"] = this.lastModifiedDate;
        data["lastModifiedBy"] = this.lastModifiedBy;
        return data; 
    }
}
export class TeamActionRequest implements ITeamActionRequest {
    teamId!: string;

    constructor(data?: ITeamActionRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamId = _data["teamId"];
        }
    }

    static fromJS(data: any): TeamActionRequest {
        data = typeof data === 'object' ? data : {};
        let result = new TeamActionRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamId"] = this.teamId;
        return data; 
    }
}
export class UserTeamRelationship implements IUserTeamRelationship {
    userTeamRelationshipId!: string;
    teamId!: string;
    userId!: string;
    user?: User | undefined;
    relationshipTypeId!: RelationshipTypeIds;
    relationshipType?: TeamRelationshipType | undefined;
    isOwner!: boolean;

    constructor(data?: IUserTeamRelationship) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTeamRelationshipId = _data["userTeamRelationshipId"];
            this.teamId = _data["teamId"];
            this.userId = _data["userId"];
            this.user = _data["user"] ? User.fromJS(_data["user"]) : <any>undefined;
            this.relationshipTypeId = _data["relationshipTypeId"];
            this.relationshipType = _data["relationshipType"] ? TeamRelationshipType.fromJS(_data["relationshipType"]) : <any>undefined;
            this.isOwner = _data["isOwner"];
        }
    }

    static fromJS(data: any): UserTeamRelationship {
        data = typeof data === 'object' ? data : {};
        let result = new UserTeamRelationship();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTeamRelationshipId"] = this.userTeamRelationshipId;
        data["teamId"] = this.teamId;
        data["userId"] = this.userId;
        data["user"] = this.user ? this.user.toJSON() : <any>undefined;
        data["relationshipTypeId"] = this.relationshipTypeId;
        data["relationshipType"] = this.relationshipType ? this.relationshipType.toJSON() : <any>undefined;
        data["isOwner"] = this.isOwner;
        return data; 
    }
}
export class TeamRelationshipType implements ITeamRelationshipType {
    teamRelationshipTypeId!: RelationshipTypeIds;
    name?: string | undefined;

    constructor(data?: ITeamRelationshipType) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.teamRelationshipTypeId = _data["teamRelationshipTypeId"];
            this.name = _data["name"];
        }
    }

    static fromJS(data: any): TeamRelationshipType {
        data = typeof data === 'object' ? data : {};
        let result = new TeamRelationshipType();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["teamRelationshipTypeId"] = this.teamRelationshipTypeId;
        data["name"] = this.name;
        return data; 
    }
}
export class EditTeamRelationshipRequest implements IEditTeamRelationshipRequest {
    userId?: string | undefined;
    relationshipTypeId!: RelationshipTypeIds;

    constructor(data?: IEditTeamRelationshipRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.relationshipTypeId = _data["relationshipTypeId"];
        }
    }

    static fromJS(data: any): EditTeamRelationshipRequest {
        data = typeof data === 'object' ? data : {};
        let result = new EditTeamRelationshipRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["relationshipTypeId"] = this.relationshipTypeId;
        return data; 
    }
}
export class UserCredentials implements IUserCredentials {
    email?: string | undefined;
    password?: string | undefined;

    constructor(data?: IUserCredentials) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.email = _data["email"];
            this.password = _data["password"];
        }
    }

    static fromJS(data: any): UserCredentials {
        data = typeof data === 'object' ? data : {};
        let result = new UserCredentials();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["email"] = this.email;
        data["password"] = this.password;
        return data; 
    }
}
export class TokenResponse implements ITokenResponse {
    token_type?: string | undefined;
    access_token?: string | undefined;
    scope?: string | undefined;
    expires_in!: number;

    constructor(data?: ITokenResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.token_type = _data["token_type"];
            this.access_token = _data["access_token"];
            this.scope = _data["scope"];
            this.expires_in = _data["expires_in"];
        }
    }

    static fromJS(data: any): TokenResponse {
        data = typeof data === 'object' ? data : {};
        let result = new TokenResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["token_type"] = this.token_type;
        data["access_token"] = this.access_token;
        data["scope"] = this.scope;
        data["expires_in"] = this.expires_in;
        return data; 
    }
}
export class ServiceUserDto implements IServiceUserDto {
    serviceUserId!: string;
    userId!: string;
    name?: string | undefined;
    appId?: string | undefined;
    primarySecret?: string | undefined;
    secondarySecret?: string | undefined;

    constructor(data?: IServiceUserDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.serviceUserId = _data["serviceUserId"];
            this.userId = _data["userId"];
            this.name = _data["name"];
            this.appId = _data["appId"];
            this.primarySecret = _data["primarySecret"];
            this.secondarySecret = _data["secondarySecret"];
        }
    }

    static fromJS(data: any): ServiceUserDto {
        data = typeof data === 'object' ? data : {};
        let result = new ServiceUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["serviceUserId"] = this.serviceUserId;
        data["userId"] = this.userId;
        data["name"] = this.name;
        data["appId"] = this.appId;
        data["primarySecret"] = this.primarySecret;
        data["secondarySecret"] = this.secondarySecret;
        return data; 
    }
}
export class WistiaVideo implements IWistiaVideo {
    name?: string | undefined;
    url?: string | undefined;
    hashed_id?: string | undefined;

    constructor(data?: IWistiaVideo) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.name = _data["name"];
            this.url = _data["url"];
            this.hashed_id = _data["hashed_id"];
        }
    }

    static fromJS(data: any): WistiaVideo {
        data = typeof data === 'object' ? data : {};
        let result = new WistiaVideo();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["url"] = this.url;
        data["hashed_id"] = this.hashed_id;
        return data; 
    }
}
export class AncillaryBenefitsDto implements IAncillaryBenefitsDto {
    ancillaryBenefits?: AncillaryBenefitInfoDto[] | undefined;

    constructor(data?: IAncillaryBenefitsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["ancillaryBenefits"])) {
                this.ancillaryBenefits = [] as any;
                for (let item of _data["ancillaryBenefits"])
                    this.ancillaryBenefits!.push(AncillaryBenefitInfoDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): AncillaryBenefitsDto {
        data = typeof data === 'object' ? data : {};
        let result = new AncillaryBenefitsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.ancillaryBenefits)) {
            data["ancillaryBenefits"] = [];
            for (let item of this.ancillaryBenefits)
                data["ancillaryBenefits"].push(item.toJSON());
        }
        return data; 
    }
}
export class AncillaryBenefitInfoDto implements IAncillaryBenefitInfoDto {
    userTermDetailId!: string;
    statusId!: UserTeamBenefitTermDetailStatuses;
    teamBenefitType!: TeamBenefitTypes;
    householdMemberIds?: string[] | undefined;

    constructor(data?: IAncillaryBenefitInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userTermDetailId = _data["userTermDetailId"];
            this.statusId = _data["statusId"];
            this.teamBenefitType = _data["teamBenefitType"];
            if (Array.isArray(_data["householdMemberIds"])) {
                this.householdMemberIds = [] as any;
                for (let item of _data["householdMemberIds"])
                    this.householdMemberIds!.push(item);
            }
        }
    }

    static fromJS(data: any): AncillaryBenefitInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new AncillaryBenefitInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userTermDetailId"] = this.userTermDetailId;
        data["statusId"] = this.statusId;
        data["teamBenefitType"] = this.teamBenefitType;
        if (Array.isArray(this.householdMemberIds)) {
            data["householdMemberIds"] = [];
            for (let item of this.householdMemberIds)
                data["householdMemberIds"].push(item);
        }
        return data; 
    }
}
export class HouseholdMemberInfoDto implements IHouseholdMemberInfoDto {
    householdMemberId?: string | undefined;
    householdMemberTypeId!: HouseholdMemberTypes;
    firstName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    birthdate!: string;
    gender?: string | undefined;
    usesTobacco!: boolean;
    sameEmployer?: boolean | undefined;
    address?: AddressDto | undefined;

    constructor(data?: IHouseholdMemberInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.householdMemberId = _data["householdMemberId"];
            this.householdMemberTypeId = _data["householdMemberTypeId"];
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.preferredName = _data["preferredName"];
            this.birthdate = _data["birthdate"];
            this.gender = _data["gender"];
            this.usesTobacco = _data["usesTobacco"];
            this.sameEmployer = _data["sameEmployer"];
            this.address = _data["address"] ? AddressDto.fromJS(_data["address"]) : <any>undefined;
        }
    }

    static fromJS(data: any): HouseholdMemberInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMemberInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["householdMemberId"] = this.householdMemberId;
        data["householdMemberTypeId"] = this.householdMemberTypeId;
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["preferredName"] = this.preferredName;
        data["birthdate"] = this.birthdate;
        data["gender"] = this.gender;
        data["usesTobacco"] = this.usesTobacco;
        data["sameEmployer"] = this.sameEmployer;
        data["address"] = this.address ? this.address.toJSON() : <any>undefined;
        return data; 
    }
}
export class AddressDto implements IAddressDto {
    addressLine1?: string | undefined;
    addressLine2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    county?: string | undefined;
    countyFips?: string | undefined;
    zip?: string | undefined;

    constructor(data?: IAddressDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.addressLine1 = _data["addressLine1"];
            this.addressLine2 = _data["addressLine2"];
            this.city = _data["city"];
            this.state = _data["state"];
            this.county = _data["county"];
            this.countyFips = _data["countyFips"];
            this.zip = _data["zip"];
        }
    }

    static fromJS(data: any): AddressDto {
        data = typeof data === 'object' ? data : {};
        let result = new AddressDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["addressLine1"] = this.addressLine1;
        data["addressLine2"] = this.addressLine2;
        data["city"] = this.city;
        data["state"] = this.state;
        data["county"] = this.county;
        data["countyFips"] = this.countyFips;
        data["zip"] = this.zip;
        return data; 
    }
}
export class HouseholdMembersDto implements IHouseholdMembersDto {
    householdMembers?: HouseholdMemberInfoDto[] | undefined;

    constructor(data?: IHouseholdMembersDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["householdMembers"])) {
                this.householdMembers = [] as any;
                for (let item of _data["householdMembers"])
                    this.householdMembers!.push(HouseholdMemberInfoDto.fromJS(item));
            }
        }
    }

    static fromJS(data: any): HouseholdMembersDto {
        data = typeof data === 'object' ? data : {};
        let result = new HouseholdMembersDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.householdMembers)) {
            data["householdMembers"] = [];
            for (let item of this.householdMembers)
                data["householdMembers"].push(item.toJSON());
        }
        return data; 
    }
}
export class MemberTermsDto implements IMemberTermsDto {
    signature?: string | undefined;
    downloadUrl?: string | undefined;

    constructor(data?: IMemberTermsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.signature = _data["signature"];
            this.downloadUrl = _data["downloadUrl"];
        }
    }

    static fromJS(data: any): MemberTermsDto {
        data = typeof data === 'object' ? data : {};
        let result = new MemberTermsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["signature"] = this.signature;
        data["downloadUrl"] = this.downloadUrl;
        return data; 
    }
}
export class PersonalInfoDto implements IPersonalInfoDto {
    firstName?: string | undefined;
    lastName?: string | undefined;
    preferredName?: string | undefined;
    birthdate!: string;
    gender?: string | undefined;
    phone?: string | undefined;
    usesTobacco!: boolean;

    constructor(data?: IPersonalInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
            this.preferredName = _data["preferredName"];
            this.birthdate = _data["birthdate"];
            this.gender = _data["gender"];
            this.phone = _data["phone"];
            this.usesTobacco = _data["usesTobacco"];
        }
    }

    static fromJS(data: any): PersonalInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new PersonalInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] = this.firstName;
        data["lastName"] = this.lastName;
        data["preferredName"] = this.preferredName;
        data["birthdate"] = this.birthdate;
        data["gender"] = this.gender;
        data["phone"] = this.phone;
        data["usesTobacco"] = this.usesTobacco;
        return data; 
    }
}
export class PlanInfoDto implements IPlanInfoDto {
    planId?: string | undefined;
    planStateId!: PlanStateIds;
    planTypeId!: PlanTypeIds;
    healthCareTypeId!: HealthCareTypeIds;
    planName?: string | undefined;
    issuerName?: string | undefined;
    isPrimaryCovered!: boolean;
    householdMembersCovered?: string[] | undefined;
    planPremium?: number | undefined;
    planPremiumWithCredits?: number | undefined;
    year!: number;
    isHsaEligible!: boolean;
    exchange?: string | undefined;
    state?: string | undefined;
    networkType?: string | undefined;
    benefitsLink?: string | undefined;
    familyDeductibleCost?: number | undefined;
    familyMoopCost?: number | undefined;
    individualDeductibleCost?: number | undefined;
    individualMoopCost?: number | undefined;
    genericDrugCost?: string | undefined;
    primaryCareCost?: string | undefined;
    specialistCost?: string | undefined;
    reimbursementOverlayDto?: ReimbursementOverlayDto | undefined;

    constructor(data?: IPlanInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.planId = _data["planId"];
            this.planStateId = _data["planStateId"];
            this.planTypeId = _data["planTypeId"];
            this.healthCareTypeId = _data["healthCareTypeId"];
            this.planName = _data["planName"];
            this.issuerName = _data["issuerName"];
            this.isPrimaryCovered = _data["isPrimaryCovered"];
            if (Array.isArray(_data["householdMembersCovered"])) {
                this.householdMembersCovered = [] as any;
                for (let item of _data["householdMembersCovered"])
                    this.householdMembersCovered!.push(item);
            }
            this.planPremium = _data["planPremium"];
            this.planPremiumWithCredits = _data["planPremiumWithCredits"];
            this.year = _data["year"];
            this.isHsaEligible = _data["isHsaEligible"];
            this.exchange = _data["exchange"];
            this.state = _data["state"];
            this.networkType = _data["networkType"];
            this.benefitsLink = _data["benefitsLink"];
            this.familyDeductibleCost = _data["familyDeductibleCost"];
            this.familyMoopCost = _data["familyMoopCost"];
            this.individualDeductibleCost = _data["individualDeductibleCost"];
            this.individualMoopCost = _data["individualMoopCost"];
            this.genericDrugCost = _data["genericDrugCost"];
            this.primaryCareCost = _data["primaryCareCost"];
            this.specialistCost = _data["specialistCost"];
            this.reimbursementOverlayDto = _data["reimbursementOverlayDto"] ? ReimbursementOverlayDto.fromJS(_data["reimbursementOverlayDto"]) : <any>undefined;
        }
    }

    static fromJS(data: any): PlanInfoDto {
        data = typeof data === 'object' ? data : {};
        let result = new PlanInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["planId"] = this.planId;
        data["planStateId"] = this.planStateId;
        data["planTypeId"] = this.planTypeId;
        data["healthCareTypeId"] = this.healthCareTypeId;
        data["planName"] = this.planName;
        data["issuerName"] = this.issuerName;
        data["isPrimaryCovered"] = this.isPrimaryCovered;
        if (Array.isArray(this.householdMembersCovered)) {
            data["householdMembersCovered"] = [];
            for (let item of this.householdMembersCovered)
                data["householdMembersCovered"].push(item);
        }
        data["planPremium"] = this.planPremium;
        data["planPremiumWithCredits"] = this.planPremiumWithCredits;
        data["year"] = this.year;
        data["isHsaEligible"] = this.isHsaEligible;
        data["exchange"] = this.exchange;
        data["state"] = this.state;
        data["networkType"] = this.networkType;
        data["benefitsLink"] = this.benefitsLink;
        data["familyDeductibleCost"] = this.familyDeductibleCost;
        data["familyMoopCost"] = this.familyMoopCost;
        data["individualDeductibleCost"] = this.individualDeductibleCost;
        data["individualMoopCost"] = this.individualMoopCost;
        data["genericDrugCost"] = this.genericDrugCost;
        data["primaryCareCost"] = this.primaryCareCost;
        data["specialistCost"] = this.specialistCost;
        data["reimbursementOverlayDto"] = this.reimbursementOverlayDto ? this.reimbursementOverlayDto.toJSON() : <any>undefined;
        return data; 
    }
}
export class PreferredProvidersDto implements IPreferredProvidersDto {
    providers?: Provider[] | undefined;

    constructor(data?: IPreferredProvidersDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["providers"])) {
                this.providers = [] as any;
                for (let item of _data["providers"])
                    this.providers!.push(Provider.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PreferredProvidersDto {
        data = typeof data === 'object' ? data : {};
        let result = new PreferredProvidersDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.providers)) {
            data["providers"] = [];
            for (let item of this.providers)
                data["providers"].push(item.toJSON());
        }
        return data; 
    }
}
export class PrescriptionsDto implements IPrescriptionsDto {
    drugs?: Drug[] | undefined;

    constructor(data?: IPrescriptionsDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["drugs"])) {
                this.drugs = [] as any;
                for (let item of _data["drugs"])
                    this.drugs!.push(Drug.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PrescriptionsDto {
        data = typeof data === 'object' ? data : {};
        let result = new PrescriptionsDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.drugs)) {
            data["drugs"] = [];
            for (let item of this.drugs)
                data["drugs"].push(item.toJSON());
        }
        return data; 
    }
}
export class SelectedPlansDto implements ISelectedPlansDto {
    planInfoDtos?: PlanInfoDto[] | undefined;
    isWaived!: boolean;

    constructor(data?: ISelectedPlansDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["planInfoDtos"])) {
                this.planInfoDtos = [] as any;
                for (let item of _data["planInfoDtos"])
                    this.planInfoDtos!.push(PlanInfoDto.fromJS(item));
            }
            this.isWaived = _data["isWaived"];
        }
    }

    static fromJS(data: any): SelectedPlansDto {
        data = typeof data === 'object' ? data : {};
        let result = new SelectedPlansDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.planInfoDtos)) {
            data["planInfoDtos"] = [];
            for (let item of this.planInfoDtos)
                data["planInfoDtos"].push(item.toJSON());
        }
        data["isWaived"] = this.isWaived;
        return data; 
    }
}
export class StringResponseDto implements IStringResponseDto {
    value?: string | undefined;

    constructor(data?: IStringResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.value = _data["value"];
        }
    }

    static fromJS(data: any): StringResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new StringResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["value"] = this.value;
        return data; 
    }
}
export class YourJobDto implements IYourJobDto {
    jobTitle?: string | undefined;
    hireDate!: string;

    constructor(data?: IYourJobDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.jobTitle = _data["jobTitle"];
            this.hireDate = _data["hireDate"];
        }
    }

    static fromJS(data: any): YourJobDto {
        data = typeof data === 'object' ? data : {};
        let result = new YourJobDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["jobTitle"] = this.jobTitle;
        data["hireDate"] = this.hireDate;
        return data; 
    }
}
